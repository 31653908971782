/* eslint-disable react/no-array-index-key */
import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AlertDialog from 'pages-preorder/components/Modal/Alert/AlertDialog';
import MyModal from 'pages-preorder/components/Modal';
import { setAuthenticated } from '@store/reducers/pre-order/auth';
import userService from '@services/preorder/user';
import { setAlert, setPreorderError } from '@store/reducers/pre-order/app';
import error2Text from '@utils/error2Text';
import { Formatter } from '@utils/formatter';

const CustomTypography = ({ content }) => {
  const bulletPoints = content.split('\n').map((point) => point.trim());

  return (
    <Typography
      sx={{
        mt: 1,
        '& > div': {
          display: 'flex',
          alignItems: 'baseline',
          marginBottom: '12px'
        },
        // '&  span::before': {
        //   content: '"• "',
        //   marginRight: '0.5em',
        //   color: '#9A9CA4',
        //   fontSize: 14
        // },
        '& p': {
          lineHeight: '1.5',
          marginLeft: '4px',
          fontSize: 14,
          fontWeight: 500,
          letterSpacing: '-0.5px',
          color: '#000',
          fontFamily: 'Pretendard',
          whiteSpace: 'pre-line',
          wordBreak: 'keep-all', // Prevent breaking words
          overflowWrap: 'break-word'
        }
      }}
    >
      {bulletPoints.map((point, index) => (
        <div key={index}>
          <span>{index + 1}.</span>
          <p>{point}</p>
        </div>
      ))}
    </Typography>
  );
};

const MyProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { version } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const [alertDialog, setAlertDialog] = useState({ open: false });
  const [minibarList, setMinibarList] = useState();
  const [selectedMinibar, setSelectedMinibar] = useState(null);
  const [userData, setUserData] = useState({
    hasChecked: false
  });

  const [dialog, setDialog] = useState({
    open: false
  });

  const handleLogout = () => {
    localStorage.removeItem('PREORDER_TOKEN');
    localStorage.removeItem('PREORDER_REFRESH_TOKEN');
    localStorage.removeItem('PREORDER_SESSION_ID');

    localStorage.removeItem('PREORDER_MENU_STORE');
    localStorage.removeItem('PREORDER_ORDER_ORIGINAL_PATH');
    localStorage.removeItem('PREORDER_CARD_ORIGINAL_PATH');
    localStorage.removeItem('PREORDER_NEW_ORDER_DATA');
    dispatch(setAuthenticated(false));
    navigate('/pre-order/ko/auth');
  };

  const fetchUserData = async () => {
    try {
      const rs = await userService.getUserData();

      if (!rs.error) {
        setUserData({ ...rs?.data });
      }
    } catch (error) {
      console.error('Error fetching store data:', error);
    }
  };

  const handleCancelMember = async () => {
    const rs = await userService.cancelUser();

    if (!rs.error) {
      setAlertDialog({ open: false });
      dispatch(setAlert({ message: '회원탈퇴가 완료되었습니다.' }));
      setTimeout(() => {
        localStorage.removeItem('PREORDER_TOKEN');
        localStorage.removeItem('PREORDER_REFRESH_TOKEN');
        localStorage.removeItem('PREORDER_SESSION_ID');
        dispatch(setAuthenticated(false));
        navigate('/pre-order/ko/auth');
      }, 100);
    } else {
      setAlertDialog({ open: false });
      dispatch(setPreorderError(error2Text(rs?.error)));
    }
  };
  useEffect(() => {
    fetchUserData();
  }, [location?.search]);

  return (
    <Stack
      sx={{
        flexDirection: 'column',
        width: '100%',
        maxWidth: '500px',
        flexGrow: 1,
        minHeight: '100%',
        position: 'relative'
      }}
    >
      <Stack
        sx={{
          width: '100%',
          maxWidth: '500px',
          top: 0,
          position: 'fixed',
          backgroundColor: '#FFFFFF',
          zIndex: 1002
        }}
      >
        <Stack
          id="navBarHeader"
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            backgroundColor: '#FFFFFF',
            width: '100%',
            py: '13px',
            px: 2.5,
            position: 'relative',
            height: { xs: '50px' }
          }}
        >
          <Stack sx={{ position: 'absolute', left: '20px' }}>
            <IconButton
              size="small"
              sx={{ p: 0 }}
              onClick={() => navigate(`/pre-order/${version}/my-page`)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
              >
                <path d="M7 1L1 7L7 13" stroke="black" />
              </svg>
            </IconButton>
          </Stack>

          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: 1
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 700,
                color: '#202020',
                letterSpacing: '-0.56px'
              }}
            >
              마이페이지
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          width: '100%',
          pt: 2.5,
          px: 2.5,
          mt: '50px',
          backgroundColor: '#FFFFFF',
          borderBottom: '1px solid #E7E7E7'
        }}
      >
        <Stack sx={{ pb: 2.5 }}>
          <Stack sx={{ width: '100%', pb: 2.5 }}>
            <Typography
              sx={{
                fontSize: 14,
                letterSpacing: '-0.56px',
                fontWeight: 700,
                color: '#202020'
              }}
            >
              기본정보
            </Typography>
          </Stack>
          {/* contact */}
          <Stack
            sx={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '6px',
              py: 2
            }}
            onClick={() => setDialog({ ...dialog, open: true })}
          >
            <Typography
              sx={{
                fontSize: 14,
                letterSpacing: '-0.56px',
                fontWeight: 500,
                color: '#9A9CA4'
              }}
            >
              이름
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                letterSpacing: '-0.56px',
                fontWeight: 500,
                color: '#202020'
              }}
            >
              {userData?.fullName}
            </Typography>
          </Stack>
          {/* contact */}
          <Stack
            sx={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '6px',
              py: 2
            }}
            onClick={() => setDialog({ ...dialog, open: true })}
          >
            <Typography
              sx={{
                fontSize: 14,
                letterSpacing: '-0.56px',
                fontWeight: 500,
                color: '#9A9CA4'
              }}
            >
              전화번호
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                letterSpacing: '-0.56px',
                fontWeight: 500,
                color: '#202020'
              }}
            >
              {Formatter.formatKoreanPhoneNumber(userData?.contact)}
            </Typography>
          </Stack>
          {/* contact */}
          <Stack
            sx={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '6px',
              py: 2
            }}
            onClick={() => setDialog({ ...dialog, open: true })}
          >
            <Typography
              sx={{
                fontSize: 14,
                letterSpacing: '-0.56px',
                fontWeight: 500,
                color: '#9A9CA4'
              }}
            >
              이메일
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                letterSpacing: '-0.56px',
                fontWeight: 500,
                color: '#202020'
              }}
            >
              {userData?.email}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ width: '100%', flexGrow: 1, backgroundColor: '#F2F2F2' }}>
        <Stack
          sx={{
            width: '100%',
            flexDirection: 'row',
            px: 2.5,
            py: 2.5,
            alignItems: 'center',
            justifyContent: 'center',
            gap: 5
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            onClick={() =>
              setAlertDialog({
                ...alertDialog,
                open: true,
                title: null,
                onOk: () => handleLogout(),
                subtitle: '로그아웃 하시겠습니까?',
                okTitle: '로그아웃',
                cancelTitle: '취소'
              })
            }
          >
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 500,
                color: '#9A9CA4',
                letterSpacing: '-0.48px',
                textDecoration: 'underline'
              }}
            >
              로그아웃
            </Typography>
          </Stack>
          {/* divider */}
          <Divider
            flexItem
            orientation="vertical"
            sx={{
              color: '#D6D7DB',
              borderColor: '#D6D7DB !important',
              backgroundColor: '#D6D7DB'
            }}
          />
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            onClick={() =>
              setDialog({
                ...dialog,
                open: true,
                title: null,
                subtitle: '로그아웃 하시겠습니까?',
                okTitle: '변경',
                cancelTitle: '취소'
              })
            }
          >
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 500,
                color: '#9A9CA4',
                letterSpacing: '-0.48px'
              }}
            >
              회원탈퇴
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <AlertDialog
        isAlert
        open={alertDialog.open && alertDialog?.type !== 'error'}
        setOpen={(open) => setAlertDialog({ ...alertDialog, open })}
        onOk={alertDialog.onOk}
        okTitle={alertDialog?.okTitle || '취소'}
        title={alertDialog.title}
        titleStyle={{ textAlign: 'center' }}
        cancelTitle={alertDialog?.cancelTitle || '닫기'}
        type={alertDialog?.type}
        subTitle={alertDialog?.subtitle}
        hasCancelButton
      />

      <MyModal
        open={dialog?.open}
        setOpen={() => setDialog({ ...dialog, open: false, title: '' })}
        title="회원탈퇴"
        titleStyle={{ height: '50px' }}
        hasBackButton
        hideDialogButton
      >
        <Stack sx={{ width: '100%', height: '100%' }}>
          <Stack sx={{ width: '100%', height: '100%' }}>
            <CustomTypography
              content={t('pages.pre-order.delete-account-policy')}
            />
          </Stack>
          <Stack sx={{ width: '100%', mb: '10px', gap: 2.5 }}>
            <Stack
              sx={{
                width: '100%',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 1
              }}
              onClick={() =>
                setUserData((prev) => ({
                  ...userData,
                  hasChecked: !prev?.hasChecked
                }))
              }
            >
              {userData?.hasChecked ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect width="24" height="24" fill="white" />
                  <rect
                    x="3"
                    y="3"
                    width="18"
                    height="18"
                    rx="4"
                    fill="#1B2872"
                  />
                  <path d="M7 11.4541L10.1298 15L17 9" stroke="white" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect width="24" height="24" fill="white" />
                  <rect
                    x="3"
                    y="3"
                    width="18"
                    height="18"
                    rx="4"
                    fill="#DADBDD"
                  />
                  <path d="M7 11.4541L10.1298 15L17 9" stroke="white" />
                </svg>
              )}
              <Typography
                sx={{
                  fontSize: 14,
                  color: '#202020',
                  fontWeight: 500,
                  letterSpacing: '-0.56px'
                }}
              >
                위 내용을 모두 확인하였습니다.
              </Typography>
            </Stack>
            <Button
              onClick={() => {
                setAlertDialog({
                  ...alertDialog,
                  open: true,
                  title: null,
                  subtitle: t('pages.pre-order.alert.cancel-account'),
                  cancelTitle: '취소',
                  okTitle: '탈퇴',
                  onOk: () => handleCancelMember()
                });
              }}
              variant="contained"
              sx={{
                minWidth: '90px',
                backgroundColor: !userData?.hasChecked
                  ? '#DADBDD !important'
                  : '#1B2872 !important',

                maxWidth: '500px',
                borderRadius: 1,
                height: 48,
                color: '#FFFFFF !important',
                fontSize: 16,
                fontWeight: 500,
                letterSpacing: '-0.64px'
              }}
              // loading={loading}
              disabled={!userData?.hasChecked}
            >
              회원탈퇴
            </Button>
          </Stack>
        </Stack>
      </MyModal>

      {minibarList?.length > 0 && (
        <MyModal
          open={dialog.open}
          setOpen={() => {}}
          title={t('pages.pre-order.auth.pickup-select-title')}
          titleStyle={{ height: '50px' }}
          subTitle={t('pages.pre-order.auth.pickup-select-subtitle')}
          onOk={() => {
            setAlertDialog({ ...alertDialog, open: true });
          }}
          okTitle={t('button.save')}
          hideDialogButton={false}
          fullScreen
        >
          {minibarList?.length < 4 ? (
            <Stack sx={{ width: '100%', gap: '30px' }}>
              {' '}
              <Typography
                sx={{
                  fontSize: 12,
                  color: '#202020',
                  letterSpacing: '-0.48px',
                  whiteSpace: 'pre-line',
                  wordBreak: 'keep-all', // Prevent breaking words
                  overflowWrap: 'break-word', // Handle long words
                  fontWeight: 500
                }}
              >
                {t('pages.pre-order.auth.pickup-select-subtitle')}
              </Typography>{' '}
              <Stack sx={{ width: '100%', alignItems: 'flex-start', gap: 1 }}>
                <Typography
                  sx={{
                    fontSize: 10,
                    color: '#202020',
                    fontWeight: 500,
                    letterSpacing: '-0.4px',
                    whiteSpace: 'pre-line'
                  }}
                >
                  {t('pages.pre-order.auth.select-main-branch')}
                </Typography>{' '}
                <Stack sx={{ width: '100%', gap: '14px' }}>
                  {minibarList?.map((item, index) => (
                    <Stack
                      key={`${item?.minibarName}-${item?.minibarCode}`}
                      sx={{
                        width: '100%',
                        borderRadius: 1,
                        py: '10px',
                        textAlign: 'center',
                        outline:
                          item?.minibarCode === selectedMinibar?.minibarCode &&
                          '1px solid #1B2872',
                        backgroundColor:
                          item?.minibarCode === selectedMinibar?.minibarCode
                            ? '#E9EBF6'
                            : '#F6F6F6'
                      }}
                      onClick={() => setSelectedMinibar(item)}
                    >
                      <Typography
                        sx={{
                          fontSize: 12,
                          color:
                            item?.minibarCode ===
                            setSelectedMinibar?.minibarCode
                              ? '#1B2872'
                              : '#9A9CA4',
                          letterSpacing: '-0.48px',
                          fontWeight: 500,
                          whiteSpace: 'pre-line'
                        }}
                      >
                        {item?.minibarName}
                      </Typography>{' '}
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <Stack />
          )}
        </MyModal>
      )}
    </Stack>
  );
};

export default MyProfile;
