import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

export default function AlertDialog({
  open,
  setOpen,
  title = 'Your Title',
  children,
  onOk,
  onCancel,
  okTitle,
  cancelTitle,
  hasCancelButton,
  isAlert = false,
  hasCloseButton,
  hideDialogButton = false,
  badgeCount,
  sx,
  type,
  subTitle,
  disabled,
  onClose,
  titleStyle,
  ...rest
}) {
  const { t } = useTranslation(null, { keyPrefix: 'button' });

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };
  const handleOk = () => {
    setOpen(false);
    if (onOk) onOk();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          zIndex: 0,
          borderRadius: '8px',
          width: isAlert ? 'auto' : 'unset',
          minWidth: '288px',
          maxWidth: '90vw',
          p: {
            xs: '20px'
          },
          ...sx
        }
      }}
      slotProps={{
        backdrop: { sx: { bgcolor: '#32313166', opacity: '0.4 !important' } }
      }}
      {...rest}
    >
      <DialogTitle
        sx={{
          color: '#202020',
          fontSize: {
            xs: '14px !important'
          },
          textAlign: isAlert ? 'left' : 'center',
          py: 0,
          px: isAlert ? 0 : 6,
          whiteSpace: isAlert ? 'wrap' : 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontWeight: 700,
          mb: isAlert ? 0 : '20px',
          ...titleStyle
        }}
      >
        {typeof title === 'function' ? title() : title}
        {!isAlert && hasCloseButton ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: { md: 22, sm: 18, xs: 18 },
              top: { md: 22, sm: 14, xs: 12 },
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
        {subTitle && typeof subTitle === 'function' ? (
          subTitle()
        ) : (
          <Typography
            sx={{
              fontSize: 14,
              color: '#202020',
              fontWeight: 500,
              letterSpacing: '-0.56px',
              whiteSpace: 'pre-line',
              mt: 1.5
            }}
          >
            {subTitle}
          </Typography>
        )}
      </DialogTitle>
      {children && <DialogContent sx={{ p: 0 }}>{children}</DialogContent>}
      {!hideDialogButton && (
        <DialogActions
          sx={{
            justifyContent: 'center',
            mt: { md: '30px', sm: '15px', xs: '20px' },
            p: 0,
            gap: '5px'
          }}
        >
          {hasCancelButton && (
            <Button
              autoFocus
              onClick={onCancel || handleClose}
              variant="outlined"
              sx={{
                borderRadius: '8px',
                height: { md: '40px', xs: '36px' },
                minWidth: { md: '80px', xs: '90px' },
                border: '1px solid #D6D7DB;',
                boxShadow: 0,
                ml: '0px !important',
                backgroundColor: '#FFFFFF',
                color: '#D6D7DB',
                ':hover': {
                  boxShadow: 0,
                  border: '1px solid #D6D7DB;',
                  backgroundColor: '#FFFFFF',
                  color: '#D6D7DB'
                },
                fontSize: 14,
                letterSpacing: '-0.56px',
                fontWeight: 500
              }}
            >
              {cancelTitle || t('cancel')}
            </Button>
          )}

          <Button
            timeout={onOk ? 500 : 0}
            onClick={handleOk || handleClose}
            variant="contained"
            sx={{
              height: { md: '40px', xs: '36px' },
              minWidth: { md: '80px', xs: '90px' },
              borderRadius: 1,
              boxShadow: 0,
              border: 0,
              backgroundColor: !['delete'].includes(type)
                ? '#1B2872'
                : '#F25D5D',
              color: '#FFFFFF',
              ml: '0px !important',
              ':hover': {
                border: 0,
                boxShadow: 0,
                backgroundColor: !['delete'].includes(type)
                  ? '#1B2872'
                  : '#F25D5D',
                color: '#FFFFFF'
              },
              fontSize: 14,
              letterSpacing: '-0.56px',
              fontWeight: 500
            }}
            disabled={disabled}
          >
            {okTitle || t('check')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
