/* eslint-disable react/no-array-index-key */
import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper/modules';

const useStyles = makeStyles({
  mySwiper: {
    height: '100%',
    width: '100%',
    overflowY: 'visible',
    alignItems: 'center',
    justifyContent: 'center',
    '& .swiper-wrapper': {
      width: '100%',
      height: '100%',
      overflowY: 'visible',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start'
    }
  },

  mySwiperSlide: {
    width: '100%',
    height: '100%'
  }
});
const Categories = ({ categoryList, currentcategory, onChange }) => {
  const [swiper, setSwiper] = useState(null);
  const classes = useStyles();
  return (
    <Stack
      sx={{ gap: 1, mt: '50px', pl: 2.5, width: '100%', alignItems: 'center' }}
    >
      <Stack
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <Stack
          sx={{
            width: {
              xs: `100%`
            },
            height: 'auto',
            alignItems: 'center',
            py: '20px'
          }}
        >
          <Swiper
            onSwiper={(swiperInstance) => {
              setSwiper(swiperInstance);
            }}
            spaceBetween={8}
            slidesPerView="auto"
            freeMode
            className={classes?.mySwiper}
            modules={[FreeMode]}
          >
            {categoryList &&
              categoryList.map((category, idx) => (
                <SwiperSlide
                  className={classes.mySwiperSlide}
                  key={idx}
                  style={{ width: 'auto' }}
                  onClick={() => onChange(category)} // Handle store type click
                >
                  {({ isActive }) => (
                    <Stack
                      sx={{
                        display: 'flex',
                        zIndex: 150000,
                        backgroundColor:
                          currentcategory?.productCategoryCode ===
                          category?.productCategoryCode
                            ? '#1B2872'
                            : '#FFFFFF',
                        border:
                          currentcategory?.productCategoryCode ===
                          category?.productCategoryCode
                            ? '1px solid #1B2872'
                            : '1px solid #DADBDD',
                        borderRadius: '20px 0px 20px 20px',
                        p: '7px 20px 6px 20px'
                      }}
                    >
                      <Typography
                        sx={{
                          whiteSpace: 'nowrap',
                          fontSize: 14,
                          letterSpacing: '-0.56px',
                          fontWeight: 500,
                          color:
                            currentcategory?.productCategoryCode !==
                            category?.productCategoryCode
                              ? '#DADBDD'
                              : '#FFFFFF'
                        }}
                      >
                        {category?.categoryName}
                      </Typography>
                    </Stack>
                  )}
                </SwiperSlide>
              ))}
          </Swiper>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Categories;
