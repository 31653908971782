import i18n from '@utils/i18n';

const t = i18n.getFixedT(null, null, '');

export const statuses = {
  ORDER_COMPLETE: 'ORDERED',
  ORDER_CONFIRM: 'CONFIRMED',
  PICK_UP_REQUEST: 'PICKUP_REQUESTED',
  COMPLETED: 'PICKUP_COMPLETED',
  CANCELED: 'CANCELLED',
  FIXED: 'FIXED'
};

export const statusParser = {
  [statuses.ORDER_COMPLETE]: '주문완료',
  [statuses.ORDER_CONFIRM]: '주문완료',
  [statuses.FIXED]: '구매확정',
  [statuses.PICK_UP_REQUEST]: '픽업요청',
  [statuses.COMPLETED]: '완료',
  [statuses.CANCELED]: '취소'
};
export const days = [
  { value: 'MONDAY', label: t('dayOfWeekShort.monday') },
  { value: 'TUESDAY', label: t('dayOfWeekShort.tuesday') },
  { value: 'WEDNESDAY', label: t('dayOfWeekShort.wednesday') },
  { value: 'THURSDAY', label: t('dayOfWeekShort.thursday') },
  { value: 'FRIDAY', label: t('dayOfWeekShort.friday') },
  { value: 'SATURDAY', label: t('dayOfWeekShort.saturday') },
  { value: 'SUNDAY', label: t('dayOfWeekShort.sunday') }
];

export const orderStatuses = [
  {
    value: 'all',
    label: t('common.total')
  },
  {
    value: statuses.ORDER_COMPLETE,
    label: '주문완료'
  },
  { value: statuses.FIXED, label: '구매확정' },
  { value: statuses.PICK_UP_REQUEST, label: '픽업요청' },
  { value: statuses.COMPLETED, label: '완료' },
  { value: statuses.CANCELED, label: '취소' }
];

export const orderStatusStyles = {
  [statuses.ORDER_COMPLETE]: {
    backgroundColor: '#202020',
    color: '#FFFFFF',
    notificationColor: '#FFFFFF',
    border: '0px'
  },
  [statuses.ORDER_CONFIRM]: {
    backgroundColor: '#202020',
    color: '#FFFFFF',
    notificationColor: '#FFFFFF',
    border: '0px'
  },
  [statuses.FIXED]: {
    backgroundColor: '#FFFFFF',
    color: '#202020',
    notificationColor: '#FFFFFF',
    border: '1px solid #000000'
  },
  [statuses.PICK_UP_REQUEST]: {
    backgroundColor: '#BEC4EA',
    color: '#1B2872',
    notificationColor: '#FFFFFF',
    border: '1px solid #1B2872'
  },
  [statuses.COMPLETED]: {
    backgroundColor: '#1B2872',
    color: '#FFFFFF',
    notificationColor: '#FFFFFF',
    border: '0px solid #1B2872'
  },
  [statuses.CANCELED]: {
    backgroundColor: '#F25D5D',
    color: '#FFFFFF',
    notificationColor: '#FFFFFF',
    border: '0px solid #1B2872'
  }
};

export const storeOwnerActions = {
  CANCEL: 'cancel',
  SEND_CONFIRM_NOTIFICATION: 'send-confirm-notitifcation',
  SEND_PICKUP_NOTIFICATION: 'send-pickup-notitifcation'
};

export const cancelReasonList = [
  { value: '상점사정', label: '상점사정' },
  { value: '고객요청', label: '고객요청' },
  { value: '상품품절', label: '상품품절' }
];
