import { Stack, Typography } from '@mui/material';

import MyModal from 'pages-preorder/components/Modal';
import React, { useState } from 'react';

const TermAndPolicy = ({ data, onAction, signUpData, checkAll }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [dialog, setDialog] = useState({ open: false, title: '' });

  return (
    <Stack
      sx={{
        width: '100%',
        flexGrow: 1,
        display: 'flex'
      }}
    >
      <Stack sx={{ py: 5 }}>
        <Typography
          sx={{
            fontSize: 24,
            color: '#202020',
            fontWeight: 700,
            letterSpacing: '-0.96px'
          }}
        >
          이용 약관
        </Typography>
      </Stack>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 1,
          pb: 5
        }}
        onClick={() => checkAll()}
      >
        {!signUpData?.agreeTermsAll ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <rect width="24" height="24" fill="white" />
            <rect x="3" y="3" width="18" height="18" rx="4" fill="#DADBDD" />
            <path d="M7 11.4541L10.1298 15L17 9" stroke="white" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <rect width="24" height="24" fill="white" />
            <rect
              x="3.5"
              y="3.5"
              width="17"
              height="17"
              rx="3.5"
              fill="#1B2872"
              stroke="#1B2872"
            />
            <path d="M7 11.4541L10.1298 15L17 9" stroke="white" />
          </svg>
        )}
        <Typography
          sx={{
            fontSize: 14,
            color: '#202020',
            fontWeight: 700,
            letterSpacing: '-0.56px'
          }}
        >
          약관 전체동의
        </Typography>
      </Stack>
      <Stack
        sx={{
          width: '100%',
          flexGrow: 1,
          display: 'flex',

          gap: 4
        }}
      >
        {data?.length > 0 &&
          data?.map((item) => (
            <Stack
              sx={{
                width: '100%',

                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
              key={`${item?.title}-`}
            >
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 1
                }}
                onClick={() => onAction(item)}
              >
                {' '}
                {!item?.isChecked ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect width="24" height="24" fill="white" />
                    <path d="M7 11.4541L10.1298 15L17 9" stroke="#DADBDD" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect width="24" height="24" fill="white" />
                    <path d="M7 11.4541L10.1298 15L17 9" stroke="#1B2872" />
                  </svg>
                )}
                <Typography
                  sx={{
                    fontSize: 14,
                    color: '#202020',
                    letterSpacing: '-0.56px',
                    fontWeight: 500,

                    '& span': {
                      fontWeight: 700
                    }
                  }}
                >
                  {item?.mandatory && (
                    <span style={{ marginRight: '4px' }}>[필수]</span>
                  )}
                  {item?.title}
                </Typography>
              </Stack>
              {item?.content && (
                <Typography
                  sx={{
                    color: '#9A9CA4',
                    fontSize: 14,
                    letterSpacing: '-0.56px',
                    fontWeight: 500,
                    textDecoration: 'underline'
                  }}
                  onClick={() => {
                    setSelectedItem(item);
                    setDialog({ ...dialog, open: true });
                  }}
                >
                  보기
                </Typography>
              )}
            </Stack>
          ))}
      </Stack>
      {selectedItem && (
        <MyModal
          open={dialog?.open}
          setOpen={() => setDialog({ ...dialog, open: false, title: '' })}
          title={selectedItem?.title}
          hasBackButton
          hideDialogButton
        >
          <Stack
            dangerouslySetInnerHTML={{ __html: selectedItem?.content }}
            sx={{
              'figure table': {
                border: '1px solid #EFEFEF',
                borderCollapse: 'collapse',
                width: '100%',
                th: {
                  span: {
                    fontSize: '12px !important',
                    whiteSpace: 'pre-line',
                    wordBreak: 'keep-all', // Prevent breaking words
                    overflowWrap: 'break-word' // Handle long words
                  },
                  backgroundColor: '#EFEFEF !important',
                  whiteSpace: 'pre-line',
                  wordBreak: 'keep-all', // Prevent breaking words
                  overflowWrap: 'break-word', // Handle long words
                  minWidth: '40px',
                  maxWidth: '40px',
                  color: '#202020'
                },
                'th, td': {
                  border: '1px solid #EFEFEF',
                  span: {
                    fontSize: '10px !important',
                    whiteSpace: 'pre-line',
                    wordBreak: 'keep-all', // Prevent breaking words
                    overflowWrap: 'break-word' // Handle long words
                  },
                  whiteSpace: 'pre-line',
                  wordBreak: 'keep-all', // Prevent breaking words
                  overflowWrap: 'break-word', // Handle long words
                  padding: '4px',
                  textAlign: 'left'
                }
              },
              figure: {
                width: '100% !important',
                margin: 0
              }
            }}
          />
        </MyModal>
      )}
    </Stack>
  );
};

export default TermAndPolicy;
