import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Stack } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import '../index.css';
import { ReactChannelIO, useChannelIOApi } from 'react-channel-plugin';
import PreorderBottomNavigation from './BottomNavigation';

const PreorderLayout = () => {
  const BOTTOM_NAV_HEIGHT = 50;

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover, user-scalable=no, shrink-to-fit=no"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
      </Helmet>
      <ReactChannelIO
        pluginKey="a421a2f2-3eb5-4190-b43d-c64163a7b0e9"
        language="ko"
        hideChannelButtonOnBoot
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '-webkit-fill-available',
            backgroundColor: '#F2F2F2',
            maxHeight: '100svh',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            overflowY: 'auto'
          }}
        >
          <Stack
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: '#FFFFFF',
              maxWidth: '500px !important',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <ChannelTalkCloseOutlet>
              <Outlet />
            </ChannelTalkCloseOutlet>
            <Stack
              sx={{
                width: '100%',
                maxWidth: '500px !important',
                height: `${BOTTOM_NAV_HEIGHT}px`,
                position: 'fixed',
                bottom: 0,
                backgroundColor: '#FFFFFF',
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 1000
              }}
            >
              <PreorderBottomNavigation />
            </Stack>
          </Stack>
        </Stack>
      </ReactChannelIO>
    </>
  );
};

const ChannelTalkCloseOutlet = ({ children }) => {
  const location = useLocation();
  const { hideChannelButton } = useChannelIOApi();

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const channelButton = document.querySelector('#ch-plugin');
      if (channelButton) {
        hideChannelButton();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });

    return () => observer.disconnect();
  }, [hideChannelButton]);

  return (
    <Stack
      sx={{
        flexGrow: 1,
        backgroundColor: '#FFFFFF',
        width: '100%',
        overflowY: 'auto'
      }}
    >
      {children}
    </Stack>
  );
};

export default PreorderLayout;
