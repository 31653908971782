/* eslint-disable no-lonely-if */
/* eslint-disable react/no-unknown-property */
/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Typography
} from '@mui/material';
import MyTextField from '@components/inputs/MyTextField';
import { Formatter } from '@utils/formatter';
import env from '@constants/env';
import MySelect from '@components/MySelect/new';

import AlertDialog from 'pages-preorder/components/Modal/Alert/AlertDialog';
import OrderAccordion from 'pages-preorder/home/components/OrderAccordion';
import React, { useEffect, useRef, useState } from 'react';
import { formatCardNumber, formatMoney } from '@utils/helpers';
import orderService from '@services/preorder/adminOrder';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';
import { setAdminAuth } from '@store/reducers/pre-order/auth';
import MyModal from 'pages-preorder/components/Modal';
import html2canvas from 'html2canvas';
import { setAlert, setPreorderError } from '@store/reducers/pre-order/app';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { cancelReasonList, statuses, storeOwnerActions } from '../enhance';

const calculateDaysLeft = (fixedDate) => {
  if (!fixedDate) return 0;

  // Create dates at the start of the day to avoid time zone issues
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const targetDate = new Date(fixedDate);
  targetDate.setHours(0, 0, 0, 0);

  const diffTime = targetDate - today;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
};

const dateFormat = 'YY-MM-DD';

const StoreOwnerOrderDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { version } = useParams();
  const { t } = useTranslation();
  const [hasImageUploaded, setHasImageUploaded] = useState(false);
  const location = useLocation();
  const [qrData, setQrData] = useState(null);
  const { orderCode } = useParams();
  const [dialog, setDialog] = useState({ open: false });
  const fileInputRef = useRef();
  const [alertDialog, setAlertDialog] = useState({ open: false });
  const [errorDialog, setErrorDialog] = useState({ open: false });
  const [openImage, setOpenImage] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [orderData, setOrderData] = useState({});

  const [errMsg, setErrMsg] = useState({
    qr: ''
  });
  const [reason, setReason] = useState(cancelReasonList[0].value);

  const fetchOrtderDetail = async (param) => {
    try {
      const rs = await orderService.getStoreOrderDetail(orderCode);

      if (!rs.error) {
        setOrderData({ ...rs.data });
      } else {
        if (rs?.error?.status === 403) {
          setErrorDialog({
            ...errorDialog,
            open: true,
            subtitle: t('pages.pre-order.alert.no-access'),
            type: 'error',
            okTitle: '확인',
            title: null,
            hasCancelButton: false,
            onOk: () => {
              localStorage.removeItem('PREORDER_ADMIN_REFRESH_TOKEN');
              localStorage.removeItem('PREORDER_ADMIN_SESSION_ID');
              localStorage.removeItem('PREORDER_ADMIN_TOKEN');
              dispatch(setAdminAuth(false));
              navigate('/pre-order/ko/admin/auth', {
                state: { originalPath: location?.pathname }
              });
            }
          });
        } else {
          dispatch(setPreorderError(rs?.error?.data));
        }
      }
    } catch (error) {
      console.error('Error fetching store data:', error);
    }
  };

  const fetchOrderDisplayImages = async () => {
    try {
      const rs = await orderService.getOrderDisplayImages(orderCode);

      if (!rs.error) {
        setImageList(rs?.data?.photos || []);
      }
    } catch (error) {
      console.error('Error fetching store data:', error);
    }
  };
  const handleFileChange = async (event) => {
    const { files } = event.target;
    setOrderData({ ...orderData, displayImg: files });

    if (files && files.length > 0) {
      const formData = new FormData();

      Array.from(files).forEach((file, index) => {
        formData.append(`photos[${index}]`, file);
      });

      try {
        const { location, error } = await orderService.uploadDisplayImage(
          orderCode,
          formData
        );

        if (!error) {
          fetchOrtderDetail();
          setHasImageUploaded(true);
          dispatch(setAlert({ message: '이미지 저장을 완료하였습니다.' }));
        } else {
          console.error('Image upload failed', error);
        }
      } catch (err) {
        console.error('Error during upload', err);
      }
    }
  };

  useEffect(() => {
    fetchOrtderDetail();
  }, [location?.search]);

  const getProgressPercent = () => {
    switch (orderData?.orderStatus) {
      case statuses.CANCELED:
      case statuses.COMPLETED:
        return 100;
      case statuses.ORDER_COMPLETE:
      case statuses.ORDER_CONFIRM:
        return 0;
      case statuses.FIXED:
        return 35;
      case statuses.PICK_UP_REQUEST:
        return 66;
      default:
        return 0;
    }
  };

  const isNotificationButtonDisabled = () => {
    switch (orderData?.orderStatus) {
      case statuses.CANCELED:
      case statuses.COMPLETED:
        return true;
      case statuses.ORDER_COMPLETE:
        return false;
      case statuses.ORDER_CONFIRM:
        return true;
      case statuses.FIXED:
        return orderData?.photoCount < 1;

      default:
        return false;
    }
  };

  const handleOrderActions = (action) => {
    switch (action) {
      case storeOwnerActions.CANCEL:
        setAlertDialog({
          ...alertDialog,
          open: true,
          title: '주문취소 하시겠습니까?',
          onOk: () => handleCancelOrder(),
          type: 'delete',

          subtitle: t('pages.pre-order.alert.order-cancel-subtitle'),
          action
        });

        break;
      case storeOwnerActions.SEND_CONFIRM_NOTIFICATION:
        setAlertDialog({
          ...alertDialog,
          open: true,
          title: '주문 확인 처리 하시겠습니까?',
          type: null,
          onOk: () => handleUpdateOrderStatus(),
          okTitle: '확인',

          subtitle: t('pages.pre-order.alert.send-confirm-subtitle'),
          action
        });
        break;

      case storeOwnerActions.SEND_PICKUP_NOTIFICATION:
        setAlertDialog({
          ...alertDialog,
          open: true,
          title: '픽업요청 알림을 전송하겠습니까?',
          onOk: () => handleUpdateOrderStatus(),
          type: null,
          okTitle: '확인',

          subtitle: t('pages.pre-order.alert.send-confirm-subtitle'),
          action
        });
        break;
      default:
        return 0;
    }
  };

  const handleCancelOrder = async () => {
    console.log('i am running');
    console.log('orderData', orderData);
    if (
      ![
        statuses.ORDER_COMPLETE,
        statuses.ORDER_CONFIRM,
        statuses.FIXED
      ].includes(orderData?.orderStatus)
    )
      return;

    const rs = await orderService.cancelOrderByStore(
      orderData?.storeCode,
      orderCode,
      {
        reason
      }
    );
    if (!rs?.error) {
      dispatch(setAlert({ message: '주문을 취소하였습니다.' }));
      fetchOrtderDetail();
      setAlertDialog({
        ...alertDialog,
        open: false,
        title: null,
        subTitle: null,
        subtitle: null,
        onOk: null
      });
    } else {
      dispatch(setPreorderError(rs?.error));
      fetchOrtderDetail();
      setAlertDialog({
        ...alertDialog,
        open: false,
        title: null,
        subTitle: null,
        subtitle: null,
        onOk: null
      });
    }
  };
  const handleDownloadQr = async () => {
    const qrElement = document.getElementById('order-qr-img');
    const qrText = document.getElementById('order-qr-text');

    if (!qrElement) {
      console.error('QR code element not found');
      return;
    }

    const originalText = qrText.innerText;

    qrText.innerText = `유효기간 : ~${
      Formatter.formatTime(qrData?.availableOn, 'YYYY-MM-DD') ||
      Formatter.formatTime(new Date(), dateFormat)
    }`;

    html2canvas(qrElement).then((canvas) => {
      qrText.innerText = originalText;

      const qrImage = canvas.toDataURL('image/png');

      const img = new Image();
      img.src = qrImage;
      const link = document.createElement('a');
      link.href = qrImage;
      link.download = `qr_code_${Formatter.formatTime(
        qrData?.availableOn,
        'YY-MM-DD'
      )}.png`;
      link.click();
    });
  };

  const handleClickImage = () => {
    fetchOrderDisplayImages();
    setOpenImage(true);
  };
  const handleUpdateOrderStatus = async () => {
    if (!orderCode) return;

    let updatedStatus;
    let msg;
    switch (orderData?.orderStatus) {
      case statuses.ORDER_COMPLETE:
        updatedStatus = statuses.ORDER_CONFIRM;
        msg = '주문을 확인하였습니다.';
        break;

      default:
        updatedStatus = statuses.PICK_UP_REQUEST;
        msg = '픽업을 요청하였습니다.';
        break;
    }
    const rs = await orderService.updateStoreOrderStatus(orderCode, {
      orderStatus: updatedStatus
    });
    if (!rs?.error) {
      fetchOrtderDetail();
      dispatch(setAlert({ message: msg }));
      setAlertDialog({ ...alertDialog, open: false });
    }
  };
  const handleOpenQrDialog = async () => {
    const rs = await orderService.getPickupQr(orderData?.deliveryCode);
    if (!rs?.error) {
      setQrData({ ...qrData, ...rs.data });

      setDialog({ ...dialog, open: true });
    }
  };

  return (
    <Stack
      sx={{
        flexDirection: 'column',
        width: '100%',

        flexGrow: 1,
        alignItems: 'center',
        position: 'relative'
      }}
    >
      <Stack
        sx={{
          flexDirection: 'column',
          width: '100%',
          maxWidth: '500px',
          flexGrow: 1,
          backgroundColor: '#FFFFFF',
          alignItems: 'center',
          position: 'relative'
        }}
      >
        {/* header */}
        <Stack
          sx={{
            width: '100%',
            maxWidth: '500px',
            top: 0,
            position: 'fixed',
            backgroundColor: '#FFFFFF',
            zIndex: 1002
          }}
        >
          <Stack
            id="navBarHeader"
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              backgroundColor: '#FFFFFF',
              width: '100%',
              py: '13px',
              px: 2.5,
              position: 'relative',
              height: { xs: '50px' }
            }}
          >
            <Stack sx={{ position: 'absolute', left: '0px' }}>
              <IconButton
                size="small"
                sx={{
                  backgroundColor: 'transparent !important',
                  px: 2.5,
                  py: 2
                }}
                onClick={() => {
                  navigate('/pre-order/ko/admin/order-list');
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                >
                  <path d="M7 1L1 7L7 13" stroke="black" />
                </svg>
              </IconButton>
            </Stack>
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                flexGrow: 1
              }}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 700,
                  color: '#202020',
                  letterSpacing: '-0.56px'
                }}
              >
                주문상세
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        {/* stepper */}

        <Stack sx={{ mt: '51px', width: '100%', gap: 1, py: 1.25, px: 2.5 }}>
          {/* linear progress */}
          <Stack sx={{ width: '100%', px: 2.5 }}>
            <Stack
              sx={{
                height: '2px',
                backgroundColor: '#DADBDD',
                width: '100%',
                zIndex: 11
              }}
            >
              <LinearProgress
                variant="determinate"
                sx={{
                  maxWidth: '100%',
                  backgroundColor: 'red',
                  '&.MuiLinearProgress-root': {
                    backgroundColor: 'transparent',
                    transition: 'width 300ms ease-in-out'
                  },
                  '& .MuiLinearProgress-barColorPrimary': {
                    backgroundColor:
                      orderData?.orderStatus === statuses?.CANCELED
                        ? '#F25D5D'
                        : '#1B2872 !important'
                  }
                }}
                value={getProgressPercent()}
              />
            </Stack>
          </Stack>
          <Grid container>
            <Grid
              item
              xs={orderData?.orderStatus === statuses.CANCELED ? 6 : 3}
            >
              <Stack
                sx={{
                  width: '100%',
                  gap: 0.4,
                  ml: 0.5,
                  alignItems: 'flex-start'
                }}
              >
                <Stack sx={{ textAlign: 'center' }}>
                  <Typography
                    sx={{
                      color: [
                        statuses.ORDER_CONFIRM,
                        statuses.ORDER_COMPLETE
                      ].includes(orderData?.orderStatus)
                        ? '#202020'
                        : '#D6D7DB',
                      fontSize: 10,
                      letterSpacing: '-0.5px',
                      fontWeight: [
                        statuses.ORDER_CONFIRM,
                        statuses.ORDER_COMPLETE
                      ].includes(orderData?.orderStatus)
                        ? 700
                        : 500
                    }}
                  >
                    주문접수
                  </Typography>
                  {orderData?.orderedDate && (
                    <Typography
                      sx={{
                        color: '#9A9CA4',
                        fontSize: 8,
                        letterSpacing: '-0.32px',
                        fontWeight: 500
                      }}
                    >
                      {Formatter.formatTime(orderData?.orderedDate, dateFormat)}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Grid>
            {orderData?.orderStatus !== statuses.CANCELED && (
              <>
                <Grid item xs={3}>
                  <Stack
                    sx={{
                      width: '100%',
                      gap: 0.4,

                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      sx={{
                        color:
                          orderData?.orderStatus === statuses.FIXED
                            ? '#202020'
                            : '#D6D7DB',
                        fontSize: 10,
                        letterSpacing: '-0.5',
                        fontWeight:
                          orderData?.orderStatus === statuses.FIXED ? 700 : 500
                      }}
                    >
                      주문확정
                    </Typography>
                    {orderData?.fixedDate &&
                      ![
                        statuses.ORDER_CONFIRM,
                        statuses.ORDER_COMPLETE
                      ].includes(orderData?.orderStatus) && (
                        <Typography
                          sx={{
                            color: '#9A9CA4',
                            fontSize: 8,
                            letterSpacing: '-0.32px',
                            fontWeight: 500
                          }}
                        >
                          {Formatter.formatTime(
                            orderData?.fixedDate,
                            dateFormat
                          )}
                        </Typography>
                      )}
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Stack
                    sx={{
                      width: '100%',
                      gap: 0.4,

                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      sx={{
                        color:
                          orderData?.orderStatus === statuses.PICK_UP_REQUEST
                            ? '#202020'
                            : '#D6D7DB',
                        fontSize: 10,
                        letterSpacing: '-0.5',
                        fontWeight:
                          orderData?.orderStatus === statuses.PICK_UP_REQUEST
                            ? 700
                            : 500
                      }}
                    >
                      픽업요청
                    </Typography>
                    {orderData?.pickupRequestedDate && (
                      <Typography
                        sx={{
                          color: '#9A9CA4',
                          fontSize: 8,
                          letterSpacing: '-0.32px',
                          fontWeight: 500
                        }}
                      >
                        {Formatter.formatTime(
                          orderData?.pickupRequestedDate,
                          dateFormat
                        )}
                      </Typography>
                    )}
                  </Stack>
                </Grid>
              </>
            )}
            {orderData?.orderStatus !== statuses.CANCELED && (
              <Grid item xs={3}>
                <Stack
                  sx={{
                    width: '100%',
                    gap: 0.4,

                    alignItems: 'flex-end'
                  }}
                >
                  <Stack sx={{ textAlign: 'center' }}>
                    <Typography
                      sx={{
                        color:
                          orderData?.orderStatus === statuses.COMPLETED
                            ? '#202020'
                            : '#D6D7DB',
                        fontSize: 10,
                        letterSpacing: '-0.5',
                        fontWeight:
                          orderData?.orderStatus === statuses.COMPLETED
                            ? 700
                            : 500
                      }}
                    >
                      완료
                    </Typography>
                    {orderData?.completedDate && (
                      <Typography
                        sx={{
                          color: '#9A9CA4',
                          fontSize: 8,
                          letterSpacing: '-0.32px',
                          fontWeight: 500
                        }}
                      >
                        {Formatter.formatTime(
                          orderData?.completedDate,
                          dateFormat
                        )}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </Grid>
            )}
            {orderData?.orderStatus === statuses.CANCELED && (
              <Grid item xs={6}>
                <Stack
                  sx={{
                    width: '100%',
                    gap: 0.4,

                    alignItems: 'flex-end'
                  }}
                >
                  <Stack sx={{ textAlign: 'center' }}>
                    <Typography
                      sx={{
                        color:
                          orderData?.orderStatus === statuses.CANCELED
                            ? '#F25D5D'
                            : '#D6D7DB',
                        fontSize: 10,
                        letterSpacing: '-0.4',
                        fontWeight:
                          orderData?.orderStatus === statuses.CANCELED
                            ? 700
                            : 500
                      }}
                    >
                      주문취소
                    </Typography>
                    {orderData?.orderStatus === statuses.CANCELED &&
                      orderData?.cancelledDate && (
                        <Typography
                          sx={{
                            color: '#9A9CA4',
                            fontSize: 8,
                            letterSpacing: '-0.32px',
                            fontWeight: 500
                          }}
                        >
                          {Formatter.formatTime(
                            orderData?.cancelledDate,
                            dateFormat
                          )}
                        </Typography>
                      )}
                  </Stack>
                </Stack>
              </Grid>
            )}
          </Grid>
        </Stack>

        <Stack
          sx={{
            width: '100%'
          }}
        >
          <Stack
            sx={{
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'space-between',
              py: '30px',
              px: 2.5,
              backgroundColor:
                ([
                  statuses.ORDER_COMPLETE,
                  statuses.ORDER_CONFIRM,
                  statuses.FIXED,
                  statuses.PICK_UP_REQUEST
                ].includes(orderData?.orderStatus) &&
                  '#202020') ||
                (orderData?.orderStatus === statuses.COMPLETED && '#1B2872') ||
                (orderData?.orderStatus === statuses.CANCELED && '#F25D5D')
            }}
          >
            <Stack
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                alignItems: 'center',

                justifyContent: 'center',
                gap: 1
              }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  color: '#FFFFFF',
                  fontWeight: 500,
                  letterSpacing: '-0.5px',
                  whiteSpace: 'pre-line',
                  textAlign: 'center',
                  '& span': {
                    color: '#F25D5D'
                  }
                }}
                dangerouslySetInnerHTML={{
                  __html: t(
                    `pages.pre-order.order-notification.${orderData?.orderStatus}`,
                    {
                      days: [
                        statuses.ORDER_COMPLETE,
                        statuses.ORDER_CONFIRM
                      ].includes(orderData?.orderStatus)
                        ? calculateDaysLeft(orderData?.fixedDate)
                        : 0
                    }
                  )
                }}
              />
            </Stack>
          </Stack>
        </Stack>

        {/* preffered pickup date */}
        <Stack
          sx={{
            width: '100%'
          }}
        >
          <Stack
            sx={{
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'space-between',
              py: 2.5,
              px: 2.5,

              backgroundColor:
                orderData?.orderStatus !== statuses?.FIXED
                  ? '#F6F6F6'
                  : '#FFFFFF'
            }}
          >
            <Stack
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 1
              }}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  color: '#202020',
                  fontWeight: 700,
                  letterSpacing: '-0.56px'
                }}
              >
                희망 픽업일자
              </Typography>
              <Stack
                sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}
              >
                {' '}
                {statuses.FIXED === orderData?.orderStatus && (
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: '#F25D5D',
                      fontWeight: 500,
                      letterSpacing: '-0.56px'
                    }}
                  >
                    {Math.ceil(
                      (new Date(orderData?.preferredPickupDate) - new Date()) /
                        (1000 * 60 * 60 * 24)
                    )}
                    일 남음
                  </Typography>
                )}
                <Typography
                  sx={{
                    fontSize: 14,
                    color: '#202020',
                    fontWeight: 500,
                    letterSpacing: '-0.56px',
                    textDecoration: 'underline'
                  }}
                >
                  {Formatter.formatTime(
                    orderData.preferredPickupDate,
                    'YY년 MM월 DD일'
                  )}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        {[statuses.FIXED].includes(orderData?.orderStatus) && (
          <Stack
            sx={{
              width: '100%',
              // Allow the stack to grow and fill available space
              backgroundColor: '#D6D7DB',
              display: 'flex',
              flexDirection: 'column',
              px: 2.5,
              py: 2
            }}
          >
            <Stack
              sx={{
                flexGrow: 1, // Allow this inner stack to grow
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 1
              }}
            >
              <Button
                sx={{
                  width: '50%',
                  height: '50px',
                  borderRadius: 1,
                  backgroundColor: '#1B2872 !important'
                }}
                onClick={() => handleOpenQrDialog()}
              >
                <Typography
                  sx={{
                    zIndex: 2,
                    fontSize: 14,
                    fontWeight: 700,
                    letterSpacing: '-0.56px',
                    color: '#FFFFFF',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  진열 QR코드{' '}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    style={{ marginLeft: '8px' }}
                  >
                    <path d="M1 13L7 7L1 0.999999" stroke="white" />
                  </svg>
                </Typography>
              </Button>
              <Button
                sx={{
                  width: '50%',
                  height: '50px',
                  borderRadius: 1,
                  border: orderData?.photoCount !== 0 && '1px solid #DADBDD',
                  backgroundColor:
                    orderData?.photoCount !== 0
                      ? '#F6F6F6 !important'
                      : '#FFFFFF !important'
                }}
                disabled={orderData?.photoCount !== 0}
                onClick={() => fileInputRef.current.click()}
              >
                <Typography
                  sx={{
                    zIndex: 2,
                    fontSize: 14,
                    fontWeight: orderData?.photoCount !== 0 ? 500 : 700,
                    letterSpacing: '-0.56px',
                    color: orderData?.photoCount !== 0 ? '#9A9CA4' : '#1B2872',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  완료 사진 업로드{' '}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    style={{ marginLeft: '8px' }}
                  >
                    <path
                      d="M1 13L7 7L1 0.999999"
                      stroke={
                        orderData?.photoCount !== 0 ? '#9A9CA4' : '#1B2872'
                      }
                    />
                  </svg>
                </Typography>
              </Button>
              <input
                ref={fileInputRef}
                hidden
                accept="image/png, image/gif, image/jpeg"
                type="file"
                name="displayImage"
                onChange={handleFileChange}
              />
            </Stack>
          </Stack>
        )}

        {[
          statuses.ORDER_COMPLETE,
          statuses.ORDER_CONFIRM,
          statuses.PICK_UP_REQUEST,
          statuses.COMPLETED
        ].includes(orderData?.orderStatus) && (
          <Stack sx={{ width: '100%', pt: 2.5, backgroundColor: '#FFFFFF' }}>
            {/* title */}
            <Stack
              sx={{
                width: '100%',
                textAlign: 'left',
                px: 2.5,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                pb: 1.5,
                borderBottom:
                  orderData?.orderStatus !== statuses.COMPLETED &&
                  '1px solid #F2F2F2'
              }}
            >
              <Typography
                sx={{
                  color: '#202020',
                  fontSize: { xs: 14 },
                  fontWeight: 700,
                  letterSpacing: '-0.56px'
                }}
              >
                {([statuses.ORDER_CONFIRM, statuses.ORDER_COMPLETE].includes(
                  orderData?.orderStatus
                ) &&
                  '주문 확정일') ||
                  (statuses.PICK_UP_REQUEST === orderData?.orderStatus &&
                    '상품 보관일자') ||
                  (statuses.COMPLETED === orderData?.orderStatus &&
                    '실제 픽업일자')}
              </Typography>

              {orderData?.orderStatus === statuses.PICK_UP_REQUEST ? (
                <Typography
                  sx={{
                    color: '#202020',
                    fontSize: { xs: 14 },
                    fontWeight: 500,
                    letterSpacing: '-0.56px'
                  }}
                >
                  {Formatter.formatTime(
                    orderData?.pickupRequestedDate,
                    'YY년 MM월 DD일'
                  )}{' '}
                  -{' '}
                  {Formatter.formatTime(
                    new Date(
                      new Date(orderData?.pickupRequestedDate).setDate(
                        new Date(orderData?.pickupRequestedDate).getDate() + 14
                      )
                    ),
                    'YY년 MM월 DD일'
                  )}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    color: '#202020',
                    fontSize: { xs: 14 },
                    fontWeight: 500,
                    letterSpacing: '-0.56px'
                  }}
                >
                  {Formatter.formatTime(
                    orderData?.cancellableDate,
                    'YY년 MM월 DD일'
                  )}
                </Typography>
              )}
            </Stack>

            {/* notice */}
            <Stack sx={{ flexDirection: 'column', gap: 1 }}>
              {orderData.orderStatus !== statuses.COMPLETED ? (
                <Stack
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    px: 2.5,
                    mt: 1.25,
                    pb: 2
                  }}
                >
                  <Typography
                    sx={{
                      color: '#202020',
                      fontSize: { xs: 10 },
                      fontWeight: 500,
                      letterSpacing: '-0.4px'
                    }}
                  >
                    {t(
                      `pages.pre-order.store-owner-notice.${orderData?.orderStatus}`
                    )}
                  </Typography>
                </Stack>
              ) : (
                <Stack
                  sx={{
                    width: '100%',
                    py: 2.5,
                    px: 2.5,
                    backgroundColor: '#D6D7DB',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <Button
                    sx={{
                      width: '100%',
                      height: 50,
                      backgroundColor: '#1B2872 !important',
                      color: '#FFFFFF !important',
                      borderRadius: 1
                    }}
                    onClick={handleClickImage}
                  >
                    완료 사진 보기{' '}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                      style={{ marginLeft: '8px' }}
                    >
                      <path d="M1 13L7 7L1 0.999999" stroke="white" />
                    </svg>
                  </Button>
                </Stack>
              )}
            </Stack>
          </Stack>
        )}
        {[statuses.CANCELED].includes(orderData?.orderStatus) && (
          <Stack
            sx={{
              width: '100%'
            }}
          >
            <Stack
              sx={{
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
                py: 2,
                px: 2.5,

                backgroundColor: '#FFFFFF'
              }}
            >
              <Stack
                sx={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 1
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    color: '#202020',
                    fontWeight: 700,
                    letterSpacing: '-0.56px'
                  }}
                >
                  취소일자
                </Typography>
                <Stack
                  sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}
                >
                  {' '}
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: '#202020',
                      fontWeight: 500,
                      letterSpacing: '-0.56px'
                    }}
                  >
                    {Formatter.formatTime(
                      orderData.orderStatusUpdatedDate,
                      'YY년 MM월 DD일'
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              sx={{
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
                py: 2,
                px: 2.5,

                backgroundColor: '#F6F6F6'
              }}
            >
              <Stack
                sx={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 1
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    color: '#202020',
                    fontWeight: 700,
                    letterSpacing: '-0.56px'
                  }}
                >
                  취소사유
                </Typography>
                <Stack
                  sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}
                >
                  {' '}
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: '#202020',
                      fontWeight: 500,
                      letterSpacing: '-0.56px'
                    }}
                  >
                    {orderData?.cancelledReason || '고객주문취소'}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}
        {![statuses.FIXED, statuses.COMPLETED, statuses.CANCELED].includes(
          orderData?.orderStatus
        ) && (
          <Stack
            sx={{ width: '100%', height: '5px', backgroundColor: '#F2F2F2' }}
          >
            <Stack
              sx={{ width: '100%', height: '1px', backgroundColor: '#E7E7E7' }}
            />
          </Stack>
        )}

        {/* product info */}
        <Stack
          sx={{ width: '100%', px: 2.5, py: 2.5, backgroundColor: '#FFFFFF' }}
        >
          <OrderAccordion data={{ title: '주문 상품' }} defaultExpanded>
            {orderData && (
              <Stack
                sx={{
                  width: '100%',
                  flexGrow: 1,
                  backgroundColor: '#FFFFFF'
                }}
              >
                {orderData?.products?.map((item, index) => (
                  <Stack
                    key={`${item.productCode}-${item.productName}`}
                    sx={{
                      width: '100%',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      gap: 1,
                      pb: orderData.products.length - 1 === index ? 0 : 2.5,
                      pt: index === 0 ? 1 : 2.5,
                      borderBottom:
                        orderData.products.length - 1 === index
                          ? 0
                          : '1px solid #F2F2F2'
                    }}
                  >
                    <Stack sx={{ flexGrow: 1, flexDirection: 'row' }}>
                      <Stack
                        sx={{
                          width: '100%',
                          flexDirection: 'row',
                          gap: 1.5,
                          justifyContent: 'flex-start'
                        }}
                      >
                        <Stack
                          sx={{
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <img
                            src={`${env.s3BaseUrl}/${item.imageUrl}`}
                            alt={item.productName}
                            style={{
                              width: '100px',
                              maxWidth: '100px',
                              height: '100px',
                              objectFit: 'cover'
                            }}
                          />
                        </Stack>
                        <Stack
                          sx={{
                            gap: 1.5,
                            flexGrow: 1,

                            justifyContent: 'space-between'
                          }}
                        >
                          <Stack sx={{ gap: 0.5 }}>
                            <Stack
                              sx={{
                                flexDirection: 'row',
                                gap: 1,
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: 14,
                                  letterSpacing: '-0.56px',
                                  fontWeight: 500,
                                  color: '#202020'
                                }}
                              >
                                {item.productName}
                              </Typography>
                            </Stack>
                            <Typography
                              sx={{
                                fontSize: 14,
                                letterSpacing: '-0.56px',
                                fontWeight: 700,
                                color: '#1B2872'
                              }}
                            >
                              {formatMoney(item.price)}원
                            </Typography>
                          </Stack>
                          <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
                            <Stack
                              sx={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 1
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: 14,
                                  letterSpacing: '-0.48px',
                                  fontWeight: 500,
                                  color: '#9A9CA4',
                                  textAlign: 'center',
                                  minWidth: '20px'
                                }}
                              >
                                수량 {item.quantity}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            )}
          </OrderAccordion>
        </Stack>

        {/* divider */}
        <Stack
          sx={{ width: '100%', height: '5px', backgroundColor: '#F2F2F2' }}
        >
          <Stack
            sx={{ width: '100%', height: '1px', backgroundColor: '#E7E7E7' }}
          />
        </Stack>

        <Stack
          sx={{ width: '100%', px: 2.5, py: 2.5, backgroundColor: '#FFFFFF' }}
          id="checkout-page"
        >
          <OrderAccordion data={{ title: '고객정보' }} defaultExpanded>
            {orderData && (
              <Stack
                sx={{
                  width: '100%',
                  flexGrow: 1,
                  backgroundColor: '#FFFFFF',
                  textAlign: 'left'
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    letterSpacing: '-0.56px',
                    fontWeight: 700,
                    color: '#202020'
                  }}
                >
                  {orderData?.pickupSpotName}
                </Typography>
                <Stack
                  sx={{
                    width: '100%',
                    pt: 2.5,
                    gap: 1.5
                  }}
                >
                  {/* name */}

                  <Stack
                    sx={{
                      flexGrow: 1,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        letterSpacing: '-0.48px',
                        fontWeight: 500,
                        color: '#9A9CA4'
                      }}
                    >
                      이름
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 12,
                        letterSpacing: '-0.48px',
                        fontWeight: 500,
                        color: '#202020'
                      }}
                    >
                      {orderData?.customerName}
                    </Typography>
                  </Stack>
                  {/* mobile */}

                  <Stack
                    sx={{
                      flexGrow: 1,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        letterSpacing: '-0.48px',
                        fontWeight: 500,
                        color: '#9A9CA4'
                      }}
                    >
                      연락처
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 12,
                        letterSpacing: '-0.48px',
                        fontWeight: 500,
                        color: '#202020'
                      }}
                    >
                      {orderData?.customerContact}
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      flexGrow: 1,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        letterSpacing: '-0.48px',
                        fontWeight: 500,
                        color: '#9A9CA4'
                      }}
                    >
                      픽업장소
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 12,
                        letterSpacing: '-0.48px',
                        fontWeight: 500,
                        color: '#202020'
                      }}
                    >
                      {orderData?.pickupSpotName}
                    </Typography>
                  </Stack>

                  <Stack
                    sx={{
                      flexGrow: 1,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        letterSpacing: '-0.48px',
                        fontWeight: 500,
                        color: '#9A9CA4'
                      }}
                    >
                      주소
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 12,
                        letterSpacing: '-0.48px',
                        fontWeight: 500,
                        color: '#202020'
                      }}
                    >
                      {orderData?.pickupSpotAddress}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </OrderAccordion>
        </Stack>
        <Stack
          sx={{ width: '100%', height: '5px', backgroundColor: '#F2F2F2' }}
        >
          <Stack
            sx={{ width: '100%', height: '1px', backgroundColor: '#E7E7E7' }}
          />
        </Stack>

        {/* message */}
        <Stack
          sx={{ width: '100%', py: 2.5, px: 2.5, backgroundColor: '#FFFFFF' }}
        >
          {/* title */}
          <Stack
            sx={{
              width: '100%',
              textAlign: 'left',
              pb: 1.5,
              borderBottom: '1px solid #F2F2F2'
            }}
          >
            <Typography
              sx={{
                color: '#202020',
                fontSize: { xs: 14 },
                fontWeight: 700,
                letterSpacing: '-0.56px'
              }}
            >
              상점 요청사항(선택)
            </Typography>
          </Stack>

          {/* calendar */}
          <Stack sx={{ flexDirection: 'column', gap: 0.5, mt: 2.5 }}>
            <Stack sx={{ flexDirection: 'row', alignItems: 'flex-end' }}>
              <MyTextField
                preorder
                fullWidth
                sx={{ p: 0 }}
                disabled
                value={orderData?.customerRequest}
                multiline
                minRows={1}
                maxRows={4}
              />
            </Stack>
          </Stack>
        </Stack>

        {/* divider */}
        <Stack
          sx={{ width: '100%', height: '5px', backgroundColor: '#F2F2F2' }}
        >
          <Stack
            sx={{ width: '100%', height: '1px', backgroundColor: '#E7E7E7' }}
          />
        </Stack>

        {/* payment detail */}
        <Stack
          sx={{
            width: '100%',
            py: 2.5,
            px: 2.5,
            backgroundColor: '#FFFFFF'
          }}
        >
          {/* title */}
          <Stack
            sx={{
              width: '100%',
              textAlign: 'left',
              pb: 1.5,
              borderBottom: '1px solid #F2F2F2'
            }}
          >
            <Typography
              sx={{
                color: '#202020',
                fontSize: { xs: 14 },
                fontWeight: 700,
                letterSpacing: '-0.56px'
              }}
            >
              결제정보
            </Typography>
          </Stack>

          {/* calendar */}
          <Stack sx={{ flexDirection: 'column', gap: 2.5, mt: 2.5 }}>
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Typography
                sx={{
                  color: '#9A9CA4',
                  fontSize: { xs: 12 },
                  fontWeight: 500,
                  letterSpacing: '-0.px'
                }}
              >
                결제수단
              </Typography>

              <Typography
                sx={{
                  color: '#202020',
                  fontSize: { xs: 12 },
                  fontWeight: 500,
                  letterSpacing: '-0.48px',
                  display: 'flex',
                  gap: 1
                }}
              >
                {orderData?.cardIssuer}{' '}
                <span>{formatCardNumber(orderData?.cardNumber)}</span>
              </Typography>
            </Stack>

            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Typography
                sx={{
                  color: '#9A9CA4',
                  fontSize: { xs: 12 },
                  fontWeight: 500,
                  letterSpacing: '-0.px'
                }}
              >
                총 결제금액
              </Typography>

              <Typography
                sx={{
                  color: '#1B2872',
                  fontSize: { xs: 12 },
                  fontWeight: 500,
                  letterSpacing: '-0.48px'
                }}
              >
                {formatMoney(orderData?.totalPrice) || 0}원
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        {/* divider */}
        {![
          statuses.PICK_UP_REQUEST,
          statuses.COMPLETED,
          statuses.CANCELED
        ].includes(orderData?.orderStatus) && (
          <Stack
            sx={{
              width: '100%',
              height: '5px',
              backgroundColor: '#F2F2F2',
              mb: '108px'
            }}
          >
            <Stack
              sx={{ width: '100%', height: '1px', backgroundColor: '#E7E7E7' }}
            />
          </Stack>
        )}

        {[
          statuses.ORDER_COMPLETE,
          statuses.ORDER_CONFIRM,
          statuses.FIXED
        ].includes(orderData?.orderStatus) && (
          <Stack
            sx={{
              width: '100%',
              px: 2.5,
              py: '30px',
              maxWidth: '500px',
              bottom: 0,
              position: 'fixed',

              backgroundColor: '#FFFFFF',
              zIndex: 1002
            }}
          >
            <Stack sx={{ width: '100%', flexDirection: 'row', gap: 1 }}>
              <Button
                sx={{
                  width: '50%',
                  px: 3,
                  height: 48,
                  backgroundColor: '#FFFFFF !important',
                  border: '1px solid #1B2872 !important',
                  fontSize: 16,
                  letterSpacing: '-0.64px',
                  fontWeight: 500,
                  borderRadius: 1,
                  color: '#1B2872 !important'
                }}
                onClick={() => handleOrderActions(storeOwnerActions.CANCEL)}
              >
                주문취소
              </Button>
              <Button
                sx={{
                  flexGrow: 1,
                  width: '50%',
                  height: 49,
                  fontSize: 16,
                  letterSpacing: '-0.64px',
                  fontWeight: 500,
                  backgroundColor: !isNotificationButtonDisabled()
                    ? '#1B2872 !important'
                    : '#DADBDD !important',
                  borderRadius: 1,
                  color: '#FFFFFF !important'
                }}
                disabled={isNotificationButtonDisabled()}
                onClick={() =>
                  handleOrderActions(
                    orderData?.orderStatus === statuses.ORDER_COMPLETE
                      ? storeOwnerActions.SEND_CONFIRM_NOTIFICATION
                      : storeOwnerActions.SEND_PICKUP_NOTIFICATION
                  )
                }
              >
                {[statuses.ORDER_CONFIRM, statuses.ORDER_COMPLETE].includes(
                  orderData?.orderStatus
                )
                  ? '주문확인'
                  : '픽업요청 알림 전송'}
              </Button>
            </Stack>
          </Stack>
        )}

        <MyModal
          open={dialog?.open}
          setOpen={() => setDialog({ ...dialog, open: false, title: '' })}
          title="진열 QR코드"
          hideDialogButton
          fullScreen={false}
        >
          <Stack
            sx={{
              minHeight: '200px',
              // backgroundColor: '#F6F6F6',
              minWidth: '200px',
              width: '100%',
              gap: 2.5
            }}
          >
            <Stack sx={{ width: '100%', alignItems: 'center' }}>
              <Typography
                sx={{
                  whiteSpace: 'pre-line',
                  textAlign: 'center',
                  fontSize: 14,
                  color: '#202020',
                  fontWeight: 500,
                  letterSpacing: '-0.56px'
                }}
              >
                {t('pages.pre-order.alert.display-open-qr')}
              </Typography>
            </Stack>
            <Stack sx={{ width: '100%', alignItems: 'center', gap: 1.25 }}>
              <Stack
                sx={{
                  mt: -2.5,
                  mb: -2.5,
                  minWidth: '200px',
                  minHeight: '200px',
                  px: 2.5,
                  py: 2.5
                }}
                id="order-qr-img"
              >
                <QRCode
                  value={qrData?.qrText}
                  size={200}
                  style={{ height: '200px', maxWidth: '100%', width: '200px' }}
                  viewBox="0 0 200 200"
                />
                <Typography
                  sx={{
                    mt: '10px',
                    fontSize: { xs: 14 },
                    color: '#202020',
                    letterSpacing: '-0.5px',
                    textAlign: 'center',
                    whiteSpace: 'pre-line'
                  }}
                  id="order-qr-text"
                >
                  유효기간 :{' '}
                  {Formatter.formatTime(qrData?.availableOn, 'YYYY-MM-DD') ||
                    Formatter.formatTime(new Date(), dateFormat)}
                </Typography>
              </Stack>
            </Stack>

            <Stack sx={{ width: '100%', flexDirection: 'row', gap: 1.5 }}>
              <Button
                sx={{
                  flexGrow: 1,
                  height: 50,
                  backgroundColor: '#1B2872 !important',
                  borderRadius: 1,
                  fontSize: 14,
                  letterSpacing: '-0.56px',
                  fontWeight: 500,
                  color: '#FFFFFF !important'
                }}
                onClick={() => handleDownloadQr()}
              >
                이미지 저장
              </Button>
            </Stack>
          </Stack>
        </MyModal>

        {imageList && (
          <MyModal
            open={openImage}
            setOpen={() => setOpenImage(false)}
            title={null}
            hideDialogButton
            fullScreen={false}
            sx={{ minWidth: '250px', width: 'auto' }}
          >
            <Stack
              sx={{
                minHeight: '200px',
                // backgroundColor: '#F6F6F6',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '200px',
                width: '100%',
                gap: 2.5,
                p: '50px'
              }}
            >
              <img
                src={`${env.s3BaseUrl}/${imageList[0]}`}
                alt="display"
                style={{
                  width: '200px',
                  height: '200px',
                  objectFit: 'cover'
                }}
              />
            </Stack>
          </MyModal>
        )}

        <AlertDialog
          isAlert
          open={errorDialog.open}
          setOpen={(open) => setErrorDialog({ ...errorDialog, open })}
          onOk={errorDialog?.onOk}
          okTitle={errorDialog?.okTitle || '취소'}
          title={errorDialog.title}
          titleStyle={{ textAlign: 'center' }}
          type={errorDialog?.type}
          subTitle={errorDialog?.subtitle}
          hasCloseButton={false}
        />

        <AlertDialog
          isAlert
          open={alertDialog.open}
          setOpen={(open) => setAlertDialog({ ...alertDialog, open })}
          onOk={alertDialog?.onOk}
          okTitle={alertDialog?.okTitle || '취소'}
          title={alertDialog.title}
          titleStyle={{ textAlign: 'center' }}
          cancelTitle="닫기"
          type={alertDialog?.type}
          subTitle={alertDialog?.subtitle}
          hasCloseButton
          hideDialogButton={alertDialog?.action === storeOwnerActions.CANCEL}
        >
          {alertDialog?.action === storeOwnerActions.CANCEL && (
            <Stack sx={{ width: '100%', mt: 2.5, gap: 1 }}>
              {' '}
              <MySelect
                fullWidth
                data={cancelReasonList}
                sx={{ borderRadius: 1 }}
                placeholder="취소 사유를 선택해주세요."
                onChange={(e) => {
                  console.log('target', e?.target);
                  setReason((prev) => e?.target?.value);
                }}
                value={reason}
                MenuProps={{
                  PaperProps: { sx: { maxHeight: 210, borderRadius: 0 } }
                }}
              />{' '}
              {orderData?.orderStatus === statuses.FIXED && (
                <Typography
                  sx={{
                    fontSize: { xs: 12 },
                    color: '#EB5656',
                    fontWeight: 500,
                    letterSpacing: '-0.48px',
                    textAlign: 'center',
                    whiteSpace: 'pre-line'
                  }}
                >
                  {t('pages.pre-order.alert.cancel-confirm')}
                </Typography>
              )}
              <Stack
                sx={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  mt: { xs: '20px' },
                  p: 0,
                  gap: '5px'
                }}
              >
                <Button
                  onClick={() =>
                    setAlertDialog({ ...alertDialog, open: false })
                  }
                  variant="outlined"
                  sx={{
                    borderRadius: '8px',
                    height: { md: '40px', xs: '36px' },
                    minWidth: { md: '80px', xs: '90px' },
                    border: '1px solid #D6D7DB;',
                    boxShadow: 0,
                    ml: '0px !important',
                    backgroundColor: '#FFFFFF',
                    color: '#D6D7DB',
                    ':hover': {
                      boxShadow: 0,
                      border: '1px solid #D6D7DB;',
                      backgroundColor: '#FFFFFF',
                      color: '#D6D7DB'
                    },
                    fontSize: 14,
                    letterSpacing: '-0.56px',
                    fontWeight: 500
                  }}
                >
                  닫기
                </Button>

                <Button
                  onClick={handleCancelOrder}
                  variant="contained"
                  sx={{
                    height: { md: '40px', xs: '36px' },
                    minWidth: { md: '80px', xs: '90px' },
                    borderRadius: 1,
                    boxShadow: 0,
                    border: 0,
                    backgroundColor: '#F25D5D !important',
                    color: '#FFFFFF',
                    ml: '0px !important',
                    ':hover': {
                      border: 0,
                      boxShadow: 0,
                      backgroundColor: '#F25D5D',
                      color: '#FFFFFF'
                    },
                    fontSize: 14,
                    letterSpacing: '-0.56px',
                    fontWeight: 500
                  }}
                >
                  취소
                </Button>
              </Stack>
            </Stack>
          )}
        </AlertDialog>
      </Stack>
    </Stack>
  );
};

export default StoreOwnerOrderDetail;
