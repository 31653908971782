import React, { useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Stack } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { ReactChannelIO, useChannelIOApi } from 'react-channel-plugin';

const PreorderSplashLayout = () => {
  const containerRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const isScrollRoute =
      location.pathname.includes('/order/') &&
      (location.pathname.match(/\/order\/[\w-]+$/) ||
        location.pathname.includes('/receipt'));

    if (isScrollRoute && containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <meta name="apple-mobile-web-app-capable" content="yes" />
      </Helmet>
      <ReactChannelIO
        pluginKey="a421a2f2-3eb5-4190-b43d-c64163a7b0e9"
        language="ko"
        hideChannelButtonOnBoot
      >
        <Stack
          ref={containerRef}
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: '-webkit-fill-available',
            maxHeight: '100svh',
            width: '100%',
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            overflowY: 'auto',
            backgroundColor: '#F2F2F2',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Stack
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: '#FFFFFF',
              maxWidth: '500px',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <ChannelTalkCloseOutlet>
              <Outlet />
            </ChannelTalkCloseOutlet>
          </Stack>
        </Stack>
      </ReactChannelIO>
    </>
  );
};

const ChannelTalkCloseOutlet = ({ children }) => {
  const { hideChannelButton } = useChannelIOApi();

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const channelButton = document.querySelector('#ch-plugin');
      if (channelButton) {
        hideChannelButton();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });

    return () => observer.disconnect();
  }, [hideChannelButton]);

  return (
    <Stack
      sx={{
        flexGrow: 1,
        backgroundColor: '#FFFFFF',
        width: '100%'
      }}
    >
      {children}
    </Stack>
  );
};

export default PreorderSplashLayout;
