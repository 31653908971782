import RestService from '../PreOrderApi';

const userService = {
  async getUserData() {
    try {
      const res = await new RestService()
        .setPath(`pre-order/member/info`)

        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getMinibarList() {
    try {
      const res = await new RestService()
        .setPath(`pre-order/member/pickup-spots`)

        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async changeMinibar(data) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/member/pickup-spot`)

        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async cancelUser() {
    try {
      const res = await new RestService()
        .setPath(`pre-order/member/cancellation`)

        .put();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default userService;
