/* eslint-disable no-useless-return */
import {
  Stack,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  IconButton
} from '@mui/material';
import React from 'react';

import env from '@constants/env';
// import productService from '@services/preorder/product';
import { useNavigate } from 'react-router-dom';
import { formatMoney } from '@utils/helpers';

const ProductList = ({
  data,
  params,
  storeData,
  handleAddCart,
  scrollRef,
  isLoading,
  hasMore
}) => {
  const navigate = useNavigate();

  const handleNavigateProductDetail = (product) => {
    if (!product) return;
    navigate(`${storeData?.storeCode}/product/${product?.productCode}`, {
      state: {
        storeName: storeData?.storeName
      }
    });
  };
  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        flexGrow: 1,

        backgroundColor: '#FFFFFF'
      }}
    >
      <Stack
        sx={{
          width: '100%',
          alignItems: 'flex-end',
          px: 2.5,
          mb: 2.5,
          zIndex: 5,
          minHeight: '21px',
          backgroundColor: '#FFFFFF'
        }}
      >
        {params?.totalRow > -1 && (
          <Typography
            sx={{
              color: '#202020',
              fontSize: 14,
              fontWeight: 500,
              textAlign: 'right',
              minWidth: '66px',
              letterSpacing: '-0.56px'
            }}
          >
            검색결과 {params?.totalRow || 0}
          </Typography>
        )}
      </Stack>

      <Grid
        container
        columnSpacing={2}
        rowSpacing={3}
        sx={{
          overflowY: 'auto',
          backgroundColor: '#FFFFFF',
          mb: '50px',
          px: 2.5
        }}
      >
        {data.map((product) => (
          <Grid item xs={6} key={product?.productCode}>
            <Card
              sx={{
                position: 'relative',
                // boxShadow: '0px 0.5px 2px 0.5px'
                borderRadius: 0,
                boxShadow: 0,
                border: 0
              }}
              onClick={() => handleNavigateProductDetail(product)}
            >
              <Stack
                sx={{
                  width: '100%',
                  height: '164px',

                  alignItems: 'center',
                  justifyContent: 'center'

                  // borderBottom: '1px solid grey'
                }}
              >
                <CardMedia
                  component="img"
                  height="100%"
                  sx={{
                    width: '100%',

                    height: '164px',
                    aspectRatio: 1,
                    objectFit: 'cover',
                    borderRadius: 0.5
                  }}
                  image={`${env?.s3BaseUrl}/${product?.imageUrl}`}
                  alt={product.name}
                />
              </Stack>

              <IconButton
                sx={{
                  position: 'absolute',
                  top: '113px',
                  right: '15px',

                  borderRadius: '50%',
                  height: 36,
                  width: 36,
                  backgroundColor: '#FFFFFF !important',
                  boxShadow: '0px 0.5px 2px 0.5px'
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddCart(product);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path d="M8 0L8 16" stroke="#202020" />
                  <path d="M16 8L5.06639e-07 8" stroke="#202020" />
                </svg>
              </IconButton>
              <CardContent
                sx={{
                  px: '0px !important',
                  pb: '0px !important',
                  pt: '6px !important'
                }}
              >
                <Typography
                  sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    lineHeight: '1.5em',
                    fontSize: 14,
                    letterSpacing: '-0.56px',
                    color: '#202020',
                    fontWeight: 500,
                    maxHeight: '3em'
                  }}
                >
                  {product?.productName}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    letterSpacing: '-0.56px',
                    color: '#1B2872',
                    fontWeight: 700
                  }}
                >
                  {formatMoney(product?.price)}원
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
        {hasMore && (
          <div ref={scrollRef} style={{ height: '20px', marginBottom: 50 }} />
        )}
      </Grid>
    </Stack>
  );
};

export default ProductList;
