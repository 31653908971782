import { tableTypes } from '@constants/utils';
import { Formatter } from '@utils/formatter';

import { formatMoney } from '@utils/helpers';
import i18n from '@utils/i18n';

const common = i18n.getFixedT(null, null, 'common');
const g = i18n.getFixedT(null, null, '');
const t = i18n.getFixedT(null, null, 'pages');
const preOrderT = i18n.getFixedT(null, null, 'pages.pre-order.admin');

export const localeOptions = [
  { value: 'all', label: g('common.total') },
  { value: 'ko', label: preOrderT('locales.ko') },
  { value: 'jp', label: preOrderT('locales.jp') }
];

export const orderStatusOptions = [
  { value: 'all', label: g('common.total') },
  { value: 'ORDERED', label: preOrderT('order-status.order-complete') },
  { value: 'CONFIRMED', label: preOrderT('order-status.order-confirm') },
  { value: 'FIXED', label: preOrderT('order-status.fixed') },
  {
    value: 'PICKUP_REQUESTED',
    label: preOrderT('order-status.pickup-request')
  },
  { value: 'PICKUP_COMPLETED', label: preOrderT('order-status.completed') },
  { value: 'CANCELLED', label: preOrderT('order-status.cancelled') }
];
export const localeRadioOptions = [
  { value: 'ko', label: preOrderT('locales.ko') },
  { value: 'jp', label: preOrderT('locales.jp') }
];

const orderStatusparser = {
  ORDERED: preOrderT('order-status.order-complete'),
  CONFIRMED: preOrderT('order-status.order-confirm'),
  FIXED: preOrderT('order-status.fixed'),
  PICKUP_REQUESTED: preOrderT('order-status.pickup-request'),
  PICKUP_COMPLETED: preOrderT('order-status.completed'),
  CANCELLED: preOrderT('order-status.cancelled')
};
const localeRenderObject = {
  ko: preOrderT('locales.ko'),
  jp: preOrderT('locales.jp')
};

export const columns = [
  {
    name: '#',
    label: 'No',
    minWidth: 40,
    align: 'center'
  },

  {
    name: 'orderedDt',
    label: preOrderT('order-date'),
    minWidth: 100,
    align: 'center',
    render: ({ orderedDt }) => orderedDt && Formatter.formatTime(orderedDt)
  },
  {
    name: 'orderName',
    label: preOrderT('order-name'),
    minWidth: 100,
    align: 'center',
    render: ({ orderName }) => (
      <span
        style={{
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2
        }}
      >
        {orderName}
      </span>
    )
  },

  {
    name: 'totalQuantity',
    label: preOrderT('total-quantity'),
    minWidth: 40,
    align: 'center'
  },
  {
    name: 'totalPrice',
    label: preOrderT('total-price'),
    minWidth: 40,
    align: 'center',
    render: (item) => formatMoney(item?.totalPrice)
  },

  {
    name: 'orderStatus',
    label: preOrderT('status'),
    minWidth: 80,
    align: 'center',
    render: ({ status }) => orderStatusparser[status] || status
  }
];
const rerender = (item, value) => {
  console.log('index', value);
  return (
    <span
      style={{
        textDecoration: item.refund === 'Y' ? 'line-through' : '',
        color: item.refund === 'Y' && 'gray'
      }}
    >
      {value}
    </span>
  );
};

export const regularOrderColumns = () => [
  {
    name: '#',
    label: 'No',
    minWidth: 30,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: (item, index) => rerender(item, index)
  },

  {
    name: 'regDate',
    label: preOrderT('purchase-date'),
    minWidth: 95,
    align: 'center',
    isGroup: true,
    groupBy: 'purchaseCode',
    dataKey: 'index',
    render: ({ regDate }) => regDate && Formatter.formatTime(regDate)
  },
  {
    name: 'productName',
    label: preOrderT('order-name'),
    minWidth: 100,

    align: 'center',
    render: ({ productName }) => (
      <span
        style={{
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2
        }}
      >
        {productName}
      </span>
    )
  },

  {
    name: 'quantity',
    label: preOrderT('total-quantity'),
    minWidth: 40,
    align: 'center'
  },
  {
    name: 'price',
    label: preOrderT('price'),
    minWidth: 40,
    align: 'center',
    render: (item) => formatMoney(Number(item?.quantity) * Number(item?.price))
  }
];

export const inventoryColumns = [
  {
    name: '#',
    label: 'No',
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'productName',
    label: g('common.productName'),
    minWidth: 100,
    align: 'center'
  },

  {
    name: 'quantity',
    label: preOrderT('inventory'),
    minWidth: 60,
    align: 'center',
    type: tableTypes.ACTION
  },
  {
    name: 'price',
    label: g('common.price'),
    minWidth: 60,
    align: 'center',
    render: (item) => formatMoney(item?.price)
  }
];
export const inventoryActionOptions = [
  { value: 'DISPLAY', label: preOrderT('inventory-actions.display') },
  { value: 'LOSS', label: preOrderT('inventory-actions.loss') },
  { value: 'DISPOSE', label: preOrderT('inventory-actions.dispose') }
];

export const inventoryActions = {
  DISPLAY: 'DISPLAY',
  LOSS: 'LOSS',
  DISPOSE: 'DISPOSE'
};
export const storeLocales = {
  KO: 'ko',
  JP: 'jp'
};

export const tableStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #D3D3D3'
    },
    th: {
      borderRight: '1px solid #D3D3D3',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FFFFFF',
      minWidth: 120
    },
    td: {
      p: '10px 12px',
      width: 480
    }
  },
  '.MuiTableCell-root': {
    p: '0 12px',
    borderBottom: 'none'
  }
};

export const tableBodyStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      width: 120,
      whiteSpace: 'nowrap'
    },
    td: {
      borderRight: '1px solid #EEEEEE',
      height: 60,
      fontSize: 14,
      color: 'text.primary'
    }
  },
  '.MuiTableCell-root': { p: '0px 12px', borderBottom: 'none' }
};
