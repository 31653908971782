import * as React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';

import { grey } from '@mui/material/colors';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { DialogTitle, Stack, Typography } from '@mui/material';

const drawerBleeding = 55;

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light'
      ? grey[100]
      : theme.palette.background.default
}));

const StyledBox = styled('div')(({ theme }) => ({
  backgroundColor: '#fff'
}));

const Puller = styled('div')(({ theme }) => ({
  width: 80,
  height: 6,
  backgroundColor: '#ADADAD',
  borderRadius: 3,
  marginTop: 8
}));

function SwipeableEdgeDrawer(props) {
  const {
    window,
    open,
    setOpen,
    children,
    title = undefined,
    subTitle,
    sx,
    id,
    StickyComponent = null
  } = props;

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Root>
      {/* <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(100%  - ${drawerBleeding}px)`,
            overflow: 'scroll',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8
          }
        }}
      /> */}

      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={100}
        minFlingVelocity={100}
        allowSwipeInChildren
        SlideProps={{
          easing: { exit: 'cubic-bezier(0.0, 0, 0, 0)' }
        }}
        transitionDuration={{ enter: 300, exit: 0 }}
        PaperProps={{
          sx: {
            height: '100%',
            maxHeight: `calc(100%  - ${drawerBleeding}px)`,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8
          }
        }}
        ModalProps={{
          id,
          keepMounted: true
        }}
      >
        <Stack
          sx={{
            position: 'sticky',
            top: 0,
            left: 0,
            height: '20px',
            width: '100%',
            backgroundColor: '#FFFFFF',
            display: 'flex',
            alignItems: 'center',
            zIndex: 10000
          }}
        >
          <Puller />
        </Stack>

        {title && (
          <DialogTitle
            sx={{
              color: '#212121',
              fontSize: '24px !important',
              fontWeight: '700 !important',
              textAlign: 'left',
              backgroundColor: '#FFFFFF',
              zIndex: 30,
              pt: 2.5,
              px: 2.5,
              mb: 1,

              whiteSpace: { xs: 'pre-line', md: 'nowrap' },
              overflow: 'hidden',

              '$ span': { color: '#244EE2' }
            }}
          >
            {title}

            {subTitle && typeof subTitle === 'function' ? (
              subTitle
            ) : (
              <Typography
                sx={{
                  fontSize: 14,
                  color: '#919193',
                  fontWeight: 500,
                  whiteSpace: { xs: 'pre-line', md: 'nowrap' }
                }}
              >
                {subTitle}
              </Typography>
            )}
          </DialogTitle>
        )}
        <Stack
          id="paper-container"
          sx={{
            pl: 2.5,
            pr: 1.5,
            maxHeight: '100%',
            overflowY: 'scroll',
            // mb: StickyComponent && '111px',
            ...sx
          }}
        >
          {children}
        </Stack>
        {StickyComponent && (
          <Stack
            sx={{
              height: 'auto'
            }}
          >
            {StickyComponent}
          </Stack>
        )}
      </SwipeableDrawer>
    </Root>
  );
}

SwipeableEdgeDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

export default SwipeableEdgeDrawer;
