/* eslint-disable no-plusplus */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Stack, Typography, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import productService from '@services/preorder/product';
import mainImg from 'images/preorder/order_complete.png';
import { useTranslation } from 'react-i18next';
import { Formatter } from '@utils/formatter';
import { useDispatch } from 'react-redux';
import { setAlert } from '@store/reducers/pre-order/app';
import dayjs from 'dayjs';

const CustomTypography = ({ content }) => {
  const bulletPoints = content.split('\n').map((point) => point.trim());

  return (
    <Typography
      sx={{
        '& > div': {
          display: 'flex',
          alignItems: 'baseline',
          marginBottom: '4px'
        },
        '&  span::before': {
          content: '"• "',
          marginRight: '0.5em',
          color: '#9A9CA4',
          fontSize: 14
        },
        '& p': {
          lineHeight: '1.5',
          fontSize: 14,
          fontWeight: 500,
          letterSpacing: '-0.36px',
          color: '#9A9CA4',
          fontFamily: 'Pretendard',
          whiteSpace: 'pre-line',
          wordBreak: 'keep-all', // Prevent breaking words
          overflowWrap: 'break-word',
          margin: 0
        }
      }}
    >
      {bulletPoints.map((point, index) => (
        <div key={index}>
          <span />
          <p>{point}</p>
        </div>
      ))}
    </Typography>
  );
};

const OrderRecceipt = () => {
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState(null);
  const dispatch = useDispatch();
  const { storeCode, version, orderId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setAlert({ message: '주문이 완료되었습니다' }));
    const fetchOrderData = async () => {
      try {
        const rs = await productService.getOrderDetail(orderId);
        if (!rs.error) {
          setOrderData(rs.data);
        }
      } catch (error) {
        console.error('Error fetching store data:', error);
      }
    };
    fetchOrderData();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    localStorage.removeItem('PREORDER_NEW_ORDER_DATA');
  }, []);
  // TODO: calculate cancellableDate

  function calculateMaxCancellableDate(
    pickupRequestedTime,
    storeOrderFixDays,
    businessHours = []
  ) {
    // Create a copy of pickup requested time
    let date = dayjs(pickupRequestedTime);

    // Helper function to check if a specific date is a business day
    const isBusinessDay = (date) => {
      const dayOfWeek = date.format('dddd').toUpperCase();
      return businessHours.some((bh) => bh.dayOfWeek === dayOfWeek);
    };

    // First subtract the store order fix days
    date = date.subtract(storeOrderFixDays, 'day');

    // Keep going backwards until we find a business day
    let attempts = 0;
    while (!isBusinessDay(date)) {
      date = date.subtract(1, 'day');

      // Safety check to prevent infinite loops
      attempts++;
      if (attempts > 365) {
        console.error(
          'No business days found within a year while calculating max cancellable date'
        );
        return dayjs(pickupRequestedTime);
      }
    }

    return date;
  }
  return (
    <Stack
      sx={{
        flexDirection: 'column',
        width: '100%',
        maxWidth: '500px',
        height: '100%',
        minHeight: '100svh',
        flexGrow: 1
      }}
    >
      <Stack
        sx={{
          width: '100%',
          maxWidth: '500px',
          top: 0,
          position: 'fixed',
          backgroundColor: '#FFFFFF',
          zIndex: 1002
        }}
      >
        <Stack
          id="navBarHeader"
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            backgroundColor: '#FFFFFF',
            width: '100%',
            py: '13px',
            px: 2.5,
            height: { xs: '50px' }
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: 1
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 700,
                color: '#202020',
                letterSpacing: '-0.56px'
              }}
            >
              주문완료
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        sx={{
          width: '100%',
          position: 'relative',
          px: 2.5,
          flexGrow: 1,
          mt: '50px',
          mb: '108px',
          alignItems: 'center',

          backgroundColor: '#FFFFFF'
        }}
      >
        <Stack
          sx={{
            width: '100%',
            alignItems: 'center',
            flexGrow: 1,

            justifyContent: 'center'
          }}
        >
          <img
            src={mainImg}
            alt="order complete"
            style={{
              width: '100%',
              minHeight: '200px',
              maxWidth: '300px',
              height: '100%'
            }}
          />
        </Stack>

        <Stack
          sx={{
            width: '100%',
            mt: 1.25,
            pb: 2.5,
            borderBottom: '2px solid #F2F2F2',
            gap: 2.5,
            textAlign: 'center'
          }}
        >
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 700,
              color: '#202020',
              letterSpacing: '-0.8px'
            }}
          >
            주문이 완료되었습니다
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 500,
              color: '#9A9CA4',
              letterSpacing: '-0.56px',
              whiteSpace: 'pre-line'
            }}
          >
            {t('pages.pre-order.receipt-subtitle')}
          </Typography>
          <Stack
            sx={{
              width: '100%',
              backgroundColor: '#F6F6F6',
              borderRadius: 1,
              minHeight: '50px',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Stack sx={{ width: '50%', borderRight: '1px solid #D6D7DB' }}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 700,
                  color: '#202020',
                  letterSpacing: '-0.56px'
                }}
              >
                희망 픽업일자
              </Typography>
            </Stack>
            <Stack sx={{ width: '50%' }}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 700,
                  color: '#202020',
                  letterSpacing: '-0.56px'
                }}
              >
                {Formatter.formatTime(orderData?.pickupDateTime, 'YYYY-MM-DD')}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack sx={{ width: '100%', py: 2.5, gap: 2.5, textAlign: 'left' }}>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 700,
              color: '#202020',
              letterSpacing: '-0.56px'
            }}
          >
            주의사항
          </Typography>
          <Stack>
            {orderData && (
              <CustomTypography
                content={t('pages.pre-order.receipt', {
                  date: calculateMaxCancellableDate(
                    orderData?.pickupDateTime,
                    orderData?.orderFixDays,
                    orderData?.businessHour || []
                  ).format('YYYY-MM-DD')
                })}
              />
            )}
          </Stack>
        </Stack>
      </Stack>

      <Stack
        sx={{
          width: '100%',
          px: 2.5,
          pt: 2.5,
          pb: '30px',
          maxWidth: '500px',
          bottom: 0,
          position: 'fixed',
          backgroundColor: '#FFFFFF',
          zIndex: 1002
        }}
      >
        <Stack sx={{ width: '100%', flexDirection: 'row', gap: 1.5 }}>
          <Button
            sx={{
              width: '50%',
              px: 3,
              height: 48,
              backgroundColor: '#FFFFFF !important',
              border: '1px solid #D6D7DB',
              fontSize: 16,
              letterSpacing: '-0.64px',
              fontWeight: 500,
              borderRadius: 1,
              color: '#9A9CA4 !important'
            }}
            onClick={() =>
              navigate(`/pre-order/ko/orders/${orderData?.orderCode}`)
            }
          >
            주문 내역 보기
          </Button>
          <Button
            sx={{
              width: '50%',
              height: 48,
              backgroundColor: '#1B2872 !important',
              borderRadius: 1,
              fontSize: 16,
              letterSpacing: '-0.64px',
              fontWeight: 500,
              color: '#FFFFFF !important'
            }}
            onClick={() => navigate('/pre-order/ko/home')}
          >
            홈 화면 가기
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default OrderRecceipt;
