import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';
import RestService from '../PreorderAdminApi';

const orderService = {
  // Store

  async getMinibarList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/pre-order/store/minibar/all')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getPickupSpotList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/pre-order/store/pickup/all')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getStoreTypeList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/preorder/store-type')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getOrderList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/pre-order/store/order')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getRegularOrderList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/pre-order/store/order/minibar')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getStoreOrderDetail(orderCode) {
    try {
      const res = await new RestService()
        .setPath(`/pre-order/store/order/${orderCode}`)

        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      console.log('error fetching data hey', err?.response);
      return { error: err?.response || err, data: null };
    }
  },
  async exportOrderData(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/pre-order/store/order/export')
        .setResponseType('blob')
        .setConfig({ params })
        .get();

      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportMinibarPurchaseData(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/pre-order/store/order/minibar/export')
        .setResponseType('blob')
        .setConfig({ params })
        .get();

      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  // 삼정주
  async getOrderDetail(orderCode) {
    try {
      const res = await new RestService()
        .setPath(`/preorder/order/${orderCode}`)
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getPickupQr(orderCode) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/store/delivery/${orderCode}/display-qr`)
        .post();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getOrderDisplayImages(orderCode) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/store/order/${orderCode}/photos`)

        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async cancelOrderByStore(storeCode, orderCode, data) {
    try {
      const res = await new RestService()
        .setPath(
          `pre-order/store/order/${storeCode}/inicis/${orderCode}/full-refund`
        )
        .post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateStoreOrderStatus(orderCode, data) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/store/order/${orderCode}`)
        .put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async uploadDisplayImage(orderCode, data) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/store/order/${orderCode}/photos`)
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default orderService;
