import React from 'react';
import {
  Button,
  Drawer,
  Hidden,
  IconButton,
  Stack,
  Toolbar,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import WorkerHigh from '@img/workers-high-white.png';
import { ReactComponent as ArrowLeftLight } from '@svg/arrow-left-light.svg';
import { useNavigate } from 'react-router-dom';
import useResponsive from '@hooks/useResponsive';
import { preorderAdminConfig } from '@utils/sidebarConfig';
import SidebarMenu from './SidebarMenu';
import Footer from '../Footer';

const Sidebar = ({
  handleDrawerClose,
  open,
  handleLogout,
  drawerWidth,
  handleDrawerOpen,
  headerHeight
}) => {
  const isSm = useResponsive('down', 1000);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const profile = JSON.parse(localStorage.getItem('PREORDER_ADMIN_PROFILE'));

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box'
        }
      }}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
      variant={isSm ? 'temporary' : 'persistent'}
      anchor="left"
      open={open}
    >
      <Toolbar
        sx={{
          height: headerHeight,
          bgcolor: 'primary.darker'
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <input
            type="image"
            src={WorkerHigh}
            alt="workers high logo"
            onClick={() => navigate('/pre-order/ko/admin')}
          />
          <IconButton
            aria-label="hide"
            component="label"
            onClick={handleDrawerClose}
          >
            <ArrowLeftLight width={20} height={20} />
          </IconButton>
        </Stack>
      </Toolbar>
      <Toolbar sx={{ height: 92, bgcolor: 'primary.lighter' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <div>
            <Typography
              variant="h6"
              sx={{ color: 'text.secondary', fontSize: '14px !important' }}
            >
              {profile?.name}
            </Typography>
          </div>
          <Button
            onClick={handleLogout}
            sx={{
              color: 'primary.darker',
              ':hover': {
                background: 'none'
              }
            }}
            type="text"
          >
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 700,
                lineHeight: '20px',
                borderBottom: '2px solid'
              }}
            >
              {t('login.button.logOut')}
            </Typography>
          </Button>
        </Stack>
      </Toolbar>
      <SidebarMenu
        navConfig={preorderAdminConfig}
        isSm={isSm}
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
        isDrawerOpen={open}
        headerHeight={headerHeight}
      />
      <Hidden mdUp>
        {' '}
        <Footer drawerWidth={drawerWidth} isSm={isSm} />
      </Hidden>
    </Drawer>
  );
};

export default Sidebar;
