import { combineReducers } from 'redux';
import appReducer from './appReducer';
import authReducer from './authReducer';
import preOrderReducers from './pre-order';

const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  preOrder: preOrderReducers
});

export default rootReducer;
