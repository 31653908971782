/* eslint-disable prefer-template */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import orderService from '@services/preorder/adminOrder';
import DateRangePicker from '@components/DateRangePicker';

import {
  Button,
  FormControlLabel,
  Hidden,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import SearchBar from '@components/SearchBar';
import { dateFormat } from '@constants/utils';
import { sub } from 'date-fns';
import HelmetContainer from '@components/HelmetContainer';
import MyAutocomplete from '@components/MyAutocomplete';
import MyTable from '@components/MyTable';
import { Formatter } from '@utils/formatter';
import MyTextField from '@components/MyTextField';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
// import MySelect from '@components/MySelect';
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import useResponsive from '@hooks/useResponsive';
import MySelect from '@components/MySelect';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import { columns, orderStatusOptions } from './enhance';

export default function OrderHistory() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [minibarList, setMinibarList] = useState([]);
  const [minibar, setMinibar] = useState(null);
  const isSm = useResponsive('down', 1000);
  const [pagination, setPagination] = useState({
    count: 0,
    totalRows: 0,
    page: 0
  });
  const searchRef = useRef();
  const [expanded, setExpanded] = useState(false);
  const { formatTime } = Formatter;
  const [param, setParam] = useState({
    storeName: '',
    orderStatus: 'all',
    locale: 'all',
    startDate: formatTime(sub(new Date(), { months: 1 }), dateFormat),
    endDate: formatTime(new Date(), dateFormat)
  });

  const [loading, setLoading] = useState(false);

  const getMinibarList = async () => {
    setLoading(true);
    const result = await orderService.getPickupSpotList();
    if (result.error) dispatch(setError(error2Text(result.error)));
    else {
      setMinibarList(result?.data || []);
      if (result?.data) {
        setMinibar(result?.data?.[0]);
      }
    }
    setLoading(false);
  };
  const [searchBarHeight, setSearchbarHeight] = useState(0);
  useEffect(() => {
    if (searchRef.current) {
      console.log('i am running', searchRef.current.clientHeight);
      setSearchbarHeight(searchRef.current.clientHeight);
    }
  }, [expanded]);
  const getData = async (params) => {
    setLoading(true);
    const result = await orderService.getOrderList(params);
    if (result.error) dispatch(setError(error2Text(result.error)));
    else {
      setData(result.data?.items);
      setPagination({
        ...pagination,
        page: result.data?.page || 0,
        totalRows: result.data?.totalRow || 0,
        count: result.data?.totalPage || 0
      });
    }
    setLoading(false);
  };

  const handleExportData = async () => {
    const query = paramsToObj(location?.search);
    const { productName, orderStatus } = query;
    const paramSubmit = {
      startDate: param?.startDate,
      endDate: param?.endDate,
      pickupSpotCode: minibar?.pickupSpotCode,
      productName,
      status: orderStatus === 'all' ? null : orderStatus
    };

    const rs = await orderService.exportOrderData(paramSubmit);
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    }
  };
  const handleSearch = () => {
    const query = {
      startDate: formatTime(param?.startDate, dateFormat),
      endDate: formatTime(param?.endDate || new Date(), dateFormat),
      productName: param?.productName,
      orderStatus: param?.orderStatus || 'all',

      pickupSpotCode: minibar?.pickupSpotCode,
      orderDate: param?.orderDate || '',
      page: 1
    };

    navigate(objToParams(ignoreEmpty(query)));
    getData({
      productName: param?.productName,
      pickupSpotCode: minibar?.pickupSpotCode,
      status: param?.orderStatus === 'all' ? null : param?.orderStatus,

      startDate: query.startDate,
      endDate: query?.endDate,
      page: 0
    });
    setExpanded(false);
  };

  const handleRowClick = (row) => {
    navigate(`/pre-order/ko/store-owner/orders/${row?.orderCode}`);
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const {
      startDate,
      endDate,
      productName,
      orderStatus,
      locale,
      pickupSpotCode,
      page
    } = query;
    const firstDayOfMonth = formatTime(new Date(), 'YYYY-MM') + '-01';
    const dataParams = {
      productName,
      startDate: formatTime(startDate || firstDayOfMonth, dateFormat),
      endDate: formatTime(endDate || new Date(), dateFormat),
      status: orderStatus === 'all' ? null : orderStatus,

      pickupSpotCode: pickupSpotCode || minibar?.pickupSpotCode,
      page: page - 1 || 0
    };
    setParam({
      ...param,
      startDate: formatTime(startDate || firstDayOfMonth, dateFormat),
      endDate: formatTime(endDate || new Date(), dateFormat),
      productName: productName || param?.productName || '',
      orderStatus: orderStatus || param?.orderStatus || 'all',
      locale: locale || 'all'
    });

    setPagination({ ...pagination, page: page - 1 || 0 });
    getData(ignoreEmpty(dataParams));
    if (minibarList?.length < 1) getMinibarList();
  }, [location?.search]);
  const onAction = async (action, value) => {
    if (action === 'selectPeriod' && value?.key === 'selection') {
      setParam({
        ...param,
        startDate: formatTime(value.startDate, dateFormat),
        endDate: formatTime(value.endDate, dateFormat)
      });
    }

    if (action === 'changePage') {
      const query = paramsToObj(location?.search);
      setParam({ ...param, page: value });
      const newQuery = objToParams(
        ignoreEmpty({
          ...query,
          page: value + 1
        })
      );
      navigate(newQuery);
    }
  };
  return (
    <HelmetContainer
      title={t('pageTitle.store-order-list')}
      content="preorder store order"
      sx={{ width: '100%' }}
    >
      <Hidden mdUp>
        <Stack ref={searchRef} sx={{ position: 'relative' }}>
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',

              height: { xs: 24 },
              gap: '8px'
            }}
          >
            <Stack direction="row" alignItems="center" sx={{ gap: 1 }}>
              <IconButton
                variant="outlined"
                onClick={handleExportData}
                disabled={!minibar}
                sx={{
                  height: { md: 44, xs: 36 },
                  backgroundColor: 'transparent !important',
                  border: '1px solid #D3D3D3',
                  borderRadius: 1
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 50 50"
                  width="24px"
                  height="24px"
                >
                  <path d="M 28.875 0 C 28.855469 0.0078125 28.832031 0.0195313 28.8125 0.03125 L 0.8125 5.34375 C 0.335938 5.433594 -0.0078125 5.855469 0 6.34375 L 0 43.65625 C -0.0078125 44.144531 0.335938 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 29.101563 50.023438 29.402344 49.949219 29.632813 49.761719 C 29.859375 49.574219 29.996094 49.296875 30 49 L 30 44 L 47 44 C 48.09375 44 49 43.09375 49 42 L 49 8 C 49 6.90625 48.09375 6 47 6 L 30 6 L 30 1 C 30.003906 0.710938 29.878906 0.4375 29.664063 0.246094 C 29.449219 0.0546875 29.160156 -0.0351563 28.875 0 Z M 28 2.1875 L 28 6.53125 C 27.867188 6.808594 27.867188 7.128906 28 7.40625 L 28 42.8125 C 27.972656 42.945313 27.972656 43.085938 28 43.21875 L 28 47.8125 L 2 42.84375 L 2 7.15625 Z M 30 8 L 47 8 L 47 42 L 30 42 L 30 37 L 34 37 L 34 35 L 30 35 L 30 29 L 34 29 L 34 27 L 30 27 L 30 22 L 34 22 L 34 20 L 30 20 L 30 15 L 34 15 L 34 13 L 30 13 Z M 36 13 L 36 15 L 44 15 L 44 13 Z M 6.6875 15.6875 L 12.15625 25.03125 L 6.1875 34.375 L 11.1875 34.375 L 14.4375 28.34375 C 14.664063 27.761719 14.8125 27.316406 14.875 27.03125 L 14.90625 27.03125 C 15.035156 27.640625 15.160156 28.054688 15.28125 28.28125 L 18.53125 34.375 L 23.5 34.375 L 17.75 24.9375 L 23.34375 15.6875 L 18.65625 15.6875 L 15.6875 21.21875 C 15.402344 21.941406 15.199219 22.511719 15.09375 22.875 L 15.0625 22.875 C 14.898438 22.265625 14.710938 21.722656 14.5 21.28125 L 11.8125 15.6875 Z M 36 20 L 36 22 L 44 22 L 44 20 Z M 36 27 L 36 29 L 44 29 L 44 27 Z M 36 35 L 36 37 L 44 37 L 44 35 Z" />
                </svg>
              </IconButton>

              <Button
                variant="outlined"
                sx={{
                  backgroundColor: 'transparent !important',
                  border: '1px solid #D3D3D3 !important',
                  height: 36,
                  boxShadow: 0,
                  fontSize: '13px !important',
                  borderRadius: '8px ',
                  gap: 0.5
                }}
                onClick={() => setExpanded((prev) => !prev)}
              >
                <Typography
                  sx={{
                    color: '#000000 !important',
                    fontWeight: '500',
                    fontSize: 13
                  }}
                >{`${t('button.search')} 옵션`}</Typography>

                {expanded ? (
                  <CloseIcon sx={{ color: '#000000', fontSize: 14 }} />
                ) : (
                  <AddOutlinedIcon sx={{ color: '#000000', fontSize: 14 }} />
                )}
              </Button>
            </Stack>

            <Stack
              direction="row"
              alignItems="flex-end"
              sx={{ height: '36px' }}
            >
              <Typography
                sx={{
                  fontWeight: 'fontWeightSemiMedium',
                  fontSize: 15,
                  color: 'text.primary'
                }}
                component="span"
              >
                {t('common.number-of-results')}{' '}
                <Typography
                  component="span"
                  sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
                >
                  {pagination?.totalRows || 0}
                </Typography>
              </Typography>
            </Stack>
          </Stack>
          <Stack
            sx={{
              position: 'absolute',
              top: 40,
              display: expanded ? 'flex' : 'none',
              zIndex: 1100,
              maxWidth: '450px'
            }}
          >
            <SearchBar
              sx={{
                display: 'flex',
                flexDirection: 'row',
                boxShadow: '0px 2px 4px 2px rgba(0, 0, 0, 0.1)',
                ...(isSm && { p: 2 })
              }}
            >
              <FormControlLabel
                label={t('labels.period')}
                labelPlacement="start"
                sx={{
                  mx: { xs: 0 },
                  width: { xs: '100%', md: 'auto' },
                  '.MuiTypography-root': {
                    color: '#6F869C',
                    fontWeight: 'fontWeightBold',
                    fontSize: 13,
                    whiteSpace: 'nowrap',
                    minWidth: isSm && '34px',
                    textAlign: 'left',
                    mr: 1
                  }
                }}
                control={
                  <DateRangePicker
                    state={{
                      startDate: new Date(param.startDate),
                      endDate: new Date(param.endDate),
                      key: 'selection'
                    }}
                    fullWidth={isSm}
                    size={isSm && 'small'}
                    setState={(e) => onAction('selectPeriod', e)}
                  />
                }
              />
              <MyAutocomplete
                label={t('common.minibar')}
                textFieldProps={{ placeholder: t('common.master') }}
                value={minibar}
                fullWidth={isSm}
                labelStyle={{ minWidth: isSm && '34px', mx: 0 }}
                isOptionEqualToValue={(option, value) =>
                  option.pickupSpotCode === value?.pickupSpotCode
                }
                getOptionLabel={(option) => option?.pickupSpotName}
                onChange={(e, val) => {
                  if (!val)
                    setParam({ ...param, productName: '', status: 'all' });
                  setMinibar(val);
                }}
                options={minibarList}
                onClose={() => {
                  if (!minibar) {
                    setParam({ ...param, productName: null });
                  }
                }}
                sx={{
                  '.MuiInputBase-root': {
                    bgcolor: 'common.white'
                  },
                  ...(isSm && { width: '100%' })
                }}
                ListboxProps={{ style: { overflow: 'visible' } }}
              />
              <MyTextField
                label={t('common.productName')}
                fullWidth={isSm}
                value={param?.productName}
                sx={{ ...(isSm && { width: '100%' }) }}
                onChange={(e) =>
                  setParam({ ...param, productName: e?.target?.value })
                }
              />

              <MySelect
                label={t('pages.pre-order.admin.status')}
                value={param?.orderStatus}
                data={orderStatusOptions}
                onChange={(e) =>
                  setParam({ ...param, orderStatus: e?.target?.value })
                }
                sx={{ ml: 0 }}
              />

              <Button
                variant="contained"
                onClick={handleSearch}
                disabled={!minibar}
                sx={{ flexGrow: 1, height: 36, borderRadius: 1 }}
              >
                {t('button.search')}
              </Button>
            </SearchBar>
          </Stack>
        </Stack>
      </Hidden>
      <Hidden mdDown>
        <SearchBar
          sx={{
            display: 'flex',
            flexDirection: 'row',
            ...(isSm && { p: 1.25 })
          }}
        >
          <FormControlLabel
            label={t('labels.period')}
            labelPlacement="start"
            sx={{
              mr: { xs: 0 },
              width: { xs: '100%', md: 'auto' },
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                minWidth: isSm && '34px',
                mr: 1
              }
            }}
            control={
              <DateRangePicker
                state={{
                  startDate: new Date(param.startDate),
                  endDate: new Date(param.endDate),
                  key: 'selection'
                }}
                fullWidth={isSm}
                size={isSm && 'small'}
                setState={(e) => onAction('selectPeriod', e)}
              />
            }
          />

          <MyAutocomplete
            label={t('common.minibar')}
            textFieldProps={{ placeholder: t('common.master') }}
            value={minibar}
            fullWidth={isSm}
            labelStyle={{ minWidth: isSm && '34px' }}
            isOptionEqualToValue={(option, value) =>
              option.pickupSpotCode === value?.pickupSpotCode
            }
            getOptionLabel={(option) => option?.pickupSpotName}
            onChange={(e, val) => {
              if (!val) setParam({ ...param, productName: '', status: 'all' });
              setMinibar(val);
            }}
            options={minibarList}
            onClose={() => {
              if (!minibar) {
                setParam({ ...param, productName: null });
              }
            }}
            sx={{
              '.MuiInputBase-root': {
                bgcolor: 'common.white'
              },
              ...(isSm && { width: '100%' })
            }}
            ListboxProps={{ style: { overflow: 'visible' } }}
          />

          <MyTextField
            label={t('common.productName')}
            fullWidth={isSm}
            value={param?.productName}
            sx={{ ...(isSm && { width: '100%' }) }}
            onChange={(e) =>
              setParam({ ...param, productName: e?.target?.value })
            }
          />

          <MySelect
            label={t('pages.pre-order.admin.status')}
            value={param?.orderStatus}
            data={orderStatusOptions}
            onChange={(e) =>
              setParam({ ...param, orderStatus: e?.target?.value })
            }
            sx={{ ml: 2 }}
          />

          <Button
            variant="contained"
            onClick={handleSearch}
            disabled={!minibar}
          >
            {t('button.search')}
          </Button>
        </SearchBar>
      </Hidden>
      <Hidden mdDown>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ mt: { md: '30px', xs: 2 }, mb: { md: 2, xs: 1 } }}
        >
          <Button
            variant="outlined"
            onClick={handleExportData}
            disabled={!minibar}
            sx={{ height: { md: 44, xs: 36 } }}
          >
            {t('button.downloadExcel')}
          </Button>
          <Stack direction="row" alignItems="center">
            <Typography
              sx={{
                fontWeight: 'fontWeightSemiMedium',
                fontSize: 15,
                color: 'text.primary'
              }}
              component="span"
            >
              {t('common.number-of-results')}{' '}
              <Typography
                component="span"
                sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
              >
                {pagination?.totalRows || 0}
              </Typography>
            </Typography>
          </Stack>
        </Stack>
      </Hidden>

      <MyTable
        hover
        rerender
        columns={columns}
        data={data || []}
        onRowClick={handleRowClick}
        pagination={pagination}
        onChangePage={(e, page) => {
          onAction('changePage', page);
        }}
        size="small"
        sx={{ height: '100%' }}
        minusHeight={200}
        {...(isSm && { maxHeight: `calc(100vh - ${240}px)` })}
      />
    </HelmetContainer>
  );
}
