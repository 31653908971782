import React from 'react';
import { Box, Hidden, Stack, Typography } from '@mui/material';

const Footer = ({ open, drawerWidth, isSm }) => {
  return (
    <Box
      component="footer"
      sx={(theme) => ({
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),

        px: '30px',
        py: '17px',
        borderTop: '1px solid #eeeeee',
        position: 'fixed',
        bottom: 0,
        zIndex: 1200,
        bgcolor: 'common.white',
        height: '52px',

        marginLeft: 0,
        width: isSm ? `${drawerWidth}px` : '100%',

        ...(open && {
          width: isSm ? `${drawerWidth}px` : `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
          })
        })
      })}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography sx={{ color: '#979797', fontSize: '11px' }} noWrap>
          Copyright © 2024 Worker&apos;s High. All rights reserved.
        </Typography>
        <Hidden mdDown>
          <Typography sx={{ color: '#979797', fontSize: '11px' }} noWrap>
            Version 1.0.0
          </Typography>
        </Hidden>
      </Stack>
    </Box>
  );
};

export default Footer;
