import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';

import LoadingButton from '@components/buttons/LoadingButton';
import { useTheme } from '@mui/styles';

export default function MyModal({
  open,
  setOpen,
  title = '서비스 문의',
  children,
  onOk,
  onCancel,
  okTitle,
  cancelTitle,
  hasCancelButton,
  hasBackButton,
  isAlert = false,
  hideDialogButton = true,
  loading,
  setLoading,
  sx,
  disabled,
  onClose,
  titleStyle,
  ...rest
}) {
  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
    if (loading) setLoading(false);
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          zIndex: 0,
          borderRadius: { md: '15px', xs: '0px' },
          maxWidth: { xs: '500px' },
          width: { xs: '100%' },

          ...sx
        }
      }}
      fullScreen={isSmallScreen}
      {...rest}
    >
      {title && (
        <DialogTitle sx={{ p: '0px !important' }}>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '100%',
              p: '20px 20px',
              position: 'relative',
              ...titleStyle
            }}
          >
            {hasBackButton && (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{ position: 'absolute', left: 20, p: 0 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                >
                  <path d="M7 1L1 7L7 13" stroke="black" />
                </svg>
              </IconButton>
            )}
            <Typography
              sx={{
                fontSize: { xs: 14 },
                color: '#202020',
                fontWeight: 700,
                textAlign: 'center',
                letterSpacing: '-0.56px'
              }}
            >
              {title}
            </Typography>
          </Stack>
        </DialogTitle>
      )}
      {children && (
        <DialogContent
          sx={{
            p: { xs: '20px 24px' },
            ...sx?.dialogContent
          }}
        >
          {children}
        </DialogContent>
      )}
      {!hideDialogButton && (
        <DialogActions
          sx={{ justifyContent: 'center', mt: '30px', mb: 2.5, p: 0 }}
        >
          {hasCancelButton && (
            <Button
              autoFocus
              onClick={onCancel || handleClose}
              variant="outlined"
              sx={{
                height: '44px',
                minWidth: '110px',
                flex: isAlert ? 1 : 'unset'
              }}
            >
              {cancelTitle || 'CANCEL'}
            </Button>
          )}
          <LoadingButton
            onClick={onOk || handleClose}
            variant="contained"
            sx={{
              minWidth: '90px',
              backgroundColor: disabled ? '#DADBDD' : '#1B2872',
              ':hover': {
                backgroundColor: disabled ? '#DADBDD' : '#1B2872',
                cursor: disabled ? 'not-allowed' : 'pointer',
                borderRadius: 1,
                height: 36,
                color: '#FFFFFF !important'
              },
              maxWidth: '500px',
              borderRadius: 1,
              height: 36,
              color: '#FFFFFF !important',
              fontSize: 14,
              fontWeight: 500,
              letterSpacing: '-0.56px'
            }}
            loading={loading}
            disabled={disabled}
          >
            {okTitle || 'OK'}
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
}
