import qs from 'qs';
import RestService from '../PreOrderApi';

const productService = {
  async getDefaultStoreData(params = {}) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/store/default`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getStoreDetail(storeCode) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/store/${storeCode}`)

        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getOrderInfoDetail(params = {}) {
    try {
      const res = await new RestService()
        .setPath(`/pre-order/order/info`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getOrderDetail(orderId) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/order/inicis/${orderId}`)

        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async refreshProductInfo(params = {}) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/order/products/update`)
        .setConfig({
          params,
          paramsSerializer: (params) =>
            qs.stringify(params, { arrayFormat: 'repeat' })
        })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getProductDetail(storeCode, productCode) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/store/${storeCode}/product/${productCode}`)
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getStoreList(params = {}) {
    try {
      const res = await new RestService()
        .setPath(`/pre-order/store`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getStoreTypeData(params = {}) {
    try {
      const res = await new RestService()
        .setPath(`preorder/storeType`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getStoreTypeList() {
    try {
      const res = await new RestService()
        .setPath(`pre-order/store/types`)
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getProductListByCategory(storeCode, params = {}) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/store/${storeCode}/products`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getCartData() {
    try {
      const res = await new RestService()
        .setPath(`pre-order/member/cart`)

        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getPaymentCardList(params = {}) {
    try {
      const res = await new RestService()
        .setPath(`/pre-order/payment/inicis/cards`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getCartStatus(params = {}) {
    try {
      const res = await new RestService()
        .setPath(`/pre-order/member/cart-check`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async addProductToCart(data) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/member/cart/product`)
        .post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async createOrder(data) {
    try {
      const res = await new RestService()
        .setPath(`/pre-order/order/inicis`)
        .post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async updateProductQuantity(data) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/member/cart/products`)
        .put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteProductFromCart(productCode) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/member/cart/product/${productCode}`)
        .delete();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteAllProductFromCart() {
    try {
      const res = await new RestService()
        .setPath(`pre-order/member/cart/products`)
        .delete();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteOrder(orderCode) {
    try {
      const res = await new RestService()
        .setPath(`/pre-order/order/inicis/${orderCode}`)
        .delete();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async logout() {
    try {
      const res = await new RestService()
        .setPath('client-admin/auth/logout')
        .post();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async changePassword(data) {
    try {
      const res = await new RestService()
        .setPath(`client-admin/info`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  // test payment
  async getTestPaymentData(url, params = {}) {
    try {
      const res = await new RestService()
        .setPath(url)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default productService;
