/* eslint-disable react/no-unknown-property */
import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
// import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveNav } from '@store/reducers/pre-order/app';
import { BOTTOM_NAVS } from '@constants/utils';

const routes = {
  [BOTTOM_NAVS.HOME]: 'home',
  [BOTTOM_NAVS.ORDER_LIST]: 'orders',
  [BOTTOM_NAVS.MY_PAGE]: 'my-page'
};

export default function PreorderBottomNavigation() {
  const [value, setValue] = React.useState('home');
  const navigate = useNavigate();
  const { version } = useParams();
  const dispatch = useDispatch();
  const { activeNav } = useSelector((state) => state.preOrder.app);
  return (
    <Box sx={{ width: 'auto' }}>
      <BottomNavigation
        showLabels
        value={activeNav}
        onChange={(event, newValue) => {
          dispatch(setActiveNav(newValue));
          navigate(`/pre-order/${version}/${routes[newValue]}`);
        }}
      >
        <BottomNavigationAction
          label={
            activeNav === BOTTOM_NAVS.ORDER_LIST ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect width="24" height="24" fill="white" />
                <path
                  d="M0 16.855V8.11943H1.43548V15.7118H5.71421V16.855H0Z"
                  fill="#1B2872"
                />
                <path
                  d="M8.08034 8.11943V16.855H6.64487V8.11943H8.08034Z"
                  fill="#1B2872"
                />
                <path
                  d="M14.6161 10.4185C14.5238 9.61232 13.753 9.14739 12.6729 9.14739C11.5144 9.14739 10.7805 9.69337 10.7805 10.4355C10.7758 11.263 11.7498 11.5872 12.4975 11.7578L13.416 11.9796C14.6253 12.2526 16.1346 12.8668 16.1346 14.4365C16.1346 15.9209 14.8607 17 12.6313 17C10.5127 17 9.18343 16.0232 9.09112 14.4365H10.5312C10.6143 15.3834 11.5282 15.8355 12.6267 15.8355C13.836 15.8355 14.7084 15.2725 14.713 14.4237C14.7084 13.6559 13.933 13.3488 12.9221 13.1014L11.8098 12.8242C10.2866 12.4403 9.34498 11.7322 9.34498 10.5123C9.34498 8.9981 10.7989 8 12.7052 8C14.6253 8 15.9685 9.01517 16.0054 10.4185H14.6161Z"
                  fill="#1B2872"
                />
                <path
                  d="M16.6749 9.26256V8.11943H24V9.26256H21.0598V16.855H19.6243V9.26256H16.6749Z"
                  fill="#1B2872"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect width="24" height="24" fill="white" />
                <path
                  d="M0 16.855V8.11943H1.43548V15.7118H5.71421V16.855H0Z"
                  fill="#202020"
                />
                <path
                  d="M8.08034 8.11943V16.855H6.64487V8.11943H8.08034Z"
                  fill="#202020"
                />
                <path
                  d="M14.6161 10.4185C14.5238 9.61232 13.753 9.14739 12.6729 9.14739C11.5144 9.14739 10.7805 9.69337 10.7805 10.4355C10.7758 11.263 11.7498 11.5872 12.4975 11.7578L13.416 11.9796C14.6253 12.2526 16.1346 12.8668 16.1346 14.4365C16.1346 15.9209 14.8607 17 12.6313 17C10.5127 17 9.18343 16.0232 9.09112 14.4365H10.5312C10.6143 15.3834 11.5282 15.8355 12.6267 15.8355C13.836 15.8355 14.7084 15.2725 14.713 14.4237C14.7084 13.6559 13.933 13.3488 12.9221 13.1014L11.8098 12.8242C10.2866 12.4403 9.34498 11.7322 9.34498 10.5123C9.34498 8.9981 10.7989 8 12.7052 8C14.6253 8 15.9685 9.01517 16.0054 10.4185H14.6161Z"
                  fill="#202020"
                />
                <path
                  d="M16.6749 9.26256V8.11943H24V9.26256H21.0598V16.855H19.6243V9.26256H16.6749Z"
                  fill="#202020"
                />
              </svg>
            )
          }
          value={BOTTOM_NAVS.ORDER_LIST}
        />
        <BottomNavigationAction
          icon={
            activeNav === BOTTOM_NAVS.HOME ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.9809 11.8371C19.5589 11.8371 19.3479 12.0568 19.3479 12.4812V22.5223H14.4123V18.5058C14.4123 18.0739 14.2013 17.7556 13.7868 17.7556H10.3055C9.88353 17.7556 9.46909 18.0739 9.46909 18.5058V22.5223H4.63142V12.898C4.63142 12.3675 4.31493 12.1629 3.99091 12.1629C3.56893 12.1629 3.25245 12.3675 3.25245 12.898V23.3634C3.25245 23.6817 3.56893 24 3.99091 24H10.3055C10.622 24 10.833 23.6817 10.833 23.3634V19.1348H13.0484V23.3634C13.0484 23.6817 13.3573 24 13.7868 24H19.9884C20.4104 24 20.7269 23.6817 20.7269 23.3634V12.4736C20.7269 12.0493 20.4104 11.8295 19.9884 11.8295L19.9809 11.8371ZM23.7711 12.3675C19.2424 7.50995 16.5071 4.55447 12.4003 0.219766C12.3024 0.113672 12.1893 0 11.9784 0C11.7674 0 11.6619 0.106094 11.4509 0.219766L0.208181 12.2614C0.102687 12.36 -0.00280762 12.5721 -0.00280762 12.7919C-0.00280762 13.0117 0.0951516 13.1026 0.208181 13.2163C0.51713 13.6407 0.939108 13.6407 1.15763 13.2163L11.9859 1.79602L22.8217 13.4285C22.9196 13.527 23.0327 13.6407 23.2437 13.6407C23.4546 13.6407 23.6656 13.5346 23.7711 13.4285C24.0801 13.1102 24.0801 12.5797 23.7711 12.3675Z"
                  fill="#1B2872"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.9809 11.8371C19.5589 11.8371 19.3479 12.0568 19.3479 12.4812V22.5223H14.4123V18.5058C14.4123 18.0739 14.2013 17.7556 13.7868 17.7556H10.3055C9.88353 17.7556 9.46909 18.0739 9.46909 18.5058V22.5223H4.63142V12.898C4.63142 12.3675 4.31493 12.1629 3.99091 12.1629C3.56893 12.1629 3.25245 12.3675 3.25245 12.898V23.3634C3.25245 23.6817 3.56893 24 3.99091 24H10.3055C10.622 24 10.833 23.6817 10.833 23.3634V19.1348H13.0484V23.3634C13.0484 23.6817 13.3573 24 13.7868 24H19.9884C20.4104 24 20.7269 23.6817 20.7269 23.3634V12.4736C20.7269 12.0493 20.4104 11.8295 19.9884 11.8295L19.9809 11.8371ZM23.7711 12.3675C19.2424 7.50995 16.5071 4.55447 12.4003 0.219766C12.3024 0.113672 12.1893 0 11.9784 0C11.7674 0 11.6619 0.106094 11.4509 0.219766L0.208181 12.2614C0.102687 12.36 -0.00280762 12.5721 -0.00280762 12.7919C-0.00280762 13.0117 0.0951516 13.1026 0.208181 13.2163C0.51713 13.6407 0.939108 13.6407 1.15763 13.2163L11.9859 1.79602L22.8217 13.4285C22.9196 13.527 23.0327 13.6407 23.2437 13.6407C23.4546 13.6407 23.6656 13.5346 23.7711 13.4285C24.0801 13.1102 24.0801 12.5797 23.7711 12.3675Z"
                  fill="#202020"
                />
              </svg>
            )
          }
          value={BOTTOM_NAVS.HOME}
        />
        <BottomNavigationAction
          label={
            activeNav === BOTTOM_NAVS.MY_PAGE ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect width="24" height="24" fill="white" />
                <path
                  d="M4 8H5.60298L8.35215 14.9741H8.45366L11.1986 8H12.8016V17H11.5454V10.5049H11.4651L8.9189 16.9868H7.87845L5.33229 10.4917H5.26039V17H4V8Z"
                  fill="#1B2872"
                />
                <path
                  d="M13.4038 8H14.9011L17.1554 12.0825H17.2484L19.5028 8H21L17.8617 13.4712V17H16.5421V13.4712L13.4038 8Z"
                  fill="#1B2872"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect width="24" height="24" fill="white" />
                <path
                  d="M4 8H5.60298L8.35215 14.9741H8.45366L11.1986 8H12.8016V17H11.5454V10.5049H11.4651L8.9189 16.9868H7.87845L5.33229 10.4917H5.26039V17H4V8Z"
                  fill="#202020"
                />
                <path
                  d="M13.4038 8H14.9011L17.1554 12.0825H17.2484L19.5028 8H21L17.8617 13.4712V17H16.5421V13.4712L13.4038 8Z"
                  fill="#202020"
                />
              </svg>
            )
          }
          value={BOTTOM_NAVS.MY_PAGE}
        />
      </BottomNavigation>
    </Box>
  );
}
