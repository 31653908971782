/* eslint-disable no-plusplus */
/* eslint-disable no-continue */
/* eslint-disable no-lonely-if */
import React, { useEffect, useState } from 'react';
import { Stack, Typography, Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import productService from '@services/preorder/product';
import env from '@constants/env';
import signUpService from '@services/preorder/auth';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { formatCardNumber, formatMoney } from '@utils/helpers';
import { days } from 'pages-preorder/orders/enhance';
import MyTextField from '@components/inputs/MyTextField';
import AlertDialog from 'pages-preorder/components/Modal/Alert/AlertDialog';
import { Formatter } from '@utils/formatter';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import moment from 'moment';
import MyModal from 'pages-preorder/components/Modal';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import OrderAccordion from './components/OrderAccordion';

const StyledMobileDatePicker = styled(MobileDatePicker)(({ theme }) => ({
  '& .MuiInputBase-input': {
    '-webkit-user-select': 'none !important',
    '-moz-user-select': 'none !important',
    '-ms-user-select': 'none !important',
    'user-select': 'none !important',
    '-webkit-tap-highlight-color': 'rgba(0,0,0,0) !important',
    'caret-color': 'transparent !important'
  },
  // Disable text selection globally for this component
  '&, & *': {
    '-webkit-user-select': 'none !important',
    '-moz-user-select': 'none !important',
    '-ms-user-select': 'none !important',
    'user-select': 'none !important'
  },
  // Force disable highlighting on touch
  '@media (hover: none)': {
    '& *': {
      '-webkit-tap-highlight-color': 'transparent !important'
    }
  }
}));
const Checkout = () => {
  const navigate = useNavigate();
  const [cartData, setCartData] = useState(null);

  const location = useLocation();
  const { storeCode, version } = useParams();
  const [policyList, setPolicyList] = useState([]);
  const [dialog, setDialog] = useState({
    open: false
  });
  const [errMsg, setErrMsg] = useState({ message: '' });
  const [orderData, setOrderData] = useState({
    paymentType: null,
    preferedDate: '',
    message: ''
  });
  const [alertDialog, setAlertDialog] = useState({
    open: false
  });
  const getPolicyList = async () => {
    try {
      const rs = await signUpService.getTermsAndPolicy({ locale: version });
      if (rs?.data) {
        setPolicyList(rs.data.map((policy) => ({ ...policy })));
      }
    } catch (error) {
      console.error('Error fetching policy list:', error);
    }
  };

  useEffect(() => {
    const fetchStoreData = async () => {
      try {
        const rs = await productService.getOrderInfoDetail({ storeCode });
        if (!rs.error) {
          const orderSavedData = localStorage.getItem(
            'PREORDER_NEW_ORDER_DATA'
          );
          if (orderSavedData) {
            const savedData = JSON.parse(orderSavedData);
            if (savedData?.storeCode === storeCode) {
              console?.log(
                'i am running',
                savedData?.storeCode,
                savedData?.preferedDate
              );
              setCartData({
                ...cartData,
                cartProducts: savedData?.productList
              });
              setOrderData((prev) => ({
                ...orderData,
                ...rs.data,
                paymentType: rs?.data?.paymentInfoCode
                  ? 'INICIS_BILLING'
                  : null,
                preferedDate: savedData?.preferedDate,
                message: savedData?.message
              }));
            }
          } else {
            setOrderData((prev) => ({
              ...orderData,
              ...rs.data,
              paymentType: rs?.data?.paymentInfoCode ? 'INICIS_BILLING' : null
            }));
          }
        }
      } catch (error) {
        console.error('Error fetching store data:', error);
      }
    };
    getPolicyList();
    fetchStoreData();
    if (location?.state?.cartData) setCartData(location?.state?.cartData);
    if (location?.state?.originalPath)
      localStorage.setItem(
        'PREORDER_ORDER_ORIGINAL_PATH',
        JSON.stringify(location.state?.originalPath)
      );
  }, []);

  const refreshProductDetails = async () => {
    const dataSubmit = {
      productCodes: cartData?.cartProducts?.map((i) => i.productCode).join(',')
    };

    const rs = await productService.refreshProductInfo(dataSubmit);
    if (!rs?.error) {
      const updatedCartProducts = rs?.data.map((product) => {
        const existingProduct = cartData.cartProducts.find(
          (p) => p.productCode === product.productCode
        );
        return {
          ...product,
          quantity: existingProduct?.quantity ?? 1
        };
      });

      setCartData({ ...cartData, cartProducts: updatedCartProducts });
    }
  };

  function calculateFutureWorkingDay(
    today,
    storeOrderFixDays,
    businessHours = []
  ) {
    let date = today.clone();

    const isBusinessDay = (date) => {
      const dayOfWeek = date.format('dddd').toUpperCase();
      return businessHours.some((bh) => bh.dayOfWeek === dayOfWeek);
    };

    const isTodayHoliday = !isBusinessDay(date);

    if (isTodayHoliday) {
      let consecutiveHolidays = 0;
      let tempDate = date.clone();

      while (!isBusinessDay(tempDate)) {
        consecutiveHolidays++;

        tempDate = tempDate.add(1, 'day');

        if (consecutiveHolidays > 7) {
          console.error('No business days found within a week');
          return date;
        }
      }

      date = date.add(consecutiveHolidays, 'day');

      date = date.add(storeOrderFixDays, 'day');
    } else {
      if (storeOrderFixDays === 1) {
        let tomorrow = date.add(1, 'day');

        if (!isBusinessDay(tomorrow)) {
          let attempts = 0;

          while (!isBusinessDay(tomorrow)) {
            tomorrow = tomorrow.add(1, 'day');

            attempts++;
            if (attempts > 7) {
              console.error('No business days found within a week');
              return date;
            }
          }
          return tomorrow;
        }
        return tomorrow;
      }

      date = date.add(storeOrderFixDays, 'day');

      let attempts = 0;
      while (!isBusinessDay(date)) {
        date = date.add(1, 'day');

        attempts++;
        if (attempts > 7) {
          console.error('No business days found within a year');
          return today;
        }
      }
    }

    return date;
  }

  const { t } = useTranslation();

  const handleCreateOrder = async () => {
    const dataSubmit = {
      storeCode,
      cartOrder: cartData?.type !== 'direct',
      paymentMethod: orderData?.paymentType,
      paymentInfoCode:
        orderData?.paymentType === 'INICIS_BILLING'
          ? orderData?.paymentInfoCode
          : null,
      customerRequest: orderData?.message || '',
      pickupDateTime: moment(orderData?.preferedDate).format(
        'YYYY-MM-DDTHH:mm:ss'
      ),
      products: cartData?.cartProducts?.map((i) => ({
        productName: i?.productName,
        price: i?.price,
        productCode: i?.productCode,
        quantity: i?.quantity
      }))
    };

    const rs = await productService.createOrder(dataSubmit);

    if (!rs?.error) {
      const { orderCode } = rs.data;

      const saveData = {
        storeCode,
        productList: cartData?.cartProducts,
        preferedDate: orderData?.preferedDate,
        message: orderData?.message
      };
      localStorage.setItem('PREORDER_NEW_ORDER_DATA', JSON.stringify(saveData));
      navigate(`/pre-order/${version}/store/${storeCode}/order/${orderCode}`, {
        state: {
          orderData: { ...orderData, orderProducts: cartData?.cartProducts }
        }
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      if (rs?.error.keyError === 'PREORDER_ORDER_PRODUCT_INFO_CHANGED') {
        setAlertDialog({
          open: true,
          okTitle: '확인',
          onOk: () => refreshProductDetails(),
          subtitle: t('pages.pre-order.alert.product-changed')
        });
      }
    }
  };
  const handleBackNavigation = () => {
    const savedPath = localStorage.getItem('PREORDER_ORDER_ORIGINAL_PATH');
    if (savedPath) {
      navigate(`${JSON.parse(savedPath)}`);
      localStorage.removeItem('PREORDER_ORDER_ORIGINAL_PATH');
    } else navigate(-1);
  };

  const handleQuantityChange = (index, change) => {
    setCartData((prevData) => {
      const updatedProducts = [...prevData.cartProducts];
      const newQuantity = Math.max(
        1,
        Math.min(999, updatedProducts[index].quantity + change)
      );
      updatedProducts[index] = {
        ...updatedProducts[index],
        quantity: newQuantity
      };

      const newCartData = { ...prevData, cartProducts: updatedProducts };

      return newCartData;
    });
  };

  const calculateTotalPrice = () => {
    if (!cartData || !cartData.cartProducts) return 0;
    return cartData.cartProducts.reduce((total, product) => {
      return total + product.price * product.quantity;
    }, 0);
  };

  const handleChangeOrderData = (name, value) => {
    if (name === 'message' && value.length >= 100) {
      setErrMsg({ ...errMsg, message: '100자 이내로 작성해 주세요.' });
      setOrderData({ ...orderData, [name]: value });
      return;
    }
    setOrderData({ ...orderData, [name]: value });
    setErrMsg({ ...errMsg, [name]: '' });
  };

  const isButtonDisabled = () => {
    return (
      !orderData?.paymentType ||
      calculateTotalPrice() === 0 ||
      !cartData?.cartProducts ||
      !orderData?.preferedDate ||
      orderData?.message?.length >= 100
    );
  };

  const handleNavigateCardManagement = () => {
    const saveData = {
      storeCode,
      productList: cartData?.cartProducts,
      preferedDate: orderData?.preferedDate,
      message: orderData?.message
    };
    localStorage.setItem('PREORDER_NEW_ORDER_DATA', JSON.stringify(saveData));
    navigate(`/pre-order/${version}/payment/cards`, {
      state: {
        originalPath: location?.pathname
      }
    });
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
      <Stack
        sx={{
          flexDirection: 'column',
          width: '100%',
          maxWidth: '500px',
          height: '100%',
          minHeight: '100vh',
          flexGrow: 1
        }}
      >
        <Stack
          sx={{
            width: '100%',
            maxWidth: '500px',
            top: 0,
            position: 'fixed',
            backgroundColor: '#FFFFFF',
            zIndex: 1002
          }}
        >
          <Stack
            id="navBarHeader"
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              backgroundColor: '#FFFFFF',
              width: '100%',
              py: '13px',
              position: 'relative',
              px: 2.5,
              height: { xs: '50px' }
            }}
          >
            <Stack sx={{ position: 'absolute', left: '20px' }}>
              <IconButton
                size="small"
                sx={{ px: 0 }}
                onClick={() => {
                  handleBackNavigation();

                  localStorage.removeItem('PREORDER_NEW_ORDER_DATA');
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                >
                  <path d="M7 1L1 7L7 13" stroke="black" />
                </svg>
              </IconButton>
            </Stack>

            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                flexGrow: 1
              }}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 700,
                  color: '#202020',
                  letterSpacing: '-0.56px'
                }}
              >
                주문목록
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          sx={{
            width: '100%',
            marginTop: '50px',
            height: '60px',
            backgroundColor: '#FFFFFF'
          }}
        >
          <Stack
            sx={{
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'space-between',
              py: 2.5,
              px: 2.5,
              backgroundColor: '#E9EBF6'
            }}
          >
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 1
              }}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  color: '#202020',
                  fontWeight: 700,
                  letterSpacing: '-0.56px'
                }}
              >
                {orderData?.pickupLocationName}
              </Typography>

              <Stack
                sx={{
                  backgroundColor: '#1B2872',
                  borderRadius: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '18px',
                  p: '4px 8px'
                }}
              >
                {' '}
                <Typography
                  sx={{
                    fontSize: 10,
                    color: '#FFFFFF',
                    fontWeight: 500,
                    letterSpacing: '-0.4px'
                  }}
                >
                  픽업 장소
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        {/* store info */}
        <Stack
          sx={{ width: '100%', px: 2.5, py: 2.5, backgroundColor: '#FFFFFF' }}
        >
          <OrderAccordion data={{ title: '상점정보' }} defaultExpanded>
            {orderData && (
              <Stack
                sx={{
                  width: '100%',
                  flexGrow: 1,
                  backgroundColor: '#FFFFFF',
                  textAlign: 'left'
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    letterSpacing: '-0.56px',
                    fontWeight: 700,
                    color: '#202020'
                  }}
                >
                  {orderData?.storeName}
                </Typography>
                <Stack
                  sx={{
                    width: '100%',
                    pt: 2.5,
                    gap: 1.5
                  }}
                >
                  <Stack
                    sx={{
                      flexGrow: 1,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        letterSpacing: '-0.48px',
                        fontWeight: 500,
                        color: '#9A9CA4'
                      }}
                    >
                      주소
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 12,
                        letterSpacing: '-0.48px',
                        fontWeight: 500,
                        color: '#202020'
                      }}
                    >
                      {orderData?.storeAddress}
                    </Typography>
                  </Stack>

                  {/* businesshours */}
                  {orderData?.businessHours?.length > 0 && (
                    <Stack
                      sx={{
                        flexGrow: 1,
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 12,
                          letterSpacing: '-0.48px',
                          fontWeight: 500,
                          color: '#9A9CA4'
                        }}
                      >
                        영업시간
                      </Typography>
                      <Stack sx={{ gap: 0.5, justifyContent: 'flex-end' }}>
                        {days.map((item) => {
                          const workingDay = orderData?.businessHours?.find(
                            (i) => i?.dayOfWeek === item?.value
                          );

                          return (
                            <Stack
                              key={`${item?.value}-${item?.label}`}
                              sx={{
                                flexDirection: 'row',
                                gap: 1.5,
                                justifyContent: 'flex-end'
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  fontWeight: 500,
                                  color: '#202020',
                                  letterSpacing: '-0.48px'
                                }}
                              >
                                {item?.label}
                              </Typography>

                              {!workingDay ? (
                                <Typography
                                  sx={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    color: '#F25D5D',
                                    letterSpacing: '-0.48px',
                                    minWidth: '69px',
                                    textAlign: 'right'
                                  }}
                                >
                                  휴무
                                </Typography>
                              ) : (
                                <Typography
                                  sx={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    color: '#202020',
                                    letterSpacing: '-0.48px',
                                    minWidth: '69px',
                                    textAlign: 'right'
                                  }}
                                >
                                  {Formatter.formatTime2(workingDay?.openAt)} -{' '}
                                  {Formatter.formatTime2(workingDay?.closeAt)}
                                </Typography>
                              )}
                            </Stack>
                          );
                        })}
                      </Stack>
                    </Stack>
                  )}
                  <Stack
                    sx={{
                      flexGrow: 1,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        letterSpacing: '-0.48px',
                        fontWeight: 500,
                        color: '#9A9CA4'
                      }}
                    >
                      전화번호
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 12,
                        letterSpacing: '-0.48px',
                        fontWeight: 500,
                        color: '#202020'
                      }}
                    >
                      {Formatter.formatKoreanPhoneNumber(
                        orderData?.storeContact
                      )}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </OrderAccordion>
        </Stack>
        <Stack
          sx={{ width: '100%', height: '5px', backgroundColor: '#F2F2F2' }}
        >
          <Stack
            sx={{ width: '100%', height: '1px', backgroundColor: '#E7E7E7' }}
          />
        </Stack>

        {/* product info */}
        <Stack
          sx={{ width: '100%', px: 2.5, py: 2.5, backgroundColor: '#FFFFFF' }}
        >
          <OrderAccordion data={{ title: '주문 상품' }} defaultExpanded>
            {cartData && (
              <Stack
                sx={{
                  width: '100%',
                  flexGrow: 1,
                  backgroundColor: '#FFFFFF'
                }}
              >
                {cartData.cartProducts?.map((item, index) => (
                  <Stack
                    key={`${item.productCode}-${item.productName}`}
                    sx={{
                      width: '100%',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      gap: 1,
                      pb: 2.5,
                      pt: index === 0 ? 1 : 2.5,
                      borderBottom: '1px solid #F2F2F2'
                    }}
                  >
                    <Stack sx={{ flexGrow: 1, flexDirection: 'row' }}>
                      <Stack
                        sx={{
                          width: '100%',
                          flexDirection: 'row',
                          gap: 1.5,
                          justifyContent: 'flex-start'
                        }}
                      >
                        <Stack
                          sx={{
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <img
                            src={`${env.s3BaseUrl}/${item.imageUrl}`}
                            alt={item.productName}
                            style={{
                              width: '100px',

                              height: '100px',
                              objectFit: 'cover'
                            }}
                          />
                        </Stack>
                        <Stack
                          sx={{
                            gap: 1.5,
                            flexGrow: 1,
                            justifyContent: 'space-between'
                          }}
                        >
                          <Stack sx={{ gap: 0.5 }}>
                            <Stack
                              sx={{
                                flexDirection: 'row',
                                gap: 1,
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: 14,
                                  letterSpacing: '-0.56px',
                                  fontWeight: 500,
                                  display: '-webkit-box',
                                  overflow: 'hidden',
                                  WebkitBoxOrient: 'vertical',
                                  WebkitLineClamp: 2,
                                  color: '#202020'
                                }}
                              >
                                {item.productName}
                              </Typography>
                            </Stack>
                            <Typography
                              sx={{
                                fontSize: 14,
                                letterSpacing: '-0.56px',
                                fontWeight: 700,
                                color: '#1B2872'
                              }}
                            >
                              {formatMoney(item.price)}원
                            </Typography>
                          </Stack>
                          <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
                            <Stack
                              sx={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 1,
                                p: '4px 10px',
                                height: '30px',
                                backgroundColor: '#FFFFFF',
                                border: '1px solid #D6D7DB',
                                borderRadius: 1
                              }}
                            >
                              <IconButton
                                size="small"
                                sx={{
                                  backgroundColor: 'transparent !important',
                                  px: 0
                                }}
                                onClick={() => handleQuantityChange(index, -1)}
                                disabled={item.quantity <= 1}
                              >
                                <RemoveIcon
                                  sx={{ color: '#202020', fontSize: 14 }}
                                  fontSize="small"
                                />
                              </IconButton>
                              <Typography
                                sx={{
                                  fontSize: 14,
                                  letterSpacing: '-0.48px',
                                  fontWeight: 500,
                                  color: '#202020',
                                  textAlign: 'center',
                                  minWidth: '27px'
                                }}
                              >
                                {item.quantity}
                              </Typography>
                              <IconButton
                                size="small"
                                sx={{
                                  backgroundColor: 'transparent !important',
                                  px: 0
                                }}
                                onClick={() => handleQuantityChange(index, 1)}
                                disabled={item.quantity >= 999}
                              >
                                <AddIcon
                                  sx={{ color: '#202020', fontSize: 14 }}
                                />
                              </IconButton>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            )}
          </OrderAccordion>
        </Stack>

        {/* divider */}
        <Stack
          sx={{ width: '100%', height: '5px', backgroundColor: '#F2F2F2' }}
        >
          <Stack
            sx={{ width: '100%', height: '1px', backgroundColor: '#E7E7E7' }}
          />
        </Stack>
        <Stack
          sx={{ width: '100%', py: 2.5, px: 2.5, backgroundColor: '#FFFFFF' }}
        >
          {/* title */}
          <Stack
            sx={{
              width: '100%',
              textAlign: 'left',
              pb: 1.5,
              borderBottom: '1px solid #F2F2F2'
            }}
          >
            <Typography
              sx={{
                color: '#202020',
                fontSize: { xs: 14 },
                fontWeight: 700,
                letterSpacing: '-0.56px'
              }}
            >
              희망 픽업 일자
            </Typography>
          </Stack>

          {/* calendar */}
          <Stack sx={{ flexDirection: 'column', gap: 0.5, mt: 2.5 }}>
            <Stack sx={{ flexDirection: 'row', alignItems: 'flex-end' }}>
              {orderData && (
                <StyledMobileDatePicker
                  sx={{ width: '100%' }}
                  disablePast
                  minDate={calculateFutureWorkingDay(
                    dayjs(),
                    orderData.storeOrderFixDays,
                    orderData.businessHours || []
                  )}
                  // defaultValue={dayjs('2022-04-17')}
                  value={
                    orderData?.preferedDate
                      ? dayjs(orderData?.preferedDate)
                      : null
                  } // Ensure value is a dayjs object
                  onAccept={(val) =>
                    handleChangeOrderData(
                      'preferedDate',
                      dayjs(val).format('YYYY-MM-DD')
                    )
                  } // Store as string
                  autoFocus={false}
                  slotProps={{
                    toolbar: {
                      hidden: true
                    },
                    textField: {
                      inputProps: {
                        style: {
                          WebkitTapHighlightColor: 'transparent !important',
                          WebkitUserSelect: 'none !important',
                          MozUserSelect: 'none !important',
                          msUserSelect: 'none !important',
                          userSelect: 'none !important',
                          caretColor: 'transparent !important',
                          '-webkit-touch-callout': 'none !important'
                        }
                      },
                      InputProps: {
                        sx: {
                          height: '40px',
                          // Add these styles to the wrapper
                          '& input': {
                            WebkitTapHighlightColor: 'transparent',
                            WebkitUserSelect: 'none !important',
                            MozUserSelect: 'none !important',
                            msUserSelect: 'none !important',
                            userSelect: 'none !important',
                            caretColor: 'transparent !important',
                            '-webkit-touch-callout': 'none !important'
                          }
                        },
                        autoFocus: false,
                        style: {
                          WebkitUserSelect: 'none',
                          userSelect: 'none',
                          caretColor: 'transparent'
                        },
                        placeholder: '희망 픽업 일자 선택',
                        endAdornment: (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <rect
                              x="3.42969"
                              width="1.14286"
                              height="2.28571"
                              fill="#DADBDD"
                            />
                            <rect
                              x="11.4297"
                              width="1.14286"
                              height="2.28571"
                              fill="#DADBDD"
                            />
                            <rect
                              x="16"
                              y="2.28516"
                              width="3.42857"
                              height="16"
                              transform="rotate(90 16 2.28516)"
                              fill="#DADBDD"
                            />
                            <rect
                              y="5.71436"
                              width="1.14286"
                              height="10.2857"
                              fill="#DADBDD"
                            />
                            <rect
                              x="14.8555"
                              y="14.9712"
                              width="1.02857"
                              height="13.7143"
                              transform="rotate(90 14.8555 14.9712)"
                              fill="#DADBDD"
                            />
                            <rect
                              x="14.8555"
                              y="5.71436"
                              width="1.14286"
                              height="10.2857"
                              fill="#DADBDD"
                            />
                          </svg>
                        )
                      }
                    }
                  }}
                  format="YYYY년 MM월 DD일" // Date format in Korean
                />
              )}
            </Stack>

            {orderData && (
              <Typography
                sx={{
                  fontSize: { xs: 10 },
                  color: '#9A9CA4',
                  fontWeight: 500,
                  letterSpacing: '-0.4px'
                }}
              >
                {calculateFutureWorkingDay(
                  dayjs(),
                  orderData.storeOrderFixDays,
                  orderData.businessHours || []
                )?.format('YYYY-MM-DD')}{' '}
                부터 픽업 가능합니다.
              </Typography>
            )}
          </Stack>
        </Stack>

        {/* divider */}
        <Stack
          sx={{ width: '100%', height: '5px', backgroundColor: '#F2F2F2' }}
        >
          <Stack
            sx={{ width: '100%', height: '1px', backgroundColor: '#E7E7E7' }}
          />
        </Stack>

        {/* message */}
        <Stack
          sx={{ width: '100%', py: 2.5, px: 2.5, backgroundColor: '#FFFFFF' }}
        >
          {/* title */}
          <Stack
            sx={{
              width: '100%',
              textAlign: 'left',
              pb: 1.5,
              borderBottom: '1px solid #F2F2F2'
            }}
          >
            <Typography
              sx={{
                color: '#202020',
                fontSize: { xs: 14 },
                fontWeight: 700,
                letterSpacing: '-0.56px'
              }}
            >
              상점 요청사항(선택)
            </Typography>
          </Stack>

          {/* calendar */}
          <Stack sx={{ flexDirection: 'column', gap: 0.5, mt: 2.5 }}>
            <Stack sx={{ flexDirection: 'row', alignItems: 'flex-end' }}>
              <MyTextField
                preorder
                required
                errMg={errMsg?.message}
                fullWidth
                placeholder="요청사항을 100자 이내로 작성해 주세요."
                value={orderData?.message}
                sx={{ p: 0 }}
                onChange={(e) =>
                  handleChangeOrderData('message', e.target.value)
                }
                multiline
                rows={4}
              />
            </Stack>

            {errMsg?.message && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#F03636',
                  letterSpacing: '-0.48px'
                }}
              >
                {errMsg?.message}
              </Typography>
            )}
          </Stack>
        </Stack>

        {/* divider */}
        <Stack
          sx={{ width: '100%', height: '5px', backgroundColor: '#F2F2F2' }}
        >
          <Stack
            sx={{ width: '100%', height: '1px', backgroundColor: '#E7E7E7' }}
          />
        </Stack>

        {/* message */}
        <Stack
          sx={{ width: '100%', py: 2.5, px: 2.5, backgroundColor: '#FFFFFF' }}
        >
          {/* title */}
          <Stack
            sx={{
              width: '100%',
              textAlign: 'left',
              pb: 1.5,
              borderBottom: '1px solid #F2F2F2'
            }}
          >
            <Typography
              sx={{
                color: '#202020',
                fontSize: { xs: 14 },
                fontWeight: 700,
                letterSpacing: '-0.56px'
              }}
            >
              결제수단
            </Typography>
          </Stack>

          {/* cards */}
          <Stack sx={{ flexDirection: 'column', gap: 0.5, mt: 2.5 }}>
            {orderData?.paymentInfoCode ? (
              <Stack sx={{ width: '100%', gap: 1.5 }}>
                {/* header */}
                <Stack
                  sx={{
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                      letterSpacing: '-0.56px',
                      fontWeight: 500,
                      color: '#202020'
                    }}
                  >
                    카드 간편결제
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: 12,
                      letterSpacing: '-0.48px',
                      fontWeight: 500,
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 1,
                      alignItems: 'center',
                      color: '#1B2872'
                    }}
                    onClick={() => handleNavigateCardManagement()}
                  >
                    카드 변경{' '}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6"
                      height="10"
                      viewBox="0 0 6 10"
                      fill="none"
                    >
                      <path d="M1 9L5 5L1 1" stroke="#1B2872" />
                    </svg>
                  </Typography>
                </Stack>
                {/* card content */}
                <Stack
                  sx={{ width: '100%', gap: 1 }}
                  onClick={() =>
                    setOrderData((prev) => ({
                      ...prev,
                      paymentType: 'INICIS_BILLING'
                    }))
                  }
                >
                  <Stack
                    sx={{
                      width: '100%',
                      borderRadius: 1,
                      overflow: 'hidden',
                      border:
                        orderData?.paymentType === 'INICIS_BILLING'
                          ? '1px solid #1B2872'
                          : '1px solid #D6D7DB'
                    }}
                  >
                    {/* card header */}
                    <Stack
                      sx={{
                        width: '100%',
                        px: 1.5,
                        py: 2,
                        backgroundColor:
                          orderData?.paymentType === 'INICIS_BILLING'
                            ? '#E9EBF6'
                            : '#F6F6F6'
                      }}
                    >
                      <Stack
                        sx={{
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          gap: 0.5
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 14,
                            letterSpacing: '-0.56px',
                            fontWeight: 500,
                            color: '#202020'
                          }}
                        >
                          {orderData?.cardIssuer}
                        </Typography>
                        <Stack
                          sx={{
                            backgroundColor:
                              orderData?.paymentType === 'INICIS_BILLING'
                                ? '#1B2872'
                                : '#9B9B9B',
                            borderRadius: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            p: '4px 8px',
                            height: '18px'
                          }}
                        >
                          {' '}
                          <Typography
                            sx={{
                              fontSize: 10,
                              color: '#FFFFFF',
                              fontWeight: 500,
                              letterSpacing: '-0.4px'
                            }}
                          >
                            기본 결제카드
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    {/* card body */}
                    <Stack
                      sx={{
                        width: '100%',
                        px: 1.5,
                        py: 2,
                        backgroundColor: '#FFFFFF'
                      }}
                    >
                      <Stack
                        sx={{
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          gap: 0.5
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 14,
                            letterSpacing: '-0.56px',
                            fontWeight: 500,
                            color: '#202020'
                          }}
                        >
                          {formatCardNumber(orderData?.cardNumber)}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack
                    sx={{
                      width: '100%',
                      textAlign: 'left'
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#9A9CA4',
                        fontSize: { xs: 12 },
                        fontWeight: 500,
                        letterSpacing: '-0.48px'
                      }}
                    >
                      *카드 간편결제 선택 시, 등록된 카드로 결제금액이 자동결제
                      됩니다.
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            ) : (
              <Stack sx={{ width: '100%', gap: 1.5 }}>
                {/* header */}
                <Stack
                  sx={{
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                      letterSpacing: '-0.56px',
                      fontWeight: 500,
                      color: '#202020'
                    }}
                  >
                    카드 간편결제
                  </Typography>
                </Stack>
                {/* card content */}
                <Stack sx={{ width: '100%', gap: 1 }}>
                  <Stack
                    sx={{
                      width: '100%',
                      borderRadius: 1,
                      overflow: 'hidden'
                    }}
                  >
                    <Button
                      sx={{
                        flexGrow: 1,
                        height: 40,
                        backgroundColor:
                          orderData?.paymentType === 'INICIS_CARD'
                            ? '#F6F6F6 !important'
                            : '#1B2872 !important',
                        borderRadius: 1,
                        fontSize: 14,
                        fontWeight: 500,
                        border: '1px solid #D6D7DB',
                        letterSpacing: '-0.56px',
                        color:
                          orderData?.paymentType === 'INICIS_CARD'
                            ? '#202020 !important'
                            : '#FFFFFF !important'
                      }}
                      onClick={() => handleNavigateCardManagement()}
                    >
                      결제카드 등록{' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                      >
                        <path
                          d="M12 8.11572V16.1157"
                          stroke={
                            orderData?.paymentType === 'INICIS_CARD'
                              ? '#202020'
                              : '#FFFFFF'
                          }
                        />
                        <path
                          d="M16 12.1157L8 12.1157"
                          stroke={
                            orderData?.paymentType === 'INICIS_CARD'
                              ? '#202020'
                              : '#FFFFFF'
                          }
                        />
                      </svg>
                    </Button>
                  </Stack>
                  <Stack
                    sx={{
                      width: '100%',
                      textAlign: 'left'
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#9A9CA4',
                        fontSize: { xs: 12 },
                        fontWeight: 500,
                        letterSpacing: '-0.48px'
                      }}
                    >
                      *카드 간편결제 선택 시, 등록된 카드로 결제금액이 자동결제
                      됩니다.
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            )}

            <Stack sx={{ width: '100%', gap: 1.5, mt: 2.5 }}>
              {/* header */}
              <Stack
                sx={{
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    letterSpacing: '-0.56px',
                    fontWeight: 500,
                    color: '#202020'
                  }}
                >
                  일반결제
                </Typography>
              </Stack>
              {/* card content */}
              <Stack sx={{ width: '100%', gap: 1 }}>
                <Stack
                  sx={{
                    width: '100%',
                    borderRadius: 1,
                    overflow: 'hidden'
                  }}
                >
                  <Button
                    sx={{
                      maxWidth: 'fit-content',
                      height: 40,
                      backgroundColor:
                        orderData?.paymentType === 'INICIS_CARD'
                          ? '#1B2872 !important'
                          : '#F6F6F6 !important',
                      borderRadius: 1,
                      fontSize: 14,
                      fontWeight: 500,
                      border:
                        orderData?.paymentType === 'INICIS_CARD'
                          ? '1px solid   #1B2872 !important'
                          : '1px solid   #D6D7DB !important',
                      letterSpacing: '-0.56px',
                      color:
                        orderData?.paymentType === 'INICIS_CARD'
                          ? '#FFFFFF !important'
                          : '#202020 !important'
                    }}
                    onClick={() =>
                      setOrderData((prev) => ({
                        ...prev,
                        paymentType: 'INICIS_CARD'
                      }))
                    }
                  >
                    신용/체크카드
                  </Button>
                </Stack>
                <Stack
                  sx={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                >
                  <Typography
                    sx={{
                      color: '#9A9CA4',
                      fontSize: { xs: 12 },
                      fontWeight: 500,
                      letterSpacing: '-0.48px'
                    }}
                  >
                    *카드 간편결제 선택 시, 등록된 카드로 결제금액이 자동결제
                    됩니다.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        {/* divider */}
        <Stack
          sx={{ width: '100%', height: '5px', backgroundColor: '#F2F2F2' }}
        >
          <Stack
            sx={{ width: '100%', height: '1px', backgroundColor: '#E7E7E7' }}
          />
        </Stack>
        <Stack
          sx={{
            width: '100%',
            px: 2.5,
            py: 2.5,
            backgroundColor: '#FFFFFF',
            borderBottom: '1px solid #E7E7E7'
          }}
        >
          <Stack
            sx={{ pb: 2, textAlign: 'left', borderBottom: '1px solid #F2F2F2' }}
          >
            <Typography
              sx={{
                fontSize: 14,
                letterSpacing: '-0.56px',
                fontWeight: 700,
                color: '#202020'
              }}
            >
              결제 금액
            </Typography>
          </Stack>
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              py: 2.5,
              justifyContent: 'space-between',
              borderBottom: '1px solid #F2F2F2'
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                letterSpacing: '-0.48px',
                fontWeight: 500,
                color: '#202020',
                textAlign: 'center',
                minWidth: '20px'
              }}
            >
              상품금액
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                letterSpacing: '-0.48px',
                fontWeight: 500,
                color: '#202020',
                textAlign: 'center',
                minWidth: '20px'
              }}
            >
              {formatMoney(calculateTotalPrice())}원
            </Typography>
          </Stack>

          <Stack
            sx={{
              width: '100%'
            }}
          >
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                pt: 1.5,
                justifyContent: 'space-between'
              }}
            >
              <Typography
                sx={{
                  fontSize: 20,
                  letterSpacing: '-0.8px',
                  fontWeight: 700,
                  color: '#202020',
                  textAlign: 'center',
                  minWidth: '20px'
                }}
              >
                최종 결제 금액
              </Typography>
              <Typography
                sx={{
                  fontSize: 20,
                  letterSpacing: '-0.8px',
                  fontWeight: 700,
                  color: '#1B2872',
                  textAlign: 'center',
                  minWidth: '20px'
                }}
              >
                {formatMoney(calculateTotalPrice())}원
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          sx={{
            width: '100%',
            px: 2.5,
            py: 2.5,
            pb: '108px',
            backgroundColor: '#F2F2F2'
          }}
        >
          {/* product info */}
          <Stack
            sx={{
              width: '100%',

              pb: '10px',
              backgroundColor: '#F2F2F2',
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderBottom: '1px solid #E7E7E7'
            }}
          >
            <Typography
              sx={{
                color: '#9A9CA4',
                fontSize: { xs: 14 },
                fontWeight: 500,
                letterSpacing: '-0.56px'
              }}
            >
              {policyList?.[1]?.title}
            </Typography>

            <Typography
              sx={{
                fontSize: 12,
                letterSpacing: '-0.48px',
                fontWeight: 500,
                color: '#9A9CA4',

                textDecoration: 'underline'
              }}
              onClick={() => setDialog({ ...dialog, open: true })}
            >
              보기
            </Typography>
          </Stack>

          <Stack
            sx={{
              width: '100%',
              pt: 2.5,
              backgroundColor: '#F2F2F2',
              textAlign: 'left'
            }}
          >
            <Typography
              sx={{
                fontSize: 10,
                letterSpacing: '-0.4px',
                fontWeight: 500,
                color: '#9A9CA4',
                textAlign: 'left',
                whiteSpace: 'pre-line',
                wordBreak: 'keep-all', // Prevent breaking words
                overflowWrap: 'break-word', // Handle long words
                minWidth: '20px'
              }}
            >
              (주)워커스하이는 통신판매중개자로 거래 당사자가 아니므로, 판매자가
              등록한 상품정보 및 거래 등에 대해 책임을 지지 않습니다.(단,
              (주)워커스하이가 판매자로 등록 판매한 상품은 판 매자로서 책임을
              부담합니다.)
            </Typography>
          </Stack>
          <Stack
            sx={{
              width: '100%',
              pt: '30px',
              backgroundColor: '#F2F2F2',
              textAlign: 'left'
            }}
          >
            <Typography
              sx={{
                fontSize: 12,
                letterSpacing: '-0.48px',
                fontWeight: 500,
                color: '#202020',
                textAlign: 'left',
                minWidth: '20px'
              }}
            >
              위 내용을 확인하였으며 결제에 동의합니다.
            </Typography>
          </Stack>
        </Stack>
        {policyList && (
          <MyModal
            open={dialog?.open}
            setOpen={() => setDialog({ ...dialog, open: false, title: '' })}
            title={policyList?.[1]?.title}
            titleStyle={{ height: '50px' }}
            hasBackButton
            hideDialogButton
          >
            <Stack
              dangerouslySetInnerHTML={{ __html: policyList?.[1]?.content }}
              sx={{
                'figure table': {
                  border: '1px solid #EFEFEF',
                  borderCollapse: 'collapse',
                  width: '100%',
                  th: {
                    span: {
                      fontSize: '12px !important',
                      whiteSpace: 'pre-line',
                      wordBreak: 'keep-all', // Prevent breaking words
                      overflowWrap: 'break-word' // Handle long words
                    },
                    backgroundColor: '#EFEFEF !important',
                    whiteSpace: 'pre-line',
                    wordBreak: 'keep-all', // Prevent breaking words
                    overflowWrap: 'break-word', // Handle long words
                    minWidth: '40px',
                    maxWidth: '40px',
                    color: '#202020'
                  },
                  'th, td': {
                    border: '1px solid #EFEFEF',
                    span: {
                      fontSize: '10px !important',
                      whiteSpace: 'pre-line',
                      wordBreak: 'keep-all', // Prevent breaking words
                      overflowWrap: 'break-word' // Handle long words
                    },
                    whiteSpace: 'pre-line',
                    wordBreak: 'keep-all', // Prevent breaking words
                    overflowWrap: 'break-word', // Handle long words
                    padding: '4px',
                    textAlign: 'left'
                  }
                },
                figure: {
                  width: '100% !important',
                  margin: 0
                }
              }}
            />
          </MyModal>
        )}
        <AlertDialog
          isAlert
          open={alertDialog.open}
          setOpen={(open) => setAlertDialog({ ...alertDialog, open })}
          onOk={alertDialog?.onOk}
          okTitle={alertDialog?.okTitle || '확인'}
          title={null}
          titleStyle={{ textAlign: 'center' }}
          type={alertDialog?.type}
          subTitle={
            <Typography
              sx={{
                fontSize: 14,
                color: '#202020',
                fontWeight: 500,
                letterSpacing: '-0.56px',
                whiteSpace: 'pre-line',
                textAlign: 'center',
                mt: 1.5
              }}
            >
              {alertDialog?.subtitle}{' '}
            </Typography>
          }
        />
        {cartData && (
          <Stack
            sx={{
              width: '100%',
              px: 2.5,
              py: '30px',
              maxWidth: '500px',
              bottom: 0,
              position: 'fixed',
              backgroundColor: '#F2F2F2',
              zIndex: 1002
            }}
          >
            <Stack sx={{ width: '100%', flexDirection: 'row', gap: 1.5 }}>
              <Button
                sx={{
                  flexGrow: 1,
                  height: 48,
                  backgroundColor: !isButtonDisabled()
                    ? '#1B2872 !important'
                    : '#DADBDD !important',
                  borderRadius: 1,
                  fontSize: 16,
                  letterSpacing: '-0.64px',
                  fontWeight: 500,
                  color: '#FFFFFF !important'
                }}
                disabled={isButtonDisabled()}
                onClick={() => handleCreateOrder()}
              >
                다음
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
    </LocalizationProvider>
  );
};

export default Checkout;
