import React from 'react';
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material';

const lang = localStorage.getItem('i18nextLng')?.toLowerCase() || 'ko';
const MySelect = ({
  label,
  multiple,
  value,
  onChange,
  data,
  sx,
  required = false,
  errMg,
  fullWidth,
  selectFieldStyle,
  ...rest
}) => {
  if (multiple) {
    return (
      <FormControl sx={{ ...sx }} error={errMg}>
        <Select
          multiple
          value={data.reduce(
            (rs, item) =>
              value?.includes(item.value) ? rs.concat([item.value]) : rs,
            []
          )}
          renderValue={(selected) =>
            data
              .reduce(
                (rs, item) =>
                  selected?.includes(item.value) ? rs.concat([item.label]) : rs,
                []
              )
              .join(', ')
          }
          onChange={onChange}
          sx={{
            bgcolor: 'common.white',
            minWidth: 180,
            width: '100%'
          }}
          size="small"
          {...rest}
        >
          {data?.length > 0 ? (
            data.map(({ value: val, label }) => (
              <MenuItem value={val} key={val}>
                <Checkbox checked={value?.indexOf(val) > -1 || false} />
                <ListItemText primary={label} />
              </MenuItem>
            ))
          ) : (
            <MenuItem>No Item</MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }
  if (!label)
    return (
      <FormControl sx={{ ...sx }} error={errMg}>
        <Select
          value={value}
          onChange={onChange}
          sx={{
            bgcolor: 'common.white',
            minWidth: 180,
            width: '100%'
          }}
          MenuProps={{ sx: { zIndex: 15100 } }}
          size="small"
          {...rest}
        >
          {data?.length > 0 ? (
            data.map(({ value, label }) => (
              <MenuItem value={value} key={value}>
                {label}
              </MenuItem>
            ))
          ) : (
            <MenuItem>No Item</MenuItem>
          )}
        </Select>
        {errMg && (
          <span
            style={{
              color: 'red',
              fontSize: 12
            }}
          >
            * {errMg}
          </span>
        )}
      </FormControl>
    );
  return (
    <FormControl
      required={required}
      error={errMg}
      sx={{
        flexDirection: { xs: 'row' },
        alignItems: { xs: 'center' },
        ...sx
      }}
    >
      <Typography
        sx={{
          color: '#6F869C',
          fontWeight: 'fontWeightBold',
          fontSize: 13,
          whiteSpace: 'nowrap'
        }}
      >
        {required && (
          <span style={{ color: 'red', fontSize: { md: 20, xs: 14 } }}>*</span>
        )}
        {label}
      </Typography>

      <Stack>
        <Select
          value={value}
          onChange={onChange}
          MenuProps={{
            'aria-hidden': 'false',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            sx: { zIndex: 15100 }
          }}
          sx={{
            bgcolor: 'common.white',
            minWidth: 150,
            ml: label && { xs: 1 },
            '.MuiTypography-root': {
              color: '#040404',
              fontWeight: 'fontWeightBold',
              fontSize: 13,
              whiteSpace: 'nowrap',
              width: '100px',

              ...sx?.label
            }
          }}
          size="small"
          {...rest}
        >
          {data?.length > 0 ? (
            data.map(({ value, label }) => (
              <MenuItem value={value} key={value}>
                {label}
              </MenuItem>
            ))
          ) : (
            <MenuItem>No Item</MenuItem>
          )}
        </Select>
      </Stack>
      {errMg && (
        <span
          style={{
            color: 'red',
            fontSize: 12
          }}
        >
          * {errMg}
        </span>
      )}
    </FormControl>
  );
};

export default MySelect;
