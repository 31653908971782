import React, { useEffect, useRef } from 'react';
import { Box, Snackbar, Typography } from '@mui/material';
import Slide from '@mui/material/Slide';
import { ReactComponent as AlertIcon } from 'images/preorder/alert.svg';

const MyAlert = ({
  open,
  onClose,
  message,
  placement = { vertical: 'bottom', horizontal: 'center' }
}) => {
  const snackbarRef = useRef(null);

  useEffect(() => {
    let timer;
    if (open) {
      timer = setTimeout(() => {
        onClose(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [open, onClose]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        snackbarRef.current &&
        !snackbarRef.current.contains(event.target) &&
        open
      ) {
        onClose(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, onClose]);

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '500px',
        zIndex: 50000,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        bottom: 0
      }}
    >
      <Snackbar
        anchorOrigin={placement}
        ref={snackbarRef}
        elevation={0}
        open={open}
        autoHideDuration={3000}
        TransitionComponent={Slide}
        onClose={() => onClose(false)}
        sx={{
          width: '100%',
          left: 0,
          right: 0,
          borderRadius: 1,
          mb: '80px',
          px: 2.5,
          '.MuiPaper-root': {
            backgroundColor: '#9A9CA4',
            minHeight: '80px',
            color: '#FFFFFF !important',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            boxShadow: 'none'
          }
        }}
        message={
          <Typography
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 0.5,
              color: '#FFFFFF',
              fontWeight: 500,
              fontSize: 14,
              letterSpacing: '-0.56px'
            }}
          >
            <AlertIcon />
            {message || 'This is a success message!'}
          </Typography>
        }
      />
    </Box>
  );
};

export default MyAlert;
