import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DialogContentText, Typography } from '@mui/material';
import MyDialogLP from '@components/dialogs/MyDialog';
import MyDialog from '@components/MyDialog';
import MySnackbar from '@components/MySnackbar';
import { setError, setInquiry, setSuccess } from '@store/reducers/appReducer';
import MyAlert from '@components/MySnackbar/PreorderSnackbar';
import { setAlert, setPreorderError } from '@store/reducers/pre-order/app';
import AlertDialog from 'pages-preorder/components/Modal/Alert/AlertDialog';

const Globals = () => {
  const { loading, error, errorLP, info, onOk, success, openInquiry } =
    useSelector((state) => state.app);
  const { alert: preOrderAlert, preorderError } = useSelector(
    (state) => state.preOrder.app
  );

  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openInquiryDialog, setOpenInquiryDialog] = useState(false);
  const [openPreorderErrorDialog, setOpenPreorderErrorDialog] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) setOpenErrorDialog(true);
    if (success) setOpenSuccessDialog(true);
    if (openInquiry) setOpenInquiryDialog(true);
    if (preorderError) setOpenPreorderErrorDialog(true);
    if (preOrderAlert) setOpenAlert(true);
  }, [error, success, openInquiry, preorderError, preOrderAlert]);

  return (
    <>
      {info && (
        <MyDialog
          open={openErrorDialog}
          setOpen={() => {
            setOpenErrorDialog(false);
            onOk();
          }}
          isAlert
          {...info}
        >
          {info?.content && (
            <DialogContentText
              sx={{
                fontSize: 13,
                color: 'primary.main',
                textAlign: 'center',
                lineHeight: '22px',
                whiteSpace: 'pre-line'
              }}
            >
              {info?.content}
            </DialogContentText>
          )}
        </MyDialog>
      )}

      {errorLP && (
        <MyDialogLP
          open={openErrorDialog}
          setOpen={setOpenErrorDialog}
          isAlert
          {...errorLP}
        >
          {errorLP?.content && (
            <DialogContentText
              sx={{
                fontSize: 13,
                color: 'primary.main',
                textAlign: 'center',
                lineHeight: '22px',
                whiteSpace: 'pre-line'
              }}
            >
              {errorLP?.content}
            </DialogContentText>
          )}
        </MyDialogLP>
      )}

      {error?.content && (
        <MyDialog
          open={openErrorDialog}
          setOpen={() => {
            setOpenErrorDialog(false);
            dispatch(setError(null));
          }}
          isAlert
          {...error}
        >
          {error?.content && (
            <DialogContentText
              sx={{
                fontSize: 13,
                color: 'primary.main',
                textAlign: 'center',
                lineHeight: '22px',
                whiteSpace: 'pre-line'
              }}
            >
              {error?.content}
            </DialogContentText>
          )}
        </MyDialog>
      )}

      {success && (
        <MySnackbar
          open={openSuccessDialog}
          onClose={() => {
            setOpenSuccessDialog(false);
            dispatch(setSuccess(null));
          }}
          message={success?.message}
        />
      )}

      {preOrderAlert && (
        <MyAlert
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            dispatch(setAlert(null));
          }}
          message={preOrderAlert?.message}
        />
      )}

      {preorderError && (
        <AlertDialog
          isAlert
          open={openPreorderErrorDialog}
          okTitle={preorderError?.okTitle || '확인'}
          title={preorderError.title || null}
          titleStyle={{ textAlign: 'center' }}
          cancelTitle={preorderError?.cancelTitle || '닫기'}
          subTitle={
            <Typography
              sx={{
                fontSize: 14,
                color: '#202020',
                fontWeight: 500,
                letterSpacing: '-0.56px',
                whiteSpace: 'pre-line',
                textAlign: 'center',
                mt: 1.5
              }}
            >
              {preorderError?.content}{' '}
            </Typography>
          }
          setOpen={() => {
            setOpenPreorderErrorDialog(false);
            dispatch(setPreorderError(null));
          }}
          {...preorderError}
        />
      )}

      {openInquiry && (
        <MySnackbar
          open={openInquiryDialog}
          onClose={() => {
            setOpenInquiryDialog(false);
            dispatch(setInquiry(false));
          }}
          message={success?.message}
        />
      )}
    </>
  );
};

export default Globals;
