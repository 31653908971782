/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useRef, useState } from 'react';
import { Stack, Typography, Button, IconButton } from '@mui/material';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import productService from '@services/preorder/product';
import MyBottomSwiper from 'pages-preorder/components/MyBottomSwiper';
import paymentService from '@services/preorder/paymentService';
import { useDispatch } from 'react-redux';

import { formatCardNumber, paramsToObj } from '@utils/helpers';
import env from '@constants/env';
import { setAlert } from '@store/reducers/pre-order/app';
import { t } from 'i18next';

const PaymentCardManagementPage = () => {
  const navigate = useNavigate();
  const [cardList, setCardList] = useState([]);
  const [inicisData, setInicisData] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const { version } = useParams();
  const [open, setOpen] = useState(false);
  const [buttonSectionHeight, setButtonSectionHeight] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const buttonRef = useRef(null);
  const [dialog, setDialog] = useState({
    open: false,
    title: null,
    action: null
  });

  const fetchCardList = async () => {
    try {
      const rs = await productService.getPaymentCardList();
      if (!rs.error) {
        const sortedCardList = rs?.data.sort((a, b) => {
          return b.defaultCard - a.defaultCard;
        });
        setCardList(sortedCardList);
      }
    } catch (error) {
      console.error('Error fetching store data:', error);
    }
  };

  const getBaseUrl = () => {
    const { protocol, host } = window.location;
    return `${protocol}//${host}`;
  };

  const getRedirectPath = (suffix = '') => {
    const baseUrl = getBaseUrl();
    const basePath = `/pre-order/${version}/payment/cards`;
    return `${baseUrl}${basePath}${suffix}`;
  };
  const [openAlert, setOpenAlert] = useState(false);

  const getRegisterCardDetails = async () => {
    try {
      const rs = await paymentService.registerCard();
      if (!rs.error) {
        console.log(rs?.data);
        setInicisData(rs?.data);
        const form = document.createElement('form');

        const redirectUrls = {
          redirectToWhenSuccess: getRedirectPath(),
          redirectToWhenFail: getRedirectPath('?r=error'),
          redirectToWhenCancel: getRedirectPath()
        };
        form.method = 'post';
        form.acceptCharset = ' UTF-8';
        form.id = 'pay_form';
        form.action = env.billingBaseUrl;

        const formSubmitData = {
          ...rs?.data,
          mid: rs?.data?.pmid,
          merchantreserved: JSON.stringify(redirectUrls)
        };
        for (let key in formSubmitData) {
          const input = document.createElement('input');
          input.name = key;
          input.hidden = true;
          input.value = formSubmitData[key];
          form.appendChild(input);
        }

        console.log('redirect urls', redirectUrls);
        document.querySelector('#card-register-page').appendChild(form);
        form.submit();
      }
    } catch (error) {
      console.error('Error fetching store data:', error);
    }
  };

  useEffect(() => {
    const params = paramsToObj(location.search);
    const { r } = params;

    if (r === 'error') {
      setOpenAlert(true);

      navigate(`/pre-order/${version}/payment/cards`, {
        replace: true
      });
    }

    if (location?.state?.originalPath)
      localStorage.setItem(
        'PREORDER_CARD_ORIGINAL_PATH',
        JSON.stringify(location?.state?.originalPath)
      );

    fetchCardList();
  }, []);

  useEffect(() => {
    if (buttonRef.current)
      setButtonSectionHeight(buttonRef.current.clientHeight);
  }, [buttonRef]);

  const handleCardActions = async (action) => {
    let rs;
    let msg;
    switch (action) {
      case 'update':
        rs = await paymentService.updateCardStatus({
          paymentInfoCode: selectedItem?.paymentInfoCode
        });
        msg = '저장되었습니다.';
        break;

      default:
        rs = await paymentService.deleteCard(selectedItem?.paymentInfoCode);
        msg = '삭제되었습니다.';
    }
    if (!rs?.error) {
      dispatch(setAlert({ message: msg }));
    }

    fetchCardList();
    setOpen(false);
    setSelectedItem(null);
  };

  return (
    <Stack
      sx={{
        flexDirection: 'column',
        width: '100%',
        maxWidth: '500px',
        height: '100%',
        minHeight: '100%',
        flexGrow: 1
      }}
      id="card-register-page"
    >
      <Stack
        sx={{
          width: '100%',
          maxWidth: '500px',
          top: 0,
          position: 'fixed',
          backgroundColor: '#FFFFFF',
          zIndex: 1002
        }}
      >
        <Stack
          id="navBarHeader"
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            backgroundColor: '#FFFFFF',
            width: '100%',
            py: '13px',
            position: 'relative',
            px: 2.5,
            height: { xs: '50px' }
          }}
        >
          <Stack sx={{ position: 'absolute', left: '20px' }}>
            <IconButton
              size="small"
              sx={{ backgroundColor: 'transparent !important' }}
              onClick={() => {
                const savedPath = localStorage.getItem(
                  'PREORDER_CARD_ORIGINAL_PATH'
                );
                if (savedPath) navigate(`${JSON.parse(savedPath)}`);
                localStorage.removeItem('PREORDER_CARD_ORIGINAL_PATH');
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
              >
                <path d="M7 1L1 7L7 13" stroke="black" />
              </svg>
            </IconButton>
          </Stack>

          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: 1
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 700,
                color: '#202020',
                letterSpacing: '-0.56px'
              }}
            >
              카드 등록
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        sx={{
          width: '100%',
          gap: 1,
          mt: '50px',
          px: 2.5,
          py: 2.5,
          backgroundColor: '#FFFFFF'
        }}
        ref={buttonRef}
      >
        <Stack
          sx={{
            width: '100%',
            borderRadius: 1
          }}
        >
          <Button
            sx={{
              flexGrow: 1,
              height: 40,
              backgroundColor: '#1B2872 !important',
              borderRadius: 1,
              fontWeight: 500,
              fontSize: 14,
              letterSpacing: '-0.56px',
              color: '#FFFFFF !important'
            }}
            onClick={() => getRegisterCardDetails()}
          >
            결제카드 등록{' '}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path d="M12 8.11572V16.1157" stroke="white" />
              <path d="M16 12.1157L8 12.1157" stroke="white" />
            </svg>
          </Button>
        </Stack>
        <Stack
          sx={{
            width: '100%',
            textAlign: 'left'
          }}
        >
          <Typography
            sx={{
              color: '#9A9CA4',
              fontSize: { xs: 12 },
              fontWeight: 500,
              letterSpacing: '-0.48px'
            }}
          >
            *카드 간편결제 선택 시, 등록된 카드로 결제금액이 자동결제 됩니다.
          </Typography>
        </Stack>
      </Stack>

      {/* divider */}
      <Stack sx={{ width: '100%', height: '5px', backgroundColor: '#F2F2F2' }}>
        <Stack
          sx={{ width: '100%', height: '1px', backgroundColor: '#E7E7E7' }}
        />
      </Stack>

      <Stack
        sx={{
          width: '100%',
          py: 2.5,
          px: 2.5,
          backgroundColor: '#FFFFFF',
          flexGrow: 1,
          maxHeight: `calc(100vh - (55px + ${buttonSectionHeight || 0}px ))`,
          overflowY: 'auto'
        }}
      >
        {/* title */}
        <Stack
          sx={{
            width: '100%',
            textAlign: 'left'
          }}
        >
          <Typography
            sx={{
              color: '#202020',
              fontSize: { xs: 14 },
              fontWeight: 700,
              letterSpacing: '-0.56px'
            }}
          >
            카드 목록 {cardList?.length > 0 ? `(${cardList.length})` : ''}
          </Typography>
        </Stack>

        {/* cards */}
        <Stack sx={{ flexDirection: 'column', mt: 2.5, flexGrow: 1 }}>
          {cardList?.length > 0 ? (
            <Stack sx={{ width: '100%', gap: 2.5 }}>
              {cardList.map((item, index) => (
                <Stack
                  key={`${item?.paymentInfoCode}-cardIssuer`}
                  sx={{
                    width: '100%',
                    pb: index === 0 && item?.defaultCard && 2.5,
                    borderBottom: item?.defaultCard && '2px solid #F2F2F2'
                  }}
                >
                  <Stack
                    sx={{
                      width: '100%',
                      borderRadius: 1,
                      overflow: 'hidden',
                      border: item?.defaultCard
                        ? '1px solid #1B2872'
                        : '1px solid #D6D7DB',
                      backgroundColor: '#FFFFFFF'
                    }}
                  >
                    {/* card header */}
                    <Stack
                      sx={{
                        width: '100%',
                        pl: 1.5,
                        pr: 2.5,
                        py: 2,
                        backgroundColor: item?.defaultCard
                          ? '#E9EBF6'
                          : '#F6F6F6'
                      }}
                    >
                      <Stack
                        sx={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Stack
                          sx={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: 0.5
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: 14,
                              letterSpacing: '-0.56px',
                              fontWeight: 500,
                              color: '#202020'
                            }}
                          >
                            {item?.cardIssuer}
                          </Typography>
                          {item?.defaultCard && (
                            <Stack
                              sx={{
                                backgroundColor: '#1B2872',
                                borderRadius: 1,
                                height: '18px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                p: '4px 8px',
                                minHeight: '18px'
                              }}
                            >
                              {' '}
                              <Typography
                                sx={{
                                  fontSize: 10,
                                  color: '#FFFFFF',
                                  fontWeight: 500,
                                  letterSpacing: '-0.4px'
                                }}
                              >
                                기본 결제카드
                              </Typography>
                            </Stack>
                          )}{' '}
                        </Stack>

                        <Stack>
                          <Typography
                            sx={{
                              fontSize: 14,
                              color: '#1B2872',
                              fontWeight: 500,
                              letterSpacing: '-0.56px'
                            }}
                            onClick={() => {
                              setSelectedItem(item);
                              setOpen(true);
                            }}
                          >
                            수정
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    {/* card body */}
                    <Stack
                      sx={{
                        width: '100%',
                        px: 1.5,
                        py: 2,
                        backgroundColor: '#FFFFFF'
                      }}
                    >
                      <Stack
                        sx={{
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          gap: 0.5
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 14,
                            letterSpacing: '-0.56px',
                            fontWeight: 500,
                            color: '#202020'
                          }}
                        >
                          {formatCardNumber(item?.cardNumber)}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          ) : (
            <Stack
              sx={{
                width: '100%',
                flexGrow: 1,
                justifyContent: 'center',

                textAlign: 'center'
              }}
            >
              <Typography
                sx={{
                  color: '#D6D7DB',
                  fontSize: { xs: 14 },
                  fontWeight: 500,
                  letterSpacing: '-0.56px'
                }}
              >
                {t('pages.pre-order.alert.no-cards')}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>

      <MyBottomSwiper
        open={openAlert}
        setOpen={setOpenAlert}
        title="카드 등록 실패"
        sx={{}}
      >
        <Stack sx={{ width: '100%', px: 2.5 }}>
          <Typography
            sx={{
              fontSize: 14,
              letterSpacing: '-0.56px',
              color: '#202020',
              fontWeight: 500
            }}
          >
            카드 정보가 올바르지 않거나 유효하지 않아 등록에 실패했습니다.
          </Typography>
        </Stack>
      </MyBottomSwiper>

      <MyBottomSwiper
        open={open}
        setOpen={setOpen}
        hasCloseButton
        sx={{
          dialogContent: {
            p: 0
          }
        }}
        title="수정하기"
      >
        <Stack sx={{ width: '100%', px: 2.5 }}>
          <Stack
            sx={{
              width: '100%',

              flexDirection: 'column',
              alignItems: 'center',
              gap: 1.5
            }}
          >
            <Button
              sx={{
                width: '100%',
                height: 48,
                backgroundColor: '#1B2872 !important',
                borderRadius: 1,
                fontSize: 16,
                letterSpacing: '-0.64px',
                fontWeight: 500,
                color: '#FFFFFF !important'
              }}
              onClick={() => handleCardActions('update')}
            >
              기본 결제 카드 설정
            </Button>
            <Button
              sx={{
                width: '100%',
                height: 48,
                backgroundColor: '#F6F6F6',
                border: '0px',
                fontSize: 16,
                letterSpacing: '-0.64px',
                fontWeight: 500,
                borderRadius: 1,
                color: '#202020'
              }}
              onClick={() => handleCardActions('delete')}
            >
              카드 삭제
            </Button>
          </Stack>
        </Stack>
      </MyBottomSwiper>
    </Stack>
  );
};

export default PaymentCardManagementPage;
