/* eslint-disable no-useless-return */
/* eslint-disable no-else-return */
import { Button, IconButton, Stack, Typography } from '@mui/material';
import productService from '@services/preorder/product';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import MyBottomSwiper from 'pages-preorder/components/MyBottomSwiper';
import env from '@constants/env';
import { setActiveNav, setAlert } from '@store/reducers/pre-order/app';
import { useTranslation } from 'react-i18next';
import { BOTTOM_NAVS } from '@constants/utils';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { formatMoney } from '@utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import 'swiper/css';
import 'swiper/css/free-mode';
import AlertDialog from 'pages-preorder/components/Modal/Alert/AlertDialog';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Categories from './components/Categories';
import PreorderHeader from './components/Header';
import ProductList from './components/ProductList';

const Products = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [alertDialog, setAlertDialog] = useState({
    open: false
  });
  const { t } = useTranslation();
  const location = useLocation();
  const [params, setParams] = useState({
    storeCode: null,
    categoryCode: null,
    page: 0,
    totalRow: -1,
    size: 10,
    totalPage: 0
  });
  const [cartData, setCartData] = useState(null);
  const [openCart, setOpenCart] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentStore, setCurrentStore] = useState(null);
  const [currentCategory, setCurrentCategory] = useState({
    productCategoryCode: 'all',
    categoryName: t('common.total')
  });
  const { activeNav } = useSelector((state) => state.preOrder.app);
  const { version } = useParams();
  const [categoryList, setCategoryList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const scrollRef = useRef(null);

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const fetchProducts = async (storeCode, dataParams, isLoadMore = false) => {
    if (storeCode && !isLoading && hasMore) {
      setIsLoading(true);
      try {
        const rs = await productService.getProductListByCategory(
          storeCode,
          dataParams
        );
        if (!rs?.error) {
          const newProducts = rs?.data?.items || [];
          setProductList((prev) =>
            isLoadMore ? [...prev, ...newProducts] : newProducts
          );

          // Update page number after successful fetch
          setParams((prev) => ({
            ...prev,
            totalRow: rs?.data?.totalRow || 0,
            totalPage: rs?.data?.totalPage || 0,
            page: prev.page + 1 // Increment page only after successful fetch
          }));

          // Check if we have more products to load
          setHasMore(dataParams.page < rs.data.totalPage - 1);
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleScroll = useCallback(
    debounce((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting && !isLoading && hasMore) {
        // Remove the state update since we'll use the current page directly in dataParams
        const dataParams = {
          productCategoryCode:
            currentCategory?.productCategoryCode === 'all'
              ? null
              : currentCategory?.productCategoryCode,
          page: params.page, // Use current page instead of incrementing
          size: params.size
        };

        fetchProducts(currentStore?.storeCode, dataParams, true);
      }
    }, 0),
    [currentStore?.storeCode, currentCategory, isLoading, hasMore, params.page]
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0
    };

    const observer = new IntersectionObserver(handleScroll, option);
    if (scrollRef.current) observer.observe(scrollRef.current);

    return () => {
      if (scrollRef.current) observer.unobserve(scrollRef.current);
    };
  }, [handleScroll]);

  const handleDeleteCart = async () => {
    const rs = await productService.deleteAllProductFromCart();
    if (rs?.error) console.log('error');
  };
  const fetchCartData = async () => {
    try {
      const rs = await productService.getCartData();
      if (!rs.error) {
        setCartData(rs.data);
      }
    } catch (error) {
      console.error('Error fetching store data:', error);
    }
  };
  useEffect(() => {
    fetchCartData();
  }, []);
  const fetchStoreData = async (storeCode, categoryCode) => {
    try {
      const rs = storeCode
        ? await productService.getStoreDetail(storeCode)
        : await productService.getDefaultStoreData();

      if (!rs.error) {
        const storeData = rs?.data;
        setCurrentStore(storeData);

        const refactoredCategoryList = [
          { productCategoryCode: 'all', categoryName: t('common.total') },
          ...storeData.productCategories
        ];
        setCategoryList(refactoredCategoryList || []);
        if (categoryCode) {
          setCurrentCategory(
            refactoredCategoryList?.find(
              (i) => i?.productCategoryCode === categoryCode
            )
          );
          const dataParams = {
            productCategoryCode: categoryCode === 'all' ? null : categoryCode,
            page: 0,
            size: params?.size
          };
          fetchProducts(storeData?.storeCode, dataParams);
        }
        setParams({
          ...params,
          storeCode: storeData?.storeCode,
          categoryCode: categoryCode || 'all'
        });
      }
    } catch (error) {
      console.error('Error fetching store data:', error);
    }
  };

  useEffect(() => {
    if (activeNav !== BOTTOM_NAVS.HOME)
      dispatch(setActiveNav(BOTTOM_NAVS.HOME));

    const storeData = localStorage.getItem('PREORDER_MENU_STORE');
    const savedStore = storeData ? JSON.parse(storeData) : null;

    fetchStoreData(
      savedStore ? savedStore?.storeCode : null,
      savedStore ? savedStore?.categoryCode : 'all'
    );
  }, []);

  // Handle increasing quantity
  const increaseQuantity = () => {
    if (quantity < 999) {
      setQuantity(quantity + 1);
    }
  };

  // Handle decreasing quantity
  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const onChangeCategory = (item) => {
    setCurrentCategory(item);
    setProductList([]);
    setHasMore(true);
    setParams((prev) => ({
      ...prev,
      page: 0 // Reset to page 0 when changing category
    }));

    localStorage.setItem(
      'PREORDER_MENU_STORE',
      JSON.stringify({
        storeCode: currentStore.storeCode,
        categoryCode: item?.productCategoryCode
      })
    );

    const dataSubmit = {
      size: params?.size,
      page: 0,
      productCategoryCode:
        item?.productCategoryCode === 'all' ? null : item?.productCategoryCode
    };
    fetchProducts(currentStore?.storeCode, dataSubmit);
  };

  const isCartClear = async (storeId, productId) => {
    const rs = await productService.getCartStatus({
      storeCode: storeId,
      productCode: productId
    });
    return rs;
  };

  const handleCheckCart = async () => {
    const checkCart = await isCartClear(
      currentStore?.storeCode,
      selectedProduct?.productCode
    );

    if (checkCart && !checkCart?.data?.otherStoreExist) {
      if (Number(checkCart.data.currentProductCount) + 1 >= 99) {
        dispatch(
          setAlert({
            message: '장바구니에는 상품을 99개까지 담을 수 있습니다.'
          })
        );
        return;
      } else if (
        Number(checkCart.data.currentProductQuantity) + quantity >=
        999
      ) {
        dispatch(
          setAlert({
            message: '상품은 999개까지 구매할 수 있습니다.'
          })
        );
        return;
      } else {
        handleProductActions('cart');
      }
    } else {
      setAlertDialog({
        ...alertDialog,
        open: true,
        title: '장바구니에는 같은 상점의 상품만 담을 수 있습니다.',
        subtitle: t('pages.pre-order.alert.cart-exists'),
        okTitle: '담기',
        cancelTitle: '취소',
        onOk: () => {
          handleDeleteCart();

          setTimeout(() => {
            handleProductActions('cart');
          }, 100);
        }
      });
    }
  };

  const handleProductActions = async (action) => {
    console.log('selected product', selectedProduct);
    if (action === 'cart') {
      const rs = await productService.addProductToCart({
        storeCode: currentStore?.storeCode,
        productCode: selectedProduct?.productCode,
        quantity: quantity || 1
      });
      if (!rs?.error) {
        dispatch(setAlert({ message: '장바구니에 추가되었습니다.' }));
        setAlertDialog({ open: false });
        setOpenCart(false);
        setQuantity(1);
        fetchCartData();
      }
    } else {
      navigate(`/pre-order/${version}/store/${currentStore?.storeCode}/order`, {
        state: {
          cartData: {
            type: 'direct',
            cartProducts: [{ ...selectedProduct, quantity }]
          },
          originalPath: location?.pathname
        }
      });
    }
  };

  const handleAddCart = (product) => {
    setSelectedProduct(product);
    setQuantity(1);
    setOpenCart(true);
  };

  const handlechangeStore = (store) => {
    setCurrentStore(store);
    setCurrentCategory({
      productCategoryCode: 'all',
      categoryName: t('common.total')
    });
    setProductList([]); // Clear existing products when store changes
    setHasMore(true); // Reset hasMore
    setParams((prev) => ({
      ...prev,
      page: 0
    }));

    localStorage.setItem(
      'PREORDER_MENU_STORE',
      JSON.stringify({ storeCode: store.storeCode, categoryCode: 'all' })
    );
    fetchStoreData(store?.storeCode, 'all');
    const dataSubmit = {
      size: params?.size,
      page: 0,
      productCategoryCode: null
    };
    fetchProducts(store?.storeCode, dataSubmit);
  };

  return (
    <Stack
      sx={{
        flexDirection: 'column',
        width: '100%',
        maxxWidth: '500px',
        backgroundColor: '#FFFFFF',
        flexGrow: 1,
        position: 'relative'
      }}
    >
      <PreorderHeader
        currentStore={currentStore}
        setCurrentStore={handlechangeStore}
        cartData={cartData}
      />
      <Categories
        categoryList={categoryList}
        currentcategory={currentCategory}
        onChange={onChangeCategory}
      />
      <ProductList
        data={productList}
        params={params}
        storeData={currentStore}
        handleAddCart={handleAddCart}
        scrollRef={scrollRef}
        isLoading={isLoading}
        hasMore={hasMore}
      />

      <MyBottomSwiper
        open={openCart}
        setOpen={() => {
          setOpenCart(false);
          setTimeout(() => {
            setSelectedProduct(null);
          }, 100);
        }}
      >
        <Stack sx={{ width: '100%', pt: '20px', px: 2.5 }}>
          <Stack
            sx={{
              width: '100%',
              flexDirection: 'row',
              gap: 1.5,
              justifyContent: 'flex-start'
            }}
          >
            {/* image */}
            <Stack
              sx={{
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <img
                src={`${env.s3BaseUrl}/${selectedProduct?.imageUrl}`}
                alt={selectedProduct?.productName}
                style={{
                  width: '100px',
                  height: '100px',
                  objectFit: 'cover',
                  maxWidth: '100px',
                  maxHeight: '100px'
                }}
              />
            </Stack>
            {/* details */}
            <Stack
              sx={{
                gap: 1.5,
                flexGrow: 1,

                justifyContent: 'space-between'
              }}
            >
              <Stack sx={{ gap: 0.5 }}>
                <Typography
                  sx={{
                    fontSize: 14,
                    letterSpacing: '-0.56px',
                    fontWeight: 500,
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    color: '#202020'
                  }}
                >
                  {selectedProduct?.productName}
                </Typography>

                <Typography
                  sx={{
                    fontSize: 14,
                    letterSpacing: '-0.56px',
                    fontWeight: 700,
                    color: '#1B2872'
                  }}
                >
                  {formatMoney(selectedProduct?.price)}원
                </Typography>
              </Stack>

              <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
                <Stack
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1,
                    p: '4px 10px',
                    height: '30px',
                    backgroundColor: '#FFFFFF',
                    borderRadius: 0.5,
                    border: '1px solid #D6D7DB'
                  }}
                >
                  <IconButton
                    size="small"
                    onClick={decreaseQuantity}
                    disabled={quantity <= 1}
                  >
                    <RemoveIcon
                      sx={{ color: '#202020', fontSize: 14 }}
                      fontSize="small"
                    />
                  </IconButton>
                  <Typography
                    sx={{
                      fontSize: 14,
                      letterSpacing: '-0.48px',
                      fontWeight: 500,
                      color: '#202020',

                      textAlign: 'center',
                      minWidth: '20px'
                    }}
                  >
                    {quantity}
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={increaseQuantity}
                    disabled={quantity >= 999}
                  >
                    <AddIcon sx={{ color: '#202020', fontSize: 14 }} />
                  </IconButton>
                </Stack>
              </Stack>
            </Stack>
          </Stack>

          <AlertDialog
            isAlert
            open={alertDialog.open}
            setOpen={(open) => setAlertDialog({ ...alertDialog, open })}
            onOk={alertDialog?.onOk}
            okTitle={alertDialog?.okTitle || '취소'}
            title={alertDialog.title}
            titleStyle={{ textAlign: 'center' }}
            cancelTitle={alertDialog?.cancelTitle || '취소'}
            type={alertDialog?.type}
            subTitle={
              <Typography
                sx={{
                  fontSize: 14,
                  color: '#202020',
                  fontWeight: 500,
                  letterSpacing: '-0.56px',
                  whiteSpace: 'pre-line',
                  textAlign: 'center',
                  mt: 1.5
                }}
              >
                {alertDialog?.subtitle}{' '}
              </Typography>
            }
            hasCancelButton
          />
          <Stack
            sx={{
              width: '100%',
              mt: 2.5,
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1
            }}
          >
            <Button
              sx={{
                width: '100%',
                height: 48,
                backgroundColor: '#FFFFFF !important',
                border: '1px solid #1B2872 !important',
                fontSize: 16,
                letterSpacing: '-0.64px',
                fontWeight: 500,
                borderRadius: 1,
                color: '#1B2872 !important'
              }}
              onClick={() => handleCheckCart()}
            >
              장바구니
            </Button>
            <Button
              sx={{
                width: '100%',
                height: 48,
                backgroundColor: '#1B2872 !important',
                fontSize: 16,
                fontWeight: 500,
                letterSpacing: '-0.64px',
                borderRadius: 1,
                color: '#FFFFFF !important'
              }}
              onClick={() => handleProductActions()}
            >
              바로구매
            </Button>
          </Stack>
        </Stack>
      </MyBottomSwiper>
    </Stack>
  );
};

export default Products;
