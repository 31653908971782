import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Stack } from '@mui/material';
import { ReactChannelIO } from 'react-channel-plugin';
import { Helmet } from 'react-helmet-async';

import { useDispatch, useSelector } from 'react-redux';
import InquiryModal from '@pages/Inquiry/InquiryServiceModal';
import { setOpenForm } from '@store/reducers/appReducer';
import Footer from './footer/Footer';
import '../index.css';
import NavbarFixed from './navbar/NavbarTransparent';

const LayoutNew = () => {
  const { openForm } = useSelector((state) => state.app);
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (openForm) {
      setOpenDialog(true);
    }
  }, [openForm]);
  return (
    <>
      <Helmet>
        <meta property="og:title" content="No.1 workershigh english title" />
        <meta property="og:description" content="english description" />
        <meta property="og:image" content="/thumnail.png" />
        <meta property="og:url" content="https://www.workershigh.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <ReactChannelIO
        // 04ad9ae3-07f3-4e83-9799-685e80be8622  a421a2f2-3eb5-4190-b43d-c64163a7b0e9
        pluginKey="a421a2f2-3eb5-4190-b43d-c64163a7b0e9"
        customLauncherSelector=".channel-btn"
        language="ko"
        autoBoot
      >
        <Stack
          display="flex"
          flexDirection="column"
          alignItems="space-between"
          justifyContent="space-between"
        >
          <Stack height="54px">
            <NavbarFixed />
          </Stack>
          <Stack minHeight="74vh">
            <Outlet />
          </Stack>
          <Stack height="184px">
            <Footer />
          </Stack>
          {openForm && (
            <InquiryModal
              open={openDialog}
              setOpen={() => {
                setOpenDialog(false);
                dispatch(setOpenForm(false));
              }}
              formType={openForm.type}
            />
          )}
        </Stack>
      </ReactChannelIO>
    </>
  );
};

export default LayoutNew;
