import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const PreorderPublicRoute = () => {
  const isLoggedIn = useSelector((state) => state.preOrder.auth.authenticated);
  const isAuthenticated = isLoggedIn || localStorage.getItem('PREORDER_TOKEN');

  return isAuthenticated ? <Navigate to="/pre-order/ko/home" /> : <Outlet />;
};

export default PreorderPublicRoute;
