/* eslint-disable react/no-unknown-property */
/* eslint-disable consistent-return */
import LoadingButton from '@components/buttons/LoadingButton';
import MyTextField from '@components/inputs/MyTextField';

import { IconButton, Stack, Typography } from '@mui/material';
import { Formatter } from '@utils/formatter';
import React, { useEffect, useState } from 'react';

import { logInActions, loginScreens } from '../enhance';

const SCREEN_TITLES = {
  FIND_ID: '아이디 찾기',
  [loginScreens.FIND_ID_SUCCEESS]: '아이디 찾기 성공',
  FIND_PASSWORD: '비밀번호 찾기',
  RESET_PASSWORD: '비밀번호 재설정'
};
const SCREEN_SUBTITLES = {
  [loginScreens.FIND_PASSWORD]:
    '비밀번호를 찾기 위해 휴대폰 번호를 인증해 주세요.',
  [loginScreens.FIND_ID_SUCCEESS]: '아래 계정으로 로그인 해주세요.',
  [loginScreens.RESET_PASSWORD]: '새로운 비밀번호를 입력해 주세요'
};
const Finder = ({
  verifyingBox,
  isVerifying,
  data,
  onAction,
  handleChange,
  errMsg,
  isVerified,
  seconds,
  setSeconds,
  screen,
  notification,
  passwordData,
  t
}) => {
  const [canResendPhone, setCanResendPhone] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false
  });

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field]
    }));
  };

  // Update resend availability for phone
  useEffect(() => {
    if (seconds.phone === 0 && !isVerified?.otpCode) {
      setCanResendPhone(true);
    }
  }, [seconds.phone, isVerified?.otpCode]);

  useEffect(() => {
    if (isVerifying?.otpCode) {
      setCanResendPhone(true);
    }
  }, [isVerifying.emailOtp, isVerifying?.otpCode]);

  useEffect(() => {
    setCanResendPhone(false);
  }, [screen]);

  return (
    <Stack
      sx={{
        width: '100%',
        flexGrow: 1,
        display: 'flex',
        pb: 5
      }}
    >
      <Stack sx={{ py: 2.5, gap: 2.5 }}>
        <Typography
          sx={{
            fontSize: 24,
            color: '#202020',
            fontWeight: 700,
            letterSpacing: '-0.96px'
          }}
        >
          {SCREEN_TITLES[screen]}
        </Typography>

        {[
          loginScreens.FIND_ID_SUCCEESS,
          loginScreens.FIND_PASSWORD,
          loginScreens.RESET_PASSWORD
        ].includes(screen) && (
          <Typography
            sx={{
              fontSize: 14,
              color: '#202020',
              fontWeight: 500,
              letterSpacing: '-0.56px'
            }}
          >
            {SCREEN_SUBTITLES[screen]}
          </Typography>
        )}
      </Stack>

      {![
        loginScreens.FIND_ID_SUCCEESS,
        loginScreens.FIND_PASSWORD,
        loginScreens.RESET_PASSWORD
      ].includes(screen) && (
        <Stack
          sx={{
            width: '100%',
            flexGrow: 1,
            display: 'flex',

            gap: 3
          }}
        >
          {/* email */}
          {screen === loginScreens.FIND_PASSWORD && (
            <Stack
              sx={{
                flexDirection: 'column',
                gap: 0.5
              }}
            >
              <Stack
                sx={{
                  flexDirection: 'row',
                  alignItems: 'flex-end'
                }}
              >
                <MyTextField
                  required
                  fullWidth
                  preorder
                  placeholder="이메일 주소(아이디)"
                  type="email"
                  value={data?.email}
                  // onChange={(e) => handleChange('phone', e.target.value)}
                  onChange={(e) => {
                    handleChange('email', e.target.value);
                  }}
                  onClear={() => handleChange('email', '')}
                  disabled={isVerified?.emailOtp}
                  // onKeyDown={onlyNumber}
                  errMg={errMsg?.eamil}
                />
              </Stack>
              {errMsg?.email && (
                <Typography
                  sx={{
                    fontSize: { xs: 12 },
                    color: '#F03636',
                    letterSpacing: '-0.48px'
                  }}
                >
                  {errMsg?.email}
                </Typography>
              )}
              {notification?.email && (
                <Typography
                  sx={{
                    fontSize: { xs: 12 },
                    color: '#1B2872',
                    letterSpacing: '-0.48px'
                  }}
                >
                  {notification?.email}
                </Typography>
              )}
            </Stack>
          )}

          {/* phone */}
          <Stack
            sx={{
              flexDirection: 'column',
              gap: '6px'
            }}
          >
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'flex-end'
              }}
            >
              <MyTextField
                required
                fullWidth
                preorder
                placeholder="휴대폰 번호( - 제외)"
                type="tel"
                value={data?.phone}
                // onChange={(e) => handleChange('phone', e.target.value)}
                onChange={(e) => {
                  handleChange('phone', e.target.value.replace(/\D/g, ''));
                }}
                onClear={() => handleChange('phone', '')}
                disabled={isVerified?.otpCode}
                // onKeyDown={onlyNumber}
                errMg={errMsg?.phone}
              />

              <Stack
                width="auto"
                sx={{
                  ml: 0.5,
                  justifyContent: 'flex-end',
                  alignItems: 'flex-start'
                }}
              >
                <LoadingButton
                  variant="outlined"
                  onClick={() =>
                    canResendPhone
                      ? onAction(logInActions.SEND_CODE_AGAIN_FIND_ID)
                      : onAction(logInActions.SEND_CODE_FIND_ID)
                  }
                  disabled={
                    isVerifying?.otpCode ||
                    errMsg?.phone ||
                    !data.phone ||
                    isVerified?.otpCode
                  }
                  sx={{
                    border: '0px !important',
                    backgroundColor:
                      isVerifying?.otpCode ||
                      errMsg?.phone ||
                      !data.phone ||
                      isVerified?.otpCode
                        ? '#DADBDD'
                        : '#1B2872',
                    ':hover': {
                      backgroundColor:
                        isVerifying?.otpCode ||
                        errMsg?.phone ||
                        !data.phone ||
                        isVerified?.otpCode
                          ? '#DADBDD'
                          : '#1B2872',
                      borderRadius: 1,
                      height: 40,
                      color: '#FFFFFF !important'
                    },

                    fontWeight: 500,
                    letterSpacing: '-0.56px',
                    minWidth: '112px',
                    maxWidth: 'fit-content',
                    color: '#FFFFFF !important',
                    height: { xs: 40 },
                    fontSize: { xs: 14 },
                    borderRadius: '8px',
                    boxShadow: 0
                  }}
                >
                  {canResendPhone ? '재전송' : '인증번호 전송'}
                </LoadingButton>
              </Stack>
            </Stack>
            {errMsg?.phone && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#F03636',
                  letterSpacing: '-0.48px'
                }}
              >
                {errMsg?.phone}
              </Typography>
            )}
            {notification?.phone && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#1B2872',
                  letterSpacing: '-0.48px'
                }}
              >
                {notification?.phone}
              </Typography>
            )}
          </Stack>
          {/* otp field */}
          <Stack
            display={verifyingBox?.otpCode}
            sx={{
              flexDirection: 'column',
              gap: '6px'
            }}
          >
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'flex-end'
              }}
            >
              <MyTextField
                preorder
                fullWidth
                placeholder="인증번호"
                type="tel"
                value={data?.otpCode}
                onChange={(e) => {
                  handleChange('otpCode', e.target.value.replace(/\D/g, ''));
                }}
                endComponent={
                  isVerified?.otpCode ? (
                    <Stack
                      sx={{
                        fontSize: { xs: 14 },
                        letterSpacing: '-0.56px',
                        color: '#1B2872'
                      }}
                    >
                      {Formatter.fSecondsToMinutes(seconds?.phone)}
                    </Stack>
                  ) : (
                    <CountTime
                      field="phone"
                      isVerifying={isVerifying?.otpCode}
                      seconds={seconds}
                      setSeconds={setSeconds}
                    />
                  )
                }
                errMg={errMsg?.otpCode}
                disabled={seconds <= 0 || isVerified?.otpCode}
              />
              <Stack
                width="auto"
                sx={{
                  ml: 0.5,
                  justifyContent: 'flex-end',
                  alignItems: 'flex-start'
                }}
              >
                <LoadingButton
                  variant="outlined"
                  onClick={() =>
                    onAction(logInActions.VERIFY_CODE_FIND_ID, data?.otpCode)
                  }
                  disabled={
                    errMsg?.phone ||
                    !data.phone ||
                    !data?.otpCode ||
                    isVerified?.otpCode
                  }
                  sx={{
                    border: '0px !important',
                    backgroundColor:
                      errMsg?.phone ||
                      !data.phone ||
                      !data?.otpCode ||
                      isVerified?.otpCode
                        ? '#DADBDD'
                        : '#1B2872',
                    ':hover': {
                      backgroundColor:
                        errMsg?.phone ||
                        !data.phone ||
                        !data?.otpCode ||
                        isVerified?.otpCode
                          ? '#DADBDD'
                          : '#1B2872',
                      borderRadius: 1,
                      height: 40,
                      color: '#FFFFFF !important'
                    },

                    fontWeight: 500,
                    letterSpacing: '-0.56px',
                    minWidth: '112px',
                    maxWidth: 'fit-content',
                    color: '#FFFFFF !important',
                    height: { xs: 40 },
                    fontSize: { xs: 14 },
                    borderRadius: '8px',
                    boxShadow: 0
                  }}
                >
                  확인
                </LoadingButton>
              </Stack>
            </Stack>
            {errMsg?.otpCode && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#F03636',
                  letterSpacing: '-0.48px'
                }}
              >
                {errMsg?.otpCode}
              </Typography>
            )}
            {notification?.otpCode && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#1B2872',
                  letterSpacing: '-0.48px'
                }}
              >
                {notification?.otpCode}
              </Typography>
            )}
          </Stack>
        </Stack>
      )}

      {[loginScreens.FIND_ID_SUCCEESS].includes(screen) && (
        <Stack
          sx={{
            width: '100%',
            flexGrow: 1,
            display: 'flex',

            gap: 3
          }}
        >
          <Stack
            sx={{
              flexDirection: 'column',
              gap: 0.5
            }}
          >
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'flex-end'
              }}
            >
              <MyTextField
                required
                fullWidth
                preorder
                value={data?.email}
                disabled
              />
            </Stack>
          </Stack>
        </Stack>
      )}

      {[loginScreens.FIND_PASSWORD].includes(screen) && (
        <Stack
          sx={{
            width: '100%',
            flexGrow: 1,
            display: 'flex',
            mt: '30px',
            gap: 3
          }}
        >
          {/* email */}
          {screen === loginScreens.FIND_PASSWORD && (
            <Stack
              sx={{
                flexDirection: 'column',
                gap: '6px'
              }}
            >
              <Stack
                sx={{
                  flexDirection: 'row',
                  alignItems: 'flex-end'
                }}
              >
                <MyTextField
                  required
                  fullWidth
                  preorder
                  type="email"
                  placeholder="아이디(이메일) 입력"
                  value={data?.email}
                  // onChange={(e) => handleChange('phone', e.target.value)}
                  onChange={(e) => {
                    handleChange('email', e.target.value);
                  }}
                  onClear={() => handleChange('email', '')}
                  errMg={errMsg?.email}
                />
              </Stack>
              {errMsg?.email && (
                <Typography
                  sx={{
                    fontSize: { xs: 12 },
                    color: '#F03636',
                    letterSpacing: '-0.48px'
                  }}
                >
                  {errMsg?.email}
                </Typography>
              )}
              {notification?.email && (
                <Typography
                  sx={{
                    fontSize: { xs: 12 },
                    color: '#1B2872',
                    letterSpacing: '-0.48px'
                  }}
                >
                  {notification?.email}
                </Typography>
              )}
            </Stack>
          )}

          {/* phone */}
          <Stack
            sx={{
              flexDirection: 'column',
              gap: '6px'
            }}
          >
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'flex-end'
              }}
            >
              <MyTextField
                required
                fullWidth
                preorder
                type="tel"
                placeholder="휴대폰 번호( - 제외)"
                value={data?.phone}
                // onChange={(e) => handleChange('phone', e.target.value)}
                onChange={(e) => {
                  handleChange('phone', e.target.value.replace(/\D/g, ''));
                }}
                onClear={() => handleChange('phone', '')}
                disabled={isVerified?.otpCode}
                // onKeyDown={onlyNumber}
                errMg={errMsg?.phone}
              />

              <Stack
                width="auto"
                sx={{
                  ml: 0.5,
                  justifyContent: 'flex-end',
                  alignItems: 'flex-start'
                }}
              >
                <LoadingButton
                  variant="outlined"
                  onClick={() =>
                    canResendPhone
                      ? onAction(logInActions.SEND_CODE_AGAIN_CHANGE_PASSWORD)
                      : onAction(logInActions.SEND_CODE_CHANGE_PASSWORD)
                  }
                  disabled={
                    isVerifying?.otpCode ||
                    errMsg?.phone ||
                    !data.phone ||
                    isVerified?.otpCode
                  }
                  sx={{
                    border: '0px !important',
                    backgroundColor:
                      isVerifying?.otpCode ||
                      errMsg?.phone ||
                      !data.phone ||
                      isVerified?.otpCode
                        ? '#DADBDD'
                        : '#1B2872',
                    ':hover': {
                      backgroundColor:
                        isVerifying?.otpCode ||
                        errMsg?.phone ||
                        !data.phone ||
                        isVerified?.otpCode
                          ? '#DADBDD'
                          : '#1B2872',
                      borderRadius: 1,
                      height: 40,
                      color: '#FFFFFF !important'
                    },

                    fontWeight: 500,
                    letterSpacing: '-0.56px',
                    minWidth: '100px',
                    maxWidth: 'fit-content',
                    color: '#FFFFFF !important',
                    height: { xs: 40 },
                    fontSize: { xs: 14 },
                    borderRadius: '8px',
                    boxShadow: 0
                  }}
                >
                  {canResendPhone ? '재전송' : '인증번호 전송'}
                </LoadingButton>
              </Stack>
            </Stack>
            {errMsg?.phone && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#F03636',
                  letterSpacing: '-0.48px'
                }}
              >
                {errMsg?.phone}
              </Typography>
            )}
            {notification?.phone && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#1B2872',
                  letterSpacing: '-0.48px'
                }}
              >
                {notification?.phone}
              </Typography>
            )}
          </Stack>
          {/* otp field */}
          <Stack
            display={verifyingBox?.otpCode}
            sx={{
              flexDirection: 'column',
              gap: '6px'
            }}
          >
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'flex-end'
              }}
            >
              <MyTextField
                preorder
                fullWidth
                placeholder="인증번호"
                type="tel"
                value={data?.otpCode}
                onChange={(e) => {
                  handleChange('otpCode', e.target.value.replace(/\D/g, ''));
                }}
                endComponent={
                  isVerified?.otpCode ? (
                    <Stack
                      sx={{
                        fontSize: { xs: 14 },
                        letterSpacing: '-0.56px',
                        color: '#1B2872'
                      }}
                    >
                      {Formatter.fSecondsToMinutes(seconds?.phone)}
                    </Stack>
                  ) : (
                    <CountTime
                      field="phone"
                      isVerifying={isVerifying?.otpCode}
                      seconds={seconds}
                      setSeconds={setSeconds}
                    />
                  )
                }
                errMg={errMsg?.otpCode}
                disabled={seconds <= 0 || isVerified?.otpCode}
              />
              <Stack
                width="auto"
                sx={{
                  ml: 0.5,
                  justifyContent: 'flex-end',
                  alignItems: 'flex-start'
                }}
              >
                <LoadingButton
                  variant="outlined"
                  onClick={() =>
                    onAction(
                      logInActions.VERIFY_CODE_CHANGE_PASSWORD,
                      data?.otpCode
                    )
                  }
                  disabled={
                    errMsg?.phone ||
                    !data.phone ||
                    !data?.otpCode ||
                    isVerified?.otpCode
                  }
                  sx={{
                    border: '0px !important',
                    backgroundColor:
                      errMsg?.phone ||
                      !data.phone ||
                      !data?.otpCode ||
                      isVerified?.otpCode
                        ? '#DADBDD'
                        : '#1B2872',
                    ':hover': {
                      backgroundColor:
                        errMsg?.phone ||
                        !data.phone ||
                        !data?.otpCode ||
                        isVerified?.otpCode
                          ? '#DADBDD'
                          : '#1B2872',
                      borderRadius: 1,
                      height: 40,
                      color: '#FFFFFF !important'
                    },

                    fontWeight: 500,
                    letterSpacing: '-0.56px',
                    minWidth: '100px',
                    maxWidth: 'fit-content',
                    color: '#FFFFFF !important',
                    height: { xs: 40 },
                    fontSize: { xs: 14 },
                    borderRadius: '8px',
                    boxShadow: 0
                  }}
                >
                  확인
                </LoadingButton>
              </Stack>
            </Stack>
            {errMsg?.otpCode && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#F03636',
                  letterSpacing: '-0.48px'
                }}
              >
                {errMsg?.otpCode}
              </Typography>
            )}
            {notification?.otpCode && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#1B2872',
                  letterSpacing: '-0.48px'
                }}
              >
                {notification?.otpCode}
              </Typography>
            )}
          </Stack>
        </Stack>
      )}

      {[loginScreens.RESET_PASSWORD].includes(screen) && (
        <Stack
          sx={{
            width: '100%',
            flexGrow: 1,
            display: 'flex',
            mt: '30px',
            gap: 3
          }}
        >
          {/* email */}

          <Stack
            sx={{
              flexDirection: 'column',
              gap: '6px'
            }}
          >
            <Stack sx={{ flexDirection: 'column', gap: 0.5 }}>
              <Stack sx={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                <MyTextField
                  required
                  fullWidth
                  preorder
                  placeholder="새 비밀번호 입력(영문, 숫자, 특수문자 조합 8~16자"
                  type={showPassword.password ? 'text' : 'password'}
                  value={passwordData?.password}
                  onChange={(e) => handleChange('password', e.target.value)}
                  endComponent={
                    <IconButton
                      size="small"
                      sx={{ mr: -1 }}
                      onClick={() => togglePasswordVisibility('password')}
                    >
                      {showPassword.password ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect width="24" height="24" fill="white" />
                          <path
                            d="M12 18C4.83934 18 1.26433 12.6205 1.11549 12.3914C1.04018 12.2755 1 12.1392 1 11.9998C1 11.8605 1.04018 11.7242 1.11549 11.6082C1.26433 11.3795 4.83934 6 12 6C19.1607 6 22.7357 11.3795 22.8845 11.6086C22.9598 11.7245 23 11.8608 23 12.0002C23 12.1395 22.9598 12.2758 22.8845 12.3918C22.7357 12.6205 19.1607 18 12 18ZM12 7.41176C6.66946 7.41176 3.48668 10.8275 2.55099 12.0014C3.48358 13.1778 6.64987 16.5882 12 16.5882C17.3305 16.5882 20.5133 13.1725 21.449 11.9986C20.5164 10.8222 17.3501 7.41176 12 7.41176Z"
                            fill="#DADBDD"
                          />
                          <path
                            d="M12 15C10.3457 15 9 13.654 9 12C9 10.346 10.3457 9 12 9C13.6543 9 15 10.346 15 12C15 13.654 13.6543 15 12 15ZM12 10.3333C11.081 10.3333 10.3333 11.081 10.3333 12C10.3333 12.919 11.081 13.6667 12 13.6667C12.919 13.6667 13.6667 12.919 13.6667 12C13.6667 11.081 12.919 10.3333 12 10.3333Z"
                            fill="#DADBDD"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect width="24" height="24" fill="white" />
                          <path
                            d="M12 18C4.83934 18 1.26433 12.6205 1.11549 12.3914C1.04018 12.2755 1 12.1392 1 11.9998C1 11.8605 1.04018 11.7242 1.11549 11.6082C1.26433 11.3795 4.83934 6 12 6C19.1607 6 22.7357 11.3795 22.8845 11.6086C22.9598 11.7245 23 11.8608 23 12.0002C23 12.1395 22.9598 12.2758 22.8845 12.3918C22.7357 12.6205 19.1607 18 12 18ZM12 7.41176C6.66946 7.41176 3.48668 10.8275 2.55099 12.0014C3.48358 13.1778 6.64987 16.5882 12 16.5882C17.3305 16.5882 20.5133 13.1725 21.449 11.9986C20.5164 10.8222 17.3501 7.41176 12 7.41176Z"
                            fill="#DADBDD"
                          />
                          <path
                            d="M12 15C10.3457 15 9 13.654 9 12C9 10.346 10.3457 9 12 9C13.6543 9 15 10.346 15 12C15 13.654 13.6543 15 12 15ZM12 10.3333C11.081 10.3333 10.3333 11.081 10.3333 12C10.3333 12.919 11.081 13.6667 12 13.6667C12.919 13.6667 13.6667 12.919 13.6667 12C13.6667 11.081 12.919 10.3333 12 10.3333Z"
                            fill="#DADBDD"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.61429 18L23 7.13722L22.3857 6L1 16.8628L1.61429 18Z"
                            fill="#DADBDD"
                          />
                        </svg>
                      )}
                    </IconButton>
                  }
                  onClear={() => handleChange('password', '')}
                  errMg={errMsg?.password}
                />
              </Stack>
              {errMsg?.password && (
                <Typography
                  sx={{
                    fontSize: { xs: 12 },
                    color: '#F03636',
                    letterSpacing: '-0.48px'
                  }}
                >
                  {errMsg?.password}
                </Typography>
              )}

              {notification?.password && (
                <Typography
                  sx={{
                    fontSize: { xs: 12 },
                    color: '#1B2872',
                    letterSpacing: '-0.48px'
                  }}
                >
                  {notification?.password}
                </Typography>
              )}
            </Stack>
          </Stack>

          {/* phone */}
          <Stack
            sx={{
              flexDirection: 'column',
              gap: '6px'
            }}
          >
            <Stack sx={{ flexDirection: 'column', gap: 0.5 }}>
              <Stack sx={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                <MyTextField
                  required
                  fullWidth
                  preorder
                  placeholder="새 비밀번호 확인"
                  type={showPassword.confirmPassword ? 'text' : 'password'}
                  value={passwordData?.confirmPassword}
                  onChange={(e) =>
                    handleChange('confirmPassword', e.target.value)
                  }
                  endComponent={
                    <IconButton
                      size="small"
                      sx={{ mr: -1 }}
                      onClick={() =>
                        togglePasswordVisibility('confirmPassword')
                      }
                    >
                      {showPassword.confirmPassword ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect width="24" height="24" fill="white" />
                          <path
                            d="M12 18C4.83934 18 1.26433 12.6205 1.11549 12.3914C1.04018 12.2755 1 12.1392 1 11.9998C1 11.8605 1.04018 11.7242 1.11549 11.6082C1.26433 11.3795 4.83934 6 12 6C19.1607 6 22.7357 11.3795 22.8845 11.6086C22.9598 11.7245 23 11.8608 23 12.0002C23 12.1395 22.9598 12.2758 22.8845 12.3918C22.7357 12.6205 19.1607 18 12 18ZM12 7.41176C6.66946 7.41176 3.48668 10.8275 2.55099 12.0014C3.48358 13.1778 6.64987 16.5882 12 16.5882C17.3305 16.5882 20.5133 13.1725 21.449 11.9986C20.5164 10.8222 17.3501 7.41176 12 7.41176Z"
                            fill="#DADBDD"
                          />
                          <path
                            d="M12 15C10.3457 15 9 13.654 9 12C9 10.346 10.3457 9 12 9C13.6543 9 15 10.346 15 12C15 13.654 13.6543 15 12 15ZM12 10.3333C11.081 10.3333 10.3333 11.081 10.3333 12C10.3333 12.919 11.081 13.6667 12 13.6667C12.919 13.6667 13.6667 12.919 13.6667 12C13.6667 11.081 12.919 10.3333 12 10.3333Z"
                            fill="#DADBDD"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect width="24" height="24" fill="white" />
                          <path
                            d="M12 18C4.83934 18 1.26433 12.6205 1.11549 12.3914C1.04018 12.2755 1 12.1392 1 11.9998C1 11.8605 1.04018 11.7242 1.11549 11.6082C1.26433 11.3795 4.83934 6 12 6C19.1607 6 22.7357 11.3795 22.8845 11.6086C22.9598 11.7245 23 11.8608 23 12.0002C23 12.1395 22.9598 12.2758 22.8845 12.3918C22.7357 12.6205 19.1607 18 12 18ZM12 7.41176C6.66946 7.41176 3.48668 10.8275 2.55099 12.0014C3.48358 13.1778 6.64987 16.5882 12 16.5882C17.3305 16.5882 20.5133 13.1725 21.449 11.9986C20.5164 10.8222 17.3501 7.41176 12 7.41176Z"
                            fill="#DADBDD"
                          />
                          <path
                            d="M12 15C10.3457 15 9 13.654 9 12C9 10.346 10.3457 9 12 9C13.6543 9 15 10.346 15 12C15 13.654 13.6543 15 12 15ZM12 10.3333C11.081 10.3333 10.3333 11.081 10.3333 12C10.3333 12.919 11.081 13.6667 12 13.6667C12.919 13.6667 13.6667 12.919 13.6667 12C13.6667 11.081 12.919 10.3333 12 10.3333Z"
                            fill="#DADBDD"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.61429 18L23 7.13722L22.3857 6L1 16.8628L1.61429 18Z"
                            fill="#DADBDD"
                          />
                        </svg>
                      )}
                    </IconButton>
                  }
                  onClear={() => handleChange('confirmPassword', '')}
                  errMg={errMsg?.confirmPassword}
                />
              </Stack>
              {errMsg?.confirmPassword && (
                <Typography
                  sx={{
                    fontSize: { xs: 12 },
                    color: '#F03636',
                    letterSpacing: '-0.48px'
                  }}
                >
                  {errMsg?.confirmPassword}
                </Typography>
              )}
              {notification?.confirmPassword && (
                <Typography
                  sx={{
                    fontSize: { xs: 12 },
                    color: '#1B2872',
                    letterSpacing: '-0.48px'
                  }}
                >
                  {notification?.confirmPassword}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

const CountTime = (props) => {
  const { isVerifying, seconds, setSeconds, field } = props;

  useEffect(() => {
    if (seconds?.[field] > 0) {
      const timer = setTimeout(() => {
        setSeconds({ ...seconds, [field]: seconds[field] - 1 });
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [seconds]);

  return (
    <Stack sx={{ fontSize: { xs: 14 }, color: '#1B2872' }}>
      {Formatter.fSecondsToMinutes(seconds?.[field])}
    </Stack>
  );
};
export default Finder;
