/* eslint-disable react/no-array-index-key */
import { Button, IconButton, Stack, Typography } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AlertDialog from 'pages-preorder/components/Modal/Alert/AlertDialog';

import userService from '@services/preorder/user';

import productService from '@services/preorder/product';
import { setAlert } from '@store/reducers/pre-order/app';

const CustomTypography = ({ content }) => {
  const bulletPoints = content.split('\n').map((point) => point.trim());

  return (
    <Typography
      sx={{
        mt: 1,
        '& > div': {
          display: 'flex',
          alignItems: 'baseline',
          marginBottom: '12px'
        },
        // '&  span::before': {
        //   content: '"• "',
        //   marginRight: '0.5em',
        //   color: '#9A9CA4',
        //   fontSize: 14
        // },
        '& p': {
          lineHeight: '1.5',
          marginLeft: '4px',
          fontSize: 14,
          fontWeight: 500,
          letterSpacing: '-0.5px',
          color: '#000',
          fontFamily: 'Pretendard',
          whiteSpace: 'pre-line',
          wordBreak: 'keep-all', // Prevent breaking words
          overflowWrap: 'break-word'
        }
      }}
    >
      {bulletPoints.map((point, index) => (
        <div key={index}>
          <span>{index + 1}.</span>
          <p>{point}</p>
        </div>
      ))}
    </Typography>
  );
};

const MinibarSelectPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { version } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const [alertDialog, setAlertDialog] = useState({ open: false });
  const [minibarList, setMinibarList] = useState();
  const [selectedMinibar, setSelectedMinibar] = useState(null);
  const [userData, setUserData] = useState({
    hasChecked: false
  });

  const handleDeleteCart = async () => {
    const rs = await productService.deleteAllProductFromCart();
    if (rs?.error) console.log('error');
  };

  const fetchUserData = async () => {
    try {
      const rs = await userService.getUserData();

      if (!rs.error) {
        setUserData({ ...rs?.data });
        setSelectedMinibar({
          pickupSpotCode: rs?.data?.pickupSpotCode,
          pickupSpotName: rs?.data?.pickupSpotName
        });
      }
    } catch (error) {
      console.error('Error fetching store data:', error);
    }
  };

  const fetchMinibars = async () => {
    try {
      const rs = await userService.getMinibarList();

      if (!rs.error) {
        setMinibarList(rs?.data || []);
      }
    } catch (error) {
      console.error('Error fetching store data:', error);
    }
  };
  const handleSavePickupMinibar = async () => {
    if (selectedMinibar?.pickupSpotCode === userData?.pickupSpotCode)
      updatePickupMinibar();
    else
      setAlertDialog({
        ...alertDialog,
        open: true,
        title: null,
        subtitle: t('pages.pre-order.alert.change-minibar'),
        onOk: () => updatePickupMinibar('clear')
      });
  };

  const updatePickupMinibar = async (action) => {
    if (action === 'clear') await handleDeleteCart();
    const rs = await userService.changeMinibar({
      pickupSpotCode: selectedMinibar?.pickupSpotCode
    });
    if (!rs?.error) {
      dispatch(setAlert({ message: '기본 픽업장소로 설정되었습니다.' }));
    }
    fetchUserData();
    setAlertDialog({ open: false });
  };
  useEffect(() => {
    fetchUserData();
    fetchMinibars();
  }, [location?.search]);

  return (
    <Stack
      sx={{
        flexDirection: 'column',
        width: '100%',
        maxWidth: '500px',
        flexGrow: 1,
        minHeight: '-webkit-fill-available',
        position: 'relative'
      }}
    >
      <Stack
        sx={{
          width: '100%',
          maxWidth: '500px',
          top: 0,
          position: 'fixed',
          backgroundColor: '#FFFFFF',
          zIndex: 1002
        }}
      >
        <Stack
          id="navBarHeader"
          sx={{
            position: 'relative',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            backgroundColor: '#FFFFFF',
            width: '100%',
            py: '13px',
            px: 2.5,
            height: { xs: '50px' }
          }}
        >
          <Stack sx={{ position: 'absolute', left: '20px' }}>
            <IconButton
              size="small"
              sx={{ px: 0, backgroundColor: 'transparent' }}
              onClick={() => navigate(`/pre-order/${version}/my-page`)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
              >
                <path d="M7 1L1 7L7 13" stroke="black" />
              </svg>
            </IconButton>
          </Stack>

          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: 1
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 700,
                color: '#202020',
                letterSpacing: '-0.56px'
              }}
            >
              픽업장소 관리
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          width: '100%',
          pt: 2.5,
          px: 2.5,
          mt: '50px',
          flexGrow: 1,
          backgroundColor: '#FFFFFF',
          borderBottom: '1px solid #E7E7E7'
        }}
      >
        <Stack sx={{ pb: 2.5 }}>
          <Stack sx={{ width: '100%', pb: 2.5 }}>
            <Typography
              sx={{
                fontSize: 20,
                letterSpacing: '-0.8px',
                fontWeight: 700,
                color: '#202020'
              }}
            >
              대표 픽업장소 선택
            </Typography>
          </Stack>
          {/* contact */}
          <Stack sx={{ mt: 1.5, gap: 1.5 }}>
            {minibarList?.length > 0 &&
              minibarList?.map((item) => (
                <Stack
                  sx={{
                    flexDirection: 'row',
                    width: '100%',
                    height: '48px',
                    textAlign: 'center',
                    justifyContent: 'center',
                    borderRadius: 1,
                    alignItems: 'center',
                    gap: '6px',
                    transition: 'all 100ms ease-in-out',
                    backgroundColor:
                      selectedMinibar?.pickupSpotCode === item?.pickupSpotCode
                        ? '#E9EBF6'
                        : '#FFFFFF',
                    py: 2,
                    border:
                      selectedMinibar?.pickupSpotCode === item?.pickupSpotCode
                        ? '1px solid #1B2872'
                        : '1px solid #DADBDD'
                  }}
                  onClick={() => setSelectedMinibar(item)}
                  key={`${item?.pickupSpotCode}-${item?.pickupSpotName}`}
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                      letterSpacing: '-0.56px',
                      fontWeight: 500,
                      color:
                        selectedMinibar?.pickupSpotCode === item?.pickupSpotCode
                          ? '#1B2872'
                          : '#9A9CA4'
                    }}
                  >
                    {item?.pickupSpotName}
                  </Typography>
                </Stack>
              ))}
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{ width: '100', px: 2.5, backgroundColor: '#FFFFFF', pb: '30px' }}
      >
        {' '}
        <Button
          onClick={() => handleSavePickupMinibar()}
          variant="contained"
          sx={{
            minWidth: '90px',
            backgroundColor: !selectedMinibar ? '#DADBDD' : '#1B2872',
            ':hover': {
              backgroundColor: !selectedMinibar ? '#DADBDD' : '#1B2872',
              cursor: !selectedMinibar ? 'not-allowed' : 'pointer',
              borderRadius: 1,
              height: 48,
              color: '#FFFFFF !important'
            },
            maxWidth: '500px',
            borderRadius: 1,
            height: 48,
            color: '#FFFFFF !important',
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: '-0.56px'
          }}
          // loading={loading}
          disabled={!selectedMinibar}
        >
          저장
        </Button>
      </Stack>

      <AlertDialog
        isAlert
        open={alertDialog.open}
        setOpen={(open) => setAlertDialog({ ...alertDialog, open })}
        onOk={alertDialog.onOk}
        okTitle={alertDialog?.okTitle || '변경'}
        title={alertDialog.title}
        titleStyle={{ textAlign: 'center' }}
        cancelTitle={alertDialog?.cancelTitle || '취소'}
        type={alertDialog?.type}
        subTitle={
          <Typography
            sx={{
              fontSize: 14,
              color: '#202020',
              fontWeight: 500,
              letterSpacing: '-0.56px',
              whiteSpace: 'pre-line',
              textAlign: 'center',
              mt: 1.5
            }}
          >
            {alertDialog?.subtitle}
          </Typography>
        }
        hasCancelButton
      />
    </Stack>
  );
};

export default MinibarSelectPage;
