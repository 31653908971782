import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { isNumber } from '@utils/helpers';
import { CancelRounded } from '@mui/icons-material';

const lang = localStorage.getItem('i18nextLng')?.toLowerCase() || 'ko';
const MyTextField = ({
  label,
  placeholder,
  name,
  value,
  onChange,
  errMg,
  fullWidth,
  required,
  endComponent,
  disabled = false,
  onKeyDown,
  onClear,
  labelStyle,
  multiline = false,
  onClick,
  minRows,
  maxRows,
  sx,
  rows,
  preorder = false,
  ...rest
}) => {
  const [openClear, setOpenClear] = useState(false);
  if (preorder)
    return (
      <Stack
        width={fullWidth && '100%'}
        sx={{
          flexDirection: { md: 'row', xs: 'column' },
          alignItems: { md: 'baseline' }
        }}
      >
        {label && (
          <Typography
            sx={{
              fontSize: { md: lang === 'ko' ? 15 : 12, xs: 12 },
              fontWeight: 500,
              width: lang === 'ko' ? '100px' : '130px',
              whiteSpace: lang === 'ko' ? 'noWrap' : 'wrap',
              ...labelStyle
            }}
          >
            {required && (
              <span style={{ color: 'red', fontSize: { md: 20, xs: 14 } }}>
                *
              </span>
            )}
            {label}
          </Typography>
        )}
        <Stack flexDirection="column" width={fullWidth && '100%'}>
          <TextField
            disabled={disabled}
            error={errMg}
            onFocus={() => {
              setOpenClear(true);
            }}
            onBlur={() =>
              setTimeout(() => {
                setOpenClear(false);
              }, 200)
            }
            onPaste={(e) => {
              const value = e.clipboardData.getData('text');
              if (onKeyDown) {
                if (!isNumber(value.replace(/,/g, ''))) {
                  e.preventDefault();
                }
              }
            }}
            onKeyDown={onKeyDown}
            autoComplete="off"
            name={name}
            size="small"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            onClick={onClick}
            InputProps={{
              sx: { height: !multiline && 40 },
              endAdornment:
                endComponent ||
                (value && !multiline && (
                  <InputAdornment
                    position="end"
                    sx={{
                      px: 0,
                      visibility: !openClear && 'hidden'
                    }}
                  >
                    <IconButton onClick={onClear} sx={{ p: '0px  !important' }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <circle cx="8" cy="8" r="8" fill="#DADBDD" />
                        <path d="M4 12L8 8L4 4" stroke="white" />
                        <path d="M12 4L8 8L12 12" stroke="white" />
                      </svg>
                    </IconButton>
                  </InputAdornment>
                ))
            }}
            sx={{
              '.MuiInputBase-input': {
                p: { xs: '10px 12px' },
                ...sx,
                fontSize: {
                  xs: 14
                },

                color: '#202020',
                letterSpacing: '-0.56px'
              },
              '.MuiInputBase-root': {
                backgroundColor: disabled && '#F6F6F6'
              }
            }}
            multiline={multiline}
            rows={rows}
            minRows={minRows}
            maxRows={maxRows}
            {...rest}
          />
        </Stack>
      </Stack>
    );

  return (
    <Stack
      width={fullWidth && '100%'}
      sx={{
        flexDirection: { md: 'row', xs: 'column' },
        alignItems: { md: 'baseline' }
      }}
    >
      {label && (
        <Typography
          sx={{
            fontSize: { md: lang === 'ko' ? 15 : 12, xs: 12 },
            fontWeight: 500,
            width: lang === 'ko' ? '100px' : '130px',
            whiteSpace: lang === 'ko' ? 'noWrap' : 'wrap',
            ...labelStyle
          }}
        >
          {required && (
            <span style={{ color: 'red', fontSize: { md: 20, xs: 14 } }}>
              *
            </span>
          )}
          {label}
        </Typography>
      )}
      <Stack flexDirection="column" width={fullWidth && '100%'}>
        <TextField
          disabled={disabled}
          error={errMg}
          onFocus={() => {
            setOpenClear(true);
          }}
          onBlur={() =>
            setTimeout(() => {
              setOpenClear(false);
            }, 200)
          }
          onPaste={(e) => {
            const value = e.clipboardData.getData('text');
            if (onKeyDown) {
              if (!isNumber(value.replace(/,/g, ''))) {
                e.preventDefault();
              }
            }
          }}
          onKeyDown={onKeyDown}
          autoComplete="off"
          name={name}
          value={value}
          onChange={onChange}
          variant="standard"
          placeholder={placeholder}
          InputProps={{
            endAdornment:
              endComponent ||
              (value && (
                <InputAdornment
                  position="end"
                  sx={{
                    visibility: !openClear && 'hidden'
                  }}
                >
                  <IconButton onClick={onClear}>
                    <CancelRounded
                      sx={{
                        fontSize: { md: '36px', xs: '24px' },
                        color: 'lightgrey'
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ))
          }}
          sx={{
            '.MuiInputBase-input': {
              p: { md: '15px 40px 15px 15px', xs: '10px 28px 10px 4px' },
              ...sx,
              fontSize: {
                md: 15,
                xs: 12
              }
            }
          }}
          {...rest}
        />
      </Stack>
    </Stack>
  );
};

export default MyTextField;
