/* eslint-disable consistent-return */
import LoadingButton from '@components/buttons/LoadingButton';
import MyTextField from '@components/inputs/MyTextField';
import MyModal from 'pages-preorder/components/Modal';
import { useTimer } from 'react-timer-hook';
import { Stack, Typography } from '@mui/material';
import { Formatter } from '@utils/formatter';
import React, { useEffect, useState } from 'react';
import { actions } from '../enhance';

const getExpiryTimestamp = (secondsToAdd) => {
  const time = new Date();
  time.setSeconds(time.getSeconds() + secondsToAdd);
  return time;
};

export const CountTimeEmail = ({ seconds, setSeconds }) => {
  const defaultExpiry = getExpiryTimestamp(0);

  const {
    seconds: timerSeconds,
    minutes,
    restart
  } = useTimer({
    expiryTimestamp: defaultExpiry,
    onExpire: () => setSeconds(0),
    autoStart: true
  });

  useEffect(() => {
    if (seconds > 0) {
      const expiryTimestamp = getExpiryTimestamp(seconds);
      restart(expiryTimestamp);
    }
  }, [seconds, restart]);

  return (
    <Stack sx={{ fontSize: { xs: 14 }, color: '#1B2872' }}>
      {`${minutes < 10 ? '0' : ''}${minutes}:${
        timerSeconds < 10 ? '0' : ''
      }${timerSeconds}`}
    </Stack>
  );
};

export const CountTimePhone = ({ seconds, setSeconds }) => {
  const defaultExpiry = getExpiryTimestamp(0);

  const {
    seconds: timerSeconds,
    minutes,
    restart
  } = useTimer({
    expiryTimestamp: defaultExpiry,
    onExpire: () => setSeconds(0),
    autoStart: true
  });

  useEffect(() => {
    if (seconds > 0) {
      const expiryTimestamp = getExpiryTimestamp(seconds);
      restart(expiryTimestamp);
    }
  }, [seconds, restart]);

  return (
    <Stack sx={{ fontSize: { xs: 14 }, color: '#1B2872' }}>
      {`${minutes < 10 ? '0' : ''}${minutes}:${
        timerSeconds < 10 ? '0' : ''
      }${timerSeconds}`}
    </Stack>
  );
};
const Verification = ({
  verifyingBox,
  isVerifying,
  data,
  onAction,
  handleChange,
  errMsg,
  isVerified,
  seconds,
  setSeconds,
  emailSeconds,
  setEmailSeconds,
  minibarList,
  notification,
  setMinibarList,
  canVerifyOtp,
  handleInputClick,
  t
}) => {
  const [dialog, setDialog] = useState({ open: false });
  const [selectedItem, setSelectedItem] = useState(null);
  const [canResendEmail, setCanResendEmail] = useState(false);
  const [canResendPhone, setCanResendPhone] = useState(false);
  useEffect(() => {
    if (minibarList?.length > 1) {
      setSelectedItem(minibarList[0]);
      setDialog({ ...dialog, open: true });
    } else {
      setSelectedItem(minibarList?.[0]);
    }
  }, [minibarList]);
  // Update resend availability for email

  useEffect(() => {
    if (isVerifying.email) {
      setCanResendEmail(true);
    }
    if (isVerifying?.phone) {
      setCanResendPhone(true);
    }
  }, [isVerifying]);

  return (
    <Stack
      sx={{
        width: '100%',
        flexGrow: 1,
        display: 'flex',
        pb: 5
      }}
    >
      <Stack sx={{ py: 5 }}>
        <Typography
          sx={{
            fontSize: 24,
            color: '#202020',
            fontWeight: 700,
            letterSpacing: '-0.96px'
          }}
        >
          본인인증
        </Typography>
      </Stack>

      <Stack
        sx={{
          width: '100%',
          flexGrow: 1,
          display: 'flex'
        }}
      >
        {/* client */}
        <Stack
          sx={{
            flexDirection: 'column',
            gap: '5px'
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'flex-end'
            }}
          >
            <MyTextField
              required
              fullWidth
              preorder
              disabled={isVerified?.client}
              placeholder="회사명"
              value={data?.clientName}
              // onChange={(e) => handleChange('phone', e.target.value)}
              onChange={(e) => {
                handleChange('clientName', e.target.value);
              }}
              onClear={() => handleChange('clientName', '')}
              // onKeyDown={onlyNumber}
              errMg={errMsg?.clientName}
              onClick={() => handleInputClick('client')}
            />

            <Stack
              width="auto"
              sx={{
                ml: '5px',
                justifyContent: 'flex-end',
                alignItems: 'flex-start'
              }}
            >
              <LoadingButton
                variant="outlined"
                onClick={() => onAction(actions?.SEARCH_CLIENT)}
                disabled={
                  !data?.clientName || isVerifying?.client || isVerified?.client
                }
                sx={{
                  border: '0px !important',
                  backgroundColor:
                    !data?.clientName ||
                    isVerifying?.client ||
                    isVerified?.client
                      ? '#DADBDD'
                      : '#1B2872',
                  ':hover': {
                    backgroundColor:
                      !data?.clientName ||
                      isVerifying?.client ||
                      isVerified?.client
                        ? '#DADBDD'
                        : '#1B2872',
                    borderRadius: 1,
                    height: 40,
                    color: '#FFFFFF !important'
                  },

                  fontWeight: 500,
                  letterSpacing: '-0.56px',
                  minWidth: '112px',
                  maxWidth: 'fit-content',
                  color: '#FFFFFF !important',
                  height: { xs: 40 },
                  fontSize: { xs: 14 },
                  borderRadius: '8px',
                  boxShadow: 0
                }}
              >
                검색
              </LoadingButton>
            </Stack>
          </Stack>
          <Stack
            sx={{ width: '100%', height: '40px', justifyContent: 'flex-start' }}
          >
            {errMsg?.clientName && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#F03636',
                  letterSpacing: '-0.48px'
                }}
              >
                {errMsg?.clientName}
              </Typography>
            )}

            {notification?.client && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#1B2872',
                  letterSpacing: '-0.48px'
                }}
              >
                {notification?.client}
              </Typography>
            )}
          </Stack>
        </Stack>

        {/* email */}
        <Stack
          sx={{
            flexDirection: 'column',
            gap: 0.5
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'flex-end'
            }}
          >
            <MyTextField
              required
              fullWidth
              preorder
              placeholder="이메일 주소(아이디)"
              value={data?.email}
              type="email"
              // onChange={(e) => handleChange('phone', e.target.value)}
              onChange={(e) => {
                handleChange('email', e.target.value);
              }}
              onClear={() => handleChange('email', '')}
              disabled={isVerified?.emailOtp}
              // onKeyDown={onlyNumber}
              errMg={errMsg?.email}
              onClick={() => handleInputClick('email')}
            />

            <Stack
              width="auto"
              sx={{
                ml: 0.5,
                justifyContent: 'flex-end',
                alignItems: 'flex-start'
              }}
            >
              <LoadingButton
                variant="outlined"
                onClick={() =>
                  canResendEmail
                    ? onAction(actions.SEND_CODE_EMAIL_AGAIN)
                    : onAction(actions.SEND_CODE_EMAIL)
                }
                disabled={
                  errMsg?.email ||
                  !data.email ||
                  isVerified?.emailOtp ||
                  isVerifying?.email
                }
                sx={{
                  border: '0px !important',
                  backgroundColor:
                    errMsg?.email ||
                    !data.email ||
                    isVerified?.emailOtp ||
                    isVerifying?.email
                      ? '#DADBDD'
                      : '#1B2872',
                  ':hover': {
                    backgroundColor:
                      errMsg?.email ||
                      !data.email ||
                      isVerified?.emailOtp ||
                      isVerifying?.email
                        ? '#DADBDD'
                        : '#1B2872',
                    borderRadius: 1,
                    height: 40,
                    color: '#FFFFFF !important'
                  },

                  fontWeight: 500,
                  letterSpacing: '-0.56px',
                  minWidth: '112px',
                  maxWidth: 'fit-content',
                  color: '#FFFFFF !important',
                  height: { xs: 40 },
                  fontSize: { xs: 14 },
                  borderRadius: '8px',
                  boxShadow: 0
                }}
              >
                {canResendEmail ? '재전송' : '인증번호 전송'}
              </LoadingButton>
            </Stack>
          </Stack>
          <Stack
            sx={{ width: '100%', height: '40px', justifyContent: 'flex-start' }}
          >
            {errMsg?.email && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#F03636',
                  letterSpacing: '-0.48px'
                }}
              >
                {errMsg?.email}
              </Typography>
            )}
            {notification?.email && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#1B2872',
                  letterSpacing: '-0.48px'
                }}
              >
                {notification?.email}
              </Typography>
            )}
          </Stack>
        </Stack>
        {/* otp field */}
        <Stack
          display={verifyingBox?.emailOtp}
          sx={{
            flexDirection: 'column'
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'flex-end'
            }}
          >
            <MyTextField
              preorder
              fullWidth
              placeholder="인증번호"
              value={data?.emailOtp}
              type="tel"
              onChange={(e) => {
                handleChange('emailOtp', e.target.value.replace(/\D/g, ''));
              }}
              endComponent={
                isVerified?.emailOtp ? (
                  <Stack
                    sx={{
                      fontSize: { xs: 14 },
                      letterSpacing: '-0.56px',
                      color: '#1B2872'
                    }}
                  >
                    {Formatter.fSecondsToMinutes(emailSeconds)}
                  </Stack>
                ) : (
                  <CountTimeEmail
                    isVerifying={isVerifying?.emailOtp}
                    seconds={emailSeconds}
                    setSeconds={setEmailSeconds}
                  />
                )
              }
              errMg={errMsg?.emailOtp}
              disabled={isVerified?.emailOtp}
              onClick={() => handleInputClick('emailOtp')}
            />
            <Stack
              width="auto"
              sx={{
                ml: 0.5,
                justifyContent: 'flex-end',
                alignItems: 'flex-start'
              }}
            >
              <LoadingButton
                variant="outlined"
                onClick={() =>
                  onAction(actions.VERIFY_CODE_EMAIL, data?.emailOtp)
                }
                disabled={
                  !canVerifyOtp?.emailOtp ||
                  !data.emailOtp ||
                  data.emailOtp?.length !== 6 ||
                  isVerifying?.emailOtp ||
                  isVerified?.emailOtp
                }
                sx={{
                  border: '0px !important',
                  backgroundColor:
                    !canVerifyOtp?.emailOtp ||
                    !data.emailOtp ||
                    data.emailOtp?.length !== 6 ||
                    isVerifying?.emailOtp ||
                    isVerified?.emailOtp
                      ? '#DADBDD'
                      : '#1B2872',
                  ':hover': {
                    backgroundColor:
                      !canVerifyOtp?.emailOtp ||
                      isVerifying?.emailOtp ||
                      data.emailOtp?.length !== 6 ||
                      !data.emailOtp ||
                      isVerified?.emailOtp
                        ? '#DADBDD'
                        : '#1B2872',
                    borderRadius: 1,
                    height: 40,
                    color: '#FFFFFF !important'
                  },

                  fontWeight: 500,
                  letterSpacing: '-0.56px',
                  minWidth: '112px',
                  maxWidth: 'fit-content',
                  color: '#FFFFFF !important',
                  height: { xs: 40 },
                  fontSize: { xs: 14 },
                  borderRadius: '8px',
                  boxShadow: 0
                }}
              >
                확인
              </LoadingButton>
            </Stack>
          </Stack>
          <Stack
            sx={{ width: '100%', height: '40px', justifyContent: 'flex-start' }}
          >
            {errMsg?.emailOtp && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#F03636',
                  letterSpacing: '-0.48px'
                }}
              >
                {errMsg?.emailOtp}
              </Typography>
            )}
            {notification?.emailOtp && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#1B2872',
                  letterSpacing: '-0.48px'
                }}
              >
                {notification?.emailOtp}
              </Typography>
            )}
          </Stack>
        </Stack>
        {/* phone */}
        <Stack
          sx={{
            flexDirection: 'column',
            gap: 0.5
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'flex-end'
            }}
          >
            <MyTextField
              required
              fullWidth
              preorder
              placeholder="휴대폰 번호( - 제외)"
              value={data?.phone}
              type="tel"
              // onChange={(e) => handleChange('phone', e.target.value)}
              onChange={(e) => {
                handleChange('phone', e.target.value.replace(/\D/g, ''));
              }}
              onClear={() => handleChange('phone', '')}
              disabled={isVerified?.otpCode}
              // onKeyDown={onlyNumber}
              errMg={errMsg?.phone}
              onClick={() => handleInputClick('phone')}
            />

            <Stack
              width="auto"
              sx={{
                ml: 0.5,
                justifyContent: 'flex-end',
                alignItems: 'flex-start'
              }}
            >
              <LoadingButton
                variant="outlined"
                onClick={() =>
                  canResendPhone
                    ? onAction(actions.SEND_CODE_AGAIN)
                    : onAction(actions.SEND_CODE)
                }
                disabled={
                  errMsg?.phone ||
                  !data.phone ||
                  isVerified?.otpCode ||
                  isVerifying?.phone
                }
                sx={{
                  border: '0px !important',
                  backgroundColor:
                    errMsg?.phone ||
                    !data.phone ||
                    isVerified?.otpCode ||
                    isVerifying?.phone
                      ? '#DADBDD'
                      : '#1B2872',
                  ':hover': {
                    backgroundColor:
                      errMsg?.phone ||
                      !data.phone ||
                      isVerified?.otpCode ||
                      isVerifying?.phone
                        ? '#DADBDD'
                        : '#1B2872',
                    borderRadius: 1,
                    height: 40,
                    color: '#FFFFFF !important'
                  },

                  fontWeight: 500,
                  letterSpacing: '-0.56px',
                  minWidth: '112px',
                  maxWidth: 'fit-content',
                  color: '#FFFFFF !important',
                  height: { xs: 40 },
                  fontSize: { xs: 14 },
                  borderRadius: '8px',
                  boxShadow: 0
                }}
              >
                {canResendPhone ? '재전송' : '인증번호 전송'}
              </LoadingButton>
            </Stack>
          </Stack>

          <Stack
            sx={{ width: '100%', height: '40px', justifyContent: 'flex-start' }}
          >
            {errMsg?.phone && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#F03636',
                  letterSpacing: '-0.48px'
                }}
              >
                {errMsg?.phone}
              </Typography>
            )}
            {notification?.phone && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#1B2872',
                  letterSpacing: '-0.48px'
                }}
              >
                {notification?.phone}
              </Typography>
            )}
          </Stack>
        </Stack>
        {/* otp field */}
        <Stack
          display={verifyingBox?.otpCode}
          sx={{
            flexDirection: 'column',
            gap: 0.5
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'flex-end'
            }}
          >
            <MyTextField
              preorder
              fullWidth
              placeholder="인증번호"
              value={data?.otpCode}
              onChange={(e) => {
                handleChange('otpCode', e.target.value.replace(/\D/g, ''));
              }}
              type="tel"
              endComponent={
                isVerified?.otpCode ? (
                  <Stack
                    sx={{
                      fontSize: { xs: 14 },
                      letterSpacing: '-0.56px',
                      color: '#1B2872'
                    }}
                  >
                    {Formatter.fSecondsToMinutes(seconds?.phone)}
                  </Stack>
                ) : (
                  <CountTimePhone
                    isVerifying={isVerifying?.otpCode}
                    seconds={seconds}
                    setSeconds={setSeconds}
                  />
                )
              }
              errMg={errMsg?.otpCode}
              disabled={isVerified?.otpCode}
              onClick={() => handleInputClick('otpCode')}
            />
            <Stack
              width="auto"
              sx={{
                px: { md: 1, xs: 1 },
                justifyContent: 'flex-end',
                alignItems: 'flex-start'
              }}
            >
              <LoadingButton
                variant="outlined"
                onClick={() => onAction(actions.VERIFY_CODE, data?.otpCode)}
                disabled={
                  !data.otpCode ||
                  data.otpCode?.length !== 6 ||
                  isVerified?.otpCode ||
                  isVerifying?.otpCode ||
                  !canVerifyOtp?.otpCode
                }
                sx={{
                  border: '0px !important',
                  backgroundColor:
                    !canVerifyOtp?.otpCode ||
                    data.otpCode?.length !== 6 ||
                    isVerifying?.otpCode ||
                    !data.otpCode ||
                    isVerified?.otpCode
                      ? '#DADBDD'
                      : '#1B2872',
                  ':hover': {
                    backgroundColor:
                      !canVerifyOtp?.otpCode ||
                      isVerifying?.otpCode ||
                      data.otpCode?.length !== 6 ||
                      !data.otpCode ||
                      isVerified?.otpCode
                        ? '#DADBDD'
                        : '#1B2872',
                    borderRadius: 1,
                    height: 40,
                    color: '#FFFFFF !important'
                  },

                  fontWeight: 500,
                  letterSpacing: '-0.56px',
                  minWidth: '112px',
                  maxWidth: 'fit-content',
                  color: '#FFFFFF !important',
                  height: { xs: 40 },
                  fontSize: { xs: 14 },
                  borderRadius: '8px',
                  boxShadow: 0
                }}
              >
                확인
              </LoadingButton>
            </Stack>
          </Stack>
          <Stack
            sx={{ width: '100%', height: '40px', justifyContent: 'flex-start' }}
          >
            {errMsg?.otpCode && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#F03636',
                  letterSpacing: '-0.48px'
                }}
              >
                {errMsg?.otpCode}
              </Typography>
            )}
            {notification?.otpCode && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#1B2872',
                  letterSpacing: '-0.48px'
                }}
              >
                {notification?.otpCode}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
      {minibarList?.length > 0 && (
        <MyModal
          open={dialog.open}
          setOpen={() => {}}
          title={t('pages.pre-order.auth.pickup-select-title')}
          subTitle={t('pages.pre-order.auth.pickup-select-subtitle')}
          onOk={() => {
            if (selectedItem) {
              handleChange('client', selectedItem);

              setTimeout(() => {
                setDialog({ open: false });
              }, 100);
            }
          }}
          okTitle={t('button.save')}
          hideDialogButton={false}
          disabled={!selectedItem}
          fullScreen={false}
          sx={{ width: '90vw', borderRadius: 1 }}
        >
          {minibarList?.length < 4 ? (
            <Stack sx={{ width: '100%', gap: '30px' }}>
              {' '}
              <Typography
                sx={{
                  fontSize: 12,
                  color: '#202020',
                  letterSpacing: '-0.48px',
                  whiteSpace: 'pre-line',
                  wordBreak: 'keep-all', // Prevent breaking words
                  overflowWrap: 'break-word', // Handle long words
                  fontWeight: 500
                }}
              >
                {t('pages.pre-order.auth.pickup-select-subtitle')}
              </Typography>{' '}
              <Stack sx={{ width: '100%', alignItems: 'flex-start', gap: 1 }}>
                <Typography
                  sx={{
                    fontSize: 10,
                    color: '#202020',
                    fontWeight: 500,
                    letterSpacing: '-0.4px',
                    whiteSpace: 'pre-line'
                  }}
                >
                  {t('pages.pre-order.auth.select-main-branch')}
                </Typography>{' '}
                <Stack sx={{ width: '100%', gap: '14px' }}>
                  {minibarList?.map((item, index) => (
                    <Stack
                      key={`${item?.minibarName}-${item?.minibarCode}`}
                      sx={{
                        width: '100%',
                        borderRadius: 1,
                        py: '10px',
                        textAlign: 'center',
                        outline:
                          item?.minibarCode === selectedItem?.minibarCode &&
                          '1px solid #1B2872',
                        backgroundColor:
                          item?.minibarCode === selectedItem?.minibarCode
                            ? '#E9EBF6'
                            : '#F6F6F6'
                      }}
                      onClick={() => setSelectedItem(item)}
                    >
                      <Typography
                        sx={{
                          fontSize: 12,
                          color:
                            item?.minibarCode === selectedItem?.minibarCode
                              ? '#1B2872'
                              : '#9A9CA4',
                          letterSpacing: '-0.48px',
                          fontWeight: 500,
                          whiteSpace: 'pre-line'
                        }}
                      >
                        {item?.minibarName}
                      </Typography>{' '}
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <Stack />
          )}
        </MyModal>
      )}
    </Stack>
  );
};

export default Verification;
