import MyDialog from '@components/MyDialog';
import MySelect from '@components/MySelect';
import MyTextField from '@components/MyTextField';
import { Stack, Typography } from '@mui/material';
import { setError, setSuccess } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { useDispatch } from 'react-redux';
import { MAX_INT } from '@constants/utils';
import inventoryService from '@services/preorder/adminInventory';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { inventoryActionOptions, inventoryActions } from '../../enhance';

const InventoryActionsDialog = ({
  open,
  setOpen,
  setSelectedItem,
  onAction,
  item
}) => {
  const { t } = useTranslation();
  const [selectedAction, setSelectedAction] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [originalValue, setOriginalValue] = useState(0);
  const dispatch = useDispatch();
  const qunatityFieldLabel = {
    [inventoryActions.DISPLAY]: t('pages.pre-order.admin.display-quantity'),
    [inventoryActions.DISPOSE]: t('pages.pre-order.admin.dispose-quantity'),
    [inventoryActions.LOSS]: t('pages.pre-order.admin.loss-quantity')
  };
  useEffect(() => {
    setOriginalValue(item?.quantity);
  }, []);

  // ask whether it is okay to reset values when action changed
  const handleChangeActions = (e) => {
    setSelectedAction(e?.target?.value);
    setQuantity(0);
    setSelectedItem({ ...item, quantity: originalValue });
  };
  const handleQuantityChange = (e) => {
    switch (selectedAction) {
      case inventoryActions.DISPLAY:
        if (e?.target?.value)
          setQuantity(Math.min(Number(e.target.value), MAX_INT));
        else setQuantity(e?.target?.value);
        setSelectedItem((prev) => ({
          ...prev,
          quantity: Number(originalValue) + Number(e.target.value)
        }));
        break;
      case inventoryActions.LOSS:
      case inventoryActions.DISPOSE:
        if (e?.target?.value)
          setQuantity(Math.min(Number(e.target.value), Number(originalValue)));
        else setQuantity(e?.target?.value);
        setSelectedItem((prev) => ({
          ...prev,
          quantity:
            Number(originalValue) -
            Math.min(Number(e.target.value), Number(originalValue))
        }));
        break;

      default:
        break;
    }
  };

  const handleUpdateMinibarInventory = async () => {
    const rs = await inventoryService.updateMinibarInventory({
      minibarStockCode: item?.minibarStockCode,
      type: selectedAction,
      quantity
    });
    if (!rs?.error) {
      dispatch(setSuccess({ message: '저장되었습니다.' }));
      onAction('success');
    } else {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  return (
    <MyDialog
      open={open}
      title={t('pages.pre-order.admin.invemtory-management')}
      setOpen={setOpen}
      hasCloseButton
      hasCancelButton
      cancelTitle={t('button.close')}
      okTitle={t('button.save')}
      disabled={!selectedAction || quantity === 0 || !quantity}
      sx={{
        minWidth: { md: '704px', xs: '350px' },
        width: { xs: '90vw', md: 'auto' },
        maxWidth: '90vw'
      }}
      onOk={handleUpdateMinibarInventory}
    >
      <Stack sx={{ width: '100%', gap: 2 }}>
        {/* 1st row */}

        <Stack
          sx={{
            width: '100%',

            alignItems: 'center',
            flexDirection: 'row',
            gap: 2
          }}
        >
          <Typography sx={{ whiteSpace: 'nowrap', minWidth: '74px' }}>
            {t('common.productName')}
          </Typography>
          <MyTextField
            fullWidth
            sx={{
              flexGrow: 1,
              '.MuiInputBase-root': {
                bgcolor: '#f2f2f2'
              }
            }}
            value={item?.productName}
            disabled
          />
        </Stack>
        {/* 2nd row */}
        <Stack
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: { md: 'row', xs: 'column' },
            gap: { md: 4, xs: 2 }
          }}
        >
          <Stack
            sx={{
              alignItems: 'center',
              flexDirection: 'row',
              width: { xs: '100%', md: 'auto' },
              gap: 2
            }}
          >
            <Typography sx={{ whiteSpace: 'nowrap', minWidth: '74px' }}>
              {t('pages.pre-order.admin.inventory')}
            </Typography>
            <MyTextField
              fullWidth
              sx={{
                flexGrow: 1,
                width: { xs: '100%', md: 'auto' },
                '.MuiInputBase-root': {
                  bgcolor: '#f2f2f2'
                }
              }}
              value={originalValue || 0}
              disabled
            />
          </Stack>

          <Stack
            sx={{
              alignItems: 'center',
              flexDirection: 'row',
              width: { xs: '100%', md: 'auto' },
              gap: 2
            }}
          >
            <Typography
              sx={{
                whiteSpace: 'nowrap',
                minWidth: '74px',
                textAlign: { xs: 'left', md: 'right' }
              }}
            >
              {t('pages.pre-order.admin.action-type')}
            </Typography>
            <MySelect
              data={inventoryActionOptions}
              value={selectedAction}
              sx={{
                flexGrow: { xs: 1, md: 0 }
              }}
              onChange={handleChangeActions}
            />
          </Stack>
        </Stack>

        <Stack
          sx={{
            width: '100%',
            border: '1px solid #D3D3D3',
            mt: 1,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 1,
            p: { md: 4, xs: 2 },
            gap: 2,
            minHeight: '160px'
          }}
        >
          {/* 1st row */}

          {selectedAction && (
            <Stack sx={{ gap: 2, width: '100%' }}>
              <Stack
                sx={{
                  width: '100%',

                  alignItems: 'center',
                  flexDirection: 'row',
                  gap: 2
                }}
              >
                <Typography sx={{ whiteSpace: 'nowrap', minWidth: '80px' }}>
                  {qunatityFieldLabel[selectedAction]}
                </Typography>
                <MyTextField
                  fullWidth
                  sx={{ flexGrow: 1, width: '100%' }}
                  value={quantity}
                  onChange={handleQuantityChange}
                  disabled={
                    selectedAction !== inventoryActions.DISPLAY &&
                    originalValue < 1
                  }
                  typeValue="onlyNumber"
                />
              </Stack>
              {/* 2nd row */}
              <Stack
                sx={{
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'row',
                  gap: 4
                }}
              >
                <Stack
                  sx={{
                    alignItems: 'center',
                    flexDirection: 'row',
                    width: '100%',
                    gap: 2
                  }}
                >
                  <Typography sx={{ whiteSpace: 'nowrap', minWidth: '80px' }}>
                    {selectedAction === inventoryActions.DISPLAY
                      ? '(+)'
                      : '(-)'}{' '}
                    {t('pages.pre-order.admin.inventory')}
                  </Typography>
                  <MyTextField
                    fullWidth
                    sx={{
                      flexGrow: 1,
                      '.MuiInputBase-root': {
                        bgcolor: '#f2f2f2'
                      }
                    }}
                    value={item?.quantity}
                    disabled
                  />
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </MyDialog>
  );
};

export default InventoryActionsDialog;
