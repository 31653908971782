export const actions = {
  AGREE_POLICY: 'agree_policy',
  SEND_CODE: 'send_code',
  SEND_CODE_AGAIN: 'send_code_again',
  VERIFY_CODE: 'verify_code',
  SEND_CODE_EMAIL: 'send_code_email',
  SEND_CODE_EMAIL_AGAIN: 'send_code_email_again',
  VERIFY_CODE_EMAIL: 'verify_code_email',
  SEARCH_CLIENT: 'search_client',
  REGISTER_MEMBER: 'register_member',
  LOGIN: 'login'
};

export const screens = {
  POLICY: 'policy',
  VERIFICATION: 'verification',
  REGISTRATION: 'registration'
};

export const loginScreens = {
  LOGIN: 'LOGIN',
  FIND_ID: 'FIND_ID',
  FIND_ID_SUCCEESS: 'FIND_ID_SUCCESS',
  FIND_PASSWORD: 'FIND_PASSWORD',
  FIND_ID_PASSWORD: 'FIND_ID_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD'
};

export const logInActions = {
  SEND_CODE_FIND_ID: 'send_code_find_id',
  SEND_CODE_AGAIN_FIND_ID: 'send_code_again_find_id',
  VERIFY_CODE_FIND_ID: 'verify_code_find_id',
  FIND_EMAIL: 'find_email',
  SEND_CODE_CHANGE_PASSWORD: 'send_code_change_password',
  SEND_CODE_AGAIN_CHANGE_PASSWORD: 'send_code_again_change_password',
  VERIFY_CODE_CHANGE_PASSWORD: 'verify_code_change_password',
  CHANGE_PASSWORD: 'change_password',
  SEARCH_CLIENT: 'search_client',
  REGISTER_MEMBER: 'register_member',
  LOGIN: 'login'
};
