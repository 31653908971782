/* eslint-disable react/no-unknown-property */
import MyTextField from '@components/inputs/MyTextField';
import { IconButton, Stack, Typography } from '@mui/material';

import React, { useState } from 'react';

const LoginForm = ({
  data,

  handleChange,
  errMsg,
  t
}) => {
  const [showPassword, setShowPassword] = useState({
    password: false
  });

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field]
    }));
  };

  return (
    <Stack
      sx={{
        width: '100%',
        flexGrow: 1,
        display: 'flex'
      }}
    >
      <Stack sx={{ pb: 5, pt: 2.5 }}>
        <Typography
          sx={{
            fontSize: 24,
            color: '#202020',
            fontWeight: 700,
            letterSpacing: '-0.96px'
          }}
        >
          로그인
        </Typography>
      </Stack>

      <Stack
        sx={{
          width: '100%',
          flexGrow: 1,
          display: 'flex',
          gap: 5
        }}
      >
        {/* name */}
        <Stack sx={{ flexDirection: 'column' }}>
          <Stack sx={{ flexDirection: 'row' }}>
            <MyTextField
              preorder
              required
              autoComplete="off"
              type="email"
              errMg={errMsg?.email}
              fullWidth
              placeholder="아이디(이메일) 입력"
              value={data?.email}
              onChange={(e) => handleChange('email', e.target.value)}
              onClear={() => handleChange('email', '')}
            />
          </Stack>
          {errMsg?.email && (
            <Typography
              sx={{
                fontSize: { xs: 12 },
                color: '#F03636',
                letterSpacing: '-0.48px'
              }}
            >
              {errMsg?.email}
            </Typography>
          )}
        </Stack>

        {/* password */}
        <Stack sx={{ flexDirection: 'column', gap: 0.5 }}>
          <Stack sx={{ flexDirection: 'row', alignItems: 'flex-end' }}>
            <MyTextField
              required
              fullWidth
              autoComplete="off"
              preorder
              placeholder="비밀번호 입력"
              type={showPassword.password ? 'text' : 'password'}
              value={data?.password}
              onChange={(e) => handleChange('password', e.target.value)}
              endComponent={
                <IconButton
                  size="small"
                  sx={{ mr: -1 }}
                  onClick={() => togglePasswordVisibility('password')}
                >
                  {showPassword.password ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect width="24" height="24" fill="white" />
                      <path
                        d="M12 18C4.83934 18 1.26433 12.6205 1.11549 12.3914C1.04018 12.2755 1 12.1392 1 11.9998C1 11.8605 1.04018 11.7242 1.11549 11.6082C1.26433 11.3795 4.83934 6 12 6C19.1607 6 22.7357 11.3795 22.8845 11.6086C22.9598 11.7245 23 11.8608 23 12.0002C23 12.1395 22.9598 12.2758 22.8845 12.3918C22.7357 12.6205 19.1607 18 12 18ZM12 7.41176C6.66946 7.41176 3.48668 10.8275 2.55099 12.0014C3.48358 13.1778 6.64987 16.5882 12 16.5882C17.3305 16.5882 20.5133 13.1725 21.449 11.9986C20.5164 10.8222 17.3501 7.41176 12 7.41176Z"
                        fill="#DADBDD"
                      />
                      <path
                        d="M12 15C10.3457 15 9 13.654 9 12C9 10.346 10.3457 9 12 9C13.6543 9 15 10.346 15 12C15 13.654 13.6543 15 12 15ZM12 10.3333C11.081 10.3333 10.3333 11.081 10.3333 12C10.3333 12.919 11.081 13.6667 12 13.6667C12.919 13.6667 13.6667 12.919 13.6667 12C13.6667 11.081 12.919 10.3333 12 10.3333Z"
                        fill="#DADBDD"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect width="24" height="24" fill="white" />
                      <path
                        d="M12 18C4.83934 18 1.26433 12.6205 1.11549 12.3914C1.04018 12.2755 1 12.1392 1 11.9998C1 11.8605 1.04018 11.7242 1.11549 11.6082C1.26433 11.3795 4.83934 6 12 6C19.1607 6 22.7357 11.3795 22.8845 11.6086C22.9598 11.7245 23 11.8608 23 12.0002C23 12.1395 22.9598 12.2758 22.8845 12.3918C22.7357 12.6205 19.1607 18 12 18ZM12 7.41176C6.66946 7.41176 3.48668 10.8275 2.55099 12.0014C3.48358 13.1778 6.64987 16.5882 12 16.5882C17.3305 16.5882 20.5133 13.1725 21.449 11.9986C20.5164 10.8222 17.3501 7.41176 12 7.41176Z"
                        fill="#DADBDD"
                      />
                      <path
                        d="M12 15C10.3457 15 9 13.654 9 12C9 10.346 10.3457 9 12 9C13.6543 9 15 10.346 15 12C15 13.654 13.6543 15 12 15ZM12 10.3333C11.081 10.3333 10.3333 11.081 10.3333 12C10.3333 12.919 11.081 13.6667 12 13.6667C12.919 13.6667 13.6667 12.919 13.6667 12C13.6667 11.081 12.919 10.3333 12 10.3333Z"
                        fill="#DADBDD"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.61429 18L23 7.13722L22.3857 6L1 16.8628L1.61429 18Z"
                        fill="#DADBDD"
                      />
                    </svg>
                  )}
                </IconButton>
              }
              onClear={() => handleChange('password', '')}
              errMg={errMsg?.password}
            />
          </Stack>
          {errMsg?.password && (
            <Typography
              sx={{
                fontSize: { xs: 12 },
                color: '#F03636',
                letterSpacing: '-0.48px'
              }}
            >
              {errMsg?.password}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LoginForm;
