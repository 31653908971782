/* eslint-disable no-restricted-globals */
import { IconButton, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import i18n from '@utils/i18n';

import { useNavigate, useParams } from 'react-router-dom';
import MyBottomSwiper from 'pages-preorder/components/MyBottomSwiper';
import MenuStores from './MenuStore';

const aboutUs = i18n.getFixedT(null, null, 'pages.contactForm');

const PreorderHeader = ({
  currentStore,
  setCurrentStore,
  cartData,
  presentation = false,
  hasBackButton = false
}) => {
  const [openMo, setOpenMo] = useState(false);

  const navigate = useNavigate();
  const { version } = useParams();
  const handleStoreTypeClick = () => {
    setOpenMo(true);
  };

  return (
    <Stack
      sx={{
        width: '100%',
        maxWidth: '500px',
        top: 0,
        position: 'fixed',
        backgroundColor: '#FFFFFF',
        zIndex: 1002
      }}
    >
      <Stack
        id="navBarHeader"
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          backgroundColor: '#FFFFF',
          width: '100%',
          position: 'relative',
          py: '13px',
          px: 2.5,
          height: { xs: '50px' }
        }}
      >
        {hasBackButton && (
          <Stack sx={{ position: 'absolute', left: '0px' }}>
            <IconButton
              size="small"
              sx={{
                p: 0,
                px: 2.5,
                py: 1,
                backgroundColor: 'transparent !important'
              }}
              onClick={() => navigate(`/pre-order/${version}/home`)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
              >
                <path d="M7 1L1 7L7 13" stroke="black" />
              </svg>
            </IconButton>
          </Stack>
        )}
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            flexGrow: 1
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',

              gap: '2px',
              cursor: 'pointer' // Add pointer cursor for better UX
            }}
            onClick={presentation ? () => {} : handleStoreTypeClick}
          >
            {!presentation && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                style={{ marginBottom: '2px' }}
              >
                <rect width="24" height="24" fill="white" />
                <path
                  d="M12 5C9.23571 5 7 7.25064 7 10.0334C7 13.0318 10.1571 17.1664 11.4571 18.7411C11.7429 19.0863 12.2643 19.0863 12.55 18.7411C13.8429 17.1664 17 13.0318 17 10.0334C17 7.25064 14.7643 5 12 5ZM12 11.831C11.0143 11.831 10.2143 11.0257 10.2143 10.0334C10.2143 9.04109 11.0143 8.23575 12 8.23575C12.9857 8.23575 13.7857 9.04109 13.7857 10.0334C13.7857 11.0257 12.9857 11.831 12 11.831Z"
                  fill="#202020"
                />
              </svg>
            )}
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 700,
                color: '#202020',
                letterSpacing: '-0.56px'
              }}
            >
              {currentStore?.storeName}
            </Typography>
          </Stack>
        </Stack>

        <Stack sx={{ position: 'absolute', right: '20px' }}>
          <IconButton
            size="small"
            sx={{
              p: 0,
              backgroundColor: 'transparent !important',
              position: 'relative'
            }}
            onClick={() =>
              navigate(`/pre-order/${version}/store/cart`, {
                state: {
                  originalPath: location?.pathname
                }
              })
            }
          >
            <Stack
              sx={{
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '10px',
                left: '2px',
                top: '3px',
                zIndex: 50
              }}
            >
              <Typography
                sx={{
                  fontSize: 8,
                  fontWeight: 700,
                  color: '#1B2872',
                  letterSpacing: '-0.32px'
                }}
              >
                {cartData?.totalProductCount}
              </Typography>
            </Stack>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <path
                d="M0 1H2.83333L6.61111 14.2317M6.61111 14.2317H18.125L20 7.25M6.61111 14.2317L5 18.5H16.25"
                stroke="black"
              />
              <circle cx="17.5" cy="18.5" r="2" fill="white" stroke="black" />
              <circle cx="5" cy="18.5" r="2" fill="white" stroke="black" />
            </svg>
          </IconButton>
        </Stack>
      </Stack>

      {/* Drawer (Modal) triggered by store type click */}
      {openMo && (
        <MyBottomSwiper
          open={openMo}
          setOpen={setOpenMo}
          title="상점선택"
          sx={{
            dialogContent: {
              p: 0
            }
          }}
        >
          <MenuStores
            setOpen={setOpenMo}
            currentStore={currentStore}
            setCurrentStore={setCurrentStore}
          />
        </MyBottomSwiper>
      )}
    </Stack>
  );
};

export default PreorderHeader;
