/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
/* eslint-disable no-case-declarations */
import React, { useState, useEffect } from 'react';
import {
  Button,
  Container,
  IconButton,
  LinearProgress,
  Stack,
  Typography
} from '@mui/material';
import AlertDialog from 'pages-preorder/components/Modal/Alert/AlertDialog';
import codeService from '@services/code';
import { setAlert, setPreorderError } from '@store/reducers/pre-order/app';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setAuthenticated } from '@store/reducers/pre-order/auth';
import { ignoreEmpty } from '@utils/helpers';
import {
  is11Number,
  isEmail,
  isValidKoreanPhoneNumber,
  isValidPassword
} from '@utils/boolean';
import signUpService from '@services/preorder/auth';
import error2Text from '@utils/error2Text';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TermAndPolicy from './terms-policy';
import { actions } from './enhance';
import Verification from './Verification';
import Registration from './Registration';

const maxLengthOf = {
  name: 10,
  phone: 20,
  otpCode: 6
};
const screens = {
  POLICY: 'POLICY',
  VERIFICATION: 'VERIFICATION',
  REGISTRATION: 'REGISTRATION'
};

export const SignUp = () => {
  const [screen, setScreen] = useState(screens.POLICY);
  const [policyList, setPolicyList] = useState([]);
  const [signUpData, setSignUpData] = useState({
    agreeTermsAll: false,
    otp: '',
    clientName: '',
    name: '',
    phone: '',
    gender: 'male',
    email: '',
    verificationCode: null
    // Add other sign-up fields here
  });
  const dispatch = useDispatch();

  const [minibarList, setminibarList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [verifyingBox, setVerifyingBox] = useState({
    otpCode: 'none',
    emailOtp: 'none'
  });

  const [canVerifyOtp, setCanVerifyOtp] = useState({
    otpCode: true,
    emailOtp: true
  });

  const [isVerifying, setVerifying] = useState({
    clientName: false,
    otpCode: false,
    phone: false,
    email: false,
    emailOtp: false
  });
  const [isVerified, setVerified] = useState({
    client: false,
    otpCode: false
  });
  const [seconds, setSeconds] = useState(-1);
  const [emailSeconds, setEmailSeconds] = useState(-1);
  const [alertDialog, setAlertDialog] = useState({
    open: false,
    title: ''
  });

  const [notification, setNotification] = useState({
    otp: ''
  });
  const [errMsg, setErrMsg] = useState({});

  const location = useLocation();

  const { version } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (screen === screens.POLICY) getPolicyList();
  }, []);

  const getPolicyList = async () => {
    try {
      const rs = await signUpService.getTermsAndPolicy({ locale: version });
      if (rs?.data) {
        setPolicyList(
          rs.data.map((policy) => ({ ...policy, isChecked: false }))
        );
      }
    } catch (error) {
      console.error('Error fetching policy list:', error);
    }
  };

  const handleChangePolicy = (item) => {
    setPolicyList((prev) => {
      const updatedPolicyList = prev.map((i) =>
        i.termPolicyCode === item.termPolicyCode
          ? { ...i, isChecked: !i.isChecked }
          : i
      );
      const allChecked = updatedPolicyList.every((policy) => policy.isChecked);
      setSignUpData((prevData) => ({ ...prevData, agreeTermsAll: allChecked }));
      return updatedPolicyList;
    });
  };

  const handleLogin = async (dataSubmit) => {
    const { error, data } = await signUpService.login(dataSubmit);
    if (!error) {
      dispatch(setAuthenticated(true));
      localStorage.setItem('PREORDER_TOKEN', data?.accessToken);
      localStorage.setItem('PREORDER_REFRESH_TOKEN', data?.refreshToken);
      if (navigator.userAgent) {
        localStorage.setItem('PREORDER_SESSION_ID', data?.sessionID);
      }

      navigate(`/pre-order/${version}/home`);
    } else {
      dispatch(setPreorderError(error2Text(error)));
    }
  };

  const handleCheckAllPolicy = () => {
    const newAgreeTermsAll = !signUpData.agreeTermsAll;
    setSignUpData((prevData) => ({
      ...prevData,
      agreeTermsAll: newAgreeTermsAll
    }));
    setPolicyList((prev) =>
      prev.map((policy) => ({ ...policy, isChecked: newAgreeTermsAll }))
    );
  };

  const isButtonDisabled = () => {
    switch (screen) {
      case screens.POLICY:
        return !policyList.every(
          (policy) => !policy.mandatory || policy.isChecked
        );
      case screens.VERIFICATION:
        return (
          !signUpData?.client ||
          !signUpData?.phone ||
          !signUpData?.email ||
          !signUpData?.emailVerificationCode ||
          !signUpData?.verificationCode ||
          !isVerified?.client ||
          !isVerified?.otpCode ||
          !isVerified?.emailOtp
        );

      case screens.REGISTRATION:
        return (
          !signUpData?.password ||
          !signUpData?.confirmPassword ||
          !signUpData?.gender ||
          !signUpData?.birthDate ||
          signUpData?.password !== signUpData?.confirmPassword ||
          loading
        );
      // Add cases for other screens
      default:
        return true;
    }
  };

  const handleNextScreen = () => {
    switch (screen) {
      case screens.POLICY:
        setScreen(screens.VERIFICATION);
        break;
      case screens.VERIFICATION:
        setScreen(screens.REGISTRATION);
        break;
      case screens.REGISTRATION:
        handleApiAction(actions.REGISTER_MEMBER);
        break;
      default:
        break;
    }
  };

  const handlePreviousScreen = () => {
    switch (screen) {
      case screens.VERIFICATION:
        setScreen(screens.POLICY);
        break;
      case screens.REGISTRATION:
        setScreen(screens.VERIFICATION);
        break;
      default:
        break;
    }
  };

  const handleCancel = () => {
    setScreen(screens.POLICY);
    setSignUpData({
      agreeTermsAll: false
    });
    setPolicyList((prev) =>
      prev.map((policy) => ({ ...policy, isChecked: false }))
    );
    navigate(`/pre-order/${version}/auth`);
  };

  const handleChangeVerification = (name, value) => {
    if (name === 'phone') {
      if (isVerifying?.otpCode)
        setVerifying({ ...isVerifying, otpCode: false });
      setSignUpData({ ...signUpData, [name]: value });
      if (!value) {
        setErrMsg({
          [name]: '휴대폰 번호를 입력해 주세요.'
        });
      } else if (is11Number(value)) {
        setErrMsg({
          phone: isValidKoreanPhoneNumber(value)
            ? ''
            : '휴대폰번호 형식이 올바르지 않습니다.'
        });
      } else {
        setErrMsg({ [name]: value ? '' : '휴대폰 번호를 입력해 주세요.' });
      }
      setNotification({ ...notification, [name]: '' });
      return;
    } else if (name === 'clientName') {
      setSignUpData({
        ...signUpData,
        [name]: value
      });
      setErrMsg({ ...errMsg, [name]: value ? '' : '회사명을 입력해 주세요.' });
      return;
    } else if (name === 'email') {
      setSignUpData({
        ...signUpData,
        [name]: value
      });
      if (isVerifying?.emailOtp)
        setVerifying({ ...isVerifying, emailOtp: false });
      setErrMsg({ ...errMsg, [name]: value ? '' : '이메일을 입력해주세요.' });
      setNotification({ ...notification, email: '' });
      return;
    } else if (name === 'client' && value?.pickupSpotCode) {
      console.log(name, value);
      setSignUpData({
        ...signUpData,
        [name]: value,
        clientName: value?.minibarName
      });
      setVerified({ ...isVerified, client: true });
      setNotification({ ...notification, client: '인증되었습니다.' });
      setminibarList([]);
      return;
    }

    if (value?.length > maxLengthOf[name]) {
      setErrMsg({ ...errMsg, [name]: value ? '' : t('common.required') });
    } else {
      setSignUpData({ ...signUpData, [name]: value });
      setErrMsg({ ...errMsg, [name]: value ? '' : t('common.required') });
    }
  };

  const handlePasswordValidation = (name, value) => {
    if (name === 'password') {
      if (value && isValidPassword(value)) {
        setNotification((prev) => ({
          ...prev,
          [name]: '사용 가능한 비밀번호입니다.'
        }));
        setErrMsg((prev) => ({ ...prev, [name]: '' }));
        if (signUpData?.confirmPassword) {
          if (value !== signUpData?.confirmPassword) {
            setErrMsg((prev) => ({
              ...prev,
              confirmPassword: '비밀번호가 일치하지 않습니다.'
            }));
            setNotification((prev) => ({
              ...prev,
              confirmPassword: ''
            }));
          } else if (value === signUpData?.confirmPassword) {
            setErrMsg((prev) => ({
              ...prev,
              confirmPassword: ''
            }));
            setNotification((prev) => ({
              ...prev,
              confirmPassword: '비밀번호가 일치합니다.'
            }));
          }
        }
      } else {
        setErrMsg((prev) => ({
          ...prev,
          [name]: value
            ? '비밀번호는 영문, 숫자, 특수문자를 조합해서 입력해 주세요.(8-16자)'
            : '비밀번호를 입력해 주세요.'
        }));
        setNotification((prev) => ({
          ...prev,
          [name]: ''
        }));
        if (signUpData?.confirmPassword) {
          if (signUpData?.confirmPassword) {
            setErrMsg((prev) => ({
              ...prev,
              confirmPassword: '비밀번호가 일치하지 않습니다.'
            }));
            setNotification((prev) => ({
              ...prev,
              confirmPassword: ''
            }));
          }
        }
      }
    }

    if (name === 'confirmPassword') {
      if (isValidPassword(value) && value === signUpData?.password) {
        setNotification((prev) => ({
          ...prev,
          [name]: '비밀번호가 일치합니다.'
        }));
        setErrMsg((prev) => ({ ...prev, [name]: '' }));
      } else {
        setErrMsg((prev) => ({
          ...prev,
          [name]: value
            ? value === signUpData?.password
              ? ''
              : '비밀번호가 일치하지 않습니다.'
            : '비밀번호를 입력해 주세요.'
        }));
        setNotification((prev) => ({ ...prev, [name]: '' }));
      }
    }
  };

  const handleNameValidation = (name, value) => {
    if (value?.length > 10) {
      setErrMsg((prev) => ({
        ...prev,
        [name]: '10글자 이내로 입력해 주세요.'
      }));
    } else if (value?.length === 1) {
      setErrMsg((prev) => ({
        ...prev,
        [name]: '최소 2글자 이상 입력해 주세요.'
      }));
    } else {
      setErrMsg((prev) => ({
        ...prev,
        [name]: value ? '' : '이름을 입력해 주세요.'
      }));
    }
  };

  const handleChangeRegistration = (name, value) => {
    console.log(name, value);

    if (value?.length > maxLengthOf[name]) {
      setErrMsg((prev) => ({
        ...prev,
        [name]: value ? '' : t('common.required')
      }));
    }

    setSignUpData((prev) => ({
      ...prev,
      [name]: ['password', 'confirmPassword'].includes(name)
        ? value?.trim()
        : value
    }));
    setErrMsg((prev) => ({
      ...prev,
      [name]: value ? '' : t('common.required')
    }));

    if (name === 'password' || name === 'confirmPassword') {
      handlePasswordValidation(name, value.trim());
    }

    if (name === 'name') {
      handleNameValidation(name, value);
    }
  };

  const handleInputClick = (name) => {
    if (name && isVerifying?.[name]) {
      setVerifying({ ...isVerifying, [name]: false });
    }
  };

  const handleApiAction = async (action, val) => {
    let rs;
    switch (action) {
      case actions.SEND_CODE:
        if (!signUpData?.phone || errMsg.phone) {
          setErrMsg({
            ...errMsg,
            phone: '11자리 숫자 형태의 휴대폰 번호를 입력해주세요.'
          });
          return;
        }
        if (!isValidKoreanPhoneNumber(signUpData?.phone)) {
          setErrMsg({
            ...errMsg,
            phone: '휴대폰번호 형식이 올바르지 않습니다.'
          });
          return;
        }

        rs = await codeService.sendPreorderOTP(
          ignoreEmpty({
            contact: signUpData?.phone,
            email: signUpData?.email,
            verificationType: 'sms',
            purpose: 'registration'
          })
        );
        if (!rs?.error) {
          setErrMsg({ otp: '' });
          setSignUpData({ ...signUpData, otpCode: '' });
          setVerifyingBox({ ...verifyingBox, otpCode: 'init' });
          setVerifying({ ...isVerifying, otpCode: true, phone: true });
          setCanVerifyOtp({ ...canVerifyOtp, otpCode: true });
          setVerified({ ...isVerified, otpCode: false });
          setSeconds(180);
          setNotification({
            ...notification,
            phone: '인증번호가 전송되었습니다.'
          });
          dispatch(
            setAlert({ message: `인증번호가 전송되었습니다.(${rs?.data}/5)` })
          );
        } else {
          dispatch(setAlert({ message: error2Text(rs?.error)?.content }));
        }
        break;
      case actions.SEND_CODE_AGAIN:
        if (!signUpData?.phone || errMsg.phone) {
          setErrMsg({
            ...errMsg,
            phone: '11자리 숫자 형태의 휴대폰 번호를 입력해주세요.'
          });
          return;
        }
        if (!isValidKoreanPhoneNumber(signUpData?.phone)) {
          setErrMsg({
            ...errMsg,
            phone: '휴대폰번호 형식이 올바르지 않습니다.'
          });
          return;
        }

        rs = await codeService.sendPreorderOTP(
          ignoreEmpty({
            contact: signUpData?.phone,
            email: signUpData?.email,
            verificationType: 'sms',
            purpose: 'registration'
          })
        );
        if (!rs?.error) {
          setErrMsg({ otp: '' });
          setSignUpData({ ...signUpData, otpCode: '' });
          setVerifyingBox({
            ...verifyingBox,

            otpCode: 'init'
          });
          setVerifying({ ...isVerifying, otpCode: true, phone: true });
          setCanVerifyOtp({ ...canVerifyOtp, otpCode: true });
          setVerified({ ...isVerified, otpCode: false });
          setSeconds(-1); // First reset to 0
          setTimeout(() => {
            setSeconds(180); // Then set to 180 after a brief delay
          }, 0);
          setNotification({
            ...notification,
            phone: '인증번호가 전송되었습니다.'
          });
          dispatch(
            setAlert({ message: `인증번호가 전송되었습니다.(${rs?.data}/5)` })
          );
        } else {
          dispatch(setAlert({ message: error2Text(rs?.error)?.content }));
        }
        break;
      case actions.VERIFY_CODE:
        if (!signUpData?.otpCode || signUpData?.otpCode?.length !== 6) {
          setErrMsg({
            otpCode: '인증번호가 올바르지 않습니다.'
          });
          return;
        }
        rs = await codeService.verifyPreorderOTP({
          contact: signUpData?.phone,
          verificationNumber: val,
          verificationType: 'sms',
          purpose: 'registration'
        });
        if (!rs?.error) {
          setVerifying({ ...isVerifying, otpCode: false, phone: false });
          setVerified({ ...isVerified, otpCode: true });
          setVerifyingBox({
            ...verifyingBox,

            otpCode: 'none'
          });
          setCanVerifyOtp({ ...canVerifyOtp, otpCode: false });
          setNotification({
            ...notification,
            phone: '인증되었습니다.'
          });
          const loc = rs?.location?.split('/');
          if (loc) setSignUpData({ ...signUpData, verificationCode: loc?.[1] });
        } else {
          setVerifying({ ...isVerifying, otpCode: true });
          if (rs?.error?.keyError === 'PREORDER_VERIFICATION_FAILED') {
            setErrMsg({ ...errMsg, otpCode: '인증번호가 올바르지 않습니다.' });
            break;
          }
          dispatch(setAlert({ message: error2Text(rs?.error)?.content }));
        }
        break;

      // email
      case actions.SEND_CODE_EMAIL:
        if (!signUpData?.email || errMsg.email) {
          setErrMsg({
            email: '이메일 형식이 올바르지 않습니다.'
          });
          return;
        }
        if (!isEmail(signUpData?.email)) {
          setErrMsg({
            email: '이메일 형식이 올바르지 않습니다.'
          });
          return;
        }
        rs = await codeService.sendPreorderOTP(
          ignoreEmpty({
            contact: signUpData?.phone,
            email: signUpData?.email,
            verificationType: 'email',
            purpose: 'registration'
          })
        );
        if (!rs?.error) {
          setErrMsg({ ...errMsg, emailOtp: '' });
          setSignUpData({ ...signUpData, emailOtp: '' });
          setVerifyingBox({ ...verifyingBox, emailOtp: 'init' });
          setVerifying({ ...isVerifying, emailOtp: true, email: true });

          setCanVerifyOtp({ ...canVerifyOtp, emailOtp: true });
          setVerified({ ...isVerified, emailOtp: false });
          setEmailSeconds(-1); // First reset to 0
          setTimeout(() => {
            setEmailSeconds(180); // Then set to 180 after a brief delay
          }, 0);
          setNotification({
            ...notification,
            email: '사용 가능한 이메일입니다.'
          });
          dispatch(
            setAlert({ message: `인증 메일이 전송되었습니다.(${rs?.data}/5)` })
          );
        } else {
          dispatch(setAlert({ message: error2Text(rs?.error)?.content }));
        }
        break;
      case actions.SEND_CODE_EMAIL_AGAIN:
        if (!signUpData?.email || errMsg.email) {
          setErrMsg({
            email: '이메일 형식이 올바르지 않습니다.'
          });
          return;
        }

        rs = await codeService.sendPreorderOTP(
          ignoreEmpty({
            contact: signUpData?.phone,
            email: signUpData?.email,
            verificationType: 'email',
            purpose: 'registration'
          })
        );
        if (!rs?.error) {
          setErrMsg({ ...errMsg, emailOtp: '' });
          setSignUpData({ ...signUpData, emailOtp: '' });
          setVerifyingBox({ ...verifyingBox, emailOtp: 'init' });
          setVerifying({ ...isVerifying, emailOtp: true, email: true });
          setCanVerifyOtp({ ...canVerifyOtp, emailOtp: true });
          setVerified({ ...isVerified, emailOtp: false });
          setEmailSeconds(-1); // First reset to 0
          setTimeout(() => {
            setEmailSeconds(180); // Then set to 180 after a brief delay
          }, 0);
          setNotification({
            ...notification,
            email: '사용 가능한 이메일입니다.'
          });
          dispatch(
            setAlert({ message: `인증 메일이 전송되었습니다.(${rs?.data}/5)` })
          );
        } else {
          dispatch(setAlert({ message: error2Text(rs?.error)?.content }));
        }
        break;
      case actions.VERIFY_CODE_EMAIL:
        if (!signUpData?.emailOtp || signUpData?.emailOtp?.length !== 6) {
          setErrMsg({
            emailOtp: '인증번호가 올바르지 않습니다.'
          });
          return;
        }
        rs = await codeService.verifyPreorderOTP({
          email: signUpData?.email,
          verificationNumber: val,
          verificationType: 'email',
          purpose: 'registration'
        });
        if (!rs?.error) {
          setVerifying({ ...isVerifying, emailOtp: false, email: false });
          setVerified({ ...isVerified, emailOtp: true });
          setCanVerifyOtp({ ...canVerifyOtp, emailOtp: false });
          setNotification({
            ...notification,
            email: '인증되었습니다.'
          });
          setVerifyingBox({ ...verifyingBox, emailOtp: 'none' });
          const loc = rs?.location?.split('/');
          if (loc)
            setSignUpData({ ...signUpData, emailVerificationCode: loc?.[1] });
        } else {
          setVerifying({ ...isVerifying, emailOtp: true });
          if (rs?.error?.keyError === 'PREORDER_VERIFICATION_FAILED') {
            setErrMsg({ ...errMsg, emailOtp: '인증번호가 올바르지 않습니다.' });
            break;
          }
          dispatch(setAlert({ message: error2Text(rs?.error)?.content }));
        }
        break;

      case actions.SEARCH_CLIENT:
        rs = await signUpService.searchClient({
          code: signUpData?.clientName
        });
        if (!rs?.error) {
          setVerifying({ ...isVerifying, client: false });
          setminibarList(rs?.data || []);
          if (rs?.data?.length === 1) {
            setSignUpData({
              ...signUpData,
              client: rs?.data?.[0],
              clientName: rs?.data?.[0]?.minibarName
            });
            setVerified({ ...isVerified, client: true });
            setNotification({ ...notification, client: '인증되었습니다.' });
            setminibarList([]);
          }
        } else {
          setVerifying({ ...isVerifying, client: true });
          setErrMsg({
            clientName: error2Text(rs?.error)?.content
          });
        }
        break;
      case actions.REGISTER_MEMBER:
        setLoading(true);
        const dataSubmit = {
          clientCode: signUpData?.client?.clientCode,
          pickupSpotCode: signUpData?.client?.pickupSpotCode,
          smsVerificationCode: signUpData?.verificationCode,
          emailVerificationCode: signUpData?.emailVerificationCode,
          preorderLocale: version,
          contact: signUpData?.phone,
          email: signUpData?.email,
          password: signUpData?.password,
          fullName: signUpData?.name,
          gender: signUpData?.gender,
          birthday: signUpData?.birthDate,
          termPolicies: policyList?.map((i) => ({
            termPolicyCode: i?.termPolicyCode,
            mandatory: i?.mandatory,
            agreed: i?.isChecked
          }))
        };

        rs = await signUpService.registerNewMemeber(dataSubmit);
        if (!rs?.error) {
          await handleLogin({
            email: signUpData?.email,
            password: signUpData?.password
          });
        } else if (rs?.error?.keyError === 'PREORDER_MEMBER_ACCOUNT_DELETED') {
          dispatch(
            setPreorderError({
              content: error2Text(rs?.error)?.content,
              okTitle: '확인',
              hasCancelButton: false
            })
          );
        } else {
          dispatch(
            setPreorderError({
              content: error2Text(rs?.error)?.content,
              okTitle: '로그인 하기',
              hasCancelButton: true,
              cancelTitle: '취소',
              onOk: () => {
                navigate(`/pre-order/${version}/auth/login`, {
                  state: {
                    email: signUpData?.email
                  }
                });
                dispatch(setPreorderError(null));
              }
            })
          );
        }
        setLoading(false);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (seconds === 0 && !isVerified?.otpCode) {
      setErrMsg({
        ...errMsg,
        otpCode: '인증번호가 만료되었습니다. 인증번호를 재전송 해주세요.'
      });
      setVerifying({ ...isVerifying, otpCode: false });
      setCanVerifyOtp({ ...canVerifyOtp, otpCode: false });
    }
    if (emailSeconds === 0 && !isVerified?.emailOtp) {
      setErrMsg({
        ...errMsg,
        emailOtp: '인증번호가 만료되었습니다. 인증번호를 재전송 해주세요.'
      });
      setVerifying({ ...isVerifying, emailOtp: false });
      setCanVerifyOtp({ ...canVerifyOtp, emailOtp: false });
    }
  }, [seconds, emailSeconds]);
  return (
    <Container
      sx={{
        bgcolor: '#FFFFFF',
        width: '100%',
        minHeight: '100%',
        maxWidth: '500px !important',
        display: 'flex',
        flexDirection: 'column',
        px: 2.5,
        py: 2.5,
        pb: '30px'
      }}
    >
      {/* Header and progress bar */}
      <Stack
        sx={{
          width: '100%',
          height: 'auto',
          py: 1.5,
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}
      >
        {screen !== screens.POLICY && (
          <IconButton
            size="small"
            disableRipple
            disableTouchRipple
            sx={{
              position: 'absolute',
              left: 0,
              px: 2.5,
              pl: 0,
              backgroundColor: 'transparent !important'
            }}
            onClick={() => handlePreviousScreen()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path d="M7 1L1 7L7 13" stroke="black" />
            </svg>
          </IconButton>
        )}
        <Typography
          sx={{
            color: '#9A9CA4 !important',
            fontSize: 14,
            fontWeight: 500,

            mr: '10px',
            letterSpacing: '-0.56px'
          }}
          onClick={handleCancel} // Attach handleCancel to Cancel button
        >
          취소
        </Typography>
      </Stack>

      <Stack
        sx={{
          height: '2px',
          backgroundColor: '#DADBDD',
          width: '100%',
          zIndex: 11
        }}
      >
        <LinearProgress
          variant="determinate"
          sx={{
            maxWidth: '100%',
            backgroundColor: 'red',
            '&.MuiLinearProgress-root': {
              backgroundColor: 'transparent',
              transition: 'width 300ms ease-in-out'
            },
            '& .MuiLinearProgress-barColorPrimary': {
              backgroundColor: '#1B2872 !important'
            }
          }}
          value={
            (Object.values(screens).indexOf(screen) + 1) *
            (100 / Object.keys(screens).length)
          }
        />
      </Stack>

      {/* Render current screen */}
      {screen === screens.POLICY && (
        <TermAndPolicy
          data={policyList}
          onAction={handleChangePolicy}
          checkAll={handleCheckAllPolicy}
          signUpData={signUpData}
        />
      )}

      {/* Add other screen components here */}
      {screen === screens.VERIFICATION && (
        <Verification
          data={signUpData}
          onAction={handleApiAction}
          verifyingBox={verifyingBox}
          handleChange={handleChangeVerification}
          isVerifying={isVerifying}
          isVerified={isVerified}
          seconds={seconds}
          setSeconds={setSeconds}
          emailSeconds={emailSeconds}
          setEmailSeconds={setEmailSeconds}
          errMsg={errMsg}
          minibarList={minibarList}
          setminibarList={setminibarList}
          notification={notification}
          t={t}
          canVerifyOtp={canVerifyOtp}
          handleInputClick={handleInputClick}
        />
      )}

      {screen === screens.REGISTRATION && (
        <Registration
          data={signUpData}
          onAction={handleApiAction}
          handleChange={handleChangeRegistration}
          errMsg={errMsg}
          notification={notification}
          t={t}
        />
      )}
      <Button
        sx={{
          width: '100%',
          backgroundColor: isButtonDisabled() ? '#DADBDD' : '#1B2872',
          ':hover': {
            backgroundColor: isButtonDisabled() ? '#DADBDD' : '#1B2872',
            cursor: isButtonDisabled() ? 'not-allowed' : 'pointer',
            borderRadius: 1,
            height: 48,
            color: '#FFFFFF !important'
          },
          maxWidth: 'sm',
          borderRadius: 1,

          height: 48,
          color: '#FFFFFF !important',
          fontSize: 16,
          fontWeight: 500,
          letterSpacing: '-0.64px'
        }}
        disabled={isButtonDisabled()}
        onClick={handleNextScreen}
      >
        {screen === screens.REGISTRATION ? '가입완료' : '다음'}
      </Button>

      <AlertDialog
        isAlert
        open={alertDialog.open}
        setOpen={(open) => setAlertDialog({ ...alertDialog, open })}
        onOk={alertDialog.onOk} // Call the onOk function when the user confirms
        okTitle="OK"
        title={alertDialog.title}
        subTitle="Are you sure you want to cancel the sign-up process?"
        hasCancelButton
      />
    </Container>
  );
};
