/* eslint-disable react/no-unknown-property */
import MyTextField from '@components/inputs/MyTextField';
import { IconButton, Stack, Typography } from '@mui/material';

import React, { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

import { styled } from '@mui/material/styles';

// Create a styled version of MobileDatePicker to handle the highlighting issue
const StyledMobileDatePicker = styled(MobileDatePicker)(({ theme }) => ({
  '& .MuiInputBase-input': {
    '-webkit-user-select': 'none !important',
    '-moz-user-select': 'none !important',
    '-ms-user-select': 'none !important',
    'user-select': 'none !important',
    '-webkit-tap-highlight-color': 'rgba(0,0,0,0) !important',
    'caret-color': 'transparent !important'
  },
  // Disable text selection globally for this component
  '&, & *': {
    '-webkit-user-select': 'none !important',
    '-moz-user-select': 'none !important',
    '-ms-user-select': 'none !important',
    'user-select': 'none !important'
  },
  // Force disable highlighting on touch
  '@media (hover: none)': {
    '& *': {
      '-webkit-tap-highlight-color': 'transparent !important'
    }
  }
}));

const Registration = ({
  data,
  onAction,
  handleChange,
  errMsg,
  isVerified,
  notification,
  t
}) => {
  const [defaultCalendarValue, setDefaultCalendarValue] = useState(null);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false
  });

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field]
    }));
  };

  const handleCalendarOpen = (isOpen) => {
    if (isOpen && !data?.birthdate) {
      setDefaultCalendarValue(isOpen);
    }
  };
  const today = dayjs();
  const minDate = today.subtract(14, 'year');

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
      <Stack
        sx={{
          width: '100%',
          flexGrow: 1,
          display: 'flex'
        }}
      >
        <Stack sx={{ py: 5 }}>
          <Typography
            sx={{
              fontSize: 24,
              color: '#202020',
              fontWeight: 700,
              letterSpacing: '-0.96px'
            }}
          >
            회원가입
          </Typography>
        </Stack>

        <Stack
          sx={{
            width: '100%',
            flexGrow: 1,
            display: 'flex',
            gap: 2.5
          }}
        >
          {/* email */}
          <Stack sx={{ flexDirection: 'column', mb: 2.5 }}>
            <Stack sx={{ flexDirection: 'row' }}>
              <MyTextField
                preorder
                required
                fullWidth
                value={data?.email}
                disabled
              />
            </Stack>
          </Stack>

          {/* password */}
          <Stack sx={{ flexDirection: 'column', gap: 0.5 }}>
            <Stack sx={{ flexDirection: 'row', alignItems: 'flex-end' }}>
              <MyTextField
                required
                fullWidth
                preorder
                placeholder="비밀번호 입력(영문, 숫자, 특수문자 조합 8-16자)"
                type={showPassword.password ? 'text' : 'password'}
                value={data?.password}
                onChange={(e) => handleChange('password', e.target.value)}
                endComponent={
                  <IconButton
                    size="small"
                    sx={{ mr: -1 }}
                    onClick={() => togglePasswordVisibility('password')}
                  >
                    {showPassword.password ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect width="24" height="24" fill="white" />
                        <path
                          d="M12 18C4.83934 18 1.26433 12.6205 1.11549 12.3914C1.04018 12.2755 1 12.1392 1 11.9998C1 11.8605 1.04018 11.7242 1.11549 11.6082C1.26433 11.3795 4.83934 6 12 6C19.1607 6 22.7357 11.3795 22.8845 11.6086C22.9598 11.7245 23 11.8608 23 12.0002C23 12.1395 22.9598 12.2758 22.8845 12.3918C22.7357 12.6205 19.1607 18 12 18ZM12 7.41176C6.66946 7.41176 3.48668 10.8275 2.55099 12.0014C3.48358 13.1778 6.64987 16.5882 12 16.5882C17.3305 16.5882 20.5133 13.1725 21.449 11.9986C20.5164 10.8222 17.3501 7.41176 12 7.41176Z"
                          fill="#DADBDD"
                        />
                        <path
                          d="M12 15C10.3457 15 9 13.654 9 12C9 10.346 10.3457 9 12 9C13.6543 9 15 10.346 15 12C15 13.654 13.6543 15 12 15ZM12 10.3333C11.081 10.3333 10.3333 11.081 10.3333 12C10.3333 12.919 11.081 13.6667 12 13.6667C12.919 13.6667 13.6667 12.919 13.6667 12C13.6667 11.081 12.919 10.3333 12 10.3333Z"
                          fill="#DADBDD"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect width="24" height="24" fill="white" />
                        <path
                          d="M12 18C4.83934 18 1.26433 12.6205 1.11549 12.3914C1.04018 12.2755 1 12.1392 1 11.9998C1 11.8605 1.04018 11.7242 1.11549 11.6082C1.26433 11.3795 4.83934 6 12 6C19.1607 6 22.7357 11.3795 22.8845 11.6086C22.9598 11.7245 23 11.8608 23 12.0002C23 12.1395 22.9598 12.2758 22.8845 12.3918C22.7357 12.6205 19.1607 18 12 18ZM12 7.41176C6.66946 7.41176 3.48668 10.8275 2.55099 12.0014C3.48358 13.1778 6.64987 16.5882 12 16.5882C17.3305 16.5882 20.5133 13.1725 21.449 11.9986C20.5164 10.8222 17.3501 7.41176 12 7.41176Z"
                          fill="#DADBDD"
                        />
                        <path
                          d="M12 15C10.3457 15 9 13.654 9 12C9 10.346 10.3457 9 12 9C13.6543 9 15 10.346 15 12C15 13.654 13.6543 15 12 15ZM12 10.3333C11.081 10.3333 10.3333 11.081 10.3333 12C10.3333 12.919 11.081 13.6667 12 13.6667C12.919 13.6667 13.6667 12.919 13.6667 12C13.6667 11.081 12.919 10.3333 12 10.3333Z"
                          fill="#DADBDD"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.61429 18L23 7.13722L22.3857 6L1 16.8628L1.61429 18Z"
                          fill="#DADBDD"
                        />
                      </svg>
                    )}
                  </IconButton>
                }
                onClear={() => handleChange('password', '')}
                errMg={errMsg?.password}
              />
            </Stack>
            {errMsg?.password && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#F03636',
                  letterSpacing: '-0.48px'
                }}
              >
                {errMsg?.password}
              </Typography>
            )}
            {notification?.password && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#1B2872',
                  letterSpacing: '-0.48px'
                }}
              >
                {notification?.password}
              </Typography>
            )}
          </Stack>

          {/* confirm password */}
          <Stack sx={{ flexDirection: 'column', gap: 0.5 }}>
            <Stack sx={{ flexDirection: 'row', alignItems: 'flex-end' }}>
              <MyTextField
                required
                fullWidth
                preorder
                placeholder="비밀번호 재입력"
                type={showPassword.confirmPassword ? 'text' : 'password'}
                value={data?.confirmPassword}
                onChange={(e) =>
                  handleChange('confirmPassword', e.target.value)
                }
                endComponent={
                  <IconButton
                    size="small"
                    sx={{ mr: -1 }}
                    onClick={() => togglePasswordVisibility('confirmPassword')}
                  >
                    {showPassword.confirmPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect width="24" height="24" fill="white" />
                        <path
                          d="M12 18C4.83934 18 1.26433 12.6205 1.11549 12.3914C1.04018 12.2755 1 12.1392 1 11.9998C1 11.8605 1.04018 11.7242 1.11549 11.6082C1.26433 11.3795 4.83934 6 12 6C19.1607 6 22.7357 11.3795 22.8845 11.6086C22.9598 11.7245 23 11.8608 23 12.0002C23 12.1395 22.9598 12.2758 22.8845 12.3918C22.7357 12.6205 19.1607 18 12 18ZM12 7.41176C6.66946 7.41176 3.48668 10.8275 2.55099 12.0014C3.48358 13.1778 6.64987 16.5882 12 16.5882C17.3305 16.5882 20.5133 13.1725 21.449 11.9986C20.5164 10.8222 17.3501 7.41176 12 7.41176Z"
                          fill="#DADBDD"
                        />
                        <path
                          d="M12 15C10.3457 15 9 13.654 9 12C9 10.346 10.3457 9 12 9C13.6543 9 15 10.346 15 12C15 13.654 13.6543 15 12 15ZM12 10.3333C11.081 10.3333 10.3333 11.081 10.3333 12C10.3333 12.919 11.081 13.6667 12 13.6667C12.919 13.6667 13.6667 12.919 13.6667 12C13.6667 11.081 12.919 10.3333 12 10.3333Z"
                          fill="#DADBDD"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect width="24" height="24" fill="white" />
                        <path
                          d="M12 18C4.83934 18 1.26433 12.6205 1.11549 12.3914C1.04018 12.2755 1 12.1392 1 11.9998C1 11.8605 1.04018 11.7242 1.11549 11.6082C1.26433 11.3795 4.83934 6 12 6C19.1607 6 22.7357 11.3795 22.8845 11.6086C22.9598 11.7245 23 11.8608 23 12.0002C23 12.1395 22.9598 12.2758 22.8845 12.3918C22.7357 12.6205 19.1607 18 12 18ZM12 7.41176C6.66946 7.41176 3.48668 10.8275 2.55099 12.0014C3.48358 13.1778 6.64987 16.5882 12 16.5882C17.3305 16.5882 20.5133 13.1725 21.449 11.9986C20.5164 10.8222 17.3501 7.41176 12 7.41176Z"
                          fill="#DADBDD"
                        />
                        <path
                          d="M12 15C10.3457 15 9 13.654 9 12C9 10.346 10.3457 9 12 9C13.6543 9 15 10.346 15 12C15 13.654 13.6543 15 12 15ZM12 10.3333C11.081 10.3333 10.3333 11.081 10.3333 12C10.3333 12.919 11.081 13.6667 12 13.6667C12.919 13.6667 13.6667 12.919 13.6667 12C13.6667 11.081 12.919 10.3333 12 10.3333Z"
                          fill="#DADBDD"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.61429 18L23 7.13722L22.3857 6L1 16.8628L1.61429 18Z"
                          fill="#DADBDD"
                        />
                      </svg>
                    )}
                  </IconButton>
                }
                onClear={() => handleChange('confirmPassword', '')}
                errMg={errMsg?.confirmPassword}
              />
            </Stack>
            {errMsg?.confirmPassword && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#F03636',
                  letterSpacing: '-0.48px'
                }}
              >
                {errMsg?.confirmPassword}
              </Typography>
            )}
            {notification?.confirmPassword && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#1B2872',
                  letterSpacing: '-0.48px'
                }}
              >
                {notification?.confirmPassword}
              </Typography>
            )}
          </Stack>

          {/* name */}
          <Stack sx={{ flexDirection: 'column', gap: '6px' }}>
            <Stack sx={{ flexDirection: 'row' }}>
              <MyTextField
                preorder
                required
                errMg={errMsg?.name}
                fullWidth
                placeholder="이름"
                value={data?.name}
                onChange={(e) => handleChange('name', e.target.value)}
                onClear={() => handleChange('name', '')}
              />
            </Stack>
            {errMsg?.name && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#F03636',
                  letterSpacing: '-0.48px'
                }}
              >
                {errMsg?.name}
              </Typography>
            )}
          </Stack>
          <Stack
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '40px',
              border: '1px solid #EBEBEB',
              borderRadius: 1,
              my: 2.5
            }}
          >
            <Stack
              sx={{
                backgroundColor:
                  data?.gender === 'male' ? '#1B2872' : 'transparent',
                height: '40px',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                width: '50%',
                borderRadius: 1,
                transition:
                  'background 200ms cubic-bezier(0.4, 0.5, 0.2, 1) 0ms'
              }}
              onClick={(e) => handleChange('gender', 'male')}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  color: data?.gender === 'male' ? '#FFFFFF' : '#DADBDD'
                }}
              >
                남성
              </Typography>
            </Stack>
            <Stack
              sx={{
                backgroundColor:
                  data?.gender === 'female' ? '#1B2872' : 'transparent',

                height: '40px',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                width: '50%',
                borderRadius: 1,
                transition:
                  'background 200ms cubic-bezier(0.4, 0.5, 0.2, 1) 0ms'
              }}
              onClick={(e) => handleChange('gender', 'female')}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  color: data?.gender === 'female' ? '#FFFFFF' : '#DADBDD'
                }}
              >
                여성
              </Typography>
            </Stack>
          </Stack>

          {/* phone */}
          {/* phone */}
          <Stack sx={{ flexDirection: 'column', gap: 0.5, mb: 5 }}>
            <Stack sx={{ flexDirection: 'row', alignItems: 'flex-end' }}>
              <StyledMobileDatePicker
                sx={{ width: '100%' }}
                disableFuture
                value={
                  data?.birthdate
                    ? dayjs(data?.birthdate)
                    : defaultCalendarValue
                }
                onAccept={(val) =>
                  handleChange('birthDate', dayjs(val).format('YYYY-MM-DD'))
                }
                maxDate={minDate} // Set the minimum date
                defaultValue={dayjs('1990-01-01')}
                onOpen={handleCalendarOpen}
                slotProps={{
                  toolbar: {
                    hidden: true
                  },
                  textField: (params) => ({
                    ...params,
                    placeholder: '생년월일',
                    value: data?.birthDate && dayjs(params?.value),
                    inputProps: {
                      style: {
                        WebkitTapHighlightColor: 'transparent !important',
                        WebkitUserSelect: 'none !important',
                        MozUserSelect: 'none !important',
                        msUserSelect: 'none !important',
                        userSelect: 'none !important',
                        caretColor: 'transparent !important',
                        '-webkit-touch-callout': 'none !important'
                      }
                    },
                    InputProps: {
                      sx: {
                        height: '40px',
                        // Add these styles to the wrapper
                        '& input': {
                          WebkitTapHighlightColor: 'transparent',
                          WebkitUserSelect: 'none !important',
                          MozUserSelect: 'none !important',
                          msUserSelect: 'none !important',
                          userSelect: 'none !important',
                          caretColor: 'transparent !important',
                          '-webkit-touch-callout': 'none !important'
                        }
                      },
                      style: {
                        WebkitUserSelect: 'none',
                        userSelect: 'none',
                        caretColor: 'transparent'
                      },
                      endAdornment: (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <rect
                            x="3.42969"
                            width="1.14286"
                            height="2.28571"
                            fill="#DADBDD"
                          />
                          <rect
                            x="11.4297"
                            width="1.14286"
                            height="2.28571"
                            fill="#DADBDD"
                          />
                          <rect
                            x="16"
                            y="2.28516"
                            width="3.42857"
                            height="16"
                            transform="rotate(90 16 2.28516)"
                            fill="#DADBDD"
                          />
                          <rect
                            y="5.71436"
                            width="1.14286"
                            height="10.2857"
                            fill="#DADBDD"
                          />
                          <rect
                            x="14.8555"
                            y="14.9712"
                            width="1.02857"
                            height="13.7143"
                            transform="rotate(90 14.8555 14.9712)"
                            fill="#DADBDD"
                          />
                          <rect
                            x="14.8555"
                            y="5.71436"
                            width="1.14286"
                            height="10.2857"
                            fill="#DADBDD"
                          />
                        </svg>
                      )
                    }
                  })
                }}
                format="YYYY년 MM월 DD일"
              />

              {/* <MobileDatePicker
                sx={{ width: '100%' }}
                disableFuture
                value={data?.birthdate ? dayjs(data?.birthdate) : null}
                onAccept={(val) =>
                  defaultCalendarValue &&
                  handleChange('birthDate', dayjs(val).format('YYYY-MM-DD'))
                }
                onOpen={() => {
                  handleCalendarOpen(true);
                }}
                defaultValue={
            
                  dayjs('1990-01-01')
                }
                slotProps={{
                  toolbar: {
                    hidden: true
                  },
                  textField: {
                    placeholder: '생년월일',
                    InputProps: {
                      sx: { height: '40px' },
                      endAdornment: (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <rect
                            x="3.42969"
                            width="1.14286"
                            height="2.28571"
                            fill="#DADBDD"
                          />
                          <rect
                            x="11.4297"
                            width="1.14286"
                            height="2.28571"
                            fill="#DADBDD"
                          />
                          <rect
                            x="16"
                            y="2.28516"
                            width="3.42857"
                            height="16"
                            transform="rotate(90 16 2.28516)"
                            fill="#DADBDD"
                          />
                          <rect
                            y="5.71436"
                            width="1.14286"
                            height="10.2857"
                            fill="#DADBDD"
                          />
                          <rect
                            x="14.8555"
                            y="14.9712"
                            width="1.02857"
                            height="13.7143"
                            transform="rotate(90 14.8555 14.9712)"
                            fill="#DADBDD"
                          />
                          <rect
                            x="14.8555"
                            y="5.71436"
                            width="1.14286"
                            height="10.2857"
                            fill="#DADBDD"
                          />
                        </svg>
                      )
                    }
                  }
                }}
                format="YYYY년 MM월 DD일"
              /> */}
            </Stack>
            {errMsg?.birthdate && (
              <Typography
                sx={{
                  fontSize: { xs: 12 },
                  color: '#F03636',
                  letterSpacing: '-0.48px'
                }}
              >
                {errMsg?.birthdate} {/* Changed from phone to birthdate */}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
    </LocalizationProvider>
  );
};

export default Registration;
