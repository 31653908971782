/* eslint-disable react/no-unknown-property */
/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Typography
} from '@mui/material';
import MyTextField from '@components/inputs/MyTextField';
import { Formatter } from '@utils/formatter';
import env from '@constants/env';
import { BOTTOM_NAVS } from '@constants/utils';
import { setActiveNav, setAlert } from '@store/reducers/pre-order/app';
import qrImg from 'images/preorder/qr_button_bg.png';
import OrderAccordion from 'pages-preorder/home/components/OrderAccordion';
import React, { useEffect, useState } from 'react';
import { formatCardNumber, formatMoney } from '@utils/helpers';
import orderService from '@services/preorder/order';
import AlertDialog from 'pages-preorder/components/Modal/Alert/AlertDialog';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';
import MyModal from 'pages-preorder/components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { days, orderStatusStyles, statuses, statusParser } from '../enhance';

const dateFormat = 'YY-MM-DD';
const OrderDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { version } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const { orderCode } = useParams();
  const [dialog, setDialog] = useState({ open: false });
  const [alertDialog, setAlertDialog] = useState({ open: false });
  const [orderData, setOrderData] = useState({});
  const { activeNav } = useSelector((state) => state.preOrder.app);
  const [qrData, setQrData] = useState(null);

  const [errMsg, setErrMsg] = useState({
    qr: ''
  });
  const [seconds, setSeconds] = useState(180);
  useEffect(() => {
    if (seconds === 0) {
      setErrMsg({
        qr: t('pages.pre-order.qr-timeout')
      });
    }
  }, [seconds]);
  const fetchOrtderDetail = async (param) => {
    try {
      const rs = await orderService.getOrderDetail(orderCode);

      if (!rs.error) {
        setOrderData(rs?.data);
      }
    } catch (error) {
      console.error('Error fetching store data:', error);
    }
  };

  useEffect(() => {
    if (activeNav !== BOTTOM_NAVS.ORDER_LIST)
      dispatch(setActiveNav(BOTTOM_NAVS.ORDER_LIST));

    fetchOrtderDetail();
  }, [location?.search]);

  const getProgressPercent = () => {
    switch (orderData?.orderStatus) {
      case statuses.CANCELED:
      case statuses.COMPLETED:
        return 100;
      case statuses.ORDER_COMPLETE:
      case statuses.ORDER_CONFIRM:
        return 0;

      case statuses.FIXED:
        return 35;
      case statuses.PICK_UP_REQUEST:
        return 73;
      default:
        return 0;
    }
  };

  const handleCancelOrder = async () => {
    // const today = new Date();
    // today.setHours(0, 0, 0, 0);

    // const cancellableDate = new Date(orderData?.cancellableDate);
    // cancellableDate.setHours(0, 0, 0, 0);

    // if (cancellableDate <= today) return;

    const rs = await orderService.cancelOrder(orderCode, {
      reason: '고객취소'
    });
    if (!rs?.error) {
      dispatch(setAlert({ message: '주문을 취소하였습니다.' }));
      fetchOrtderDetail();
      setAlertDialog({ open: false });
    }
  };

  const hadnleRenewQr = async () => {
    const rs = await orderService.getNewQr(orderCode);
    if (!rs?.error) {
      setQrData({ ...qrData, ...rs.data });
      setSeconds(180);
    }
  };
  const handleOpenQrDialog = async () => {
    const rs = await orderService.getNewQr(orderCode);
    if (!rs?.error) {
      setQrData({ ...qrData, ...rs.data });
      setSeconds(180);
      setDialog({ ...dialog, open: true });
    }
  };
  return (
    <Stack
      sx={{
        flexDirection: 'column',
        width: '100%',
        maxxWidth: '500px',
        flexGrow: 1,
        display: orderData ? 'flex' : 'none',
        position: 'relative'
      }}
    >
      {/* header */}
      <Stack
        sx={{
          width: '100%',
          maxWidth: '500px',
          top: 0,
          position: 'fixed',
          backgroundColor: '#FFFFFF',
          zIndex: 1002
        }}
      >
        <Stack
          id="navBarHeader"
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            backgroundColor: '#FFFFFF',
            width: '100%',
            py: '13px',
            px: 2.5,
            height: { xs: '50px' },
            position: 'relative'
          }}
        >
          <Stack sx={{ position: 'absolute', left: 0 }}>
            <IconButton
              size="small"
              sx={{
                px: 2.5,
                py: 1,
                backgroundColor: 'transparent !important'
              }}
              onClick={() => navigate(`/pre-order/${version}/orders`)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
              >
                <path d="M7 1L1 7L7 13" stroke="black" />
              </svg>
            </IconButton>
          </Stack>

          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: 1
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 700,
                color: '#202020',
                letterSpacing: '-0.56px'
              }}
            >
              주문 확인
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {/* stepper */}
      <Stack
        sx={{
          display: orderData.orderCode ? 'flex' : 'none',
          width: '100%',
          maxWidth: '500px'
        }}
      >
        <Stack
          sx={{
            mt: '51px',
            width: '100%',
            gap: 1,
            py: 1.25,
            px: 2.5,
            display: orderData.orderCode ? 'flex' : 'none'
          }}
        >
          {/* linear progress */}
          <Stack sx={{ width: '100%', px: 2.5 }}>
            <Stack
              sx={{
                height: '2px',
                backgroundColor: '#DADBDD',
                width: '100%',
                zIndex: 11
              }}
            >
              <LinearProgress
                variant="determinate"
                sx={{
                  maxWidth: '100%',
                  backgroundColor: 'red',
                  '&.MuiLinearProgress-root': {
                    backgroundColor: 'transparent',
                    transition: 'width 300ms ease-in-out'
                  },
                  '& .MuiLinearProgress-barColorPrimary': {
                    backgroundColor:
                      orderData?.orderStatus === statuses?.CANCELED
                        ? '#F25D5D'
                        : '#1B2872 !important'
                  }
                }}
                value={getProgressPercent()}
              />
            </Stack>
          </Stack>
          <Grid
            container
            sx={{ display: orderData.orderCode ? 'flex' : 'none' }}
          >
            <Grid
              item
              xs={orderData?.orderStatus === statuses.CANCELED ? 6 : 3}
            >
              <Stack
                sx={{
                  width: '100%',
                  gap: 0.4,
                  ml: 0.5,
                  alignItems: 'flex-start'
                }}
              >
                <Stack sx={{ textAlign: 'center' }}>
                  <Typography
                    sx={{
                      color: [
                        statuses.ORDER_COMPLETE,
                        statuses.ORDER_CONFIRM
                      ].includes(orderData?.orderStatus)
                        ? '#202020'
                        : '#D6D7DB',
                      fontSize: 10,
                      letterSpacing: '-0.5px',
                      fontWeight: [
                        statuses.ORDER_COMPLETE,
                        statuses.ORDER_CONFIRM
                      ].includes(orderData?.orderStatus)
                        ? 700
                        : 500
                    }}
                  >
                    주문완료
                  </Typography>
                  {orderData?.orderedDate && (
                    <Typography
                      sx={{
                        color: '#9A9CA4',
                        fontSize: 8,
                        letterSpacing: '-0.32px',
                        fontWeight: 500
                      }}
                    >
                      {Formatter.formatTime(orderData?.orderedDate, dateFormat)}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Grid>
            {orderData?.orderStatus !== statuses.CANCELED && (
              <>
                <Grid item xs={3}>
                  <Stack
                    sx={{
                      width: '100%',
                      // gap: 0.4,

                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      sx={{
                        color:
                          orderData?.orderStatus === statuses.FIXED
                            ? '#202020'
                            : '#D6D7DB',
                        fontSize: 10,
                        letterSpacing: '-0.5',
                        fontWeight:
                          orderData?.orderStatus === statuses.FIXED ? 700 : 500
                      }}
                    >
                      구매확정
                    </Typography>
                    {orderData?.fixedDate &&
                      ![
                        statuses.ORDER_COMPLETE,
                        statuses.ORDER_CONFIRM
                      ].includes(orderData?.orderStatus) && (
                        <Typography
                          sx={{
                            color: '#9A9CA4',
                            fontSize: 8,
                            letterSpacing: '-0.32px',
                            fontWeight: 500
                          }}
                        >
                          {Formatter.formatTime(
                            orderData?.fixedDate,
                            dateFormat
                          )}
                        </Typography>
                      )}
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Stack
                    sx={{
                      width: '100%',

                      alignItems: 'flex-end'
                    }}
                  >
                    <Typography
                      sx={{
                        color:
                          orderData?.orderStatus === statuses.PICK_UP_REQUEST
                            ? '#202020'
                            : '#D6D7DB',
                        fontSize: 10,
                        letterSpacing: '-0.5',
                        fontWeight:
                          orderData?.orderStatus === statuses.PICK_UP_REQUEST
                            ? 700
                            : 500
                      }}
                    >
                      픽업요청
                    </Typography>
                    {orderData?.pickupRequestedDate && (
                      <Typography
                        sx={{
                          color: '#9A9CA4',
                          fontSize: 8,
                          letterSpacing: '-0.32px',
                          fontWeight: 500
                        }}
                      >
                        {Formatter.formatTime(
                          orderData?.pickupRequestedDate,
                          dateFormat
                        )}
                      </Typography>
                    )}
                  </Stack>
                </Grid>
              </>
            )}
            {orderData?.orderStatus !== statuses.CANCELED && (
              <Grid item xs={3}>
                <Stack
                  sx={{
                    width: '100%',

                    alignItems: 'flex-end'
                  }}
                >
                  <Stack sx={{ textAlign: 'center' }}>
                    <Typography
                      sx={{
                        color:
                          orderData?.orderStatus === statuses.COMPLETED
                            ? '#202020'
                            : '#D6D7DB',
                        fontSize: 10,
                        letterSpacing: '-0.5',
                        mr: '4px',
                        fontWeight:
                          orderData?.orderStatus === statuses.COMPLETED
                            ? 700
                            : 500
                      }}
                    >
                      완료
                    </Typography>
                    {orderData?.completedDate && (
                      <Typography
                        sx={{
                          color: '#9A9CA4',
                          fontSize: 8,
                          letterSpacing: '-0.32px',
                          fontWeight: 500
                        }}
                      >
                        {Formatter.formatTime(
                          orderData?.completedDate,
                          dateFormat
                        )}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </Grid>
            )}
            {orderData?.orderStatus === statuses.CANCELED && (
              <Grid item xs={6}>
                <Stack
                  sx={{
                    width: '100%',
                    gap: 0.4,

                    alignItems: 'flex-end'
                  }}
                >
                  <Stack sx={{ textAlign: 'center' }}>
                    <Typography
                      sx={{
                        color:
                          orderData?.orderStatus === statuses.CANCELED
                            ? '#F25D5D'
                            : '#D6D7DB',
                        fontSize: 10,
                        letterSpacing: '-0.4',
                        fontWeight:
                          orderData?.orderStatus === statuses.CANCELED
                            ? 700
                            : 500
                      }}
                    >
                      주문취소
                    </Typography>
                    {orderData?.orderStatus === statuses.CANCELED &&
                      orderData?.cancelledDate && (
                        <Typography
                          sx={{
                            color: '#9A9CA4',
                            fontSize: 8,
                            letterSpacing: '-0.32px',
                            fontWeight: 500
                          }}
                        >
                          {Formatter.formatTime(
                            orderData?.cancelledDate,
                            dateFormat
                          )}
                        </Typography>
                      )}
                  </Stack>
                </Stack>
              </Grid>
            )}
          </Grid>
        </Stack>

        {[
          statuses.ORDER_COMPLETE,
          statuses.ORDER_CONFIRM,
          statuses?.FIXED
        ].includes(orderData?.orderStatus) && (
          <Stack
            sx={{
              width: '100%',
              display: orderData.orderCode ? 'flex' : 'none',
              height: '60px',
              backgroundColor: '#FFFFFF'
            }}
          >
            <Stack
              sx={{
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
                py: 2.5,
                px: 2.5,
                backgroundColor: '#F6F6F6'
              }}
            >
              <Stack
                sx={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 1
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    color: '#202020',
                    fontWeight: 700,
                    letterSpacing: '-0.56px'
                  }}
                >
                  희망 픽업일자
                </Typography>
                <Stack
                  sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}
                >
                  {' '}
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: '#202020',
                      fontWeight: 500,
                      letterSpacing: '-0.56px',
                      textDecoration: 'underline'
                    }}
                  >
                    {Formatter.formatTime(
                      orderData.preferredPickupDate,
                      'YY년 MM월 DD일'
                    )}
                  </Typography>
                  <Stack
                    sx={{
                      backgroundColor:
                        orderStatusStyles?.[orderData?.orderStatus]
                          ?.backgroundColor,
                      borderRadius: '10px',
                      minWidth: '57px',
                      textAlign: 'center',
                      border:
                        orderStatusStyles?.[orderData?.orderStatus]?.border,
                      p: '4px 8px'
                    }}
                  >
                    {' '}
                    <Typography
                      sx={{
                        fontSize: 10,
                        color:
                          orderStatusStyles?.[orderData?.orderStatus]?.color,
                        fontWeight: 500,
                        letterSpacing: '-0.4px'
                      }}
                    >
                      {statusParser[orderData?.orderStatus] ||
                        orderData?.orderStatus}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}

        {![
          statuses.ORDER_COMPLETE,
          statuses.ORDER_CONFIRM,
          statuses?.FIXED
        ].includes(orderData?.orderStatus) && (
          <Stack
            sx={{
              width: '100%',
              display: orderData.orderCode ? 'flex' : 'none',
              // Allow the stack to grow and fill available space
              backgroundColor: '#FFFFFF',

              flexDirection: 'column'
            }}
          >
            <Stack
              sx={{
                flexGrow: 1, // Allow this inner stack to grow
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'stretch'
              }}
            >
              <Stack sx={{ flexGrow: 1 }}>
                <Stack
                  sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    height: '50%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#F6F6F6',
                    py: 2,
                    px: 2.5
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: '#202020',
                      fontWeight: 700,
                      letterSpacing: '-0.56px'
                    }}
                  >
                    희망 픽업일자
                  </Typography>
                  <Stack>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: '#202020',
                        fontWeight: 500,
                        letterSpacing: '-0.56px',
                        textDecoration: 'underline'
                      }}
                    >
                      {Formatter.formatTime(
                        orderData.orderStatusUpdatedDate,
                        'YY년 MM월 DD일'
                      )}
                    </Typography>
                  </Stack>
                </Stack>

                {/* Second Row */}
                <Stack
                  sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '50%',
                    justifyContent: 'space-between',
                    py: 2,
                    px: 2.5
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: '#202020',
                      fontWeight: 700,
                      letterSpacing: '-0.56px'
                    }}
                  >
                    {orderData?.orderStatus === statuses?.CANCELED
                      ? '상품 취소일자'
                      : '상품 픽업일자'}
                  </Typography>
                  <Stack
                    sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: '#202020',
                        fontWeight: 500,
                        letterSpacing: '-0.56px'
                      }}
                    >
                      {Formatter.formatTime(
                        orderData?.orderStatus === statuses?.CANCELED
                          ? orderData?.cancelledDate
                          : orderData?.pickupRequestedDate,
                        'YY년 MM월 DD일'
                      )}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              {/* Gray Background Stack */}
              <Stack
                sx={{
                  display: 'flex',
                  minWidth: '141px',
                  p: 2.5,
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#F0F0F0',
                  flexShrink: 0 // Prevent shrinking
                }}
              >
                <Stack
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1.5
                  }}
                >
                  {' '}
                  <Typography
                    sx={{
                      fontSize: 10,
                      color: '#9A9CA4',
                      fontWeight: 500,
                      letterSpacing: '-0.4px'
                    }}
                  >
                    주문상태
                  </Typography>
                  <Stack
                    sx={{
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 0.5
                    }}
                  >
                    <Stack
                      sx={{
                        backgroundColor:
                          orderStatusStyles?.[orderData?.orderStatus]
                            ?.backgroundColor || '#1B2872',
                        borderRadius: '10px',
                        border:
                          orderStatusStyles?.[orderData?.orderStatus]?.border ||
                          0,
                        minWidth: '57px',
                        height: '20px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        p: '4px 8px'
                      }}
                    >
                      {' '}
                      <Typography
                        sx={{
                          fontSize: 10,
                          color:
                            orderStatusStyles?.[orderData?.orderStatus]
                              ?.color || '#FFFFFF',
                          fontWeight: 500,
                          letterSpacing: '-0.4px'
                        }}
                      >
                        {statusParser[orderData?.orderStatus] ||
                          orderData?.orderStatus}
                      </Typography>
                    </Stack>
                    {orderData?.orderStatus === statuses?.COMPLETED && (
                      <Typography
                        sx={{
                          fontSize: 10,
                          color: '#F25D5D',
                          fontWeight: 500,
                          letterSpacing: '-0.4px'
                        }}
                      >
                        취소불가
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}

        {[statuses.PICK_UP_REQUEST].includes(orderData?.orderStatus) && (
          <Stack
            sx={{
              width: '100%',
              // Allow the stack to grow and fill available space
              backgroundColor: '#FFFFFF',

              display: orderData.orderCode ? 'flex' : 'none',
              flexDirection: 'column'
            }}
          >
            <Stack
              sx={{
                width: '100%',
                px: 2.5,
                py: 1.5,
                overflow: 'hidden',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {' '}
              <Stack
                sx={{
                  width: '100%',
                  minHeight: '84px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                  borderRadius: 1,
                  overflow: 'clip'
                }}
                onClick={() => {
                  handleOpenQrDialog();
                }}
              >
                {' '}
                <img
                  src={qrImg}
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    top: 0,
                    right: 0,
                    width: '100%',
                    height: '100%',

                    zIndex: 1
                  }}
                  alt="qr"
                />
                <Typography
                  sx={{
                    zIndex: 2,
                    fontSize: 18,
                    fontWeight: 700,
                    letterSpacing: '-0.72px',
                    color: '#FFFFFF',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  픽업 QR코드{' '}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    style={{ marginLeft: '8px' }}
                  >
                    <path d="M1 13L7 7L1 0.999999" stroke="white" />
                  </svg>
                </Typography>
              </Stack>
            </Stack>

            {/* <Stack
              sx={{ width: '100%', height: '5px', backgroundColor: '#F2F2F2' }}
            >
              <Stack
                sx={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#E7E7E7'
                }}
              />
            </Stack> */}
          </Stack>
        )}
        {[
          statuses.ORDER_COMPLETE,
          statuses.ORDER_CONFIRM,
          statuses.FIXED
        ].includes(orderData?.orderStatus) && (
          <Stack
            sx={{
              width: '100%',
              py: 2.5,
              px: 2.5,
              backgroundColor: '#FFFFFF',
              display: orderData.orderCode ? 'flex' : 'none'
            }}
          >
            {/* title */}
            <Stack
              sx={{
                width: '100%',
                textAlign: 'left',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                ...([statuses.FIXED].includes(orderData?.orderStatus) && {
                  pb: 1.5,
                  borderBottom: '1px solid #F2F2F2'
                })
              }}
            >
              <Typography
                sx={{
                  color: '#202020',
                  fontSize: { xs: 14 },
                  fontWeight: 700,
                  letterSpacing: '-0.56px'
                }}
              >
                취소 가능기간
              </Typography>

              {[statuses.ORDER_COMPLETE, statuses.ORDER_CONFIRM].includes(
                orderData?.orderStatus
              ) ? (
                <Typography
                  sx={{
                    color: '#9A9CA4',
                    fontSize: { xs: 14 },
                    fontWeight: 500,
                    letterSpacing: '-0.56px'
                  }}
                >
                  ~
                  {Formatter.formatTime(
                    orderData?.cancellableDate,
                    'YY년 MM월 DD일'
                  )}
                  , 23:59
                </Typography>
              ) : (
                <Typography
                  sx={{
                    color: '#F25D5D',
                    fontSize: { xs: 10 },
                    fontWeight: 500,
                    letterSpacing: '-0.4px'
                  }}
                >
                  취소불가
                </Typography>
              )}
            </Stack>

            {/* calendar */}
            {[statuses.FIXED].includes(orderData?.orderStatus) && (
              <Stack sx={{ flexDirection: 'column', gap: 1, mt: 1.25 }}>
                <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Typography
                    sx={{
                      color: '#202020',
                      fontSize: { xs: 10 },
                      fontWeight: 500,
                      letterSpacing: '-0.4px'
                    }}
                  >
                    * 주문확정 단계로 취소가 불가합니다.
                  </Typography>
                </Stack>
                <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Typography
                    sx={{
                      color: '#202020',
                      fontSize: { xs: 10 },
                      fontWeight: 500,
                      letterSpacing: '-0.4px'
                    }}
                  >
                    * 주문확정 단계에서도 상점 사유로 부득이하게 주문이
                    취소되거나 지연될 수 있습니다.
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
        )}
        <Stack
          sx={{ width: '100%', height: '5px', backgroundColor: '#F2F2F2' }}
        >
          <Stack
            sx={{ width: '100%', height: '1px', backgroundColor: '#E7E7E7' }}
          />
        </Stack>
        {/* store info */}
        <Stack
          sx={{ width: '100%', px: 2.5, py: 2.5, backgroundColor: '#FFFFFF' }}
          id="checkout-page"
        >
          <OrderAccordion data={{ title: '상점정보' }} defaultExpanded>
            {orderData && (
              <Stack
                sx={{
                  width: '100%',
                  flexGrow: 1,
                  backgroundColor: '#FFFFFF',
                  textAlign: 'left'
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    letterSpacing: '-0.56px',
                    fontWeight: 700,
                    color: '#202020'
                  }}
                >
                  {orderData?.storeName}
                </Typography>
                <Stack
                  sx={{
                    width: '100%',
                    pt: 2.5,
                    gap: 1.5
                  }}
                >
                  <Stack
                    sx={{
                      flexGrow: 1,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        letterSpacing: '-0.48px',
                        fontWeight: 500,
                        color: '#9A9CA4'
                      }}
                    >
                      주소
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 12,
                        letterSpacing: '-0.48px',
                        fontWeight: 500,
                        color: '#202020'
                      }}
                    >
                      {orderData?.storeAddress}
                    </Typography>
                  </Stack>

                  {/* businesshours */}
                  {orderData?.businessHours?.length > 0 && (
                    <Stack
                      sx={{
                        flexGrow: 1,
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 12,
                          letterSpacing: '-0.48px',
                          fontWeight: 500,
                          color: '#9A9CA4'
                        }}
                      >
                        영업시간
                      </Typography>
                      <Stack sx={{ gap: 0.5, justifyContent: 'flex-end' }}>
                        {days.map((item) => {
                          const workingDay = orderData?.businessHours?.find(
                            (i) => i?.dayOfWeek === item?.value
                          );

                          return (
                            <Stack
                              key={`${item?.value}-${item?.label}`}
                              sx={{
                                flexDirection: 'row',
                                gap: 1.5,
                                justifyContent: 'flex-end'
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  fontWeight: 500,
                                  color: '#202020',
                                  letterSpacing: '-0.48px'
                                }}
                              >
                                {item?.label}
                              </Typography>
                              {!workingDay ? (
                                <Typography
                                  sx={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    color: '#F25D5D',
                                    letterSpacing: '-0.48px',
                                    minWidth: '69px',
                                    textAlign: 'right'
                                  }}
                                >
                                  휴무
                                </Typography>
                              ) : (
                                <Typography
                                  sx={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    color: '#202020',
                                    letterSpacing: '-0.48px',
                                    minWidth: '69px',
                                    textAlign: 'right'
                                  }}
                                >
                                  {Formatter.formatTime2(workingDay?.openAt)} -{' '}
                                  {Formatter.formatTime2(workingDay?.closeAt)}
                                </Typography>
                              )}
                            </Stack>
                          );
                        })}
                      </Stack>
                    </Stack>
                  )}
                  <Stack
                    sx={{
                      flexGrow: 1,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        letterSpacing: '-0.48px',
                        fontWeight: 500,
                        color: '#9A9CA4'
                      }}
                    >
                      전화번호
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 12,
                        letterSpacing: '-0.48px',
                        fontWeight: 500,
                        color: '#202020'
                      }}
                    >
                      {Formatter.formatKoreanPhoneNumber(
                        orderData?.storeContact
                      )}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </OrderAccordion>
        </Stack>
        <Stack
          sx={{ width: '100%', height: '4px', backgroundColor: '#F2F2F2' }}
        >
          <Stack
            sx={{ width: '100%', height: '1px', backgroundColor: '#E7E7E7' }}
          />
        </Stack>

        {/* product info */}
        <Stack
          sx={{ width: '100%', px: 2.5, py: 2.5, backgroundColor: '#FFFFFF' }}
        >
          <OrderAccordion data={{ title: '주문 상품' }} defaultExpanded>
            {orderData && (
              <Stack
                sx={{
                  width: '100%',
                  flexGrow: 1,
                  backgroundColor: '#FFFFFF'
                }}
              >
                {orderData?.products?.map((item, index) => (
                  <Stack
                    key={`${item.productCode}-${item.productName}`}
                    sx={{
                      width: '100%',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      gap: 1,
                      pb: orderData.products.length - 1 === index ? 0 : 2.5,
                      pt: index === 0 ? 1 : 2.5,
                      borderBottom:
                        orderData.products.length - 1 === index
                          ? 0
                          : '1px solid #F2F2F2'
                    }}
                  >
                    <Stack
                      sx={{
                        flexGrow: 1,
                        flexDirection: 'row'
                      }}
                    >
                      <Stack
                        sx={{
                          width: '100%',
                          flexDirection: 'row',
                          gap: 1.5,
                          justifyContent: 'flex-start'
                        }}
                      >
                        <Stack
                          sx={{
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <img
                            src={`${env.s3BaseUrl}/${item.imageUrl}`}
                            alt={item.productName}
                            style={{
                              width: '100px',
                              maxWidth: '100px',
                              height: '100px',
                              objectFit: 'cover'
                            }}
                          />
                        </Stack>
                        <Stack
                          sx={{
                            gap: 1.5,
                            flexGrow: 1,

                            justifyContent: 'space-between'
                          }}
                        >
                          <Stack sx={{ gap: 0.5 }}>
                            <Stack
                              sx={{
                                flexDirection: 'row',
                                gap: 1,
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: 14,
                                  letterSpacing: '-0.56px',
                                  fontWeight: 500,
                                  color: '#202020',
                                  display: '-webkit-box',
                                  overflow: 'hidden',
                                  WebkitBoxOrient: 'vertical',
                                  WebkitLineClamp: 2
                                }}
                              >
                                {item.productName}
                              </Typography>
                            </Stack>
                            <Typography
                              sx={{
                                fontSize: 14,
                                letterSpacing: '-0.56px',
                                fontWeight: 700,
                                color: '#1B2872'
                              }}
                            >
                              {formatMoney(item.price)}원
                            </Typography>
                          </Stack>
                          <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
                            <Stack
                              sx={{
                                flexDirection: 'row'
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: 14,
                                  letterSpacing: '-0.48px',
                                  fontWeight: 500,
                                  color: '#9A9CA4',
                                  textAlign: 'center',
                                  minWidth: '20px'
                                }}
                              >
                                수량 {item.quantity}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            )}
          </OrderAccordion>
        </Stack>

        {/* divider */}
        <Stack
          sx={{ width: '100%', height: '5px', backgroundColor: '#F2F2F2' }}
        >
          <Stack
            sx={{ width: '100%', height: '1px', backgroundColor: '#E7E7E7' }}
          />
        </Stack>

        {/* message */}
        <Stack
          sx={{ width: '100%', py: 2.5, px: 2.5, backgroundColor: '#FFFFFF' }}
        >
          {/* title */}
          <Stack
            sx={{
              width: '100%',
              textAlign: 'left',
              pb: 1.5,
              borderBottom: '1px solid #F2F2F2'
            }}
          >
            <Typography
              sx={{
                color: '#202020',
                fontSize: { xs: 14 },
                fontWeight: 700,
                letterSpacing: '-0.56px'
              }}
            >
              상점 요청사항(선택)
            </Typography>
          </Stack>

          {/* calendar */}
          <Stack sx={{ flexDirection: 'column', gap: 0.5, mt: 2.5 }}>
            <Stack sx={{ flexDirection: 'row', alignItems: 'flex-end' }}>
              <MyTextField
                sx={{ p: 0 }}
                preorder
                fullWidth
                disabled
                value={orderData?.customerRequest}
                multiline
                minRows={1}
                maxRows={4}
              />
            </Stack>
          </Stack>
        </Stack>

        {/* divider */}
        <Stack
          sx={{ width: '100%', height: '5px', backgroundColor: '#F2F2F2' }}
        >
          <Stack
            sx={{ width: '100%', height: '1px', backgroundColor: '#E7E7E7' }}
          />
        </Stack>

        {/* payment detail */}
        <Stack
          sx={{
            width: '100%',
            pt: 2.5,

            backgroundColor: '#FFFFFF',
            mb:
              ![statuses.ORDER_COMPLETE, statuses.ORDER_CONFIRM].includes(
                orderData?.orderStatus
              ) &&
              orderData?.orderStatus !== statuses.CANCELED &&
              '50px'
          }}
        >
          {/* title */}
          <Stack
            sx={{
              width: '100%',
              textAlign: 'left',
              pb: 1.5,
              px: 2.5,
              borderBottom: '1px solid #F2F2F2'
            }}
          >
            <Typography
              sx={{
                color: '#202020',
                fontSize: { xs: 14 },
                fontWeight: 700,
                letterSpacing: '-0.56px'
              }}
            >
              결제정보
            </Typography>
          </Stack>

          {/* calendar */}
          <Stack
            sx={{
              flexDirection: 'column',
              gap: 2.5,
              px: 2.5,
              pb: 2.5,
              mt: 2.5,
              borderBottom:
                ![statuses.ORDER_COMPLETE, statuses.ORDER_CONFIRM].includes(
                  orderData?.orderStatus
                ) && '1px solid #DBDCDF'
            }}
          >
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Typography
                sx={{
                  color: '#9A9CA4',
                  fontSize: { xs: 12 },
                  fontWeight: 500,
                  letterSpacing: '-0.px'
                }}
              >
                결제수단
              </Typography>

              <Typography
                sx={{
                  color: '#202020',
                  fontSize: { xs: 12 },
                  fontWeight: 500,
                  letterSpacing: '-0.48px',
                  display: 'flex',
                  gap: 1
                }}
              >
                {orderData?.cardIssuer}{' '}
                <span>{formatCardNumber(orderData?.cardNumber)}</span>
              </Typography>
            </Stack>

            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Typography
                sx={{
                  color: '#9A9CA4',
                  fontSize: { xs: 12 },
                  fontWeight: 500,
                  letterSpacing: '-0.px'
                }}
              >
                총 결제금액
              </Typography>

              <Typography
                sx={{
                  color: '#1B2872',
                  fontSize: { xs: 12 },
                  fontWeight: 500,
                  letterSpacing: '-0.48px'
                }}
              >
                {formatMoney(orderData?.totalPrice)}원
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        {orderData?.orderStatus === statuses.CANCELED && (
          <>
            <Stack
              sx={{ width: '100%', height: '5px', backgroundColor: '#F2F2F2' }}
            >
              <Stack
                sx={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#E7E7E7'
                }}
              />
            </Stack>
            <Stack
              sx={{
                width: '100%',
                pt: 2.5,

                backgroundColor: '#FFFFFF',
                mb:
                  ![statuses.ORDER_COMPLETE, statuses.ORDER_CONFIRM].includes(
                    orderData?.orderStatus
                  ) && '50px'
              }}
            >
              {/* title */}
              <Stack
                sx={{
                  width: '100%',
                  textAlign: 'left',
                  pb: 1.5,
                  px: 2.5,
                  borderBottom: '1px solid #F2F2F2'
                }}
              >
                <Typography
                  sx={{
                    color: '#202020',
                    fontSize: { xs: 14 },
                    fontWeight: 700,
                    letterSpacing: '-0.56px'
                  }}
                >
                  환불정보
                </Typography>
              </Stack>

              {/* calendar */}
              <Stack
                sx={{
                  flexDirection: 'column',
                  gap: 2.5,
                  px: 2.5,
                  pb: 2.5,
                  mt: 2.5,
                  borderBottom:
                    ![statuses.ORDER_COMPLETE, statuses.ORDER_CONFIRM].includes(
                      orderData?.orderStatus
                    ) && '1px solid #DBDCDF'
                }}
              >
                <Stack
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Typography
                    sx={{
                      color: '#9A9CA4',
                      fontSize: { xs: 12 },
                      fontWeight: 500,
                      letterSpacing: '-0.px'
                    }}
                  >
                    결제수단
                  </Typography>

                  <Typography
                    sx={{
                      color: '#202020',
                      fontSize: { xs: 12 },
                      fontWeight: 500,
                      letterSpacing: '-0.48px',
                      display: 'flex',
                      gap: 1
                    }}
                  >
                    {orderData?.refundCardIssuer}{' '}
                    <span>{formatCardNumber(orderData?.refundCardNumber)}</span>
                  </Typography>
                </Stack>

                <Stack
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Typography
                    sx={{
                      color: '#9A9CA4',
                      fontSize: { xs: 12 },
                      fontWeight: 500,
                      letterSpacing: '-0.px'
                    }}
                  >
                    총 결제금액
                  </Typography>

                  <Typography
                    sx={{
                      color: '#1B2872',
                      fontSize: { xs: 12 },
                      fontWeight: 500,
                      letterSpacing: '-0.48px'
                    }}
                  >
                    {formatMoney(orderData?.refundTotalPrice)}원
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </>
        )}
        {[statuses.ORDER_COMPLETE, statuses.ORDER_CONFIRM].includes(
          orderData?.orderStatus
        ) && (
          <Stack
            sx={{
              width: '100%',
              p: 2.5,
              mb: '50px',
              maxWidth: '500px',

              backgroundColor: '#D6D7DB'
            }}
          >
            <Stack sx={{ width: '100%', flexDirection: 'row', gap: 1.5 }}>
              <Button
                sx={{
                  flexGrow: 1,
                  height: 50,
                  backgroundColor: '#1B2872',
                  borderRadius: 1,
                  fontSize: 14,
                  letterSpacing: '-0.56px',
                  fontWeight: 700,
                  color: '#FFFFFF !important'
                }}
                onClick={() => {
                  setAlertDialog({
                    ...alertDialog,
                    open: true,
                    title: '주문을 취소 하시겠습니까?',
                    subTitle: t('pages.pre-order.alert.cancel-alert-subtitle'),
                    onOk: () => handleCancelOrder()
                  });
                }}
              >
                주문취소
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  style={{ marginLeft: '6px' }}
                  fill="none"
                >
                  <path d="M1 13L7 7L1 0.999999" stroke="white" />
                </svg>
              </Button>
            </Stack>
          </Stack>
        )}
        <Stack
          sx={{ width: '100%', height: '1px', backgroundColor: '#DBDCDF' }}
        />
        {/* divider */}

        {/* <Stack
          sx={{
            minHeight: '74px',

            width: '100%',
            backgroundColor: '#F2F2F2'
          }}
        /> */}

        <MyModal
          open={dialog?.open}
          setOpen={() => {
            setDialog({ ...dialog, open: false, title: '' });
            fetchOrtderDetail();
          }}
          title="픽업 QR코드"
          hideDialogButton
          sx={{ maxWidth: '430px' }}
          fullScreen={false}
        >
          <Stack
            sx={{
              minHeight: '200px',
              // backgroundColor: '#F6F6F6',
              minWidth: '200px',
              width: '100%',
              gap: 2.5
            }}
          >
            <Stack sx={{ width: '100%', alignItems: 'center' }}>
              <Typography
                sx={{
                  whiteSpace: 'pre-line',
                  textAlign: 'center',
                  fontSize: 14,
                  color: '#202020',
                  fontWeight: 500,
                  letterSpacing: '-0.56px'
                }}
              >
                {t('pages.pre-order.pick-up-qr-subtitle')}
              </Typography>
            </Stack>
            <Stack sx={{ width: '100%', alignItems: 'center', gap: 1.25 }}>
              <Stack
                sx={{
                  width: '200px',
                  height: '200px'
                }}
              >
                {seconds < 1 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="200"
                    height="200"
                    viewBox="0 0 200 200"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_506_10891)">
                      <path
                        d="M62.5743 0H0.5V62.5086H62.5743V0ZM50.1526 50H12.9217V12.5086H50.1697V50.0173L50.1526 50Z"
                        fill="#202020"
                        fill-opacity="0.05"
                      />
                      <path
                        d="M37.7474 25H23.9531V38.8908H37.7474V25Z"
                        fill="#202020"
                        fill-opacity="0.05"
                      />
                      <path
                        d="M200.074 0H138V62.5086H200.074V0ZM187.67 50H150.422V12.5086H187.67V50.0173V50Z"
                        fill="#202020"
                        fill-opacity="0.05"
                      />
                      <path
                        d="M175.794 25H162V38.8908H175.794V25Z"
                        fill="#202020"
                        fill-opacity="0.05"
                      />
                      <path
                        d="M0.5 200H62.5743V137.491H0.5V200ZM12.9217 150H50.1697V187.508H12.9217V150Z"
                        fill="#202020"
                        fill-opacity="0.05"
                      />
                      <path
                        d="M37.7474 162.509H23.9531V176.4H37.7474V162.509Z"
                        fill="#202020"
                        fill-opacity="0.05"
                      />
                      <path
                        d="M99.8048 51.3822H112.227V37.5086H99.8048V12.5086H87.4002V0H74.9785V25H87.4002V37.5086H74.9785V51.3822H87.4002V62.5086H99.8048V51.3822Z"
                        fill="#202020"
                        fill-opacity="0.05"
                      />
                      <path
                        d="M87.4005 87.5088V62.5088H74.9787V75.0001H43.2553V100H25.3262V112.509H61.1845V100H74.9787V87.5088H87.4005Z"
                        fill="#202020"
                        fill-opacity="0.05"
                      />
                      <path
                        d="M37.7482 75H12.9219V87.5086H37.7482V75Z"
                        fill="#202020"
                        fill-opacity="0.05"
                      />
                      <path
                        d="M23.9537 125V112.509H12.9217V87.5088H0.5V112.509V125H12.9217H23.9537Z"
                        fill="#202020"
                        fill-opacity="0.05"
                      />
                      <path
                        d="M87.4002 137.509H74.9785V150.017H87.4002V137.509Z"
                        fill="#202020"
                        fill-opacity="0.05"
                      />
                      <path
                        d="M161.879 100V87.5088H149.457V100V112.509H124.631V125H149.457H161.879V112.509H174.283V100H161.879Z"
                        fill="#202020"
                        fill-opacity="0.05"
                      />
                      <path
                        d="M74.9788 112.509H62.5742V125H106.702V112.509H99.8051V100H74.9788V112.509Z"
                        fill="#202020"
                        fill-opacity="0.05"
                      />
                      <path
                        d="M186.705 75V112.509H174.283V125H186.705H200.499V112.509V75H186.705Z"
                        fill="#202020"
                        fill-opacity="0.05"
                      />
                      <path
                        d="M112.227 150H99.8048V162.509H112.227V175V187.509H99.8048V175V162.509H74.9785V175H87.4002V187.509H74.9785V200H174.284V187.509H161.879V175H174.284V187.509H186.705V200H200.5V175V162.509V148.618H186.705V137.509H174.284V150H186.705V162.509H161.879V137.509H148.085V187.509H124.631V175H137.053V162.509H124.631V150H137.053V137.509H124.631V125H112.227V150Z"
                        fill="#202020"
                        fill-opacity="0.05"
                      />
                      <path
                        d="M126.021 68.0547H112.226V87.5087H99.8047V100H112.226V106.945H126.021V100H137.053V87.5087H126.021V68.0547Z"
                        fill="#202020"
                        fill-opacity="0.05"
                      />
                      <path
                        d="M174.301 75H161.879V87.5086H174.301V75Z"
                        fill="#202020"
                        fill-opacity="0.05"
                      />
                      <path
                        d="M149.474 75H137.053V87.5086H149.474V75Z"
                        fill="#202020"
                        fill-opacity="0.05"
                      />
                      <path
                        d="M124.648 12.5088H112.227V37.5088H124.648V12.5088Z"
                        fill="#202020"
                        fill-opacity="0.05"
                      />
                      <path
                        d="M112.226 0H99.8047V12.5086H112.226V0Z"
                        fill="#202020"
                        fill-opacity="0.05"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_506_10891">
                        <rect
                          width="200"
                          height="200"
                          fill="white"
                          transform="translate(0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                ) : (
                  <QRCode
                    value={qrData?.qrText}
                    size={200}
                    style={{
                      height: '200px',
                      maxWidth: '100%',
                      width: '200px'
                    }}
                    viewBox="0 0 200 200"
                  />
                )}
              </Stack>
              {seconds > 0 && (
                <CountTime seconds={seconds} setSeconds={setSeconds} />
              )}
              {errMsg?.qr && seconds < 1 && (
                <Typography
                  sx={{
                    fontSize: { xs: 14 },
                    color: '#202020',
                    letterSpacing: '-0.5px',
                    textAlign: 'center',
                    whiteSpace: 'pre-line'
                  }}
                >
                  {errMsg?.qr}
                </Typography>
              )}
            </Stack>

            <Stack sx={{ width: '100%', flexDirection: 'row', gap: 1.5 }}>
              <Button
                sx={{
                  flexGrow: 1,
                  height: 50,
                  backgroundColor:
                    seconds > 0 ? '#F6F6F6 !important' : '#1B2872 !important',
                  borderRadius: 1,
                  fontSize: 14,
                  letterSpacing: '-0.56px',
                  fontWeight: 500,
                  color:
                    seconds > 0 ? '#202020 !important' : '#FFFFFF !important'
                }}
                disabled={seconds > 0}
                onClick={() => hadnleRenewQr()}
              >
                다시 만들기
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M5.53994 11.2207L6.47021 12.6062L6.91954 13.2754C7.00213 13.3982 7.17206 13.4003 7.25726 13.2793L7.73239 12.6052L8.68082 11.2597C8.78464 11.1125 8.688 10.9011 8.51578 10.899L7.804 10.8902C7.87316 10.5173 7.97759 10.153 8.11683 9.79995C8.38234 9.12697 8.76235 8.52243 9.24634 8.00337C9.73033 7.4843 10.294 7.07658 10.9217 6.792C11.5713 6.49726 12.2616 6.34793 12.973 6.34793C13.6845 6.34793 14.3748 6.49726 15.0244 6.792C15.6519 7.07675 16.2156 7.4843 16.6997 8.00337C17.1837 8.52243 17.5637 9.12697 17.8293 9.79995C18.1041 10.4967 18.2433 11.237 18.2433 12C18.2433 12.763 18.1041 13.5033 17.8293 14.2C17.5637 14.873 17.1837 15.4776 16.6997 15.9968C16.2156 16.5159 15.6521 16.9234 15.0244 17.2082C14.3748 17.5029 13.6845 17.6522 12.973 17.6522C12.2616 17.6522 11.5713 17.5029 10.9217 17.2082C10.2942 16.9234 9.73048 16.5159 9.24634 15.9968L8.35776 16.9498C9.53887 18.2165 11.1705 19 12.973 19C16.5778 19 19.5 15.866 19.5 12C19.5 8.13404 16.5778 5 12.973 5C9.72559 5 7.03206 7.54358 6.53006 10.8744L5.71247 10.8643C5.54055 10.8622 5.43933 11.0711 5.53994 11.2206L5.53994 11.2207Z"
                    fill={seconds > 0 ? '#202020 ' : '#FFFFFF '}
                  />
                </svg>
              </Button>
            </Stack>
          </Stack>
        </MyModal>

        <AlertDialog
          isAlert
          open={alertDialog.open}
          setOpen={(open) => setAlertDialog({ ...alertDialog, open })}
          onOk={alertDialog?.onOk}
          okTitle={alertDialog?.okTitle || '취소'}
          title={alertDialog.title}
          titleStyle={{ textAlign: 'center' }}
          cancelTitle="닫기"
          type={alertDialog?.type || 'delete'}
          subTitle={
            <Typography
              sx={{
                fontSize: 14,
                color: '#202020',
                fontWeight: 500,
                letterSpacing: '-0.56px',
                whiteSpace: 'pre-line',
                textAlign: 'center',
                mt: 1.5
              }}
            >
              {alertDialog?.subTitle}{' '}
            </Typography>
          }
          hasCancelButton
        />
      </Stack>
    </Stack>
  );
};

const CountTime = (props) => {
  const { isVerifying, seconds, setSeconds } = props;

  useEffect(() => {
    if (seconds > 0) {
      const timer = setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [seconds]);

  return (
    <Stack
      sx={{ fontSize: { xs: 14 }, color: '#9A9CA4', letterSpacing: '-0.56px' }}
    >
      {Formatter.fSecondsToMinutes(seconds)}
    </Stack>
  );
};
export default OrderDetail;
