import React from 'react';
import { Outlet } from 'react-router-dom';

import { Box, Toolbar } from '@mui/material';

const Content = ({ headerHeight, drawerWidth, open }) => {
  return (
    <Box
      sx={{
        bgcolor: 'common.white',
        minHeight: '100vh',
        width: `calc(100vw - ${open ? drawerWidth : 0}px)`
      }}
    >
      <Toolbar sx={{ height: `${headerHeight}px` }} />
      <Box
        component="main"
        sx={{
          p: { md: '30px', xs: 2.5 },
          height: `calc(100vh - ${headerHeight}px)`
        }}
        // style={{ maxHeight: `calc(100vh - ${150}px)` }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default Content;
