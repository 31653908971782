import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import MyLoadingButton from '@components/MyLoadingButton';

export default function MyDialog({
  open,
  setOpen,
  title = 'Your Title',
  children,
  onOk,
  onCancel,
  okTitle,
  cancelTitle,
  hasCancelButton,
  isAlert = false,
  hasCloseButton,
  hideDialogButton = false,
  disableBackdropClick = false,
  sx,
  buttonId,
  subTitle,
  disabled,
  onClose,
  ...rest
}) {
  const { t } = useTranslation(null, { keyPrefix: 'button' });

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={disableBackdropClick ? null : handleClose}
      PaperProps={{
        sx: {
          zIndex: 0,
          borderRadius: 0,
          width: 'auto',
          minWidth: { md: '280px', xs: '365px' },
          maxWidth: 'fit-content',
          p: { md: '32px', xs: '24px 20px 20px 20px' },
          ...sx
        }
      }}
      slotProps={{
        backdrop: { sx: { bgcolor: '#343434', opacity: '0.6 !important' } }
      }}
      {...rest}
    >
      <DialogTitle
        sx={{
          color: '#212121',
          width: '100%',
          fontSize: { md: '20px !important', xs: 16 },
          textAlign: 'center',
          px: { md: 4, xs: 0 },
          pt: { md: 4 },
          whiteSpace: 'pre-line',
          overflow: 'hidden'
        }}
      >
        {title}
        {!isAlert && hasCloseButton ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 32,
              top: 30,
              color: 'black'
            }}
          >
            <CloseIcon sx={{ color: 'black' }} />
          </IconButton>
        ) : null}
        {subTitle && typeof subTitle === 'function' ? (
          subTitle
        ) : (
          <Typography
            sx={{
              fontSize: { md: 14, xs: 13 },
              color: '#919193',
              fontWeight: 500,
              mt: '15px',
              whiteSpace: 'pre-line'
            }}
          >
            {subTitle}
          </Typography>
        )}
      </DialogTitle>
      {children && (
        <DialogContent sx={{ px: 4, pb: 4 }}>{children}</DialogContent>
      )}
      {!hideDialogButton && (
        <DialogActions sx={{ justifyContent: 'center', mt: '15px', p: 0 }}>
          {hasCancelButton && (
            <Button
              autoFocus
              onClick={onCancel || handleClose}
              variant="outlined"
              sx={{
                height: '44px',
                minWidth: '110px',
                flex: isAlert ? 1 : 'unset'
              }}
            >
              {cancelTitle || t('cancel')}
            </Button>
          )}
          <MyLoadingButton
            onClick={onOk || handleClose}
            variant="contained"
            id={buttonId}
            sx={{
              minWidth: '110px',
              flex: 1,
              borderRadius: '0',
              height: { md: 40, xs: 40 },
              width: { md: '100%', xs: 80 },
              fontSize: { md: 16, xs: 15 },
              backgroundColor: '#244EE2',
              color: '#FFFFFF',
              p: '10px 16px',
              fontWeight: 500,
              ':hover': {
                backgroundColor: '#1D3FB7'
              },

              '&.Mui-disabled': {
                backgroundColor: '#B2BEEB',
                color: '#FFFFFF'
              }
            }}
            disabled={disabled}
          >
            {okTitle || t('check')}
          </MyLoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
}
