import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  authenticated: Boolean(localStorage.getItem('PREORDER_TOKEN')),
  authenticatedAdmin: Boolean(localStorage.getItem('PREORDER_ADMIN_TOKEN')),
  profile: {
    name: '',
    userId: ''
  },
  signUp: {
    agreeTermsAll: false
  }
};
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthenticated: (state, { payload }) => {
      state.authenticated = payload;
    },
    setAdminAuth: (state, { payload }) => {
      state.authenticatedAdmin = payload;
    },
    setProfile: (state, { payload }) => {
      state.profile = payload;
    }
  }
});
export const { setAuthenticated, setProfile, setAdminAuth } = authSlice.actions;
export default authSlice.reducer;
