import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

function OrderAccordion({
  data,
  expanded,
  onChange,
  children,
  titleStyle,
  summaryStyle,
  sx,
  ...rest
}) {
  console.log(data?.title, expanded);
  const expandIcon = expanded ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
    >
      <path d="M13 7L7 1L1 7" stroke="black" />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
    >
      <path d="M1 1L7 7L13 1" stroke="black" />
    </svg>
  );
  return (
    <Accordion
      expanded={expanded}
      square
      disableGutters
      onChange={onChange}
      sx={{ width: '100%', boxShadow: 0, ...sx }}
      {...rest}
    >
      <AccordionSummary
        disableRipple
        disableTouchRipple
        focusRipple={false}
        expandIcon={expandIcon}
        sx={{
          p: { xs: 0 },
          minHeight: { xs: '32px' },
          m: 0,
          '& .MuiAccordionSummary-content': { m: 0 },
          height: { xs: '32px', md: 'auto' },
          borderBottom: '1px solid #F2F2F2',
          ...summaryStyle
        }}
        aria-controls={`${data?.title
          ?.toLowerCase()
          .replace(/ /g, '-')}-content`}
        id={`${data?.title?.toLowerCase().replace(/ /g, '-')}-header`}
      >
        <Typography
          sx={{
            color: '#202020',
            fontSize: { xs: 14 },
            fontWeight: 700,
            letterSpacing: '-0.56px',
            ...titleStyle
          }}
        >
          {data?.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          mt: 2.5,
          px: 0,
          p: 0
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

export default OrderAccordion;
