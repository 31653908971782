import RestService from '@services/RestService';

const codeService = {
  async sendOTP(data = {}) {
    try {
      const res = await new RestService()
        .setPath('/code/send-otp')
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async verifyOTP(data = {}) {
    try {
      const res = await new RestService()
        .setPath('/code/verify-otp')
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async sendPreorderOTP(data = {}) {
    try {
      const res = await new RestService()
        .setPath('pre-order/verification/send-verification')
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async verifyPreorderOTP(data = {}) {
    try {
      const res = await new RestService()
        .setPath('pre-order/verification/check-verification')
        .post(data);
      const location = res?.headers?.location;
      return { data: res?.data, error: null, location };
    } catch (err) {
      return { error: err?.response?.data || err, data: null, location: null };
    }
  }
};

export default codeService;
