import {
  downloadFileFromBinary,
  getFilenameFromHeader
} from '@utils/downloadFile';
import RestService from '../PreorderAdminApi';

const inventoryService = {
  // Store

  async getMinibarList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/pre-order/store/minibar/all')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getStoreTypeList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/preorder/store-type')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getOrderList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/pre-order/store/order')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getRegularOrderList(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/pre-order/store/order/minibar')
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getStoreOrderDetail(orderCode) {
    try {
      const res = await new RestService()
        .setPath(`/pre-order/store/order/${orderCode}`)

        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async exportOrderData(params = {}) {
    try {
      const res = await new RestService()
        .setPath('/pre-order/store/order')
        .setResponseType('blob')
        .setConfig({ params })
        .get();

      const filename = getFilenameFromHeader(res.headers);
      if (res?.data) downloadFileFromBinary(res.data, filename);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getStoreProductList(params = {}) {
    try {
      const res = await new RestService()
        .setPath(`/pre-order/store/product-minibar`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async createStoreOptions(storeTypeCode, data) {
    try {
      const res = await new RestService()
        .setPath(`/preorder/store-type/${storeTypeCode}/product-info`)
        .post(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateMinibarInventory(data) {
    try {
      const res = await new RestService()
        .setPath(`/pre-order/store/product-minibar`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default inventoryService;
