/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';

import {
  AppBar,
  Breadcrumbs,
  Button,
  IconButton,
  Stack,
  Toolbar,
  Typography
} from '@mui/material';

import koFlag from 'assets/images/ko_flag.png';
import usFlag from 'assets/images/us_flag.png';

import MenuIcon from '@mui/icons-material/Menu';
import { ReactComponent as HomeIcon } from '@svg/house.svg';
import { ReactComponent as ArrowRightIcon } from '@svg/arrow-right.svg';

import sidebarConfig from '@utils/sidebarConfig';
import { useDispatch, useSelector } from 'react-redux';
import { setLang } from '@store/reducers/appReducer';

const Header = ({ handleDrawerOpen, open, isSm, drawerWidth }) => {
  const [breadcrumbsData, setBreadcrumbsData] = useState([]);
  const [title, setTitle] = useState('');
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const location = useLocation();
  useEffect(() => {
    const tempList = [];
    const getBreadcrumbsData = (tempList = [], data = sidebarConfig) => {
      const matched = data.find(({ path }) => {
        return pathname.startsWith(path);
      });

      if (!matched) return;

      tempList.push(matched);
      if (matched?.children?.length > 0)
        getBreadcrumbsData(tempList, matched?.children);
    };
    getBreadcrumbsData(tempList, sidebarConfig);
    setBreadcrumbsData(tempList);
  }, [pathname]);

  useEffect(() => {
    setTitle(document.title);
  }, [document.title]);

  const { lang } = useSelector((state) => state.app);

  return (
    <AppBar
      position="fixed"
      sx={(theme) => ({
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        marginLeft: 0,
        width: '100%',
        ...(open && {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: `${drawerWidth}px`,
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
          })
        })
      })}
    >
      <Toolbar
        sx={{ height: 80, bgcolor: 'common.white', color: 'text.primary' }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <Typography variant="h6" noWrap component="div">
            {title?.split('|')?.[0]}
          </Typography>
          <Stack flexDirection="row" alignItems="center" gap={4}>
            <Button
              startIcon={
                <img
                  src={lang && lang === 'en' ? koFlag : usFlag}
                  alt="flag"
                  height="14px"
                  width="22px"
                  style={{
                    border:
                      lang && lang === 'en' ? '1px solid' : '1px solid white'
                  }}
                />
              }
              variant="outlined"
              sx={{
                height: 30,
                p: 1,
                borderRadius: '8px !important',
                fontSize: 11,
                fontWeight: '500'
              }}
              onClick={() => {
                window.location.reload();
                dispatch(setLang(lang && lang === 'en' ? 'ko' : 'en'));
                localStorage.setItem('i18nextLng', lang === 'en' ? 'ko' : 'en');
              }}
            >
              {lang && lang === 'en' ? 'KO' : 'EN'}
            </Button>
            {!isSm && (
              <Breadcrumbs
                separator={<ArrowRightIcon />}
                aria-label="breadcrumb"
                sx={{
                  color: 'text.secondary',
                  fontSize: 13,
                  whiteSpace: 'nowrap',
                  '.MuiBreadcrumbs-ol': { flexWrap: 'nowrap' },
                  a: {
                    textDecoration: 'none',
                    ':hover, :visited:hover': {
                      color: 'primary.dark'
                    },
                    ':visited': {
                      color: 'currentColor'
                    },
                    ':active, :visited:active': {
                      color: 'primary.darker'
                    }
                  }
                }}
              >
                <Link
                  key="home"
                  color="inherit"
                  to="/"
                  style={{ display: 'flex' }}
                >
                  <HomeIcon fill="currentColor" />
                </Link>
                {breadcrumbsData
                  .concat(
                    location?.pathname?.split('/').length > 3
                      ? [
                          {
                            title: document.title?.split('|')?.[0],
                            path: location?.pathname
                          }
                        ]
                      : []
                  )
                  .map((breadcrumb) => {
                    return (
                      <Link
                        key={breadcrumb.path}
                        color="inherit"
                        to={breadcrumb.path}
                      >
                        {breadcrumb?.title}
                      </Link>
                    );
                  })}
              </Breadcrumbs>
            )}
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
