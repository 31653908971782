import React, { forwardRef } from 'react';
import { FormControl, FormControlLabel, TextField } from '@mui/material';
import { isNumber } from '@utils/helpers';

const MyTextField = forwardRef(
  (
    {
      label,
      sx,
      errMg,
      required,
      onKeyDown,
      typeValue,
      onChange,
      fullWidth = false,
      ...rest
    },
    ref
  ) => {
    if (!label)
      return (
        <FormControl sx={{ ...sx }}>
          <TextField
            size="small"
            sx={{
              '.MuiInputBase-root': {
                bgcolor: 'common.white'
              },
              ...sx
            }}
            onPaste={(e) => {
              const value = e.clipboardData.getData('text');
              if (onKeyDown) {
                if (!isNumber(value.replace(/,/g, ''))) {
                  e.preventDefault();
                }
              }
            }}
            onKeyDown={onKeyDown}
            error={errMg}
            inputRef={ref}
            {...rest}
            onChange={(e) => {
              if (typeValue === 'onlyNumber') {
                const value = e.target.value.replace(/\D/g, '');
                e.target.value = value;
                onChange(e);
              } else {
                onChange(e);
              }
            }}
          />
          {errMg && (
            <span
              style={{
                color: 'red',
                fontSize: 12
              }}
            >
              * {errMg}
            </span>
          )}
        </FormControl>
      );
    return (
      <FormControl
        sx={{
          flexDirection: 'row',
          ...(fullWidth && { width: fullWidth && '100%' })
        }}
        error={errMg}
      >
        <FormControlLabel
          label={
            <span>
              {label} <span style={{ color: 'red' }}>{required && '*'}</span>
            </span>
          }
          labelPlacement="start"
          sx={{
            ...(fullWidth && { width: fullWidth && '100%' }),
            mx: { xs: fullWidth && 0 },
            '.MuiTypography-root': {
              color: '#6F869C',
              fontWeight: 'fontWeightBold',
              fontSize: 13,
              whiteSpace: 'nowrap'
            }
          }}
          control={
            <TextField
              size="small"
              sx={{
                '.MuiInputBase-root': { bgcolor: 'common.white' },
                ml: 1,

                ...sx
              }}
              onPaste={(e) => {
                const value = e.clipboardData.getData('text');
                if (onKeyDown) {
                  if (!isNumber(value.replace(/,/g, ''))) {
                    e.preventDefault();
                  }
                }
              }}
              onKeyDown={onKeyDown}
              inputRef={ref}
              {...rest}
              onChange={(e) => {
                if (typeValue === 'onlyNumber') {
                  const value = e.target.value.replace(/\D/g, '');
                  e.target.value = value;
                  onChange(e);
                } else {
                  onChange(e);
                }
              }}
            />
          }
        />
        {errMg && (
          <span
            style={{
              color: 'red',
              fontSize: 12
            }}
          >
            * {errMg}
          </span>
        )}
      </FormControl>
    );
  }
);

export default MyTextField;
