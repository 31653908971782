/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import inventoryService from '@services/preorder/adminInventory';

import { Button, Hidden, Stack, Typography } from '@mui/material';
import SearchBar from '@components/SearchBar';

import HelmetContainer from '@components/HelmetContainer';
import MyAutocomplete from '@components/MyAutocomplete';
import MyTable from '@components/MyTable';
import { Formatter } from '@utils/formatter';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';

import MyTextField from '@components/MyTextField';
// import MySelect from '@components/MySelect';
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import useResponsive from '@hooks/useResponsive';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import { inventoryColumns } from '../enhance';
import InventoryActionsDialog from './components/InventoryActionsDialog';

export default function StoreInventoryManagement() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState([]);
  const [minibarList, setMinibarList] = useState([]);
  const [minibar, setMinibar] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [pagination, setPagination] = useState({
    count: 0,
    totalRows: 0,
    page: 0
  });
  const [expanded, setExpanded] = useState(false);
  const isSm = useResponsive('down', 1000);
  const { formatTime } = Formatter;
  const [param, setParam] = useState({
    productName: ''
  });
  const [dialog, setDialog] = useState(null);

  const [loading, setLoading] = useState(false);

  const getMinibarList = async () => {
    setLoading(true);
    const result = await inventoryService.getMinibarList();
    if (result.error) dispatch(setError(error2Text(result.error)));
    else {
      setMinibarList(result?.data || []);
      if (result?.data) {
        setMinibar(result?.data?.[0]);
      }
    }
    setLoading(false);
  };

  const getData = async (params) => {
    setLoading(true);
    const result = await inventoryService.getStoreProductList(params);
    if (result.error) dispatch(setError(error2Text(result.error)));
    else {
      setData(result.data?.items);
      setPagination({
        ...pagination,
        page: result.data?.page || 0,
        totalRows: result.data?.totalRow || 0,
        count: result.data?.totalPage || 0
      });
    }
    setLoading(false);
  };

  const handleExportData = async () => {
    const paramSubmit = {
      startDate: param?.startDate,
      endDate: param?.endDate
    };

    const rs = await inventoryService.exportOrderData(paramSubmit);
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  const getActionButtons = (item) => {
    return (
      <Typography
        sx={{ textDecoration: 'underline' }}
        onClick={(e) => {
          e?.stopPropagation();
          setDialog({ ...dialog, open: true });
          setSelectedItem(item);
        }}
      >
        {item?.quantity}
      </Typography>
    );
  };
  const handleSearch = () => {
    const query = {
      productName: param?.productName,

      minibarCode: minibar?.minibarCode,
      page: pagination.page + 1
    };

    navigate(objToParams(ignoreEmpty(query)));
    getData({
      productName: param?.productName,
      minibarCode: minibar?.minibarCode,

      page: 0
    });
    setExpanded(false);
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const { productName, minibarCode, page } = query;

    const dataParams = {
      productName,
      minibarCode: minibarCode || minibar?.minibarCode,
      page: page - 1 || 0
    };
    setParam({
      ...param,

      productName: productName || ''
    });

    setPagination({ ...pagination, page: page - 1 || 0 });
    getData(ignoreEmpty(dataParams));
    if (minibarList?.length < 1) getMinibarList();
  }, [location?.search]);
  const onAction = async (action, value) => {
    if (action === 'success') {
      getData({ ...param, page: pagination?.page });
      setDialog({ ...dialog, open: false });
      setSelectedItem(null);
    }
  };
  return (
    <HelmetContainer
      title={t('pageTitle.inventory-management')}
      content="preorder store inventory"
      sx={{ width: '100%' }}
    >
      <Hidden mdUp>
        <Stack sx={{ position: 'relative' }}>
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',

              height: { xs: 24 },
              gap: '8px'
            }}
          >
            <Stack direction="row" alignItems="center" sx={{ gap: 1 }}>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: 'transparent !important',
                  border: '1px solid #D3D3D3 !important',
                  height: 36,
                  boxShadow: 0,
                  fontSize: '13px !important',
                  borderRadius: '8px ',
                  gap: 0.5
                }}
                onClick={() => setExpanded((prev) => !prev)}
              >
                <Typography
                  sx={{
                    color: '#000000 !important',
                    fontWeight: '500',
                    fontSize: 13
                  }}
                >{`${t('button.search')} 옵션`}</Typography>

                {expanded ? (
                  <CloseIcon sx={{ color: '#000000', fontSize: 14 }} />
                ) : (
                  <AddOutlinedIcon sx={{ color: '#000000', fontSize: 14 }} />
                )}
              </Button>
            </Stack>

            <Stack
              direction="row"
              alignItems="flex-end"
              sx={{ height: '40px' }}
            >
              <Typography
                sx={{
                  fontWeight: 'fontWeightSemiMedium',
                  fontSize: 13,
                  color: 'text.primary'
                }}
                component="span"
              >
                {t('common.number-of-results')}{' '}
                <Typography
                  component="span"
                  sx={{
                    fontSize: 13,
                    fontWeight: 'fontWeightBold',
                    color: 'primary.dark'
                  }}
                >
                  {pagination?.totalRows || 0}
                </Typography>
              </Typography>
            </Stack>
          </Stack>
          <Stack
            sx={{
              position: 'absolute',
              top: 40,
              display: expanded ? 'flex' : 'none',
              zIndex: 1100,
              maxWidth: '450px'
            }}
          >
            <SearchBar
              sx={{ p: 2, boxShadow: '0px 2px 4px 2px rgba(0, 0, 0, 0.1)' }}
            >
              <MyAutocomplete
                label={t('common.minibar')}
                textFieldProps={{ placeholder: t('common.master') }}
                value={minibar}
                fullWidth={isSm}
                labelStyle={{ minWidth: isSm && '34px' }}
                isOptionEqualToValue={(option, value) =>
                  option.minibarCode === value?.minibarCode
                }
                getOptionLabel={(option) => option?.minibarName}
                onChange={(e, val) => {
                  if (!val) setParam({ ...param, productName: '' });
                  setMinibar(val);
                }}
                options={minibarList}
                onClose={() => {
                  if (!minibar) {
                    setParam({ ...param, productName: null });
                  }
                }}
                sx={{
                  '.MuiInputBase-root': {
                    bgcolor: 'common.white'
                  },
                  ...(isSm && { width: isSm && '100%' })
                }}
                ListboxProps={{ style: { overflow: 'visible' } }}
              />

              <MyTextField
                label={t('common.productName')}
                fullWidth={isSm}
                value={param?.productName}
                sx={{ ...(isSm && { width: '100%' }) }}
                onChange={(e) =>
                  setParam({ ...param, productName: e?.target?.value })
                }
              />
              <Stack sx={{ flexGrow: 1, alignItems: 'center' }}>
                <Button
                  variant="contained"
                  onClick={handleSearch}
                  disabled={!minibar}
                  sx={{
                    ml: { md: 2 },
                    // mx: { xs: 5, md: 0 },
                    my: { xs: 1, md: 0 },
                    width: 'auto',
                    minWidth: '90px',
                    borderRadius: 1,
                    height: { xs: 36, md: 44 }
                  }}
                >
                  {t('button.search')}
                </Button>
              </Stack>
            </SearchBar>
          </Stack>
        </Stack>
      </Hidden>
      <Hidden mdDown>
        {' '}
        <SearchBar sx={{}}>
          <MyAutocomplete
            label={t('common.minibar')}
            textFieldProps={{ placeholder: t('common.master') }}
            value={minibar}
            fullWidth={isSm}
            labelStyle={{ minWidth: isSm && '34px' }}
            isOptionEqualToValue={(option, value) =>
              option.minibarCode === value?.minibarCode
            }
            getOptionLabel={(option) => option?.minibarName}
            onChange={(e, val) => {
              if (!val) setParam({ ...param, productName: '' });
              setMinibar(val);
            }}
            options={minibarList}
            onClose={() => {
              if (!minibar) {
                setParam({ ...param, productName: null });
              }
            }}
            sx={{
              '.MuiInputBase-root': {
                bgcolor: 'common.white'
              },
              ...(isSm && { width: isSm && '100%' })
            }}
            ListboxProps={{ style: { overflow: 'visible' } }}
          />

          <MyTextField
            label={t('common.productName')}
            fullWidth={isSm}
            value={param?.productName}
            sx={{ ...(isSm && { width: '100%' }) }}
            onChange={(e) =>
              setParam({ ...param, productName: e?.target?.value })
            }
          />

          <Button
            variant="contained"
            onClick={handleSearch}
            disabled={!minibar}
            sx={{
              ml: { md: 2 },
              // mx: { xs: 5, md: 0 },
              my: { xs: 1, md: 0 },
              width: isSm && '100%',
              height: { xs: 36, md: 44 }
            }}
          >
            {t('button.search')}
          </Button>
        </SearchBar>
      </Hidden>
      <Hidden mdDown>
        {' '}
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ mt: { md: '30px', xs: 2 }, mb: { md: 2, xs: 1 } }}
        >
          <Stack direction="row" alignItems="center">
            <Typography
              sx={{
                fontWeight: 'fontWeightSemiMedium',
                fontSize: 15,
                color: 'text.primary'
              }}
              component="span"
            >
              {t('common.number-of-results')}{' '}
              <Typography
                component="span"
                sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
              >
                {pagination?.totalRows || 0}
              </Typography>
            </Typography>
          </Stack>
        </Stack>
      </Hidden>
      <MyTable
        hover
        rerender
        columns={inventoryColumns}
        data={data || []}
        pagination={pagination}
        getActionButtons={getActionButtons}
        onChangePage={(e, page) => {
          const query = objToParams(ignoreEmpty({ page: page + 1 }));
          setPagination({ ...pagination, page });
          getData({ page, size: 20 });
          navigate(query);
        }}
        minusHeight={200}
        {...(isSm && { maxHeight: `calc(100vh - ${240}px)` })}
      />
      {selectedItem && (
        <InventoryActionsDialog
          open={dialog.open}
          setOpen={() => {
            setDialog({ ...dialog, open: false });
            setSelectedItem(null);
          }}
          setSelectedItem={setSelectedItem}
          item={selectedItem}
          onAction={onAction}
        />
      )}
    </HelmetContainer>
  );
}
