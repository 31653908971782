/* eslint-disable no-else-return */
/* eslint-disable no-useless-return */
import { Divider, Stack, Typography, Button, IconButton } from '@mui/material';
import productService from '@services/preorder/product';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import env from '@constants/env';
import MyBottomSwiper from 'pages-preorder/components/MyBottomSwiper';
import { setAlert } from '@store/reducers/pre-order/app';
import { formatMoney } from '@utils/helpers';
import AlertDialog from 'pages-preorder/components/Modal/Alert/AlertDialog';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PreorderHeader from './components/Header';

const ProductDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const location = useLocation();
  const pathArr = location?.pathname?.split('/');
  const productCode = pathArr[pathArr.length - 1];
  const { storeCode, version } = useParams();
  const [cartData, setCartData] = useState(null);
  const [productData, setProductData] = useState(null);
  const [openCart, setOpenCart] = useState(false);
  const [alertDialog, setAlertDialog] = useState({
    open: false
  });
  const [quantity, setQuantity] = useState(1); // default quantity state

  useEffect(() => {
    const fetchProductData = async () => {
      if (!storeCode || !productCode) return;
      try {
        const rs = await productService.getProductDetail(
          storeCode,
          productCode
        );

        if (!rs.error) {
          const storeData = rs?.data;
          setProductData(storeData);
        }
      } catch (error) {
        console.error('Error fetching store data:', error);
      }
    };

    fetchProductData();
  }, [location?.search]);

  const fetchCartData = async () => {
    try {
      const rs = await productService.getCartData();
      if (!rs.error) {
        setCartData(rs.data);
      }
    } catch (error) {
      console.error('Error fetching store data:', error);
    }
  };
  useEffect(() => {
    fetchCartData();
  }, []);
  useEffect(() => {
    console.log('opencart', openCart);
  }, [openCart]);
  // Handle increasing quantity
  const increaseQuantity = () => {
    if (quantity < 999) {
      setQuantity(quantity + 1);
    }
  };
  const handleDeleteCart = async () => {
    const rs = await productService.deleteAllProductFromCart();
    if (rs?.error) console.log('error');
  };

  const isCartClear = async (storeId, productId) => {
    const rs = await productService.getCartStatus({
      storeCode: storeId,
      productCode: productId
    });

    return rs;
  };

  const handleCheckCart = async () => {
    const checkCart = await isCartClear(storeCode, productCode);

    if (checkCart && !checkCart?.data?.otherStoreExist) {
      if (Number(checkCart.data.currentProductCount) + 1 >= 99) {
        dispatch(
          setAlert({
            message: '장바구니에는 상품을 99개까지 담을 수 있습니다.'
          })
        );
        return;
      } else if (
        Number(checkCart.data.currentProductQuantity) + quantity >=
        999
      ) {
        dispatch(
          setAlert({
            message: '상품은 999개까지 구매할 수 있습니다.'
          })
        );
        return;
      } else {
        handleProductActions('cart');
      }
    } else {
      setAlertDialog({
        ...alertDialog,
        open: true,
        title: '장바구니에는 같은 상점의 상품만 담을 수 있습니다.',
        subtitle: t('pages.pre-order.alert.cart-exists'),
        okTitle: '담기',
        cancelTitle: '취소',
        onOk: () => {
          handleDeleteCart();

          setTimeout(() => {
            handleProductActions('cart');
          }, 100);
        }
      });
    }
  };

  // Handle decreasing quantity
  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleProductActions = async (action) => {
    if (action === 'cart') {
      const rs = await productService.addProductToCart({
        storeCode,
        productCode,
        quantity: quantity || 1
      });
      if (!rs?.error) {
        setAlertDialog({ open: false });
        setOpenCart(false);
        dispatch(setAlert({ message: '장바구니에 추가되었습니다.' }));
        fetchCartData();
        setQuantity(1);
      }
    } else {
      navigate(`/pre-order/${version}/store/${productData?.storeCode}/order`, {
        state: {
          cartData: {
            type: 'direct',
            cartProducts: [{ ...productData, quantity }]
          },
          originalPath: location?.pathname
        }
      });
    }
  };

  return (
    <Stack
      sx={{
        flexDirection: 'column',
        width: '100%',
        maxxWidth: '500px',
        backgroundColor: '#FFFFFF',
        flexGrow: 1
      }}
    >
      {productData && (
        <MyBottomSwiper
          open={openCart}
          setOpen={setOpenCart}
          sx={{
            dialogContent: {
              p: 0
            }
          }}
        >
          <Stack sx={{ width: '100%', pt: '20px', px: 2.5 }}>
            <Stack
              sx={{
                width: '100%',
                flexDirection: 'row',
                gap: 1.5,
                justifyContent: 'flex-start'
              }}
            >
              {/* image */}
              <Stack
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <img
                  src={`${env.s3BaseUrl}/${productData?.imageUrl}`}
                  alt={productData?.productName}
                  style={{
                    width: '100px',
                    height: '100px',
                    maxWidth: '100px',
                    maxHeight: '100px',
                    objectFit: 'cover'
                  }}
                />
              </Stack>
              {/* details */}
              <Stack
                sx={{
                  gap: 1.5,
                  flexGrow: 1,

                  justifyContent: 'space-between'
                }}
              >
                <Stack sx={{ gap: 0.5 }}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      letterSpacing: '-0.56px',
                      fontWeight: 500,
                      display: '-webkit-box',
                      overflow: 'hidden',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      color: '#202020'
                    }}
                  >
                    {productData?.productName}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: 14,
                      letterSpacing: '-0.56px',
                      fontWeight: 700,
                      color: '#1B2872'
                    }}
                  >
                    {formatMoney(productData?.price)}원
                  </Typography>
                </Stack>

                <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 1,
                      p: '4px 10px',
                      height: '30px',
                      borderRadius: 0.5,
                      backgroundColor: '#FFFFFF',
                      border: '1px solid #D6D7DB'
                    }}
                  >
                    <IconButton
                      size="small"
                      sx={{ backgroundColor: 'transparent !important' }}
                      onClick={decreaseQuantity}
                      disabled={quantity <= 1}
                    >
                      <RemoveIcon
                        sx={{ color: '#202020', fontSize: 14 }}
                        fontSize="small"
                      />
                    </IconButton>
                    <Typography
                      sx={{
                        fontSize: 14,
                        minWidth: '27px',
                        letterSpacing: '-0.48px',
                        fontWeight: 500,
                        color: '#202020',
                        textAlign: 'center'
                      }}
                    >
                      {quantity}
                    </Typography>
                    <IconButton
                      size="small"
                      sx={{ backgroundColor: 'transparent !important' }}
                      onClick={increaseQuantity}
                      disabled={quantity >= 999}
                    >
                      <AddIcon sx={{ color: '#202020', fontSize: 14 }} />
                    </IconButton>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              sx={{
                width: '100%',
                mt: 2.5,
                flexDirection: 'row',
                alignItems: 'center',
                gap: 1
              }}
            >
              <Button
                sx={{
                  width: '100%',
                  height: 48,
                  backgroundColor: '#FFFFFF !important',
                  border: '1px solid #1B2872 !important',
                  fontSize: 16,
                  letterSpacing: '-0.64px',
                  fontWeight: 500,
                  borderRadius: 1,
                  color: '#1B2872 !important'
                }}
                onClick={() => handleCheckCart()}
              >
                장바구니
              </Button>
              <Button
                sx={{
                  width: '100%',
                  height: 48,
                  backgroundColor: '#1B2872 !important',
                  fontSize: 16,
                  letterSpacing: '-0.64px',
                  fontWeight: 500,
                  borderRadius: 1,
                  color: '#FFFFFF !important'
                }}
                onClick={() => handleProductActions()}
              >
                바로구매
              </Button>
            </Stack>
          </Stack>
        </MyBottomSwiper>
      )}
      <PreorderHeader
        currentStore={{
          storeName: location?.state?.storeName,
          storeCode
        }}
        cartData={cartData}
        version
        presentation
        hasBackButton
      />
      <Stack
        sx={{ flexDirection: 'column', mt: '50px', flexGrow: 1, mb: '118px' }}
      >
        {/* image */}
        <Stack
          sx={{
            width: '100%',
            // borderBottom: '1px solid grey',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {productData?.imageUrl && (
            <img
              src={`${env.s3BaseUrl}/${productData?.imageUrl}`}
              alt={productData?.productName}
              style={{
                width: '100%',

                aspectRatio: 1,
                objectFit: 'cover'
              }}
            />
          )}
        </Stack>

        {/* product title */}
        <Stack sx={{ width: '100%', p: 2.5, gap: 1.5 }}>
          <Typography
            sx={{
              fontSize: 18,
              letterSpacing: '-0.72px',
              fontWeight: 500,
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              color: '#202020'
            }}
          >
            {productData?.productName}
          </Typography>
          <Stack sx={{ width: '100%', textAlign: 'right' }}>
            <Typography
              sx={{
                fontSize: 24,
                letterSpacing: '-0.96px',
                fontWeight: 700,
                color: '#1B2872'
              }}
            >
              {formatMoney(productData?.price)}원
            </Typography>
          </Stack>
        </Stack>
        <Divider sx={{ backgroundColor: '#D6D7DB', mx: 2.5 }} />
        {/* product detail */}
        <Stack
          sx={{
            width: '100%',
            pt: 2.5,
            px: 2.5,
            gap: 2.5
          }}
        >
          <Stack sx={{ width: '100%' }}>
            <Typography
              sx={{
                fontSize: 14,
                letterSpacing: '-0.56px',
                fontWeight: 700,
                color: '#202020'
              }}
            >
              상품정보
            </Typography>
          </Stack>

          <Stack
            sx={{
              width: '100%',
              gap: 1,
              borderBottom: '1px solid #D6D7DB',
              pb: 2.5
            }}
          >
            {productData?.productInfos?.map((item) => {
              const maxLabelWidth = Math.max(
                ...productData.productInfos.map((i) => i.infoField.length)
              );
              const maxSpecNameWidth = maxLabelWidth * 11;
              return (
                <Stack
                  key={`${item?.infoField}-${item?.infoText}`}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: 0.5,
                    alignItems: 'flex-start'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      letterSpacing: '-0.48px',
                      fontWeight: 500,
                      color: '#9A9CA4',
                      minWidth: `${maxSpecNameWidth}px`
                    }}
                  >
                    {item?.infoField}:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 12,
                      letterSpacing: '-0.48px',
                      fontWeight: 500,
                      textAlign: 'right',
                      whiteSpace: 'pre-line',
                      wordBreak: 'keep-all', // Prevent breaking words
                      overflowWrap: 'break-word', // Handle long words
                      color: '#202020'
                    }}
                  >
                    {item?.infoText}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Stack>

        {/* product detail */}
        <Stack sx={{ width: '100%', px: 2.5, pt: 2.5, gap: '4px' }}>
          <Stack sx={{ width: '100%' }}>
            <Typography
              sx={{
                fontSize: 14,
                letterSpacing: '-0.56px',
                fontWeight: 700,
                color: '#202020'
              }}
            >
              상세정보
            </Typography>
          </Stack>
          <Stack
            sx={{
              '& img': {
                maxWidth: '80%',
                objectFit: 'contain !important'
              },
              '& h1': {
                fontSize: 20,
                color: '#202020',
                letterSpacing: '-0.96px',
                fontWeight: 700
              },
              '& h2': {
                fontSize: 16,
                color: '#202020',
                letterSpacing: '-0.64px',
                fontWeight: 600
              },
              '& h3': {
                fontSize: 14,
                color: '#202020',
                letterSpacing: '-0.56px',
                fontWeight: 500
              },
              '& p': {
                fontSize: 12,
                color: '#202020',
                letterSpacing: '-0.48px',
                fontWeight: 500
              }
            }}
            dangerouslySetInnerHTML={{ __html: productData?.description }}
          />
        </Stack>
      </Stack>
      <Stack
        sx={{
          width: '100%',
          px: 2.5,
          py: '30px',
          maxWidth: '500px',
          bottom: 0,
          position: 'fixed',
          backgroundColor: '#FFFFFF',
          zIndex: 1000000
        }}
      >
        <Button
          sx={{
            width: '100%',
            height: 48,
            backgroundColor: '#1B2872 !important',
            borderRadius: 1,
            fontSize: 16,
            letterSpacing: '-0.64px',
            fontWeight: 500,
            color: '#FFFFFF !important'
          }}
          disabled={false}
          onClick={() => setOpenCart(true)}
        >
          구매하기
        </Button>
      </Stack>

      <AlertDialog
        isAlert
        open={alertDialog.open}
        setOpen={(open) => setAlertDialog({ ...alertDialog, open })}
        onOk={alertDialog?.onOk}
        okTitle={alertDialog?.okTitle || '취소'}
        title={alertDialog.title}
        titleStyle={{ textAlign: 'center' }}
        cancelTitle={alertDialog?.cancelTitle || '취소'}
        type={alertDialog?.type}
        subTitle={
          <Typography
            sx={{
              fontSize: 14,
              color: '#202020',
              fontWeight: 500,
              letterSpacing: '-0.56px',
              whiteSpace: 'pre-line',
              textAlign: 'center',
              mt: 1.5
            }}
          >
            {alertDialog?.subtitle}{' '}
          </Typography>
        }
        hasCancelButton
      />
    </Stack>
  );
};

export default ProductDetail;
