const env = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  s3BaseUrl: process.env.REACT_APP_AWS_S3_BASE_URL,
  ggMapAPIKey: process.env.REACT_APP_GG_MAP_API_KEY,
  kakaoKey: process.env.REACT_APP_KAKAO_APP_KEY,
  billingMid: process.env.REACT_APP_INICIS_MID_BILLING,
  paymentMid: process.env.REACT_APP_INICIS_MID_CARD,
  billingBaseUrl: process.env.REACT_APP_INICIS_BILLING_BASE_URL,
  paymentBaseUrl: process.env.REACT_APP_INICIS_PAYMENT_BASE_URL,
  devEnv: process.env.REACT_APP_DEV_ENV
};

export default env;
