import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

const PrivateRoute = () => {
  const isLoggedIn = useSelector((state) => state.auth.authenticated);
  return <Outlet />;
};

export default PrivateRoute;
