/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Hidden } from '@mui/material';

import useResponsive from '@hooks/useResponsive';
import { setAdminAuth } from '@store/reducers/pre-order/auth';
import authServices from '@services/auth';
import Content from './Content';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

const drawerWidth = 270;
const headerHeight = 80;

// [IMPORVE] reponsive and props
export default function PreOrderAdminLayout() {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSm = useResponsive('down', 600);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    await authServices.logout();
    localStorage.removeItem('PREORDER_ADMIN_TOKEN');
    localStorage.removeItem('PREORDER_ADMIN_REFRESH_TOKEN');
    dispatch(setAdminAuth(false));
    navigate('/preorder/ko/admin/auth');
  };

  useEffect(() => {
    setOpen(!isSm);
  }, [isSm]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar
        handleLogout={handleLogout}
        handleDrawerClose={handleDrawerClose}
        open={open}
        drawerWidth={drawerWidth}
        headerHeight={headerHeight}
        handleDrawerOpen={handleDrawerOpen}
      />
      <Box
        sx={(theme) => ({
          flexGrow: 1,
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
          }),
          marginLeft: isSm
            ? open
              ? `${drawerWidth + drawerWidth}px`
              : `0px`
            : `-${drawerWidth}px`,
          ...(open && {
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen
            }),
            marginLeft: 0
          }),
          overflowX: 'hidden'
        })}
      >
        <Header
          handleDrawerOpen={handleDrawerOpen}
          open={open}
          isSm={isSm}
          drawerWidth={drawerWidth}
        />
        <Content
          headerHeight={headerHeight}
          drawerWidth={drawerWidth}
          open={open}
        />
        <Hidden mdDown>
          <Footer open={open} drawerWidth={drawerWidth} isSm={isSm} />
        </Hidden>
      </Box>
    </Box>
  );
}
