import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Stack, Typography, Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import productService from '@services/preorder/product';
import mainImg from 'images/preorder/main_grey.png';
import env from '@constants/env';
import AlertDialog from 'pages-preorder/components/Modal/Alert/AlertDialog';
import { useDispatch } from 'react-redux';
import { setAlert } from '@store/reducers/pre-order/app';
import { formatMoney } from '@utils/helpers';
import { useTranslation } from 'react-i18next';

const CartPage = () => {
  const navigate = useNavigate();
  const [cartData, setCartData] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openCart, setOpenCart] = useState(false);
  const [storeCode, setStoreCode] = useState(null);
  const [dialog, setAlertDialog] = useState({
    open: false
  });
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const { version } = useParams();
  const debounceTimeout = useRef(null);
  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const rs = await productService.getCartData();
        if (!rs.error) {
          setStoreCode(rs?.data?.storeCode);
          setCartData(rs.data);

          if (rs?.data?.changed) {
            setAlertDialog({
              ...dialog,
              open: true,
              type: 'edit',
              hasCancelButton: false,
              title: null,
              okTitle: '확인',
              subtitle: t('pages.pre-order.alert.product-changed'),
              onOk: (open) => setAlertDialog({ ...dialog, open: false })
            });
          }
        }
      } catch (error) {
        console.error('Error fetching store data:', error);
      }
    };
    if (location?.state?.originalPath)
      localStorage.setItem(
        'PREORDER_CART_ORIGINAL_PATH',
        JSON.stringify(location.state?.originalPath)
      );
    fetchProductData();
  }, []);

  const handleQuantityChange = (index, change) => {
    setCartData((prevData) => {
      const updatedProducts = [...prevData.cartProducts];
      const newQuantity = Math.max(
        1,
        Math.min(999, updatedProducts[index].quantity + change)
      );
      updatedProducts[index] = {
        ...updatedProducts[index],
        quantity: newQuantity
      };

      const newCartData = { ...prevData, cartProducts: updatedProducts };

      debounceUpdateCart(newCartData);

      return newCartData;
    });
  };

  const debounceUpdateCart = useCallback((latestCartData) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(async () => {
      const updatedProducts = latestCartData.cartProducts.map((product) => ({
        productCode: product.productCode,
        quantity: product.quantity
      }));

      const payload = {
        storeCode: latestCartData?.storeCode,
        products: updatedProducts
      };

      try {
        await productService.updateProductQuantity(payload);
        console.log('Cart updated successfully');
      } catch (error) {
        console.error('Error updating cart:', error);
      }
    }, 500);
  }, []);

  const calculateTotalPrice = () => {
    if (!cartData || !cartData.cartProducts) return 0;
    return cartData.cartProducts.reduce((total, product) => {
      if (product.available) {
        return total + product.price * product.quantity;
      }
      return total;
    }, 0);
  };

  const canMakeOrder =
    cartData?.cartProducts?.filter((product) => !product.available).length ===
      0 || cartData?.cartProducts?.length === 0;

  const handleProductActions = async (action) => {
    const products = cartData?.cartProducts?.filter((i) => i?.available);
    navigate(`/pre-order/${version}/store/${cartData?.storeCode}/order`, {
      state: {
        cartData: { ...cartData, cartProducts: products, type: 'cart' },
        originalPath: location?.pathname
      }
    });
  };

  const handleDeleteProduct = async (item) => {
    const rs = await productService.deleteProductFromCart(item?.productCode);
    if (!rs?.error) {
      setCartData((prevData) => ({
        ...prevData,
        cartProducts: prevData.cartProducts.filter(
          (_, i) => _.productCode !== item?.productCode
        )
      }));
      setAlertDialog({ ...dialog, openDelete: false, title: '', onOk: null });
      dispatch(setAlert({ message: '삭제되었습니다.' }));
    }
  };

  const handleBulkDelete = async () => {
    const rs = await productService.deleteAllProductFromCart();
    if (!rs?.error) {
      setCartData((prevData) => ({
        ...prevData,
        cartProducts: []
      }));
      setAlertDialog({ ...dialog, open: false, title: '', onOk: null });
      dispatch(setAlert({ message: '삭제되었습니다.' }));
    }
  };

  const handleBackNavigation = () => {
    const savedPath = localStorage.getItem('PREORDER_CART_ORIGINAL_PATH');
    if (savedPath) {
      navigate(`${JSON.parse(savedPath)}`);
      localStorage.removeItem('PREORDER_CART_ORIGINAL_PATH');
    } else navigate(-1);
  };

  return (
    <Stack
      sx={{
        flexDirection: 'column',
        width: '100%',
        maxWidth: '500px',
        height: '100%',
        minHeight: '100vh',
        flexGrow: 1
      }}
    >
      <Stack
        sx={{
          width: '100%',
          maxWidth: '500px',
          top: 0,
          position: 'fixed',
          backgroundColor: '#FFFFFF',
          zIndex: 1002
        }}
      >
        <Stack
          id="navBarHeader"
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            backgroundColor: '#FFFFFF',
            width: '100%',
            position: 'relative',
            py: '13px',

            height: { xs: '50px' }
          }}
        >
          <Stack sx={{ position: 'absolute', left: '0px' }}>
            <IconButton
              size="small"
              sx={{
                p: 0,
                px: 2.5,
                py: 1,
                backgroundColor: 'transparent !important'
              }}
              onClick={() => handleBackNavigation()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path d="M0.999999 13L7 7L1 1" stroke="black" />
                <path d="M13 1L7 7L13 13" stroke="black" />
              </svg>
            </IconButton>
          </Stack>

          <Stack
            sx={{
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: 1,
              px: 2.5
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 700,
                color: '#202020',
                letterSpacing: '-0.56px'
              }}
            >
              장바구니
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {cartData?.cartProducts?.length > 0 && (
        <Stack
          sx={{
            width: '100%',
            marginTop: '50px',
            height: '60px',
            backgroundColor: '#FFFFFF'
          }}
        >
          <Stack
            sx={{
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              py: 2.5,
              px: 2.5
            }}
          >
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 1
              }}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  color: '#202020',
                  fontWeight: 700,
                  letterSpacing: '-0.56px'
                }}
              >
                검색결과 {cartData?.cartProducts?.length || 0}
              </Typography>
            </Stack>
            <Button
              size="small"
              onClick={() => {
                setAlertDialog({
                  ...dialog,
                  open: true,
                  title: null,
                  subtitle: '상품을 전체 삭제하시겠습니까?',
                  type: 'delete',

                  hasCancelButton: true,
                  okTitle: '전체 삭제',
                  onOk: () => handleBulkDelete()
                });
              }}
              disabled={cartData?.cartProducts?.length === 0}
              sx={{
                height: 24,
                borderRadius: '4px !important',
                fontSize: 12,
                letterSpacing: '-0.48px',
                fontWeight: 500,
                color: '#202020 !important',
                backgroundColor: '#F6F6F6 !important'
              }}
            >
              전체삭제
            </Button>
          </Stack>
        </Stack>
      )}

      {cartData?.cartProducts?.length < 1 && (
        <Stack
          sx={{
            width: '100%',
            position: 'relative',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#FFFFFF'
          }}
        >
          <Typography
            sx={{
              fontSize: 14,
              color: '#D6D7DB',
              fontWeight: 500,
              mb: 10,
              letterSpacing: '-0.56px'
            }}
          >
            장바구니에 담긴 상품이 없습니다.
          </Typography>
          <img
            src={mainImg}
            alt="preorder"
            style={{
              objectFit: 'contain',
              position: 'absolute',
              bottom: '30px'
            }}
          />
        </Stack>
      )}

      {cartData?.cartProducts?.length > 0 && (
        <Stack
          sx={{
            width: '100%',
            flexGrow: 1,
            backgroundColor: '#FFFFFF'
          }}
        >
          {cartData.cartProducts.map((item, index) => (
            <Stack
              key={`${item.productCode}-${item.productName}`}
              sx={{
                width: '100%',
                flexDirection: 'row',
                alignItems: 'flex-start',
                gap: 1,
                px: 2.5
              }}
            >
              <Stack
                sx={{
                  flexGrow: 1,
                  flexDirection: 'row',
                  py: 2.5,
                  borderTop: index === 0 && '1px solid #F2F2F2',
                  borderBottom: '1px solid #F2F2F2'
                }}
              >
                <Stack
                  sx={{
                    width: '100%',
                    flexDirection: 'row',
                    gap: 1.5,
                    justifyContent: 'flex-start'
                  }}
                >
                  <Stack
                    sx={{
                      width: '100px',
                      height: '100px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative'
                    }}
                  >
                    {!item?.available && (
                      <Stack
                        sx={{
                          position: 'absolute',

                          top: 0,
                          bottom: 0,
                          width: '100%',
                          height: '100%',
                          zIndex: 5,
                          textAlign: 'center',
                          alignItems: 'center',
                          justifyContent: 'center',
                          maxWidth: '100px',
                          maxHeight: '100px',
                          // backgroundColor: 'black',
                          background:
                            'linear-gradient(0deg, rgba(32, 32, 32, 0.70) 0%, rgba(32, 32, 32, 0.70) 100%)'
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 14,
                            letterSpacing: '-0.56px',
                            fontWeight: 500,
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            color: '#FFFFFF'
                          }}
                        >
                          구매불가
                        </Typography>
                      </Stack>
                    )}
                    <img
                      src={`${env.s3BaseUrl}/${item.imageUrl}`}
                      alt={item.productName}
                      style={{
                        width: '100px',

                        height: '100px',
                        objectFit: 'cover'
                      }}
                    />
                  </Stack>
                  <Stack
                    sx={{
                      gap: 1.5,
                      flexGrow: 1,

                      justifyContent: 'space-between'
                    }}
                  >
                    <Stack sx={{ gap: 0.5 }}>
                      <Stack
                        sx={{
                          flexDirection: 'row',
                          gap: 2,
                          justifyContent: 'space-between',
                          alignItems: 'flex-start'
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 14,
                            letterSpacing: '-0.56px',
                            fontWeight: 500,
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            color: '#202020'
                          }}
                        >
                          {item.productName}
                        </Typography>
                        <IconButton
                          sx={{
                            p: 0,
                            pt: '2px',
                            backgroundColor: 'transparent !important'
                          }}
                          onClick={() => {
                            setSelectedItem(item);
                            setAlertDialog({
                              ...dialog,
                              openDelete: true,
                              type: 'delete',
                              onOk: () => handleDeleteProduct(item),
                              subtitle: '상품을 삭제하시겠습니까?',
                              okTitle: null,
                              title: null
                            });
                          }}
                        >
                          {' '}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                          >
                            <path d="M0.999999 11L6 6L1 1" stroke="#D6D7DB" />
                            <path d="M11 1L6 6L11 11" stroke="#D6D7DB" />
                          </svg>{' '}
                        </IconButton>
                      </Stack>
                      <Typography
                        sx={{
                          fontSize: 14,
                          letterSpacing: '-0.56px',
                          fontWeight: 700,
                          color: '#1B2872'
                        }}
                      >
                        {formatMoney(item.price)}원
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{
                        width: '100%',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end'
                      }}
                    >
                      {item?.available ? (
                        <Stack
                          sx={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 1,
                            p: '4px 10px',
                            borderRadius: 0.5,
                            height: '30px',
                            backgroundColor: '#FFFFFF',
                            border: '1px solid #D6D7DB'
                          }}
                        >
                          <IconButton
                            size="small"
                            sx={{
                              backgroundColor: 'transparent !important',
                              px: 0
                            }}
                            onClick={() => handleQuantityChange(index, -1)}
                            disabled={item.quantity <= 1 || !item?.available}
                          >
                            <RemoveIcon
                              sx={{ color: '#202020', fontSize: 14 }}
                              fontSize="small"
                            />
                          </IconButton>
                          <Typography
                            sx={{
                              fontSize: 14,
                              letterSpacing: '-0.48px',
                              fontWeight: 500,
                              color: '#202020',
                              minWidth: '27px',
                              textAlign: 'center'
                            }}
                          >
                            {item.quantity}
                          </Typography>
                          <IconButton
                            size="small"
                            sx={{
                              backgroundColor: 'transparent !important',
                              px: 0
                            }}
                            onClick={() => handleQuantityChange(index, 1)}
                            disabled={item.quantity >= 999 || !item?.available}
                          >
                            <AddIcon sx={{ color: '#202020', fontSize: 14 }} />
                          </IconButton>
                        </Stack>
                      ) : (
                        <Typography
                          sx={{
                            fontSize: 14,
                            letterSpacing: '-0.48px',
                            fontWeight: 500,
                            color: '#9A9CA4',
                            textAlign: 'center',
                            minWidth: '20px'
                          }}
                        >
                          수량 {item.quantity}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          ))}

          <Stack
            sx={{
              width: '100%',
              px: 2.5
            }}
          >
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                py: 2.5,
                justifyContent: 'space-between',
                borderBottom: '1px solid #F2F2F2'
              }}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  letterSpacing: '-0.48px',
                  fontWeight: 500,
                  color: '#202020',
                  textAlign: 'center',
                  minWidth: '20px'
                }}
              >
                상품금액
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  letterSpacing: '-0.48px',
                  fontWeight: 500,
                  color: '#202020',
                  textAlign: 'center',
                  minWidth: '20px'
                }}
              >
                {formatMoney(calculateTotalPrice())}원
              </Typography>
            </Stack>
            <AlertDialog
              isAlert
              open={dialog.open}
              setOpen={(open) => setAlertDialog({ ...dialog, open: false })}
              onOk={dialog?.onOk}
              okTitle={dialog?.okTitle}
              title={dialog.title}
              type={dialog?.type}
              subTitle={
                <Typography
                  sx={{
                    fontSize: 14,
                    color: '#202020',
                    fontWeight: 500,
                    letterSpacing: '-0.56px',
                    whiteSpace: 'pre-line',
                    textAlign: 'center',
                    mt: 1.5
                  }}
                >
                  {' '}
                  {dialog?.subtitle}
                </Typography>
              }
              cancelTitle="아니오"
              hasCancelButton={dialog.hasCancelButton}
            />
            {selectedItem && (
              <AlertDialog
                isAlert
                open={dialog.openDelete}
                setOpen={(open) => {
                  setAlertDialog({ ...dialog, openDelete: false });
                  setSelectedItem(null);
                }}
                onOk={dialog?.onOk || handleDeleteProduct}
                okTitle={dialog?.okTitle || '삭제'}
                title={dialog.title}
                type="delete"
                subTitle={
                  (
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: '#202020',
                        fontWeight: 500,
                        letterSpacing: '-0.56px',
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                        mt: 1.5
                      }}
                    >
                      {' '}
                      {dialog?.subtitle}
                    </Typography>
                  ) || '상품을 삭제하시겠습니까?'
                }
                cancelTitle="아니오"
                hasCancelButton
              />
            )}
          </Stack>

          <Stack
            sx={{
              width: '100%',
              px: 2.5
            }}
          >
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                py: 2.5,
                justifyContent: 'space-between'
              }}
            >
              <Typography
                sx={{
                  fontSize: 20,
                  letterSpacing: '-0.8px',
                  fontWeight: 700,
                  color: '#202020',
                  textAlign: 'center',
                  minWidth: '20px'
                }}
              >
                총 결제금액
              </Typography>
              <Typography
                sx={{
                  fontSize: 20,
                  letterSpacing: '-0.8px',
                  fontWeight: 700,
                  color: '#1B2872',
                  textAlign: 'center',
                  minWidth: '20px'
                }}
              >
                {formatMoney(calculateTotalPrice())}원
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      )}

      {cartData?.cartProducts?.length > 0 && (
        <Stack
          sx={{
            width: '100%',
            px: 2.5,
            backgroundColor: '#FFFFFF'
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              pt: 2.5,
              mb: '108px',
              justifyContent: 'space-between'
            }}
          >
            <Typography
              sx={{
                fontSize: 10,
                letterSpacing: '-0.4px',
                fontWeight: 500,
                color: '#9A9CA4',
                textAlign: 'center'
              }}
            >
              장바구니에는 최대 99개의 상품을 담을 수 있으며, 상품은 최대 90일
              동안 보관됩니다.
            </Typography>
          </Stack>
        </Stack>
      )}
      {cartData?.cartProducts?.length > 0 && (
        <Stack
          sx={{
            width: '100%',
            px: 2.5,
            py: '30px',
            maxWidth: '500px',
            bottom: 0,
            position: 'fixed',
            backgroundColor: '#FFFFFF',
            zIndex: 1002
          }}
        >
          <Stack sx={{ width: '100%', flexDirection: 'row', gap: 1.5 }}>
            <Button
              sx={{
                maxWidth: 'fit-content',
                px: 3,
                height: 48,
                backgroundColor: '#FFFFFF !important',
                border: '1px solid #D6D7DB !important',
                fontSize: 16,
                letterSpacing: '-0.64px',
                fontWeight: 500,
                borderRadius: 1,
                color: '#9A9CA4 !important'
              }}
              onClick={() => handleBackNavigation()}
            >
              더 담기
            </Button>
            <Button
              sx={{
                flexGrow: 1,
                height: 48,
                fontSize: 16,
                letterSpacing: '-0.64px',
                fontWeight: 500,
                backgroundColor: canMakeOrder
                  ? '#1B2872 !important'
                  : '#DADBDD !important',
                borderRadius: 1,
                color: '#FFFFFF !important'
              }}
              disabled={!canMakeOrder}
              onClick={() => handleProductActions()}
            >
              주문하기
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default CartPage;
