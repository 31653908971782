import RestService from '../PreOrderApi';

const authService = {
  async getTermsAndPolicy(params = {}) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/verification/terms-policies`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async findID(params = {}) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/member/email`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getPaymentHistory(id) {
    try {
      const res = await new RestService()
        .setPath(`client-admin/member/${id}/payment-history`)
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async searchClient(params = {}) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/verification/available-minibars`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async registerNewMemeber(data) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/member`)
        .post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async login(data) {
    try {
      const res = await new RestService()
        .setPath('pre-order/auth/login')
        .post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async resetPassword(data) {
    try {
      const res = await new RestService()
        .setPath('pre-order/member/password')
        .put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async logout() {
    try {
      const res = await new RestService()
        .setPath('client-admin/auth/logout')
        .post();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async changePassword(data) {
    try {
      const res = await new RestService()
        .setPath(`client-admin/info`)
        .put(data);
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default authService;
