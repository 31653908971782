import React, { forwardRef } from 'react';
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  TextField
} from '@mui/material';
import { ReactComponent as ArrowDownBoldIcon } from '@svg/arrow-down-bold.svg';
import { useTranslation } from 'react-i18next';

const MyAutocomplete = forwardRef(
  (
    {
      label,
      textFieldProps,
      required,
      sx,
      errMg,
      fullWidth = false,
      labelStyle,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation();

    if (!label)
      return (
        <Autocomplete
          size="small"
          sx={{
            width: 250,
            '.MuiInputBase-root': { bgcolor: 'common.white' },
            ...sx
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={errMg}
              inputRef={ref}
              {...textFieldProps}
              helperText={errMg && `* ${errMg}`}
            />
          )}
          slotProps={{
            popupIndicator: { sx: { width: '24px', height: '24px' } }
          }}
          popupIcon={<ArrowDownBoldIcon />}
          ref={ref}
          {...rest}
        />
      );
    return (
      <FormControl
        sx={{
          flexDirection: 'row',
          ...(fullWidth && { width: fullWidth && '100%' })
        }}
      >
        <FormControlLabel
          label={
            <span>
              {label} <span style={{ color: 'red' }}>{required && '*'}</span>
            </span>
          }
          labelPlacement="start"
          sx={{
            mx: { xs: fullWidth && 0 },
            ...(fullWidth && { width: fullWidth && '100%' }),
            '.MuiTypography-root': {
              color: '#6F869C',
              fontWeight: 'fontWeightBold',
              fontSize: 13,
              whiteSpace: 'nowrap',
              ...labelStyle
            }
          }}
          control={
            <Autocomplete
              disablePortal
              size="small"
              sx={{
                width: 300,
                ml: 1,
                '.MuiInputBase-root': { bgcolor: 'common.white' },
                ...sx
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errMg}
                  aria-readonly
                  {...textFieldProps}
                  helperText={errMg && `* ${errMg}`}
                />
              )}
              slotProps={{
                popupIndicator: { sx: { width: '24px', height: '24px' } }
              }}
              popupIcon={<ArrowDownBoldIcon />}
              noOptionsText={t('common.noOptions')}
              ref={ref}
              {...rest}
            />
          }
        />
      </FormControl>
    );
  }
);

export default MyAutocomplete;
