import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PreorderAdminPrivateRoute = () => {
  const isLoggedIn = useSelector(
    (state) => state.preOrder.auth.authenticatedAdmin
  );
  const { pathname } = useLocation();
  return isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate
      to="/pre-order/ko/admin/auth"
      state={{ originalPath: pathname }}
    />
  );
};

export default PreorderAdminPrivateRoute;
