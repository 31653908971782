import RestService from '../PreOrderApi';

const paymentService = {
  async getPaymentCardList(params = {}) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/payment/inicis/cards`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async requestIncisPaymentDetails(orderCode) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/order/inicis/${orderCode}/purchase-card`)
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async registerCard() {
    try {
      const res = await new RestService()
        .setPath(`pre-order/payment/inicis/card-registration`)
        .post();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async requestDirectPayment(orderCode) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/order/inicis/${orderCode}/purchase-billing`)
        .post();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async updateCardStatus(data) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/payment/inicis/default-card`)
        .put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteCard(paymentInfoCode) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/payment/inicis/card/${paymentInfoCode}`)
        .delete();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default paymentService;
