/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Stack, Typography, Button, IconButton } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AlertDialog from 'pages-preorder/components/Modal/Alert/AlertDialog';

import env from '@constants/env';
import paymentService from '@services/preorder/paymentService';
import { useTranslation } from 'react-i18next';
import orderService from '@services/preorder/order';
import { formatCardNumber, formatMoney } from '@utils/helpers';
import MyTextField from '@components/inputs/MyTextField';
import error2Text from '@utils/error2Text';
import NotFound from 'pages-preorder/components/NotFound';
import { Formatter } from '@utils/formatter';
import { days } from 'pages-preorder/orders/enhance';
import productService from '@services/preorder/product';
import { useDispatch } from 'react-redux';
import { setAlert } from '@store/reducers/pre-order/app';
import OrderAccordion from './components/OrderAccordion';

const OrderConfirmPage = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { storeCode, version, orderId } = useParams();

  const [orderData, setOrderData] = useState({
    paymentType: null,
    preferedDate: '',
    message: ''
  });
  const [alertDialog, setAlertDialog] = useState({
    open: false
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getOrderDetail = async () => {
    const rs = await productService?.getOrderDetail(orderId);
    if (rs?.data) {
      if (rs?.data?.paymentStatus === 'FAILED') {
        const deleteOrderRs = await productService.deleteOrder(orderId);
        navigate(`/pre-order/${version}/store/${storeCode}/order`, {
          state: {
            cartData: { ...orderData }
          }
        });
        return;
      }

      setOrderData({ ...orderData, ...rs.data });
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    if (orderId) {
      getOrderDetail();
    }
  }, [location.search]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [orderData]);

  const getBaseUrl = () => {
    const { protocol, host } = window.location;
    return `${protocol}//${host}`;
  };

  const getRedirectPath = (path) => {
    const baseUrl = getBaseUrl();
    return `${baseUrl}/pre-order/${version}/store/${storeCode}${path}`;
  };

  const refreshProductDetails = async () => {
    const dataSumit = {
      productCodes: orderData?.products?.map((i) => i.productCode)
    };
    const rs = await productService.updateProductInfo(dataSumit);
    if (!rs?.error) {
      setOrderData({ ...orderData, products: rs?.data });
    }
  };

  const handlePayment = async () => {
    let rs;

    switch (orderData?.paymentMethod) {
      case 'INICIS_BILLING':
        rs = await paymentService.requestDirectPayment(orderId);

        break;

      default:
        rs = await paymentService.requestIncisPaymentDetails(orderId);
        break;
    }

    if (!rs?.error) {
      if (orderData?.paymentMethod === 'INICIS_BILLING') {
        // success state
        if (rs?.data) {
          localStorage.removeItem('PREORDER_NEW_ORDER_DATA');
          localStorage.removeItem('PREORDER_ORDER_ORIGINAL_PATH');
          navigate(
            `/pre-order/${version}/store/${storeCode}/order/${orderId}/receipt`,
            {
              state: {
                cartData: { ...orderData }
              }
            }
          );
        }
      } else {
        const redirectUrls = {
          redirectToWhenSuccess: getRedirectPath(`/order/${orderId}/receipt`),
          redirectToWhenFail: getRedirectPath(`/order/${orderId}`),
          redirectToWhenCancel: getRedirectPath(`/order/${orderId}`)
        };
        const incisFormData = {
          P_INI_PAYMENT: 'CARD',
          P_MID: rs?.data?.pmid,
          P_OID: rs?.data?.poid,
          P_AMT: rs?.data?.pamt,
          P_GOODS: rs?.data?.pgoods,
          P_UNAME: rs?.data?.puname,

          P_NEXT_URL: `${rs?.data?.pnextUrl}/${orderId}`,
          P_NOTI_UR: rs?.data?.pnotiUrl,
          P_HPP_METHOD: 1,
          P_NOTI: JSON.stringify(redirectUrls),
          P_CHARSET: 'utf8'
        };
        const form = document.createElement('form');
        form.method = 'POST';
        form.acceptCharset = 'EUC-KR';
        form.id = 'pay_form';

        form.action = env.paymentBaseUrl;

        for (let key in incisFormData) {
          const input = document.createElement('input');
          input.name = key;
          input.hidden = true;
          input.value = incisFormData[key];
          form.appendChild(input);
        }

        document.querySelector('#checkout-page').appendChild(form);
        form.submit();
      }
    } else {
      if (rs?.error.keyError === 'PREORDER_ORDER_PRODUCT_INFO_CHANGED') {
        setAlertDialog({
          open: true,
          okTitle: '확인',
          onOk: () => refreshProductDetails(),
          subtitle: t('pages.pre-order.alert.product-changed')
        });
      }
      dispatch(setAlert({ message: error2Text(rs?.error)?.content }));
    }
  };
  const calculateTotalPrice = () => {
    if (!orderData || !orderData.products) return 0;
    return orderData?.products.reduce((total, product) => {
      return total + product.price * product.quantity;
    }, 0);
  };

  const handleDeleteOrder = async () => {
    if (!orderId) return;
    const rs = await orderService.deleteOrder(orderId);
    if (rs?.error) console.log('error', rs?.error);
  };

  if (orderData?.paymentStatus === 'PAID') {
    return <NotFound />;
  }
  return (
    <Stack
      sx={{
        flexDirection: 'column',
        width: '100%',
        maxWidth: '500px',
        height: '100%',
        minHeight: '100svh',
        flexGrow: 1
      }}
    >
      <Stack
        sx={{
          width: '100%',
          maxWidth: '500px',
          top: 0,
          position: 'fixed',
          backgroundColor: '#FFFFFF',
          zIndex: 1002
        }}
      >
        <Stack
          id="navBarHeader"
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            backgroundColor: '#FFFFFF',
            width: '100%',
            py: '13px',
            position: 'relative',
            px: 2.5,
            height: { xs: '50px' }
          }}
        >
          <Stack sx={{ position: 'absolute', left: '20px' }}>
            <IconButton
              size="small"
              sx={{ backgroundColor: 'transparent !important' }}
              onClick={() => {
                handleDeleteOrder();
                navigate(`/pre-order/${version}/store/${storeCode}/order`);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
              >
                <path d="M7 1L1 7L7 13" stroke="black" />
              </svg>
            </IconButton>
          </Stack>

          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: 1
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 700,
                color: '#202020',
                letterSpacing: '-0.56px'
              }}
            >
              주문 확인
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        sx={{
          width: '100%',
          marginTop: '50px',
          height: '60px',
          backgroundColor: '#FFFFFF'
        }}
      >
        <Stack
          sx={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            py: 2.5,
            px: 2.5,
            backgroundColor: '#E9EBF6'
          }}
        >
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 1
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                color: '#202020',
                fontWeight: 700,
                letterSpacing: '-0.56px'
              }}
            >
              {orderData?.pickupLocationName}
            </Typography>

            <Stack
              sx={{
                backgroundColor: '#1B2872',
                borderRadius: 1,
                height: '18px',
                justifyContent: 'center',
                alignItems: 'center',
                p: '4px 8px'
              }}
            >
              {' '}
              <Typography
                sx={{
                  fontSize: 10,
                  color: '#FFFFFF',
                  fontWeight: 500,
                  letterSpacing: '-0.4px'
                }}
              >
                픽업 장소
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {/* store info */}
      <Stack
        sx={{ width: '100%', px: 2.5, py: 2.5, backgroundColor: '#FFFFFF' }}
        id="checkout-page"
      >
        <OrderAccordion data={{ title: '상점정보' }} defaultExpanded>
          {orderData && (
            <Stack
              sx={{
                width: '100%',
                flexGrow: 1,
                backgroundColor: '#FFFFFF',
                textAlign: 'left'
              }}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  letterSpacing: '-0.56px',
                  fontWeight: 700,
                  color: '#202020'
                }}
              >
                {orderData?.storeName}
              </Typography>
              <Stack
                sx={{
                  width: '100%',
                  pt: 2.5,
                  gap: 1.5
                }}
              >
                <Stack
                  sx={{
                    flexGrow: 1,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      letterSpacing: '-0.48px',
                      fontWeight: 500,
                      color: '#9A9CA4'
                    }}
                  >
                    주소
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 12,
                      letterSpacing: '-0.48px',
                      fontWeight: 500,
                      color: '#202020'
                    }}
                  >
                    {orderData?.storeAddress}
                  </Typography>
                </Stack>

                {/* businesshours */}
                {orderData?.businessHour?.length > 0 && (
                  <Stack
                    sx={{
                      flexGrow: 1,
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        letterSpacing: '-0.48px',
                        fontWeight: 500,
                        color: '#9A9CA4'
                      }}
                    >
                      영업시간
                    </Typography>
                    <Stack sx={{ gap: 0.5, justifyContent: 'flex-end' }}>
                      {days.map((item) => {
                        const workingDay = orderData?.businessHour?.find(
                          (i) => i?.dayOfWeek === item?.value
                        );
                        console?.log('working day', workingDay);
                        return (
                          <Stack
                            key={`${item?.value}-${item?.label}`}
                            sx={{
                              flexDirection: 'row',
                              gap: 1.5,
                              justifyContent: 'flex-end'
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: 12,
                                fontWeight: 500,
                                color: '#202020',
                                letterSpacing: '-0.48px'
                              }}
                            >
                              {item?.label}
                            </Typography>
                            {!workingDay ? (
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  fontWeight: 500,
                                  color: '#F25D5D',
                                  letterSpacing: '-0.48px',
                                  minWidth: '69px',
                                  textAlign: 'right'
                                }}
                              >
                                휴무
                              </Typography>
                            ) : (
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  fontWeight: 500,
                                  color: '#202020',
                                  letterSpacing: '-0.48px',
                                  minWidth: '69px',
                                  textAlign: 'right'
                                }}
                              >
                                {Formatter.formatTime2(workingDay?.openAt)} -{' '}
                                {Formatter.formatTime2(workingDay?.closeAt)}
                              </Typography>
                            )}
                          </Stack>
                        );
                      })}
                    </Stack>
                  </Stack>
                )}
                <Stack
                  sx={{
                    flexGrow: 1,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      letterSpacing: '-0.48px',
                      fontWeight: 500,
                      color: '#9A9CA4'
                    }}
                  >
                    전화번호
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 12,
                      letterSpacing: '-0.48px',
                      fontWeight: 500,
                      color: '#202020'
                    }}
                  >
                    {Formatter.formatKoreanPhoneNumber(orderData?.storeContact)}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )}
        </OrderAccordion>
      </Stack>
      <Stack sx={{ width: '100%', height: '5px', backgroundColor: '#F2F2F2' }}>
        <Stack
          sx={{ width: '100%', height: '1px', backgroundColor: '#E7E7E7' }}
        />
      </Stack>

      {/* product info */}
      <Stack
        sx={{ width: '100%', px: 2.5, py: 2.5, backgroundColor: '#FFFFFF' }}
      >
        <OrderAccordion data={{ title: '주문 상품' }} defaultExpanded>
          {orderData && (
            <Stack
              sx={{
                width: '100%',
                flexGrow: 1,
                backgroundColor: '#FFFFFF'
              }}
            >
              {orderData?.products?.map((item, index) => (
                <Stack
                  key={`${item.productCode}-${item.productName}`}
                  sx={{
                    width: '100%',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    gap: 1,
                    pt: index !== 0 && 2.5,
                    pb: orderData.products.length - 1 === index ? 1 : 2.5,
                    borderBottom:
                      orderData.products.length - 1 !== index &&
                      '1px solid #F2F2F2'
                  }}
                >
                  <Stack sx={{ flexGrow: 1, flexDirection: 'row' }}>
                    <Stack
                      sx={{
                        width: '100%',
                        flexDirection: 'row',
                        gap: 1.5,
                        justifyContent: 'flex-start'
                      }}
                    >
                      <Stack
                        sx={{
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <img
                          src={`${env.s3BaseUrl}/${item.imageUrl}`}
                          alt={item.productName}
                          style={{
                            width: '100px',

                            height: '100px',
                            objectFit: 'cover'
                          }}
                        />
                      </Stack>
                      <Stack
                        sx={{
                          gap: 1.5,
                          flexGrow: 1,

                          justifyContent: 'space-between'
                        }}
                      >
                        <Stack sx={{ gap: 0.5 }}>
                          <Stack
                            sx={{
                              flexDirection: 'row',
                              gap: 1,
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: 14,
                                letterSpacing: '-0.56px',
                                fontWeight: 500,
                                color: '#202020',
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2
                              }}
                            >
                              {item.productName}
                            </Typography>
                          </Stack>
                          <Typography
                            sx={{
                              fontSize: 14,
                              letterSpacing: '-0.56px',
                              fontWeight: 700,
                              color: '#1B2872'
                            }}
                          >
                            {formatMoney(item.price)}원
                          </Typography>
                        </Stack>
                        <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
                          <Stack
                            sx={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'center',

                              backgroundColor: '#FFFFFF'
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: 14,
                                letterSpacing: '-0.48px',
                                fontWeight: 500,
                                color: '#9A9CA4',
                                textAlign: 'center',
                                minWidth: '20px'
                              }}
                            >
                              수량 {item.quantity}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          )}
        </OrderAccordion>
      </Stack>

      {/* divider */}
      <Stack sx={{ width: '100%', height: '5px', backgroundColor: '#F2F2F2' }}>
        <Stack
          sx={{ width: '100%', height: '1px', backgroundColor: '#E7E7E7' }}
        />
      </Stack>
      <Stack
        sx={{ width: '100%', py: 2.5, px: 2.5, backgroundColor: '#FFFFFF' }}
      >
        {/* title */}
        <Stack
          sx={{
            width: '100%',
            textAlign: 'left',
            pb: 1.5,
            borderBottom: '1px solid #F2F2F2'
          }}
        >
          <Typography
            sx={{
              color: '#202020',
              fontSize: { xs: 14 },
              fontWeight: 700,
              letterSpacing: '-0.56px'
            }}
          >
            희망 픽업 일자
          </Typography>
        </Stack>

        {/* calendar */}
        <Stack sx={{ flexDirection: 'column', gap: 0.5, mt: 2.5 }}>
          <Stack sx={{ flexDirection: 'row', alignItems: 'flex-end' }}>
            <MyTextField
              preorder
              fullWidth
              value={Formatter.formatTime(
                orderData?.pickupDateTime,
                'YYYY-MM-DD'
              )}
              disabled
            />
          </Stack>
        </Stack>
      </Stack>

      {/* divider */}
      <Stack sx={{ width: '100%', height: '5px', backgroundColor: '#F2F2F2' }}>
        <Stack
          sx={{ width: '100%', height: '1px', backgroundColor: '#E7E7E7' }}
        />
      </Stack>

      {/* message */}
      <Stack
        sx={{ width: '100%', py: 2.5, px: 2.5, backgroundColor: '#FFFFFF' }}
      >
        {/* title */}
        <Stack
          sx={{
            width: '100%',
            textAlign: 'left',
            pb: 1.5,
            borderBottom: '1px solid #F2F2F2'
          }}
        >
          <Typography
            sx={{
              color: '#202020',
              fontSize: { xs: 14 },
              fontWeight: 700,
              letterSpacing: '-0.56px'
            }}
          >
            상점 요청사항(선택)
          </Typography>
        </Stack>

        {/* calendar */}
        <Stack sx={{ flexDirection: 'column', gap: 0.5, mt: 2.5 }}>
          <Stack sx={{ flexDirection: 'row', alignItems: 'flex-end' }}>
            <MyTextField
              preorder
              fullWidth
              disabled
              sx={{ p: 0 }}
              value={orderData?.customerRequest}
              multiline
              minRows={2}
              maxRows={4}
            />
          </Stack>
        </Stack>
      </Stack>

      {/* divider */}
      <Stack sx={{ width: '100%', height: '5px', backgroundColor: '#F2F2F2' }}>
        <Stack
          sx={{ width: '100%', height: '1px', backgroundColor: '#E7E7E7' }}
        />
      </Stack>

      {/* payment method */}
      <Stack
        sx={{ width: '100%', py: 2.5, px: 2.5, backgroundColor: '#FFFFFF' }}
      >
        {/* title */}
        <Stack
          sx={{
            width: '100%',
            textAlign: 'left',
            pb: 1.5,
            borderBottom: '1px solid #F2F2F2'
          }}
        >
          <Typography
            sx={{
              color: '#202020',
              fontSize: { xs: 14 },
              fontWeight: 700,
              letterSpacing: '-0.56px'
            }}
          >
            결제수단
          </Typography>
        </Stack>

        {/* cards */}
        <Stack sx={{ flexDirection: 'column', gap: 0.5, mt: 2.5 }}>
          {orderData?.paymentMethod === 'INICIS_BILLING' &&
          orderData?.paymentCardNumber ? (
            <Stack sx={{ width: '100%' }}>
              {/* card content */}
              <Stack sx={{ width: '100%', gap: 1 }}>
                <Stack
                  sx={{
                    width: '100%',
                    borderRadius: 1,
                    overflow: 'hidden',
                    border: '1px solid #D6D7DB'
                  }}
                >
                  {/* card header */}
                  <Stack
                    sx={{
                      width: '100%',
                      px: 1.5,
                      py: 2,
                      backgroundColor: '#F6F6F6'
                    }}
                  >
                    <Stack
                      sx={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: 0.5
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 14,
                          letterSpacing: '-0.56px',
                          fontWeight: 500,
                          color: '#202020'
                        }}
                      >
                        {orderData?.paymentCardIssuer}
                      </Typography>
                      <Stack
                        sx={{
                          backgroundColor: '#9B9B9B',
                          height: '18px',
                          alignItems: 'center',
                          justifyContent: 'center',

                          borderRadius: 1,
                          p: '4px 8px'
                        }}
                      >
                        {' '}
                        <Typography
                          sx={{
                            fontSize: 10,
                            color: '#FFFFFF',
                            fontWeight: 500,
                            letterSpacing: '-0.4px'
                          }}
                        >
                          기본 결제카드
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  {/* card body */}
                  <Stack
                    sx={{
                      width: '100%',
                      px: 1.5,
                      py: 2,
                      backgroundColor: '#FFFFFF'
                    }}
                  >
                    <Stack
                      sx={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: 0.5
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 14,
                          letterSpacing: '-0.56px',
                          fontWeight: 500,
                          color: '#202020'
                        }}
                      >
                        {formatCardNumber(orderData?.paymentCardNumber)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                >
                  <Typography
                    sx={{
                      color: '#9A9CA4',
                      fontSize: { xs: 12 },
                      fontWeight: 500,
                      letterSpacing: '-0.48px'
                    }}
                  >
                    *카드 간편결제 선택 시, 등록된 카드로 결제금액이 자동결제
                    됩니다.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <Stack sx={{ width: '100%', gap: 1.5 }}>
              {/* card content */}
              <Stack sx={{ width: '100%', gap: 1 }}>
                <Stack
                  sx={{
                    width: '100%',
                    borderRadius: 1,
                    overflow: 'hidden'
                  }}
                >
                  <Button
                    sx={{
                      maxWidth: 'fit-content',
                      minWidth: '165px',
                      height: 40,
                      backgroundColor: '#F6F6F6 !important',
                      borderRadius: 1,
                      fontSize: 14,
                      fontWeight: 500,
                      border:
                        orderData?.paymentMethod === 'inicis'
                          ? 0
                          : '1px solid   #D6D7DB !important',
                      letterSpacing: '-0.56px',
                      color: '#202020 !important'
                    }}
                    disabled
                  >
                    신용/체크카드
                  </Button>
                </Stack>
                <Stack
                  sx={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                >
                  <Typography
                    sx={{
                      color: '#9A9CA4',
                      fontSize: { xs: 12 },
                      fontWeight: 500,
                      letterSpacing: '-0.48px'
                    }}
                  >
                    *카드 간편결제 선택 시, 등록된 카드로 결제금액이 자동결제
                    됩니다.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>

      {/* divider */}
      <Stack sx={{ width: '100%', height: '5px', backgroundColor: '#F2F2F2' }}>
        <Stack
          sx={{ width: '100%', height: '1px', backgroundColor: '#E7E7E7' }}
        />
      </Stack>
      <Stack
        sx={{
          width: '100%',
          px: 2.5,
          py: 2.5,
          pb: '128px',
          backgroundColor: '#FFFFFF',
          borderBottom: '1px solid #E7E7E7'
        }}
      >
        <Stack
          sx={{ pb: 2, textAlign: 'left', borderBottom: '1px solid #F2F2F2' }}
        >
          <Typography
            sx={{
              fontSize: 14,
              letterSpacing: '-0.56px',
              fontWeight: 700,
              color: '#202020'
            }}
          >
            결제 금액
          </Typography>
        </Stack>
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            py: 2.5,
            justifyContent: 'space-between',
            borderBottom: '1px solid #F2F2F2'
          }}
        >
          <Typography
            sx={{
              fontSize: 14,
              letterSpacing: '-0.48px',
              fontWeight: 500,
              color: '#202020',
              textAlign: 'center',
              minWidth: '20px'
            }}
          >
            상품금액
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              letterSpacing: '-0.48px',
              fontWeight: 500,
              color: '#202020',
              textAlign: 'center',
              minWidth: '20px'
            }}
          >
            {formatMoney(calculateTotalPrice())}원
          </Typography>
        </Stack>

        <Stack
          sx={{
            width: '100%'
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              pt: 1.5,
              justifyContent: 'space-between'
            }}
          >
            <Typography
              sx={{
                fontSize: 20,
                letterSpacing: '-0.8px',
                fontWeight: 700,
                color: '#202020',
                textAlign: 'center',
                minWidth: '20px'
              }}
            >
              최종 결제 금액
            </Typography>
            <Typography
              sx={{
                fontSize: 20,
                letterSpacing: '-0.8px',
                fontWeight: 700,
                color: '#1B2872',
                textAlign: 'center',
                minWidth: '20px'
              }}
            >
              {formatMoney(calculateTotalPrice())}원
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <AlertDialog
        isAlert
        open={alertDialog.open}
        setOpen={(open) => setAlertDialog({ ...alertDialog, open })}
        onOk={alertDialog?.onOk}
        okTitle={alertDialog?.okTitle || '확인'}
        title={alertDialog.title}
        titleStyle={{ textAlign: 'center' }}
        type={alertDialog?.type}
        subTitle={
          <Typography
            sx={{
              fontSize: 14,
              color: '#202020',
              fontWeight: 500,
              letterSpacing: '-0.56px',
              whiteSpace: 'pre-line',
              textAlign: 'center',
              mt: 1.5
            }}
          >
            {alertDialog?.subtitle}{' '}
          </Typography>
        }
      />
      {orderData && (
        <Stack
          sx={{
            width: '100%',
            px: 2.5,
            py: '30px',
            maxWidth: '500px',
            bottom: 0,
            position: 'fixed',
            backgroundColor: '#F2F2F2',
            zIndex: 1002
          }}
        >
          <Stack sx={{ width: '100%', flexDirection: 'row', gap: 1.5 }}>
            <Button
              sx={{
                flexGrow: 1,
                height: 48,
                backgroundColor: '#1B2872 !important',
                borderRadius: 1,
                fontSize: 16,
                letterSpacing: '-0.64px',
                fontWeight: 500,
                color: '#FFFFFF !important'
              }}
              disabled={
                !orderData?.paymentMethod ||
                calculateTotalPrice() === 0 ||
                orderData?.products?.length < 1
              }
              onClick={() => handlePayment()}
            >
              결제하기
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default OrderConfirmPage;
