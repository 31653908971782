import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const PreorderPrivateRoute = () => {
  const isLoggedIn = useSelector((state) => state.preOrder.auth.authenticated);
  return isLoggedIn ? <Outlet /> : <Navigate to="/pre-order/ko/auth" />;
};

export default PreorderPrivateRoute;
