import * as React from 'react';
import PropTypes from 'prop-types';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import useResponsive from '@hooks/useResponsive';

function MyBottomSwiper(props) {
  const {
    window,
    open,
    setOpen,
    hasCloseButton = true,
    children,
    title = undefined,
    subTitle,
    sx
  } = props;

  const [contentHeight, setContentHeight] = React.useState('auto');
  const contentRef = React.useRef(null);
  const isMd = useResponsive('up', 500);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      minFlingVelocity={100}
      allowSwipeInChildren
      sx={{
        '& .MuiDrawer-paper': {
          visibility: open ? 'visible' : 'hidden',
          bottom: open ? 0 : '-100%'
        },
        '& .MuiBackdrop-root': {
          visibility: open ? 'visible' : 'hidden'
        },
        bottom: 0,
        transform: 'translateX(-50% ,50%)',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
      }}
      SlideProps={{
        easing: { exit: 'cubic-bezier(0.0, 0, 0, 0)' }
      }}
      transitionDuration={{ enter: 300, exit: 300 }}
      PaperProps={{
        sx: {
          minHeight: contentHeight,
          width: '100%',
          maxWidth: '500px !important',

          pt: 5,
          pb: '30px',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          position: 'fixed',
          ...(isMd && {
            left: '50%',
            transform: 'translateX(-50%)'
          })
        }
      }}
      ModalProps={{
        keepMounted: false // Changed to false to remove from DOM when closed
      }}
      disableSwipeToOpen // Prevent accidental opens
    >
      {hasCloseButton && (
        <IconButton
          onClick={() => setOpen(false)}
          sx={{
            position: 'absolute',
            top: 20,
            right: 20,
            p: 0,
            backgroundColor: 'transparent !important',
            zIndex: 10000
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <circle cx="8" cy="8" r="8" fill="#DADBDD" />
            <path d="M4 12L8 8L4 4" stroke="white" />
            <path d="M12 4L8 8L12 12" stroke="white" />
          </svg>
        </IconButton>
      )}
      {title && (
        <DialogTitle
          sx={{
            color: '#202020',
            fontSize: '24px !important',
            fontWeight: '700 !important',
            textAlign: 'left',
            backgroundColor: '#FFFFFF',
            zIndex: 30,
            letterSpacing: '-0.96px',
            pt: 1,
            pb: 2.5,
            px: 2.5,
            whiteSpace: { xs: 'pre-line', md: 'nowrap' },
            overflow: 'hidden',
            '$ span': { color: '#244EE2' }
          }}
        >
          {title}
          {subTitle && typeof subTitle === 'function' ? (
            subTitle
          ) : (
            <Typography
              sx={{
                fontSize: 14,
                color: '#919193',
                fontWeight: 500,
                whiteSpace: { xs: 'pre-line', md: 'nowrap' }
              }}
            >
              {subTitle}
            </Typography>
          )}
        </DialogTitle>
      )}
      <Stack
        ref={contentRef}
        id="paper-container"
        sx={{
          ...sx
        }}
      >
        {children}
      </Stack>
    </SwipeableDrawer>
  );
}

MyBottomSwiper.propTypes = {
  window: PropTypes.func
};

export default MyBottomSwiper;
