import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Stack,
  Typography,
  CircularProgress,
  Skeleton
} from '@mui/material';
import 'swiper/css';
import 'swiper/css/free-mode';
import { makeStyles } from '@mui/styles';
import productService from '@services/preorder/product';
import { ignoreEmpty, objToParams } from '@utils/helpers';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

const useStyles = makeStyles({
  mySwiper: {
    height: '100%',
    width: '100%',

    '& .swiper-wrapper': {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start'
    }
  },
  mySwiperSlide: {
    width: 'fit-content', // Allow slides to adapt to content width
    height: '100%'
  }
});

const MenuStores = ({ currentStore, setCurrentStore, setOpen }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedStoreType, setSelectedStoreType] = useState(null);
  const [selectedStore, setSelectedStore] = useState(currentStore);
  const [storeTypeList, setStoreTypeList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [loadingStoreTypes, setLoadingStoreTypes] = useState(true);
  const [loadingStores, setLoadingStores] = useState(false);

  const fetchStoreTypeList = useCallback(async () => {
    setLoadingStoreTypes(true);
    try {
      const rs = await productService.getStoreTypeList();
      if (!rs.error) {
        setStoreTypeList(rs?.data || []);
        if (rs?.data?.length > 0) {
          if (currentStore?.storeTypeCode) {
            const currentStoreType = rs?.data?.find(
              (i) => i?.storeTypeCode === currentStore?.storeTypeCode
            );
            setSelectedStoreType(currentStoreType);
          } else setSelectedStoreType(rs.data[0]);
        }
      }
    } catch (error) {
      console.error('Error fetching store type list:', error);
    } finally {
      setLoadingStoreTypes(false);
    }
  }, []);

  const fetchStoreList = useCallback(async (storeTypeCode) => {
    setLoadingStores(true);
    try {
      const rs = await productService.getStoreList({ storeTypeCode });
      if (!rs.error) {
        setStoreList(rs?.data || []);
      }
    } catch (error) {
      console.error('Error fetching store list:', error);
    } finally {
      setLoadingStores(false);
    }
  }, []);

  useEffect(() => {
    fetchStoreTypeList();
  }, [fetchStoreTypeList]);

  useEffect(() => {
    if (selectedStoreType?.storeTypeCode) {
      fetchStoreList(selectedStoreType.storeTypeCode);
    }
  }, [selectedStoreType, fetchStoreList]);

  const handleStoreTypeClick = useCallback((storeType) => {
    setSelectedStoreType(storeType);
  }, []);

  const handleStoreClick = useCallback((store) => {
    setSelectedStore(store);
  }, []);

  const handleSave = useCallback(() => {
    if (selectedStoreType && selectedStore) {
      const dataParams = {
        storeTypeCode: selectedStoreType?.storeTypeCode,
        storeCode: selectedStore?.storeCode
      };
      const query = objToParams(ignoreEmpty(dataParams));
      setCurrentStore(selectedStore);
      navigate(query);
      setOpen(false);
    }
  }, [selectedStoreType, selectedStore, setCurrentStore, navigate, setOpen]);

  return (
    <Stack
      height="100%"
      bgcolor="white"
      sx={{ width: '100%', gap: 2.5, overflowX: 'clip' }}
    >
      {/* Store Types */}
      <Stack sx={{ gap: 1, pl: 2.5 }}>
        <Typography
          sx={{
            fontSize: 14,
            letterSpacing: '-0.56px',
            color: '#9A9CA4',
            fontWeight: 500
          }}
        >
          분류
        </Typography>

        <Swiper
          spaceBetween={8}
          slidesPerView="auto"
          className={classes?.mySwiper}
        >
          {loadingStoreTypes ? (
            <Stack
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                height: 40,

                pr: 2.5
              }}
            >
              <Skeleton
                variant="rectangular"
                sx={{ bgcolor: '#F6F6F6' }}
                width="100%"
                height={40}
              />
            </Stack>
          ) : (
            storeTypeList.map((storeType) => (
              <SwiperSlide
                key={storeType.storeTypeCode}
                className={classes.mySwiperSlide}
                style={{ width: 'auto' }}
                onClick={() => handleStoreTypeClick(storeType)}
              >
                <Stack
                  sx={{
                    display: 'flex',
                    backgroundColor:
                      selectedStoreType?.storeTypeCode ===
                      storeType.storeTypeCode
                        ? '#1B2872'
                        : '#FFFFFF',
                    border:
                      selectedStoreType?.storeTypeCode ===
                      storeType.storeTypeCode
                        ? '0px'
                        : '1px solid #DADBDD',
                    borderRadius: '20px 0px 20px 20px',
                    p: '7px 20px 6px 20px'
                  }}
                >
                  <Typography
                    sx={{
                      whiteSpace: 'nowrap',
                      fontSize: 14,
                      letterSpacing: '-0.56px',
                      fontWeight: 500,
                      color:
                        selectedStoreType?.storeTypeCode ===
                        storeType.storeTypeCode
                          ? '#FFFFFF'
                          : '#DADBDD'
                    }}
                  >
                    {storeType.storeTypeName}
                  </Typography>
                </Stack>
              </SwiperSlide>
            ))
          )}
        </Swiper>
      </Stack>

      {/* Stores */}
      <Stack
        sx={{
          mx: 2.5,
          px: 2.5,
          py: 2.5,
          backgroundColor: '#F6F6F6',
          borderRadius: 1,
          gap: 1.25
        }}
      >
        <Typography
          sx={{
            fontSize: 14,
            letterSpacing: '-0.56px',
            color: '#9A9CA4',
            fontWeight: 500
          }}
        >
          상점명
        </Typography>
        {loadingStores ? (
          <Stack
            sx={{ alignItems: 'center', justifyContent: 'center', height: 30 }}
          >
            <CircularProgress size={24} />
          </Stack>
        ) : (
          <Stack
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: 0.5,
              columnGap: '8px',
              rowGap: '12px'
            }}
          >
            {storeList.map((store) => (
              <Stack
                key={store.storeCode}
                sx={{
                  p: '6px 20px',
                  height: '30px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#FFFFFF',
                  border:
                    store.storeCode === selectedStore?.storeCode
                      ? '1px solid #1B2872'
                      : '1px solid #DADBDD',
                  borderRadius: 1
                }}
                onClick={() => handleStoreClick(store)}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    letterSpacing: '-0.56px',
                    color:
                      store.storeCode === selectedStore?.storeCode
                        ? '#1B2872'
                        : '#DADBDD',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {store.storeName}
                </Typography>
              </Stack>
            ))}
          </Stack>
        )}
      </Stack>

      {/* Save Button */}
      <Stack sx={{ px: 2.5, gap: 1.25 }}>
        <Button
          sx={{
            height: 48,
            width: '100%',
            backgroundColor: '#1B2872',
            fontSize: 16,
            fontWeight: 500,
            letterSpacing: '-0.64px',
            color: '#FFFFFF',
            borderRadius: 1
          }}
          onClick={handleSave}
          disabled={loadingStoreTypes || loadingStores}
        >
          저장
        </Button>
      </Stack>
    </Stack>
  );
};

export default MenuStores;
