import RestService from '../PreOrderApi';

const orderService = {
  async getOrderList(params = {}) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/order`)
        .setConfig({ params })
        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async getOrderDetail(orderCode) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/order/${orderCode}`)

        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getNewQr(orderCode) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/order/${orderCode}/pickup-qr`)
        .post();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getPickupQr(orderCode) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/store/delivery/${orderCode}/display-qr`)
        .post();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async getOrderDisplayImages(orderCode) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/store/order/${orderCode}/photos`)

        .get();
      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async cancelOrder(orderCode, data) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/order/inicis/${orderCode}/full-refund`)
        .post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async cancelOrderByStore(storeCode, orderCode, data) {
    try {
      const res = await new RestService()
        .setPath(
          `pre-order/store/order/${storeCode}/inicis/${orderCode}/full-refund`
        )
        .post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },

  async updateStoreOrderStatus(orderCode, data) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/store/order/${orderCode}`)
        .put(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async requestDirectPayment(orderCode) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/order/inicis/${orderCode}/purchase-billing`)
        .post();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async uploadDisplayImage(orderCode, data) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/store/order/${orderCode}/photos`)
        .setHeaders({ 'Content-Type': 'multipart/form-data' })
        .post(data);

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteCard(paymentInfoCode) {
    try {
      const res = await new RestService()
        .setPath(`pre-order/payment/inicis/card/${paymentInfoCode}`)
        .delete();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  },
  async deleteOrder(orderCode) {
    try {
      const res = await new RestService()
        .setPath(`/pre-order/order/inicis/${orderCode}`)
        .delete();

      return { data: res?.data, error: null };
    } catch (err) {
      return { error: err?.response?.data || err, data: null };
    }
  }
};

export default orderService;
