import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PreorderAdminPublicRoute = () => {
  const isLoggedIn = useSelector(
    (state) => state.preOrder.auth.authenticatedAdmin
  );
  const location = useLocation();
  const isAuthenticated =
    isLoggedIn || localStorage.getItem('PREORDER_ADMIN_TOKEN');

  return isAuthenticated ? (
    <Navigate
      to={
        location?.state?.originalPath?.split('/')?.includes('store-owner')
          ? location?.state?.originalPath
          : '/pre-order/ko/admin/order-list'
      }
    />
  ) : (
    <Outlet />
  );
};

export default PreorderAdminPublicRoute;
