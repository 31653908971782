/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-exponentiation-operator */
import i18n from '@utils/i18n';
import jwtDecode from 'jwt-decode';

const t = i18n.getFixedT(null, null, 'dayOfWeekShort');
// const g = i18n.getFixedT(
//   null,
//   null,
//   'pages.performance-management.goal.labels'
// );

const capitalize = (text) => {
  return text
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
};

const compareDates = (date1, date2) => {
  const d1 = new Date(date1).getTime();
  const d2 = new Date(date2).getTime();

  if (d1 > d2) {
    return 1;
  }
  if (d1 < d2) {
    return -1;
  }
  return 0;
};

function countDates(date1, date2) {
  const oneDay = 1000 * 60 * 60 * 24; // milliseconds in a day
  const d1 = new Date(date1).getTime();
  const d2 = new Date(date2).getTime();
  const timeDiff = Math.abs(d2 - d1);
  const diffDays = Math.ceil(timeDiff / oneDay);
  return diffDays;
}

function dayOfWeekShort(date) {
  return t(`${date?.toLowerCase()}`);
}

// const convertMonth = (month) => {
//   return g(`${month === 'Dec' ? 'dcb' : month.toLowerCase()}`);
// };

function secondsToTime(secs) {
  const hours = Math.floor(secs / 3600);
  const minutes = Math.floor((secs % 3600) / 60);
  if (!hours && !minutes) return `0: 00`;
  return `${hours} : ${minutes < 10 ? '0' : ''}${minutes}`;
}
function space2Date(date1, date2) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return d2 - d1;
}

function convertToHangulCurrency(money) {
  let number = null;
  if (typeof money === 'string') {
    number = money?.replace(/,/g, '');
  } else number = `${money}`;
  const han1 = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  const han2 = ['', '십', '백', '천'];
  const han3 = ['', '만', '억', '조', '경'];
  let result = '';
  const len = number.length;
  let nowInt = 0;
  let hasHan3 = false;
  for (let i = len; i > 0; i--) {
    nowInt = parseInt(number.substring(len - i, len - i + 1), 10);
    const han2Pick = (i - 1) % 4;
    if (nowInt > 0) {
      result += han1[nowInt] + han2[han2Pick];
      if (han2Pick > 0) {
        hasHan3 = false;
      }
    }
    if (!hasHan3 && han2Pick === 0) {
      result += han3[(i - 1) / 4];
      hasHan3 = true;
    }
  }
  return result;
}
function formatMoney(n) {
  if (!Number(n)) {
    return n;
  }
  if (n || n === 0) return (Math.round(n * 100) / 100).toLocaleString('en-CA');
  return null;
}
// eslint-disable-next-line consistent-return
const onlyNumber = (event) => {
  const key = event?.key;
  const keyCode = event?.keyCode;
  // Kiểm tra xem ký tự có phải là số không
  const number =
    isNumber(key) ||
    key === 'Backspace' ||
    key === 'Delete' ||
    keyCode === 37 ||
    keyCode === 39;
  // Kiểm tra xem Ctrl hoặc Command (Mac) có được nhấn cùng với phím vừa nhấn không
  const isCtrlOrCmdPressed = event?.ctrlKey || event?.metaKey;

  // Chặn các phím không phải số nếu không có Ctrl/Command được nhấn cùng lúc
  if (!number && !isCtrlOrCmdPressed) {
    event.target.blur();
    // setTimeout(() => {
    //   event.target.focus();
    // }, 200);
    event?.preventDefault();
  }
  return 'number';
};

const hasKeyInObj = (obj, key) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const property in obj) {
    if (property === key) return true;
  }
  return false;
};

const isNumber = (char) => {
  // Kiểm tra bằng phương pháp isNaN()
  // return !isNaN(char);

  // Kiểm tra bằng regex
  const numberRegex = /^[0-9]+$/;
  return numberRegex.test(char);
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const objToParams = (obj) => `?${new URLSearchParams(obj).toString()}`;
const paramsToObj = (params) => {
  const obj = Object.fromEntries(new URLSearchParams(params));
  // eslint-disable-next-line no-restricted-syntax
  for (const property in obj) {
    if (
      obj[property] === 'null' ||
      obj[property] === 'undefined' ||
      !obj[property]
    )
      obj[property] = null;
  }
  return obj;
};
const ignoreEmpty = (obj) => {
  const newObj = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const property in obj) {
    if (
      obj[property] === 'null' ||
      obj[property] === 'undefined' ||
      !obj[property]
    ) {
      obj[property] = null;
    } else {
      newObj[property] = obj[property];
    }
  }
  return newObj;
};

const authorizeClient = (clientCode) => {
  let isAuthorized = false;
  const authorizedClients = ['minicli66c6fa7f0007c', 'minicli64894ef08453a'];
  const token = localStorage.getItem('token');
  if (token) {
    const decodedToken = jwtDecode(token);
    // eslint-disable-next-line no-unused-expressions
    isAuthorized = authorizedClients.includes(decodedToken?.sub);
  }
  return isAuthorized;
};
const formatCardNumber = (cardNumber) => {
  if (!cardNumber) return '';

  return cardNumber
    .replace(/(.{4})(?=.)/g, '$1 ')
    .split(' ')
    .map((group, index) => (
      <span key={index} style={{ marginRight: index < 3 ? '10px' : '0' }}>
        {group}
      </span>
    ));
};

export {
  ignoreEmpty,
  paramsToObj,
  objToParams,
  space2Date,
  capitalize,
  compareDates,
  countDates,
  dayOfWeekShort,
  secondsToTime,
  // convertMonth,
  convertToHangulCurrency,
  formatMoney,
  onlyNumber,
  hasKeyInObj,
  delay,
  isNumber,
  authorizeClient,
  formatCardNumber
};
