import { Button, Container, Stack } from '@mui/material';
import BannerImg from 'images/preorder/main.png';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Auth = () => {
  const navigate = useNavigate();
  const version = 'ko';
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  useEffect(() => {
    const updateHeight = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', updateHeight);
    window.addEventListener('orientationchange', updateHeight);

    // Initial update
    updateHeight();

    return () => {
      window.removeEventListener('resize', updateHeight);
      window.removeEventListener('orientationchange', updateHeight);
    };
  }, []);
  return (
    <Container
      sx={{
        width: '100%',
        bgcolor: '#1B2872',
        maxHeight: '100vh',
        height: `${viewportHeight}px`,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '500px !important',
        alignItems: 'center',
        justifyContent: 'flex-end',
        px: 2.5,
        py: 5,
        overflow: 'hidden', // Prevent scrolling
        position: 'fixed', // Fix container position
        left: '50%', // Center horizontally
        transform: 'translateX(-50%)', // Center horizontally
        top: 0
      }}
    >
      <Stack
        sx={{
          width: '100%',
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',

          //   border: '1px solid red',
          mb: '100px'
        }}
      >
        <img
          src={BannerImg}
          alt="preorder banner"
          style={{ maxWidth: '250px', objectFit: 'contain' }}
        />
      </Stack>
      <Stack sx={{ gap: 2.5, width: '100%', alignItems: 'center' }}>
        <Stack
          sx={{
            gap: 1.25,
            width: '100%'
          }}
        >
          <Button
            sx={{
              width: '100%',
              backgroundColor: '#6C78BC',
              ':hover': {
                backgroundColor: '#6C78BC',
                borderRadius: 1,
                height: 48,
                color: '#FFFFFF'
              },
              borderRadius: 1,
              height: 48,
              color: '#FFFFFF',
              fontSize: 16,
              fontWeight: 500,
              letterSpacing: '-0.64px'
            }}
            onClick={() => navigate(`/pre-order/${version}/auth/login`)}
          >
            로그인
          </Button>
          <Button
            sx={{
              width: '100%',
              backgroundColor: '#3748A6',
              borderRadius: 1,
              height: 48,
              color: '#FFFFFF',
              fontSize: 16,
              fontWeight: 500,
              letterSpacing: '-0.64px',
              ':hover': {
                backgroundColor: '#3748A6',
                borderRadius: 1,
                height: 48,
                color: '#FFFFFF'
              }
            }}
            onClick={() => navigate(`/pre-order/${version}/auth/signup`)}
          >
            회원가입
          </Button>
        </Stack>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="16"
          viewBox="0 0 100 16"
          fill="none"
        >
          <path
            d="M6.66401 5.55362C6.52879 4.78758 6.2576 4.66705 5.59718 4.51457L5.63602 4.30908H10.4637L10.4256 4.51457C9.74127 4.5988 9.43048 4.70118 9.36772 5.04244C9.35203 5.12812 9.35054 5.22905 9.36324 5.34886L10.0072 10.221H10.0775L12.0453 6.28553C12.2619 5.84334 12.3486 5.55362 12.3703 5.43309C12.4674 4.90521 12.1693 4.65035 11.352 4.51457L11.3908 4.30908H14.7572L14.7199 4.51457C13.9489 4.5988 13.1958 4.95749 12.8036 5.7061L9.38864 12.3173H7.77344L6.94193 7.24036L4.09777 12.3173H2.51768L1.18488 5.55362C1.02949 4.80283 0.761282 4.66705 0 4.51457L0.0366071 4.30908H4.9502L4.91284 4.51457C4.26138 4.5988 3.9506 4.70118 3.89083 5.02574C3.87663 5.10997 3.88485 5.24721 3.90054 5.34886L4.74325 10.2733H4.81199L6.86349 6.72845L6.66401 5.55362Z"
            fill="#6C78BC"
          />
          <path
            d="M18.6838 8.24409C19.0999 5.94525 18.9834 4.51338 17.8142 4.51338C16.6651 4.51338 16.1631 5.94525 15.7462 8.24409C15.3286 10.5458 15.4414 12.0089 16.6091 12.0089C17.7424 12.0089 18.2662 10.5458 18.6838 8.24409ZM12.9394 8.27895C13.3996 5.73977 15.3346 4.17139 17.9621 4.17139C20.6583 4.17139 21.95 5.67224 21.4898 8.21069C21.0266 10.7666 19.0887 12.3502 16.4784 12.3502C13.7463 12.3502 12.4755 10.8341 12.9394 8.27895Z"
            fill="#6C78BC"
          />
          <path
            d="M20.7725 12.0106C21.7549 11.9939 21.9745 11.8233 22.0485 11.4145L23.1183 5.51926C23.211 5.00808 23.078 4.70166 22.1015 4.68569L22.1381 4.4802L25.9543 4.27544L25.6629 5.87868C26.3846 4.83745 27.4223 4.22534 28.3681 4.22534C29.4327 4.22534 29.8989 4.8723 29.7383 5.75814C29.5769 6.64471 28.9008 7.25754 28.1111 7.25754C27.1817 7.25754 26.7678 6.60986 26.8918 5.92878C26.9374 5.67319 27.0592 5.38348 27.1675 5.16056C26.7499 5.19541 26.0835 5.63906 25.636 6.21777L24.6932 11.4145C24.6177 11.8233 24.8516 11.9605 26.1664 12.0106L26.1291 12.2154H20.7351L20.7725 12.0106Z"
            fill="#6C78BC"
          />
          <path
            d="M32.1423 11.4143C32.0683 11.8231 32.2947 11.9938 32.8924 12.0105L32.855 12.2152H28.2014L28.238 12.0105C29.2025 11.9938 29.4222 11.8231 29.4976 11.4143L31.3422 1.24236C31.4348 0.732637 31.3183 0.42477 30.2731 0.407343L30.3104 0.204761L34.2125 0L32.6488 8.62028L35.2591 6.25101C36.2685 5.33249 36.0279 4.85544 35.0425 4.51418L35.0798 4.30942H39.0476L39.0088 4.51418C37.7514 4.82059 36.7518 5.41817 35.4541 6.50878L35.1097 6.79704L37.1343 10.9721C37.5579 11.858 37.7171 11.9255 38.4933 12.0105L38.4559 12.2152H33.6634L33.7007 12.0105C34.4261 11.9771 34.5793 11.8064 34.3006 11.1595L33.1479 8.5179L32.5741 9.02835L32.1423 11.4143Z"
            fill="#6C78BC"
          />
          <path
            d="M41.1782 7.76777H43.6166C44.1994 5.79059 44.0373 4.51411 43.0899 4.51411C42.1979 4.51411 41.6115 5.75719 41.1782 7.76777ZM38.2839 8.38133C38.7703 5.70564 40.9204 4.17139 43.2573 4.17139C45.7122 4.17139 46.7477 5.75719 46.2016 8.10831H41.1169L41.0743 8.3472L41.0668 8.38133C40.6776 10.5284 41.3567 11.619 43.0048 11.619C44.0171 11.619 44.7941 11.1238 45.5882 10.2554L45.7563 10.3745C44.7216 11.619 43.4725 12.3502 41.79 12.3502C39.282 12.3502 37.8148 10.9713 38.2839 8.38133Z"
            fill="#6C78BC"
          />
          <path
            d="M45.5098 12.0106C46.4922 11.9939 46.7103 11.8233 46.7858 11.4145L47.8541 5.51926C47.9483 5.00808 47.8153 4.70166 46.8388 4.68569L46.8754 4.4802L50.6916 4.27544L50.4002 5.87868C51.1219 4.83745 52.1596 4.22534 53.1054 4.22534C54.17 4.22534 54.6362 4.8723 54.4756 5.75814C54.3157 6.64471 53.6373 7.25754 52.8469 7.25754C51.9205 7.25754 51.5051 6.60986 51.6292 5.92878C51.6747 5.67319 51.7965 5.38348 51.9048 5.16056C51.4872 5.19541 50.8223 5.63906 50.3733 6.21777L49.4305 11.4145C49.3565 11.8233 49.5889 11.9605 50.9037 12.0106L50.8664 12.2154H45.4724L45.5098 12.0106Z"
            fill="#6C78BC"
          />
          <path
            d="M54.0598 9.35285H54.3168C55.1176 11.089 55.9477 12.0097 57.1669 12.0097C57.9752 12.0097 58.5154 11.5842 58.6484 10.8508C58.7851 10.1015 58.4115 9.79505 56.6716 9.25047C54.9324 8.70444 54.2764 8.15987 54.5103 6.86523C54.8113 5.21189 56.2868 4.17139 58.2614 4.17139C59.2416 4.17139 59.9842 4.42915 60.4847 4.80164L61.113 4.17139H61.32L60.8314 6.86523H60.5729C59.8512 5.34767 59.3185 4.51338 58.2001 4.51338C57.462 4.51338 56.9114 4.99043 56.7717 5.75647C56.6447 6.45498 57.3447 6.76285 58.3817 7.01771C60.1911 7.46136 61.1377 8.10831 60.8724 9.57286C60.5818 11.1754 58.942 12.3502 56.9331 12.3502C56.2965 12.3502 54.7538 11.9596 54.3579 11.6865L53.7206 12.3502H53.5151L54.0598 9.35285Z"
            fill="#6C78BC"
          />
          <path
            d="M63.6228 12.0105C64.5865 11.9938 64.8077 11.8231 64.8816 11.4143L66.727 1.24309C66.8196 0.732637 66.7023 0.425496 65.6571 0.407343L65.6945 0.204761L69.5973 0L68.5274 5.89377C69.4314 4.8903 70.7022 4.22519 71.8012 4.22519C73.3297 4.22519 73.8362 5.12846 73.5396 6.76364L72.6969 11.4143C72.6215 11.8231 72.7642 11.9938 73.7563 12.0105L73.7189 12.2152H69.0481L69.0855 12.0105C69.6884 11.9938 69.9775 11.8231 70.0522 11.4143L70.892 6.77889C71.0526 5.89377 70.7702 5.36589 69.9977 5.36589C69.4643 5.36589 68.9914 5.70644 68.4662 6.23576L67.5271 11.4143C67.4509 11.8231 67.6795 11.9938 68.2764 12.0105L68.2398 12.2152H63.5854L63.6228 12.0105Z"
            fill="#6C78BC"
          />
          <path
            d="M76.4869 1.65423C76.6565 0.716102 77.3849 0.103271 78.2963 0.103271C79.2227 0.103271 79.7195 0.681249 79.5552 1.58525C79.3856 2.52192 78.6758 3.11732 77.7487 3.11732C76.8372 3.11732 76.3158 2.58945 76.4869 1.65423ZM73.6113 12.0113C74.593 11.9946 74.8814 11.8247 74.9553 11.4159L76.0252 5.52072C76.1178 5.00954 75.9168 4.70313 74.9396 4.68643L74.977 4.48094L78.8962 4.27618L77.6015 11.4159C77.5276 11.8247 77.6501 11.9946 78.6445 12.0113L78.6071 12.2168H73.574L73.6113 12.0113Z"
            fill="#6C78BC"
          />
          <path
            d="M84.599 6.86714C84.8687 5.38444 84.7305 4.532 83.8362 4.532C82.9778 4.532 82.6842 5.21381 82.3831 6.86714C82.0835 8.51902 82.2651 9.21753 83.1414 9.21753C83.9834 9.21753 84.2994 8.51902 84.599 6.86714ZM84.8253 13.6642C84.9367 13.0521 84.5751 12.6767 83.1676 12.6767H80.6596C80.1717 12.9991 79.9065 13.3236 79.8199 13.8C79.6211 14.8906 80.4198 15.3161 82.0693 15.3161C83.5299 15.3161 84.6348 14.7207 84.8253 13.6642ZM85.55 4.46447C86.1118 3.54377 86.9515 2.79443 87.8809 2.79443C88.5996 2.79443 88.9022 3.21993 88.7969 3.80009C88.7109 4.27568 88.3247 4.70263 87.7233 4.70263C87.2765 4.70263 86.8492 4.31054 86.9628 3.67955C86.5922 3.83349 86.1514 4.1733 85.8384 4.58355C86.7775 4.99235 87.3094 5.74168 87.1129 6.83229C86.8118 8.48562 85.1122 9.52395 83.0174 9.52395C82.6229 9.52395 82.2681 9.49055 81.9341 9.439C81.2124 9.62633 80.9315 9.84779 80.8703 10.1891C80.8075 10.5296 81.1885 10.7002 82.2038 10.7002H84.2112C86.0849 10.7002 86.9232 11.3806 86.6751 12.7442C86.3942 14.2952 84.3151 15.6239 81.5673 15.6239C79.4373 15.6239 77.9529 14.9937 78.1329 14.0055C78.2629 13.2902 78.8389 12.8633 80.2502 12.66L80.2562 12.6251C79.2879 12.2839 79.0085 11.5512 79.1221 10.9202C79.2252 10.359 80.0507 9.59148 81.4224 9.31991C80.289 8.94452 79.6398 8.16178 79.8692 6.90054C80.1658 5.26391 81.8654 4.22558 83.9789 4.22558C84.5616 4.22558 85.098 4.31054 85.55 4.46447Z"
            fill="#6C78BC"
          />
          <path
            d="M86.561 12.0105C87.5248 11.9938 87.7444 11.8231 87.8199 11.4143L89.6652 1.24309C89.7578 0.732637 89.6405 0.425496 88.5954 0.407343L88.6327 0.204761L92.5355 0L91.4657 5.89377C92.3696 4.8903 93.6404 4.22519 94.7394 4.22519C96.2679 4.22519 96.7745 5.12846 96.4779 6.76364L95.6352 11.4143C95.5612 11.8231 95.7024 11.9938 96.6945 12.0105L96.6572 12.2152H91.9864L92.0237 12.0105C92.6266 11.9938 92.9143 11.8231 92.9905 11.4143L93.8302 6.77889C93.9908 5.89377 93.7084 5.36589 92.9359 5.36589C92.4025 5.36589 91.9296 5.70644 91.4029 6.23576L90.4653 11.4143C90.3906 11.8231 90.6177 11.9938 91.2146 12.0105L91.178 12.2152H86.5237L86.561 12.0105Z"
            fill="#6C78BC"
          />
          <path
            d="M99.9943 0L96.0915 0.204761L96.0557 0.402987C96.093 0.402987 96.1281 0.412426 96.1655 0.413878C96.2917 0.418961 96.4158 0.430579 96.5375 0.450184C97.9533 0.67818 99.0365 1.86463 99.0365 3.30594C99.0365 3.40034 99.0194 3.48456 99.0164 3.57605H99.3458L99.9943 0Z"
            fill="#6C78BC"
          />
          <path
            d="M96.0557 0.407371C96.0975 0.408127 96.1295 0.412666 96.1683 0.414179C96.1303 0.412666 96.0953 0.402832 96.0564 0.402832L96.0557 0.407371Z"
            fill="#6C78BC"
          />
        </svg>
      </Stack>
    </Container>
  );
};

export default Auth;
