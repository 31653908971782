/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable no-case-declarations */
import React, { useState, useEffect, useCallback } from 'react';
import { Button, Container, Stack, Typography } from '@mui/material';
import AlertDialog from 'pages-preorder/components/Modal/Alert/AlertDialog';
import codeService from '@services/code';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  isEmail,
  isValidKoreanPhoneNumber,
  isValidPassword
} from '@utils/boolean';
import LoginService from '@services/preorder/auth';
import { ignoreEmpty } from '@utils/helpers';

import error2Text from '@utils/error2Text';
import { setAlert, setPreorderError } from '@store/reducers/pre-order/app';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setAuthenticated } from '@store/reducers/pre-order/auth';
import { actions, logInActions, loginScreens } from './enhance';
import LoginForm from './LoginForm';
import Finder from './FindID';

const maxLengthOf = {
  name: 10,
  phone: 20,
  otpCode: 6
};

const BUTTON_LABEL = {
  [loginScreens.LOGIN]: '로그인',
  [loginScreens.FIND_ID_SUCCEESS]: '로그인',
  [loginScreens.FIND_PASSWORD]: '다음',
  [loginScreens.FIND_ID]: '다음',
  [loginScreens.FIND_ID_PASSWORD]: '다음',
  [loginScreens.RESET_PASSWORD]: '비밀번호 재설정'
};

export const Login = () => {
  const [screen, setScreen] = useState(loginScreens.LOGIN);

  const [loginData, setLoginData] = useState({
    email: '',
    password: ''
    // Add other sign-up fields here
  });

  const [resetPasswordData, setResetPasswordData] = useState({
    password: '',
    confirmPassword: ''
  });
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [verifyingBox, setVerifyingBox] = useState({
    otpCode: 'none',
    emailOtp: 'none'
  });

  const [isVerifying, setVerifying] = useState({
    clientName: false,
    otpCode: false
  });
  const [isVerified, setVerified] = useState({
    client: false,
    otpCode: false
  });
  const [seconds, setSeconds] = useState({ email: -1, phone: -1 });
  const [alertDialog, setAlertDialog] = useState({
    open: false,
    title: ''
  });

  const [notification, setNotification] = useState({
    otp: ''
  });
  const [errMsg, setErrMsg] = useState({});

  const location = useLocation();
  const pathArr = location.pathname?.split('/');
  const { version } = useParams();
  const navigate = useNavigate();

  const isButtonDisabled = () => {
    switch (screen) {
      case loginScreens.LOGIN:
        return false;

      case loginScreens.FIND_ID:
        return (
          !loginData?.phone ||
          !loginData?.verificationCode ||
          !isVerified?.otpCode
        );
      case loginScreens.FIND_PASSWORD:
        return (
          !loginData?.phone ||
          !loginData?.verificationCode ||
          !isVerified?.otpCode
        );
      case loginScreens.RESET_PASSWORD:
        return (
          !isValidPassword(resetPasswordData?.password) ||
          !isValidPassword(resetPasswordData?.confirmPassword) ||
          resetPasswordData?.password !== resetPasswordData?.confirmPassword
        );
      case loginScreens.FIND_ID_SUCCEESS:
        return false;
      default:
        return true;
    }
  };

  const handleNextScreen = (newScreen) => {
    switch (screen) {
      case loginScreens.LOGIN:
        handleApiAction(actions?.LOGIN);
        return;
      case loginScreens.FIND_ID:
        handleApiAction(logInActions.FIND_EMAIL);
        return;
      case loginScreens.FIND_ID_SUCCEESS:
        setScreen(loginScreens?.LOGIN);
        return;
      case loginScreens.RESET_PASSWORD:
        handleApiAction(logInActions.CHANGE_PASSWORD);

        return;
      case loginScreens.FIND_PASSWORD:
      case loginScreens.FIND_ID_PASSWORD:
        setScreen(loginScreens.RESET_PASSWORD);
        return;
      default:
        setScreen(newScreen);
        break;
    }
  };

  const handleCancel = () => {
    switch (screen) {
      case loginScreens.LOGIN:
        navigate(`/pre-order/${version}/auth`);

        break;

      default:
        window.location.reload();
        break;
    }
  };

  const handleChangeVerification = (name, value) => {
    // if (name === 'otpCode' && value?.length === 6) {
    //   handleApiAction(logInActions?.VERIFY_CODE_FIND_ID, value);
    // } else if (name === 'emailOtp' && value?.length === 6) {
    //   handleApiAction(actions?.VERIFY_CODE_EMAIL, value);
    // }

    setLoginData({ ...loginData, [name]: value });
    setErrMsg({ ...errMsg, [name]: value ? '' : t('common.required') });
    if (name === 'email') {
      if (value)
        setNotification({
          ...notification,
          [name]: isEmail(value) ? '사용 가능한 이메일입니다.' : ''
        });
      else {
        setErrMsg({
          ...errMsg,
          [name]: value ? '' : '아이디를 입력해 주세요)'
        });
        setNotification({
          ...notification,
          [name]: isEmail(value) ? '사용 가능한 이메일입니다.' : ''
        });
      }
    }

    if (name === 'phone') {
      if (isVerifying?.otpCode)
        setVerifying({ ...isVerifying, otpCode: false });
      setLoginData({ ...loginData, [name]: value });
      if (!value) {
        setErrMsg({
          [name]: '휴대폰 번호를 입력해 주세요.'
        });
      } else {
        setErrMsg({ [name]: value ? '' : '휴대폰 번호를 입력해 주세요.' });
      }
      setNotification({ ...notification, [name]: '' });
    }
    // if (value?.length > maxLengthOf[name]) {
    //     setErrMsg({ ...errMsg, [name]: value ? '' : t('common.required') });
    //   }
  };

  const handleChangeRegistration = (name, value) => {
    if (value?.length > maxLengthOf[name]) {
      setErrMsg({ ...errMsg, [name]: value ? '' : t('common.required') });
    }

    setLoginData({ ...loginData, [name]: value });
    setErrMsg({ ...errMsg, [name]: value ? '' : t('common.required') });
    if (name === 'password') {
      setErrMsg({
        ...errMsg,
        [name]: value?.length >= 6 ? '' : '비밀번호를 6자 이상 입력해주세요'
      });
    } else if (name === 'email') {
      if (value) {
        setErrMsg({
          ...errMsg,
          [name]: isEmail(value) ? '' : '이메일 형식이 올바르지 않습니다.'
        });
        return;
      }
      setErrMsg({
        ...errMsg,
        [name]: value ? '' : '이메일을 입력해 주세요'
      });
    }
  };

  const handleChangePasswordReset = (name, value) => {
    setResetPasswordData({ ...resetPasswordData, [name]: value });
    setErrMsg({ ...errMsg, [name]: value ? '' : t('common.required') });
    console.log(name, value);
    if (name === 'password') {
      if (value && isValidPassword(value))
        setNotification({
          ...notification,
          [name]: '사용 가능한 비밀번호입니다.'
        });
      else {
        if (value) {
          setErrMsg({
            ...errMsg,
            [name]: isValidPassword(value)
              ? ''
              : '비밀번호는 영문, 숫자, 특수문자를 조합해서 입력해 주세요.(8-16자)'
          });
          return;
        }
        setErrMsg({
          ...errMsg,
          [name]: '비밀번호를 입력해 주세요'
        });
        setNotification({
          ...notification,
          [name]: ''
        });
      }
    }
    if (name === 'confirmPassword') {
      if (isValidPassword(value) && value === resetPasswordData?.password)
        setNotification({
          ...notification,
          [name]: '동일한 비밀번호입니다.'
        });
      else {
        if (value)
          setErrMsg({
            ...errMsg,
            [name]: isValidPassword(value)
              ? ''
              : '비밀번호는 영문, 숫자, 특수문자를 조합해서 입력해 주세요.(8-16자)'
          });
        else
          setErrMsg({
            ...errMsg,
            [name]: value ? '' : '비밀번호를 입력해 주세요.'
          });
        setNotification({
          ...notification,
          [name]: ''
        });
      }
    }
  };
  const handleChangePasswordError = useCallback((error) => {
    setAlertDialog({
      open: true,
      title: '',
      subTitle: error2Text(error)?.content,
      hideCanelButton: true,
      type: 'alert'
    });
  }, []);

  const handleApiAction = async (action, val) => {
    let rs;
    switch (action) {
      case logInActions.SEND_CODE_FIND_ID:
        if (!loginData?.phone || errMsg.phone) {
          setErrMsg({
            phone: '11자리 숫자 형태의 휴대폰 번호를 입력해주세요.'
          });
          return;
        }
        if (!isValidKoreanPhoneNumber(loginData?.phone)) {
          setErrMsg({
            phone: '휴대폰번호 형식이 올바르지 않습니다.'
          });
          return;
        }

        rs = await codeService.sendPreorderOTP(
          ignoreEmpty({
            contact: loginData?.phone,

            verificationType: 'sms',
            purpose: 'email'
          })
        );
        if (!rs?.error) {
          setErrMsg({ otpCode: '' });
          setLoginData({ ...loginData, otpCode: '' });
          setVerifyingBox({ ...verifyingBox, otpCode: 'init' });
          setVerifying({ ...isVerifying, otpCode: true });
          setVerified({ ...isVerified, otpCode: false });
          setSeconds({ ...seconds, phone: 180 });
          setNotification({
            ...notification,
            phone: '인증번호가 전송되었습니다.'
          });
          dispatch(
            setAlert({ message: `인증번호가 전송되었습니다.(${rs?.data}/5)` })
          );
        } else {
          setErrMsg({ ...errMsg, phone: error2Text(rs?.error)?.content });
        }
        break;
      case logInActions.SEND_CODE_AGAIN_FIND_ID:
        if (!loginData?.phone || errMsg.phone) {
          setErrMsg({
            phone: '11자리 숫자 형태의 휴대폰 번호를 입력해주세요.'
          });
          return;
        }
        if (!isValidKoreanPhoneNumber(loginData?.phone)) {
          setErrMsg({
            phone: '휴대폰번호 형식이 올바르지 않습니다.'
          });
          return;
        }

        rs = await codeService.sendPreorderOTP({
          contact: loginData?.phone,
          verificationType: 'sms',
          purpose: 'email'
        });
        if (!rs?.error) {
          setErrMsg({ otp: '' });
          setLoginData({ ...loginData, otpCode: '' });
          setVerifyingBox({ ...verifyingBox, otpCode: 'init' });
          setVerifying({ ...isVerifying, otpCode: true });
          setVerified({ ...isVerified, otpCode: false });
          setSeconds({ ...seconds, phone: 180 });
          setNotification({
            ...notification,
            phone: '인증번호가 전송되었습니다.'
          });
          dispatch(
            setAlert({ message: `인증번호가 전송되었습니다.(${rs?.data}/5)` })
          );
        } else {
          dispatch(setAlert({ message: error2Text(rs?.error)?.content }));
        }
        break;

      case logInActions.SEND_CODE_CHANGE_PASSWORD:
        if (!loginData?.phone || errMsg.phone) {
          setErrMsg({
            ...errMsg,
            phone: '11자리 숫자 형태의 휴대폰 번호를 입력해주세요.'
          });
          return;
        }
        if (!isValidKoreanPhoneNumber(loginData?.phone)) {
          setErrMsg({
            ...errMsg,
            phone: '휴대폰번호 형식이 올바르지 않습니다.'
          });
          return;
        }

        if (!loginData?.email || errMsg.email) {
          setErrMsg({
            ...errMsg,
            email: '이메일 형식이 올바르지 않습니다.'
          });
          setNotification({
            ...notification,
            email: ''
          });
          return;
        }
        if (!isEmail(loginData?.email)) {
          setErrMsg({
            ...errMsg,
            email: '이메일 형식이 올바르지 않습니다.'
          });
          setNotification({
            ...notification,
            email: ''
          });
          return;
        }

        rs = await codeService.sendPreorderOTP({
          contact: loginData?.phone,
          verificationType: 'sms',
          email: loginData?.email,
          purpose: 'password'
        });
        if (!rs?.error) {
          setErrMsg({ otpCode: '' });
          setLoginData({ ...loginData, otpCode: '' });
          setVerifyingBox({ ...verifyingBox, otpCode: 'init' });
          setVerifying({ ...isVerifying, otpCode: true });
          setVerified({ ...isVerified, otpCode: false });
          setSeconds({ ...seconds, phone: 180 });
          setNotification({
            ...notification,
            phone: '인증번호가 전송되었습니다.'
          });
          dispatch(
            setAlert({ message: `인증번호가 전송되었습니다.(${rs?.data}/5)` })
          );
        } else {
          if (rs?.error?.keyError === 'PREORDER_MEMBER_EMAIL_PHONE_NOT_MATCH') {
            setErrMsg({
              email: '일치하는 사용자 정보를 찾을 수 없습니다.',
              phone: '일치하는 사용자 정보를 찾을 수 없습니다.'
            });
            setNotification({
              ...notification,
              email: ''
            });
            break;
          }

          dispatch(
            setAlert({ message: `인증번호가 전송되었습니다.(${rs?.data}/5)` })
          );
          setNotification({
            ...notification,
            email: ''
          });
        }
        break;

      case logInActions.SEND_CODE_AGAIN_CHANGE_PASSWORD:
        if (!loginData?.phone || errMsg.phone) {
          setErrMsg({
            phone: '11자리 숫자 형태의 휴대폰 번호를 입력해주세요.'
          });
          return;
        }
        if (!isValidKoreanPhoneNumber(loginData?.phone)) {
          setErrMsg({
            ...errMsg,
            phone: '휴대폰번호 형식이 올바르지 않습니다.'
          });
          return;
        }

        rs = await codeService.sendPreorderOTP({
          contact: loginData?.phone,
          verificationType: 'sms',
          email: loginData?.email,
          purpose: 'password'
        });
        if (!rs?.error) {
          setErrMsg({ otpCode: '' });
          setLoginData({ ...loginData, otpCode: '' });
          setVerifyingBox({ otpCode: 'init' });
          setVerifying({ ...isVerifying, otpCode: true });
          setVerified({ ...isVerified, otpCode: false });
          setSeconds({ ...seconds, phone: 180 });
          setNotification({
            ...notification,
            phone: '인증번호가 전송되었습니다.'
          });
          dispatch(
            setAlert({ message: `인증번호가 전송되었습니다.(${rs?.data}/5)` })
          );
        } else {
          setErrMsg({
            phone: rs.error?.message
          });
          dispatch(setAlert({ message: error2Text(rs?.error)?.content }));
        }
        break;

      case actions.SEND_CODE_AGAIN:
        if (!loginData?.phone || errMsg.phone) {
          setErrMsg({
            phone: '11자리 숫자 형태의 휴대폰 번호를 입력해주세요.'
          });
          return;
        }

        rs = await codeService.sendPreorderOTP({
          contact: loginData?.phone,
          verificationType: 'sms',
          purpose: 'registration'
        });
        if (!rs?.error) {
          setErrMsg({ otp: '' });
          setLoginData({ ...loginData, otpCode: '' });
          setVerifyingBox('init');
          setVerifying({ ...isVerifying, otpCode: true });
          setVerified({ ...isVerified, otpCode: false });
          setSeconds({ ...seconds, phone: 180 });
          setNotification({
            phone: '인증번호가 전송되었습니다.'
          });
        } else {
          setErrMsg({
            phone: rs.error?.message
          });
        }
        break;
      case logInActions.VERIFY_CODE_FIND_ID:
        rs = await codeService.verifyPreorderOTP({
          contact: loginData?.phone,
          verificationNumber: val,
          verificationType: 'sms',
          purpose: 'email'
        });
        if (!rs?.error) {
          setVerifying({ ...isVerifying, otpCode: false });
          setVerifyingBox({ ...verifyingBox, otpCode: 'none' });
          setVerified({ ...isVerified, otpCode: true });
          setNotification({
            ...notification,
            phone: '인증되었습니다'
          });
          setErrMsg({
            otpCode: ''
          });
          const loc = rs?.location?.split('/');
          if (loc) setLoginData({ ...loginData, verificationCode: loc?.[1] });
        } else {
          setErrMsg({
            otpCode: error2Text(rs?.error)?.content
          });
        }
        break;

      case logInActions.VERIFY_CODE_CHANGE_PASSWORD:
        rs = await codeService.verifyPreorderOTP({
          contact: loginData?.phone,
          email: loginData?.email,
          verificationNumber: val,
          verificationType: 'sms',
          purpose: 'password'
        });
        if (!rs?.error) {
          setVerifying({ ...isVerifying, otpCode: false });
          setVerifyingBox({ otpCode: 'none' });
          setVerified({ ...isVerified, otpCode: true });
          setNotification({
            ...notification,
            phone: '인증되었습니다'
          });
          setErrMsg({
            otpCode: ''
          });
          const loc = rs?.location?.split('/');
          if (loc) setLoginData({ ...loginData, verificationCode: loc?.[1] });
        } else {
          setErrMsg({
            otpCode: error2Text(rs?.error)?.content
          });
        }
        break;

      case logInActions.FIND_EMAIL:
        rs = await LoginService.findID({
          verificationCode: loginData?.verificationCode
        });
        if (!rs?.error) {
          setLoginData({ email: rs?.data?.userEmail });
          setScreen(loginScreens.FIND_ID_SUCCEESS);
        }
        break;

      case logInActions.CHANGE_PASSWORD:
        rs = await LoginService.resetPassword({
          verificationCode: loginData?.verificationCode,
          password: resetPasswordData?.password
        });
        if (!rs?.error) {
          console.log('success');
          setAlertDialog({
            open: true,
            title: '',
            subTitle: t('pages.pre-order.alert.reset-password-login'),
            hideCanelButton: true,
            onOk: () => window.location.reload(),
            type: 'alert'
          });
        } else {
          handleChangePasswordError(rs.error);
        }
        break;

      case actions.LOGIN:
        if (!loginData?.email) {
          setErrMsg({
            email: '아이디를 입력해 주세요.'
          });
          dispatch(
            setPreorderError(
              error2Text({ keyError: 'PREORDER_MEMBER_PASSWORD_NOT_INSERTED' })
            )
          );
          return;
        } else if (!isEmail(loginData?.email)) {
          setErrMsg({
            email: '아이디 형식이 올바르지 않습니다.'
          });
          dispatch(
            setPreorderError(
              error2Text({ keyError: 'PREORDER_MEMBER_PASSWORD_NOT_MATCH' })
            )
          );
          return;
        } else if (!loginData?.password) {
          setErrMsg({
            ...errMsg,
            password: '비밀번호를 입력해 주세요.'
          });
          dispatch(
            setPreorderError(
              error2Text({ keyError: 'PREORDER_MEMBER_PASSWORD_NOT_INSERTED' })
            )
          );
          return;
        } else if (loginData?.password?.length < 6) {
          setErrMsg({
            ...errMsg,
            password: '비밀번호를 6자 이상 입력해주세요'
          });
          dispatch(
            setPreorderError(
              error2Text({ keyError: 'PREORDER_MEMBER_PASSWORD_NOT_MATCH' })
            )
          );
          return;
        }
        const dataSubmit = {
          email: loginData?.email,
          password: loginData?.password
        };
        const { error, data } = await LoginService.login(dataSubmit);
        if (!error) {
          dispatch(setAuthenticated(true));
          localStorage.removeItem('PREORDER_MENU_STORE');
          localStorage.removeItem('PREORDER_ORDER_ORIGINAL_PATH');
          localStorage.removeItem('PREORDER_CARD_ORIGINAL_PATH');
          localStorage.removeItem('PREORDER_NEW_ORDER_DATA');
          localStorage.setItem('PREORDER_TOKEN', data?.accessToken);
          localStorage.setItem('PREORDER_REFRESH_TOKEN', data?.refreshToken);
          if (navigator.userAgent) {
            localStorage.setItem('PREORDER_SESSION_ID', data?.sessionID);
          }

          navigate(`/pre-order/${version}/home`);
        } else {
          dispatch(setPreorderError(error2Text(error)));
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (seconds?.phone === 0) {
      setErrMsg({
        otpCode: '인증번호가 만료되었습니다. 인증번호를 재전송 해주세요.'
      });
      setVerifying({ ...isVerifying, otpCode: false });
    }
  }, [seconds?.phone]);

  useEffect(() => {
    if (location?.state?.email)
      setLoginData({ email: location?.state?.email, password: '' });
  }, []);

  useEffect(() => {
    // Reset states based on screen type
    switch (screen) {
      case loginScreens.LOGIN:
        setErrMsg({});
        setNotification({ otp: '' });
        break;

      case loginScreens.FIND_ID:
      case loginScreens.FIND_PASSWORD:
        setLoginData((prev) => ({
          ...prev,
          phone: '',
          verificationCode: '',
          otpCode: ''
        }));
        setVerifyingBox({
          otpCode: 'none',
          emailOtp: 'none'
        });
        setVerifying({
          clientName: false,
          otpCode: false
        });
        setVerified({
          client: false,
          otpCode: false
        });
        setSeconds({ email: -1, phone: -1 });
        setErrMsg({});
        setNotification({ otp: '' });
        break;

      case loginScreens.RESET_PASSWORD:
        setResetPasswordData({
          password: '',
          confirmPassword: ''
        });
        setErrMsg({});
        setNotification({ otp: '' });
        break;

      case loginScreens.FIND_ID_SUCCEESS:
        // Keep the found email data but reset other states
        setVerifyingBox({
          otpCode: 'none',
          emailOtp: 'none'
        });
        setVerifying({
          clientName: false,
          otpCode: false
        });
        setVerified({
          client: false,
          otpCode: false
        });
        setSeconds({ email: -1, phone: -1 });
        setErrMsg({});
        break;

      default:
        // Reset all states to initial values
        setLoginData({
          email: '',
          password: ''
        });
        setResetPasswordData({
          password: '',
          confirmPassword: ''
        });
        setVerifyingBox({
          otpCode: 'none',
          emailOtp: 'none'
        });
        setVerifying({
          clientName: false,
          otpCode: false
        });
        setVerified({
          client: false,
          otpCode: false
        });
        setSeconds({ email: 0, phone: 0 });
        setErrMsg({});
        setNotification({ otp: '' });
        break;
    }
  }, [screen]);
  return (
    <Container
      sx={{
        bgcolor: '#FFFFFF',
        width: '100%',
        height: '100%',
        maxHeight: '-webkit-fill-available',
        maxWidth: '500px !important',
        display: 'flex',
        flexDirection: 'column',
        px: 2.5,
        py: 2.5,
        pb: '30px'
      }}
    >
      {/* Header and progress bar */}
      <Stack
        sx={{
          width: '100%',
          height: 'auto',
          haight: '50px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}
      >
        <Typography
          sx={{
            color: '#9A9CA4 !important',
            fontSize: 14,
            fontWeight: 500,

            mr: '10px',
            letterSpacing: '-0.56px'
          }}
          onClick={handleCancel} // Attach handleCancel to Cancel button
        >
          취소
        </Typography>
      </Stack>

      {/* Add other screen components here */}
      {screen !== loginScreens.LOGIN && (
        <Finder
          data={loginData}
          onAction={handleApiAction}
          verifyingBox={verifyingBox}
          handleChange={
            screen === loginScreens.RESET_PASSWORD
              ? handleChangePasswordReset
              : handleChangeVerification
          }
          isVerifying={isVerifying}
          isVerified={isVerified}
          seconds={seconds}
          setSeconds={setSeconds}
          errMsg={errMsg}
          screen={screen}
          notification={notification}
          t={t}
          passwordData={resetPasswordData}
        />
      )}

      {screen === loginScreens.LOGIN && (
        <LoginForm
          data={loginData}
          onAction={handleApiAction}
          handleChange={handleChangeRegistration}
          errMsg={errMsg}
          t={t}
        />
      )}

      <Stack sx={{ width: '100%', gap: 2.5 }}>
        <Stack
          sx={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            gap: 2,
            display: screen === loginScreens.RESET_PASSWORD && 'none'
          }}
        >
          {screen !== loginScreens.FIND_ID &&
            screen !== loginScreens.FIND_ID_SUCCEESS &&
            ![loginScreens.FIND_PASSWORD, loginScreens.RESET_PASSWORD].includes(
              screen
            ) && (
              <Typography
                sx={{
                  color: '#9A9CA4',
                  fontSize: 14,
                  letterSpacing: '-0.56px',
                  fontWeight: 500
                }}
                onClick={() => setScreen(loginScreens.FIND_ID)}
              >
                아이디 찾기
              </Typography>
            )}
          {screen === loginScreens.LOGIN && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2"
              height="18"
              viewBox="0 0 2 18"
              fill="none"
            >
              <path d="M1 0L1 18" stroke="#9A9CA4" />
            </svg>
          )}
          {[loginScreens.LOGIN, loginScreens.FIND_ID_SUCCEESS].includes(
            screen
          ) && (
            <Typography
              sx={{
                color: '#9A9CA4',
                fontSize: 14,
                letterSpacing: '-0.56px',
                fontWeight: 800,

                lineHeight: 1.5
              }}
              onClick={() => setScreen(loginScreens.FIND_PASSWORD)}
            >
              비밀번호 찾기
            </Typography>
          )}
        </Stack>
        <Button
          sx={{
            width: '100%',
            backgroundColor: isButtonDisabled() ? '#DADBDD' : '#1B2872',
            ':hover': {
              backgroundColor: isButtonDisabled() ? '#DADBDD' : '#1B2872',
              cursor: isButtonDisabled() ? 'not-allowed' : 'pointer',
              borderRadius: 1,
              height: 48,
              color: '#FFFFFF !important'
            },
            maxWidth: 'sm',
            borderRadius: 1,

            height: 48,
            color: '#FFFFFF !important',
            fontSize: 16,
            fontWeight: 500,
            letterSpacing: '-0.64px'
          }}
          disabled={isButtonDisabled()}
          onClick={handleNextScreen}
        >
          {BUTTON_LABEL[screen]}
        </Button>
      </Stack>

      <AlertDialog
        isAlert
        open={alertDialog.open}
        setOpen={(open) => setAlertDialog({ ...alertDialog, open: false })}
        onOk={
          alertDialog?.type === 'alert'
            ? () => {
                setAlertDialog({ ...alertDialog, open: false });
                if (alertDialog?.onOk) alertDialog.onOk();
              }
            : alertDialog.onOk
        }
        okTitle={alertDialog?.okTitle || '확인'}
        title={alertDialog.title}
        subTitle={
          (
            <Typography
              sx={{
                fontSize: 14,
                color: '#202020',
                fontWeight: 500,
                letterSpacing: '-0.56px',
                whiteSpace: 'pre-line',
                textAlign: 'center',
                mt: 1.5
              }}
            >
              {' '}
              {alertDialog?.subTitle}
            </Typography>
          ) || 'Are you sure you want to cancel the sign-up process?'
        }
        hasCancelButton={!alertDialog?.hideCanelButton}
      />
    </Container>
  );
};
