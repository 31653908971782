/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { env } from '@constants/index';

class PreOrderApi {
  constructor() {
    this.instance = axios.create();

    this.instance.interceptors.request.use((config) => {
      const token = localStorage.getItem('PREORDER_ADMIN_TOKEN');
      const sessionID = localStorage.getItem('PREORDER_ADMIN_SESSION_ID');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        if (navigator?.userAgent && sessionID) {
          config.headers.SessionID = sessionID;
        }
      }
      return config;
    });

    this.instance.interceptors.response.use(
      (response) => {
        if (response.status >= 300 && response.status < 400) {
          const redirectUrl = response.headers.location;
          if (redirectUrl) {
            console.log('Redirecting to:');
            return this.instance.get(redirectUrl, response.config);
          }
        }
        return response;
      },
      async (error) => {
        console.log('Request failed:', error.config);
        console.log('Response error:', error.response);

        const originalRequest = error.config;

        // Handle invalid session scenario
        if (error?.response?.data?.keyError === 'SESSION_INVALID') {
          localStorage.removeItem('PREORDER_ADMIN_REFRESH_TOKEN');
          localStorage.removeItem('PREORDER_ADMIN_SESSION_ID');
          localStorage.removeItem('PREORDER_ADMIN_TOKEN');

          window.location.reload();
          return false;
        }

        // Handle token expiration and retry original request
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          try {
            const refreshToken = localStorage.getItem(
              'PREORDER_ADMIN_REFRESH_TOKEN'
            );
            localStorage.removeItem('PREORDER_ADMIN_TOKEN');
            let res = null;
            if (refreshToken) {
              res = await this.instance.post(
                `${env.baseUrl}/pre-order/store/auth/refresh-token`,
                { refreshToken }
              );
            } else {
              return this.instance(originalRequest);
            }

            if (res.status === 200) {
              const { accessToken, refreshToken: newRefreshToken } = res.data;
              localStorage.setItem('PREORDER_ADMIN_TOKEN', accessToken);
              localStorage.setItem(
                'PREORDER_ADMIN_REFRESH_TOKEN',
                newRefreshToken
              );
            }

            return this.instance(originalRequest);
          } catch (_error) {
            localStorage.removeItem('PREORDER_ADMIN_REFRESH_TOKEN');

            window.location.reload();
            return Promise.reject(_error);
          }
        }
        return Promise.reject(error);
      }
    );

    this.path = '';
    this.config = {
      baseURL: env.baseUrl,
      headers: {
        Authorization: '',
        'Content-Type': 'application/json'
      }
    };
  }

  setPath(path) {
    this.path = path;
    return this;
  }

  setHeaders(options) {
    this.config.headers = { ...this.config.headers, ...options };
    return this;
  }

  setConfig(options) {
    this.config = { ...this.config, ...options };
    return this;
  }

  setResponseType(type) {
    this.config.responseType = type;
    return this;
  }

  setAuthHeader(token) {
    if (token) {
      this.config.headers.Authorization = `Bearer ${token}`;
    }
    return this;
  }

  get() {
    return this.instance.get(this.path, this.config);
  }

  post(data = {}) {
    return this.instance.post(this.path, data, this.config);
  }

  put(data = {}) {
    return this.instance.put(this.path, data, this.config);
  }

  delete() {
    return this.instance.delete(this.path, this.config);
  }

  patch(data = {}) {
    return this.instance.patch(this.path, data, this.config);
  }

  setSignal(controller) {
    this.config = { ...this.config, signal: controller.signal };
    return this;
  }
}
export default PreOrderApi;
