/* eslint-disable react/no-array-index-key */
import { Stack, Typography } from '@mui/material';
import orderService from '@services/preorder/order';
import React, { useEffect, useRef, useState } from 'react';
import { BOTTOM_NAVS } from '@constants/utils';
import { setActiveNav } from '@store/reducers/pre-order/app';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { formatMoney, ignoreEmpty } from '@utils/helpers';
import env from '@constants/env';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Categories from './components/OrderCaegories';
import { orderStatuses, orderStatusStyles, statusParser } from './enhance';

const OrderList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { version } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const [params, setParams] = useState({
    page: 0,
    totalRow: -1,
    size: 20,
    totalPage: 0
  });

  const [status, setStatus] = useState({
    value: 'all',
    label: t('common.total')
  });
  const [loadMore, setLoadMore] = useState(false);
  const [endLoad, setEndLoad] = useState(true);
  const [orderList, setOrderList] = useState([]);
  const { activeNav } = useSelector((state) => state.preOrder.app);
  const onLoadMore = async () => {
    if (!endLoad && orderList?.length > 19) {
      setLoadMore(true);
      const additionalPayload = {
        orderStatus: status?.value === 'all' ? null : status?.value
      };

      const { data } = await orderService.getOrderList(
        ignoreEmpty({ page: params.page + 1, ...additionalPayload })
      );
      setParams({ ...params, page: params.page + 1 });
      setEndLoad(!data?.items?.length);
      if (data?.items && data?.items?.length > 0) {
        setOrderList(orderList.concat(data?.items));
      }
      setLoadMore(false);
    }
    if (orderList?.length < 20) {
      setEndLoad(true);
      setLoadMore(false);
    }
    return true;
  };

  const observer = useRef();
  const lastOptionElementRef = (node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && onLoadMore) {
        setTimeout(() => {
          onLoadMore();
        }, 300);
      }
    });
    if (node) observer.current.observe(node);
  };
  const fetchOrtderList = async (param) => {
    try {
      const rs = await orderService.getOrderList(ignoreEmpty(param));

      if (!rs.error) {
        setOrderList(rs?.data?.items || []);
        setParams({
          ...params,
          page: rs?.data?.page,
          totalRow: rs?.data?.totalRow || 0,
          totalPage: rs?.data?.totalPage
        });
      }
    } catch (error) {
      console.error('Error fetching store data:', error);
    }
  };
  useEffect(() => {
    if (activeNav !== BOTTOM_NAVS.ORDER_LIST)
      dispatch(setActiveNav(BOTTOM_NAVS.ORDER_LIST));
    const dataSubmit = {
      size: params?.size,
      status: params?.status === 'all' ? null : params?.status
    };
    fetchOrtderList(dataSubmit);
  }, [location?.search, dispatch, navigate]);

  const onChangeStatus = (item) => {
    setStatus(item);
    const dataSubmit = {
      size: params?.size,
      status: item?.value === 'all' ? null : item?.value
    };
    fetchOrtderList(dataSubmit);
  };

  return (
    <Stack
      sx={{
        flexDirection: 'column',
        width: '100%',
        maxWidth: '500px',
        flexGrow: 1,

        position: 'relative'
      }}
    >
      <Stack
        sx={{
          width: '100%',
          maxWidth: '500px',
          top: 0,
          position: 'fixed',
          backgroundColor: '#FFFFFF',
          zIndex: 1002
        }}
      >
        <Stack
          id="navBarHeader"
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            backgroundColor: '#FFFFFF',
            width: '100%',
            py: '13px',
            px: 2.5,
            height: { xs: '50px' }
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: 1
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 700,
                color: '#202020',
                letterSpacing: '-0.56px'
              }}
            >
              주문내역
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Categories
        statusList={orderStatuses}
        currentStatus={status}
        onChange={onChangeStatus}
      />
      <Stack
        sx={{
          width: '100%',
          height: '100%',
          flexGrow: 1,

          gap: 2.5,
          mb: 2.5,

          backgroundColor: '#FFFFFF'
        }}
      >
        <Stack sx={{ width: '100%', alignItems: 'flex-end', px: 2.5 }}>
          {params?.totalRow > -1 && (
            <Typography
              sx={{
                color: '#202020',
                fontSize: 14,
                fontWeight: 500,
                textAlign: 'right',
                minWidth: '66px',
                letterSpacing: '-0.56px'
              }}
            >
              검색결과 {params?.totalRow || 0}
            </Typography>
          )}
        </Stack>

        <Stack sx={{ width: '100%', flexGrow: 1, mb: '50px' }}>
          {orderList.map((item, index) => {
            const isLastOption = index + 1 === orderList.length;
            return (
              <>
                {' '}
                <Stack
                  sx={{
                    width: '100%',
                    backgroundColor: index % 2 === 0 ? '#F6F6F6' : '#FFFFFF',
                    py: 2,
                    px: 2.5
                  }}
                  onClick={() =>
                    navigate(`/pre-order/${version}/orders/${item?.orderCode}`)
                  }
                  key={`${index}`}
                  ref={isLastOption ? lastOptionElementRef : null}
                >
                  <Stack
                    sx={{
                      width: '100%',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 1
                    }}
                  >
                    <Stack
                      sx={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        flexGrow: 1,
                        gap: 0.5
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#202020',
                          fontSize: 14,
                          letterSpacing: '-0.56px',
                          fontWeight: 500,
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 1
                        }}
                      >
                        {item?.productName}{' '}
                      </Typography>{' '}
                      <Typography
                        sx={{
                          color: '#202020',
                          fontSize: 14,
                          letterSpacing: '-0.56px',
                          fontWeight: 500,
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 1,
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {item?.others > 0 && `외 ${item?.others}개`}
                      </Typography>{' '}
                    </Stack>

                    <Stack sx={{ flexDirection: 'row', gap: 1 }}>
                      {' '}
                      <Typography
                        sx={{
                          color: '#202020',
                          fontSize: 14,
                          letterSpacing: '-0.56px',
                          fontWeight: 500,
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {formatMoney(item?.totalPrice)}원
                      </Typography>
                      <Stack
                        sx={{
                          backgroundColor:
                            orderStatusStyles?.[item?.status]?.backgroundColor,
                          borderRadius: '10px',
                          minWidth: '57px',
                          textAlign: 'center',
                          height: '20px',
                          alignItems: 'center',
                          justifyContent: 'center',
                          p: '4px 8px',
                          border: orderStatusStyles?.[item?.status]?.border
                        }}
                        onClick={(e) => {
                          if (env.devEnv === 'development') {
                            e?.stopPropagation();
                            navigate(
                              `/pre-order/ko/store-owner/${item?.orderCode}/orders/${item?.orderCode}`
                            );
                          }
                        }}
                      >
                        {' '}
                        <Typography
                          sx={{
                            fontSize: 10,
                            color: orderStatusStyles?.[item?.status]?.color,
                            fontWeight: 500,
                            letterSpacing: '-0.4px',
                            textAlign: 'center'
                          }}
                        >
                          {statusParser[item?.status] || item?.status}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
                {isLastOption && !endLoad && (
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    visibility={loadMore && 'visible'}
                    key={`${item?.orderCode}---${index}`}
                  >
                    <span
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                        letterSpacing: '1px',
                        margin: '0 5px'
                      }}
                    >
                      Loading...
                    </span>
                  </Stack>
                )}
              </>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default OrderList;
